import { useEffect, useState, useRef, RefObject } from "react";
import { useApp } from "../context/AppContext";

export default function useOnScreen(
  ref: RefObject<HTMLElement>,
  threshold = 0.5
) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting);
        if (entry.isIntersecting) {
          observerRef?.current?.disconnect();
        }
      },
      { threshold: threshold }
    );
  }, []);

  useEffect(() => {
    if (ref.current) observerRef?.current?.observe(ref.current);

    return () => {
      if (ref.current) observerRef?.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export function useOnScreenV2(ref: HTMLElement | null) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const { tabRefs } = useApp();

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting);
        // if (entry.isIntersecting) {
        //   observerRef?.current?.disconnect();
        // }
      },
      { threshold: 0.8 }
    );
  }, []);

  useEffect(() => {
    if (ref) observerRef?.current?.observe(ref);
  }, [ref, tabRefs]);

  return isOnScreen;
}
