import React from 'react';
import LayoutStyles from '../../App.styles';
import { Blog } from '../../context/AppContext';
import { formatDate } from '../../utils/data';
import { useTheme, useMediaQuery } from '@mui/material';

const SmallBlogCard: React.FC<{data: Blog}> = ({data}) => {
    const { BlogCards } = LayoutStyles;
    const theme = useTheme();
    const shortenName = useMediaQuery(theme.breakpoints.between(theme.breakpoints.values.sm, 1000));
    const formatName = (name: string) => {
        if (name.length > 80) {
            return name.slice(0, 80) + '...'
        } else return name
    }
    return(
        <BlogCards.SmallCard>
            <BlogCards.SmallImg src={data.featuredImage} alt={data.featuredImageAltText} />
            <BlogCards.SmallInfo>
                <BlogCards.SmallHeadline>{shortenName ? formatName(data.name) : data.name}</BlogCards.SmallHeadline>
                <LayoutStyles.BlogSwiper.Date>{formatDate(data.publishDate)}</LayoutStyles.BlogSwiper.Date>
            </BlogCards.SmallInfo>
        </BlogCards.SmallCard>
    )
}

export default SmallBlogCard;