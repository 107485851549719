import React, { useRef, useState, useEffect } from "react";
import LayoutStyles from "../App.styles";
import VideoButton from "../Common/Button/VideoButton";
import ReactPlayer from "react-player";
import { useTheme, useMediaQuery } from "@mui/material";
import SlideButton from "../Common/Button/SlideButton";

const VideoBlock: React.FC<{
  bgColor: string;
  video?: string;
  headline?: string;
  subtitle?: string;
}> = ({ bgColor, video, headline, subtitle }) => {
  const { HeroImage, VideoBlock } = LayoutStyles;
  const videoRef = useRef<ReactPlayer | null>(null);
  const [playing, setPlaying] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );


  return (
    <HeroImage.Container style={{ padding: isMediumScreen ? "0" : "0 75px" }}>
      <VideoBlock.Headline>{headline}</VideoBlock.Headline>
      <VideoBlock.Subtitle>{subtitle}</VideoBlock.Subtitle>
      <div style={{ display: 'flex', justifyContent: 'center', width: '225px', marginBottom: '65px'}}>
      <SlideButton blackButton text="Learn more" path="/metaverse" />
      </div>
      <LayoutStyles.FlexboxRow
        $padding={isSmallScreen ? "0" : "0 25px"}
        $flexDirection="column"
        $width="100%"
      >
        <div
          
          style={{
            pointerEvents: "auto",
            borderRadius: isSmallScreen ? "0" : "21px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
            paddingTop: `${9 / 16 * 100}%`,
            zIndex: '3',
            height: '100%',
            maxWidth: '1300px'
          }}
        >
          {!playing && (
            <div
            onClick={() => {
                if (!playing) setPlaying(true)
              }}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.42",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                borderRadius: "12px",
                cursor: "pointer",
              }}
            >
              <VideoButton text="See how it works" clickFunction={() => {}} />
            </div>
          )}
          <ReactPlayer
            controls
            className="react-player"
            ref={videoRef}
            width="100%"
            height="100%"
            onStart={() => setPlaying(true)}
            onEnded={() => setPlaying(false)}
            onPause={() => setPlaying(false)}
            style={{ backgroundColor: theme.palette.neutral.light }}
            playing={playing}
            playIcon={<VideoButton text="See how it works" />}
            url={"https://player.vimeo.com/video/810937213?h=24efd4a4db"}
          />
        </div>
        <LayoutStyles.VideoBlock.ColorBlock $bgColor={"#000000"} />
      </LayoutStyles.FlexboxRow>
    </HeroImage.Container>
  );
};

export default VideoBlock;
