import { padding } from "@mui/system";

const Tag = ({ text, center = false }: { text: string; center?: boolean }) => {
  return (
    <div
      style={{
        backgroundColor: "#151418",
        height: 40,
        width: "fit-content",
        borderRadius: 24,
        padding: "10px 24px",
        color: "white",
        margin: center ? "0 auto 40px auto" : undefined,
      }}
    >
      {text}
    </div>
  );
};

export default Tag;
