import React, { useState, useRef} from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import LayoutStyles from "../App.styles";
import ImageText from "../PageBlocks/ImageText";
import HeroImage from "../PageBlocks/HeroImage";
import TextFirstHero from "../PageBlocks/TextFirstHero";
import DualTxtImg from "../PageBlocks/DualTxtImg";
import dataJson from "../data.json";
import Avatars from "../PageBlocks/Avatars";
import ReactPlayer from "react-player";
import VideoButton from "../Common/Button/VideoButton";

// Images
import Tablet from "../assets/images/SalesEnablement/tablet.png";
import Visualization from "../assets/images/SalesEnablement/product_visual.png";
import Meetings from "../assets/images/SalesEnablement/meetings.png";
import AugReality from "../assets/images/SalesEnablement/aug-reality.png";
import SalesMgmtAnim from "../assets/animations/metaverse/customize/customize";


const Metaverse: React.FC = () => {
  const theme = useTheme();
  const videoRef = useRef<ReactPlayer | null>(null);
  const [playing, setPlaying] = useState(false);
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  return (
    <>
      <TextFirstHero textFirst data={dataJson.metaverse.hero} />
      <LayoutStyles.FlexboxRow
        $padding={isSmallScreen ? "0" : "0 85px"}
        $margin="45px 0 0 0"
        $flexDirection="column"
        $width="100%"
        style={{ maxWidth: '1300px', width: isSmallScreen ? '100%' : '85%', position: 'relative'}}
      >
        <div
          
          style={{
            pointerEvents: "auto",
            borderRadius: isSmallScreen ? "0" : "21px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
            paddingTop: `${9 / 16 * 100}%`,
            zIndex: '3',
            height: '100%',
            maxWidth: '1300px'
          }}
        >
          {!playing && (
            <div
            onClick={() => {
                if (!playing) setPlaying(true)
              }}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.42",
                display: "flex",
                bottom: '0',
                left: '0',
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                borderRadius: isSmallScreen ? "0" : "21px",
                cursor: "pointer",
                zIndex: "3"
              }}
            >
              <VideoButton text="See how it works" clickFunction={() => {}} />
            </div>
          )}
          <ReactPlayer
            controls
            loop
            autoPlay  
            muted
            className="react-player"
            ref={videoRef}
            width="100%"
            height="100%"
            onStart={() => setPlaying(true)}
            onEnded={() => setPlaying(false)}
            onPause={() => setPlaying(false)}
            style={{ backgroundColor: theme.palette.neutral.light }}
            playing={playing}
            playIcon={<VideoButton text="See how it works" />}
            url={"https://player.vimeo.com/video/812799356?h=98df1bd73f"}
          />
        </div>
        <LayoutStyles.VideoBlock.ColorBlock style={{zIndex: '1'}} $bgColor={"#000000"} />
      </LayoutStyles.FlexboxRow>
      <LayoutStyles.SectionContainer
        id="sales-innovation"
        $backgroundColor={theme.palette.neutral.black}
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
      >
        <ImageText
          image={Tablet}
          buttonType="animated"
          sectionID="sales-innovation"
          padding={!isMediumScreen ? "0 75px" : "0"}
          alignItems={"center"}
          color={theme.palette.primary.main}
          removeTxtPadding
          enableMobileMaxWidth
          imageSize={[isMediumScreen ? "auto" : 751, "100%"]}
          height={"auto"}
          hideButton={false}
          data={dataJson.metaverse.sales_innovation}
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        id="ar"
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
        $backgroundColor={theme.palette.neutral.light}
      >
        <ImageText
          data={dataJson.metaverse.aug_reality}
          padding={!isMediumScreen ? "0 75px" : "0"}
          hideButton
          sectionID="ar"
          alignItems={"center"}
          imageSize={[isMediumScreen ? "auto" : 751, "100%"]}
          image={AugReality}
          reverse
          color={"#F9F9F9"}
          height={"100%"}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        id="avatars"
        $padding={
          isSmallScreen ? "60px 0 100px" : "50px 0 110px"
        }
        $backgroundColor="radial-gradient(circle, #4e4e4e, #000000 25%)"
      >
      <Avatars />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        id="product-visualization"
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
      >
        <ImageText
          data={dataJson.metaverse.visualization}
          height={"100%"}
          padding={!isMediumScreen ? "0 75px" : "0"}
          sectionID="product-visualization"
          hideButton={false}
          videoButton
          color={theme.palette.primary.main}
          alignItems={"center"}
          image={Visualization}
          imageSize={[isMediumScreen ? "auto" : 751, "100%"]}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      
      <LayoutStyles.SectionContainer
        id="content-management"
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
        $backgroundColor="#F4F7FF"
      >
        <ImageText
          data={dataJson.metaverse.content_mgmt}
          padding={!isMediumScreen ? "0 75px" : "0"}
          height={"100%"}
          sectionID="content-management"
          hideButton={false}
          alignItems={isMediumScreen ? "flex-start" : "center"}
          lottieAnimation={SalesMgmtAnim}
          imageSize={[isMediumScreen ? "auto" : 816, "100%"]}
          color={theme.palette.primary.main}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        id="virtual-meetings"
        $padding={
          isSmallScreen ? "100px 0" : isMediumScreen ? "150px 0" : "201px 0"
        }
      >
        <ImageText
          data={dataJson.metaverse.meetings}
          padding={!isMediumScreen ? "0 75px" : "0"}
          height={"100%"}
          sectionID="meetings"
          hideButton={false}
          alignItems={"center"}
          image={Meetings}
          imageSize={[isMediumScreen ? "auto" : 816, "100%"]}
          color={theme.palette.primary.main}
          altColor={false}
          reverse
        />
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default Metaverse;
