import img_0 from "./images/img_0.jpg";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";
import img_7 from "./images/img_7.png";
import img_8 from "./images/img_8.png";
import img_9 from "./images/img_9.png";
import img_10 from "./images/img_10.png";
import img_11 from "./images/img_11.png";
import img_12 from "./images/img_12.png";
import img_13 from "./images/img_13.png";
import img_14 from "./images/img_14.png";
import img_15 from "./images/img_15.png";
import img_16 from "./images/img_16.png";
import img_17 from "./images/img_17.png";
import img_18 from "./images/img_18.jpg";
import img_19 from "./images/img_19.png";
import img_20 from "./images/img_20.png";
import img_21 from "./images/img_21.jpg";
import img_22 from "./images/img_22.png";
import img_23 from "./images/img_23.png";
import img_24 from "./images/img_24.jpg";
import img_25 from "./images/img_25.png";
import img_26 from "./images/img_26.png";
import img_27 from "./images/img_27.png";
import img_28 from "./images/img_28.png";
import img_29 from "./images/img_29.png";
import img_30 from "./images/img_30.png";
import img_31 from "./images/img_31.png";
import img_32 from "./images/img_32.png";
import img_33 from "./images/img_33.png";
import img_34 from "./images/img_34.png";
import img_35 from "./images/img_35.png";
import img_36 from "./images/img_36.png";
import img_37 from "./images/img_37.png";
import img_38 from "./images/img_38.png";
import img_39 from "./images/img_39.png";
import img_40 from "./images/img_40.png";


export default {
    v: "5.7.4",
    fr: 29.9700012207031,
    ip: 0,
    op: 530.000021587343,
    w: 3263,
    h: 1833,
    nm: "Quiz and Leaderboard Animation",
    ddd: 0,
    assets: [
      {
        id: "image_0",
        w: 3840,
        h: 2638,
        u: "",
        p: img_0,
        e: 0
      },
      {
        id: "image_1",
        w: 108,
        h: 144,
        u: "",
        p: img_1,
        e: 0
      },
      {
        id: "image_2",
        w: 390,
        h: 66,
        u: "",
        p: img_2,
        e: 0
      },
      {
        id: "image_3",
        w: 590,
        h: 816,
        u: "",
        p: img_3,
        e: 0
      },
      {
        id: "image_4",
        w: 468,
        h: 146,
        u: "",
        p: img_4,
        e: 0
      },
      {
        id: "image_5",
        w: 468,
        h: 146,
        u: "",
        p: img_5,
        e: 0
      },
      {
        id: "image_6",
        w: 590,
        h: 816,
        u: "",
        p: img_6,
        e: 0
      },
      {
        id: "image_7",
        w: 590,
        h: 816,
        u: "",
        p: img_7,
        e: 0
      },
      {
        id: "image_8",
        w: 1138,
        h: 270,
        u: "",
        p: img_8,
        e: 0
      },
      {
        id: "image_9",
        w: 1666,
        h: 1624,
        u: "",
        p: img_9,
        e: 0
      },
      {
        id: "image_10",
        w: 780,
        h: 156,
        u: "",
        p: img_10,
        e: 0
      },
      {
        id: "image_11",
        w: 1132,
        h: 274,
        u: "",
        p: img_11,
        e: 0
      },
      {
        id: "image_12",
        w: 1132,
        h: 274,
        u: "",
        p: img_12,
        e: 0
      },
      {
        id: "image_13",
        w: 780,
        h: 156,
        u: "",
        p: img_13,
        e: 0
      },
      {
        id: "image_14",
        w: 780,
        h: 156,
        u: "",
        p: img_14,
        e: 0
      },
      {
        id: "image_15",
        w: 1132,
        h: 274,
        u: "",
        p: img_15,
        e: 0
      },
      {
        id: "image_16",
        w: 1344,
        h: 1446,
        u: "",
        p: img_16,
        e: 0
      },
      {
        id: "image_17",
        w: 532,
        h: 128,
        u: "",
        p: img_17,
        e: 0
      },
      {
        id: "image_18",
        w: 1324,
        h: 1392,
        u: "",
        p: img_18,
        e: 0
      },
      {
        id: "image_19",
        w: 548,
        h: 280,
        u: "",
        p: img_19,
        e: 0
      },
      {
        id: "image_20",
        w: 3840,
        h: 2160,
        u: "",
        p: img_20,
        e: 0
      },
      {
        id: "image_21",
        w: 1322,
        h: 1392,
        u: "",
        p: img_21,
        e: 0
      },
      {
        id: "image_22",
        w: 290,
        h: 36,
        u: "",
        p: img_22,
        e: 0
      },
      {
        id: "image_23",
        w: 1132,
        h: 596,
        u: "",
        p: img_23,
        e: 0
      },
      {
        id: "image_24",
        w: 1054,
        h: 156,
        u: "",
        p: img_24,
        e: 0
      },
      {
        id: "image_25",
        w: 1140,
        h: 166,
        u: "",
        p: img_25,
        e: 0
      },
      {
        id: "image_26",
        w: 1650,
        h: 1305,
        u: "",
        p: img_26,
        e: 0
      },
      {
        id: "image_27",
        w: 1653,
        h: 1305,
        u: "",
        p: img_27,
        e: 0
      },
      {
        id: "image_28",
        w: 2784,
        h: 1532,
        u: "",
        p: img_28,
        e: 0
      },
      {
        id: "image_29",
        w: 2,
        h: 266,
        u: "",
        p: img_29,
        e: 0
      },
      {
        id: "image_30",
        w: 354,
        h: 472,
        u: "",
        p: img_30,
        e: 0
      },
      {
        id: "image_31",
        w: 188,
        h: 434,
        u: "",
        p: img_31,
        e: 0
      },
      {
        id: "image_32",
        w: 174,
        h: 46,
        u: "",
        p: img_32,
        e: 0
      },
      {
        id: "image_33",
        w: 174,
        h: 44,
        u: "",
        p: img_33,
        e: 0
      },
      {
        id: "image_34",
        w: 160,
        h: 44,
        u: "",
        p: img_34,
        e: 0
      },
      {
        id: "image_35",
        w: 1116,
        h: 468,
        u: "",
        p: img_35,
        e: 0
      },
      {
        id: "image_36",
        w: 156,
        h: 156,
        u: "",
        p: img_36,
        e: 0
      },
      {
        id: "image_37",
        w: 156,
        h: 156,
        u: "",
        p: img_37,
        e: 0
      },
      {
        id: "image_38",
        w: 156,
        h: 156,
        u: "",
        p: img_38,
        e: 0
      },
      {
        id: "image_39",
        w: 418,
        h: 92,
        u: "",
        p: img_39,
        e: 0
      },
      {
        id: "image_40",
        w: 3732,
        h: 2100,
        u: "",
        p: img_40,
        e: 0
      },
      {
        id: "comp_0",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 0,
            nm: "Train title",
            refId: "comp_1",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: "a",
                pt: {
                  a: 0,
                  k: {
                    i: [
                      [
                        540.521,
                        0
                      ],
                      [
                        0,
                        -540.52
                      ],
                      [
                        -540.52,
                        0
                      ],
                      [
                        0,
                        540.52
                      ]
                    ],
                    o: [
                      [
                        -540.52,
                        0
                      ],
                      [
                        0,
                        540.52
                      ],
                      [
                        540.521,
                        0
                      ],
                      [
                        0,
                        -540.52
                      ]
                    ],
                    v: [
                      [
                        1918.518,
                        102.98
                      ],
                      [
                        939.82,
                        1081.678
                      ],
                      [
                        1918.518,
                        2060.376
                      ],
                      [
                        2897.217,
                        1081.678
                      ]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 3
                },
                x: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [
                          0.833
                        ],
                        y: [
                          0.833
                        ]
                      },
                      o: {
                        x: [
                          0.927
                        ],
                        y: [
                          0.01
                        ]
                      },
                      t: 89,
                      s: [
                        1249
                      ]
                    },
                    {
                      t: 138.999538864692,
                      s: [
                        -989.9
                      ]
                    }
                  ],
                  ix: 4
                },
                nm: "Mask 1"
              }
            ],
            w: 3840,
            h: 2160,
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 0,
            nm: "Redemption overlay",
            refId: "comp_2",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 871,
                    s: [
                      100
                    ]
                  },
                  {
                    t: 885.999594681252,
                    s: [
                      2
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            w: 3840,
            h: 2160,
            ip: 395.00001608868,
            op: 6981.00028434196,
            st: -211.000008594206,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: "redeemedbtn.png",
            cl: "png",
            refId: "image_4",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 573,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 582.999683902313,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2998,
                  1379,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  234,
                  73,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 671.00002733039,
            op: 6842.00027868037,
            st: -350.000014255792,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: "redeem.png",
            cl: "png",
            refId: "image_5",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 569,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 578.999781395644,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2998,
                  1379,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  234,
                  73,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 604,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 611,
                    s: [
                      81,
                      81,
                      100
                    ]
                  },
                  {
                    t: 622.999684554984,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 569.000023175845,
            op: 6842.00027868037,
            st: -350.000014255792,
            bm: 0
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: "Shape Layer 11",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 0,
                      k: [
                        521.295,
                        203.567
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        0.996078012504,
                        1,
                        0.901961023667,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        1079.008,
                        302.833
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 593.000024153385,
            op: 671.00002733039,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 6,
            ty: 2,
            nm: "Group 18.png",
            cl: "png",
            refId: "image_6",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.007
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 512,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 542.000369732377,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 512,
                    s: [
                      3000,
                      1296,
                      0
                    ],
                    to: [
                      0,
                      -28,
                      0
                    ],
                    ti: [
                      0,
                      28,
                      0
                    ]
                  },
                  {
                    t: 542.000369732377,
                    s: [
                      3000,
                      1128,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  295,
                  408,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 512.000020854187,
            op: 6989.00028466781,
            st: -203.000008268359,
            bm: 0
          },
          {
            ddd: 0,
            ind: 7,
            ty: 2,
            nm: "Group 17.png",
            cl: "png",
            refId: "image_7",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        0.995
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 504,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 534.00036940653,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 504,
                    s: [
                      2400,
                      1296,
                      0
                    ],
                    to: [
                      0,
                      -28,
                      0
                    ],
                    ti: [
                      0,
                      28,
                      0
                    ]
                  },
                  {
                    t: 534.00036940653,
                    s: [
                      2400,
                      1128,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  295,
                  408,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 504.000020528341,
            op: 6981.00028434196,
            st: -211.000008594206,
            bm: 0
          },
          {
            ddd: 0,
            ind: 8,
            ty: 2,
            nm: "Group 16.png",
            cl: "png",
            refId: "image_8",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        0.996
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 494,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 523.999637553879,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 494,
                    s: [
                      2690,
                      682,
                      0
                    ],
                    to: [
                      0,
                      -35,
                      0
                    ],
                    ti: [
                      0,
                      35,
                      0
                    ]
                  },
                  {
                    t: 523.999637553879,
                    s: [
                      2690,
                      472,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  569,
                  135,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 494.000020121032,
            op: 6981.00028434196,
            st: -211.000008594206,
            bm: 0
          },
          {
            ddd: 0,
            ind: 9,
            ty: 2,
            nm: "UI Overlay BG Copy 2.png",
            cl: "png",
            refId: "image_9",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.001
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 489,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 518.999759420543,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 489,
                    s: [
                      2586,
                      1153.5,
                      0
                    ],
                    to: [
                      22.667,
                      -22.667,
                      0
                    ],
                    ti: [
                      -22.667,
                      22.667,
                      0
                    ]
                  },
                  {
                    t: 518.999759420543,
                    s: [
                      2722,
                      1017.5,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  833,
                  812,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100.72,
                  105.788,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 489.000019917378,
            op: 6981.00028434196,
            st: -211.000008594206,
            bm: 0
          },
          {
            ddd: 0,
            ind: 10,
            ty: 0,
            nm: "2nd",
            refId: "comp_3",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667
                      ],
                      y: [
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 433,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 463.000262022449,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 433,
                    s: [
                      1239.729,
                      1318,
                      0
                    ],
                    to: [
                      0,
                      -19.333,
                      0
                    ],
                    ti: [
                      0,
                      19.333,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 0.667
                    },
                    o: {
                      x: 0,
                      y: 0
                    },
                    t: 463,
                    s: [
                      1239.729,
                      1202,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 478,
                    s: [
                      1239.729,
                      1202,
                      0
                    ],
                    to: [
                      0,
                      -55.333,
                      0
                    ],
                    ti: [
                      0,
                      55.333,
                      0
                    ]
                  },
                  {
                    t: 491.999555195802,
                    s: [
                      1239.729,
                      870,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            w: 1132,
            h: 274,
            ip: 433.000017636452,
            op: 1426.00005808217,
            st: 433.000017636452,
            bm: 0
          },
          {
            ddd: 0,
            ind: 11,
            ty: 0,
            nm: "1st",
            refId: "comp_4",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.003
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 425,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 455.000261696602,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 425,
                    s: [
                      1239.729,
                      986,
                      0
                    ],
                    to: [
                      0,
                      -19.333,
                      0
                    ],
                    ti: [
                      0,
                      19.333,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 0.667
                    },
                    o: {
                      x: 0,
                      y: 0
                    },
                    t: 455,
                    s: [
                      1239.729,
                      870,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 478,
                    s: [
                      1239.729,
                      870,
                      0
                    ],
                    to: [
                      0,
                      55.333,
                      0
                    ],
                    ti: [
                      0,
                      -55.333,
                      0
                    ]
                  },
                  {
                    t: 492.000335469271,
                    s: [
                      1239.729,
                      1202,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            w: 1132,
            h: 274,
            ip: 425.000017310605,
            op: 1418.00005775632,
            st: 425.000017310605,
            bm: 0
          },
          {
            ddd: 0,
            ind: 12,
            ty: 0,
            nm: "3rd",
            refId: "comp_5",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        0.999
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 441,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 471.000262348296,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 441,
                    s: [
                      1239.729,
                      1650,
                      0
                    ],
                    to: [
                      0,
                      -19.333,
                      0
                    ],
                    ti: [
                      0,
                      19.333,
                      0
                    ]
                  },
                  {
                    t: 471.000262348296,
                    s: [
                      1239.729,
                      1534,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            w: 1132,
            h: 274,
            ip: 441.000017962298,
            op: 1434.00005840802,
            st: 441.000017962298,
            bm: 0
          },
          {
            ddd: 0,
            ind: 13,
            ty: 0,
            nm: "Leaderboard Initial",
            refId: "comp_6",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: "a",
                pt: {
                  a: 0,
                  k: {
                    i: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    o: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    v: [
                      [
                        2175.458,
                        250
                      ],
                      [
                        328,
                        250
                      ],
                      [
                        328,
                        1774.81
                      ],
                      [
                        2175.458,
                        1774.81
                      ]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 3
                },
                x: {
                  a: 0,
                  k: 0,
                  ix: 4
                },
                nm: "Mask 1"
              }
            ],
            w: 3840,
            h: 2160,
            ip: 410.000016699642,
            op: 6987.00028458634,
            st: -205.000008349821,
            bm: 0
          },
          {
            ddd: 0,
            ind: 14,
            ty: 2,
            nm: "Great job!.png",
            cl: "png",
            refId: "image_17",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.001
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 381,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 406.000414974235,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 381,
                    s: [
                      2546.034,
                      1201,
                      0
                    ],
                    to: [
                      0,
                      -6.167,
                      0
                    ],
                    ti: [
                      0,
                      6.167,
                      0
                    ]
                  },
                  {
                    t: 406.000414974235,
                    s: [
                      2546.034,
                      1164,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  266,
                  64,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 381.000015518448,
            op: 5722.00023306184,
            st: -257.000010467825,
            bm: 0
          },
          {
            ddd: 0,
            ind: 15,
            ty: 4,
            nm: "Shape Layer 10",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2543.617,
                  936.62,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  330,
                  32,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  120.777,
                  131,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        o: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        v: [
                          [
                            257.116,
                            32.52
                          ],
                          [
                            311.886,
                            77.021
                          ],
                          [
                            406.887,
                            -20.789
                          ]
                        ],
                        c: false
                      },
                      ix: 2
                    },
                    nm: "Path 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 22,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Shape 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              },
              {
                ty: "tm",
                s: {
                  a: 0,
                  k: 0,
                  ix: 1
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [
                          0
                        ],
                        y: [
                          1.002
                        ]
                      },
                      o: {
                        x: [
                          0.167
                        ],
                        y: [
                          0.167
                        ]
                      },
                      t: 381,
                      s: [
                        0
                      ]
                    },
                    {
                      t: 406.000246028916,
                      s: [
                        100
                      ]
                    }
                  ],
                  ix: 2
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3
                },
                m: 1,
                ix: 2,
                nm: "Trim Paths 1",
                mn: "ADBE Vector Filter - Trim",
                hd: false
              }
            ],
            ip: 381.000015518448,
            op: 2785.00011343538,
            st: -184.000007494474,
            bm: 0
          },
          {
            ddd: 0,
            ind: 16,
            ty: 2,
            nm: "greenbox.png",
            cl: "png",
            refId: "image_18",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2567.811,
                  1774.754,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  662,
                  1391.758,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.491,
                        0,
                        0.667
                      ],
                      y: [
                        1.142,
                        1.008,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0,
                        0.167,
                        0
                      ]
                    },
                    t: 355,
                    s: [
                      100.529,
                      0,
                      100
                    ]
                  },
                  {
                    t: 385.000488337641,
                    s: [
                      100.529,
                      99.856,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 355.000014459446,
            op: 5897.00024018973,
            st: -235.000009571746,
            bm: 0
          },
          {
            ddd: 0,
            ind: 17,
            ty: 4,
            nm: "Shape Layer 9",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2279,
                  1094,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  330,
                  32,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  79.289,
                  86,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        o: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        v: [
                          [
                            257.116,
                            32.52
                          ],
                          [
                            311.886,
                            77.021
                          ],
                          [
                            406.887,
                            -20.789
                          ]
                        ],
                        c: false
                      },
                      ix: 2
                    },
                    nm: "Path 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        0.996078431373,
                        1,
                        0.901960784314,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 22,
                      ix: 5
                    },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Shape 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              },
              {
                ty: "tm",
                s: {
                  a: 0,
                  k: 0,
                  ix: 1
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [
                          0
                        ],
                        y: [
                          1.002
                        ]
                      },
                      o: {
                        x: [
                          0.167
                        ],
                        y: [
                          0.167
                        ]
                      },
                      t: 460,
                      s: [
                        0
                      ]
                    },
                    {
                      t: 485.000249246652,
                      s: [
                        100
                      ]
                    }
                  ],
                  ix: 2
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3
                },
                m: 1,
                ix: 2,
                nm: "Trim Paths 1",
                mn: "ADBE Vector Filter - Trim",
                hd: false
              }
            ],
            ip: 460.000018736184,
            op: 2864.00011665311,
            st: -105.000004276738,
            bm: 0
          },
          {
            ddd: 0,
            ind: 18,
            ty: 2,
            nm: "cursor-quiz.png",
            cl: "png",
            refId: "image_1",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 301.698,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 306.443698028582,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 273,
                    s: [
                      1854.96,
                      2243.867,
                      0
                    ],
                    to: [
                      65.104,
                      -193.858,
                      0
                    ],
                    ti: [
                      -65.104,
                      193.858,
                      0
                    ]
                  },
                  {
                    t: 343.999855808276,
                    s: [
                      2245.587,
                      1080.718,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  19,
                  20,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 343,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 349,
                    s: [
                      47,
                      47,
                      100
                    ]
                  },
                  {
                    t: 360.00044142093,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 273.000011119518,
            op: 7050.00028715239,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 19,
            ty: 2,
            nm: "correct.png",
            cl: "png",
            refId: "image_19",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 346,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 351.999951837251,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2279.66,
                  1087.423,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  274,
                  140,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 346.000014092869,
            op: 6982.00028438269,
            st: -210.000008553475,
            bm: 0
          },
          {
            ddd: 0,
            ind: 20,
            ty: 0,
            nm: "New3DQuizItem",
            refId: "comp_7",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.001
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 273,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 297.000025768934,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1240.392,
                  1081.25,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  661,
                  696,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100.076,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            w: 1322,
            h: 1392,
            ip: 273.000011119518,
            op: 7050.00028715239,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 21,
            ty: 2,
            nm: "Question 4 of 15.png",
            cl: "png",
            refId: "image_22",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.008
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 273,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 307.999757662274,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 273,
                    s: [
                      2570.049,
                      1725,
                      0
                    ],
                    to: [
                      0,
                      -9.5,
                      0
                    ],
                    ti: [
                      0,
                      9.5,
                      0
                    ]
                  },
                  {
                    t: 307.999757662274,
                    s: [
                      2570.049,
                      1668,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  145,
                  18,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 273.000011119518,
            op: 7050.00028715239,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 22,
            ty: 0,
            nm: "Answers",
            refId: "comp_8",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 250,
                    s: [
                      1930.322,
                      981.782,
                      0
                    ],
                    to: [
                      0,
                      0.167,
                      0
                    ],
                    ti: [
                      -0.389,
                      0.711,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 251,
                    s: [
                      1933.322,
                      994.782,
                      0
                    ],
                    to: [
                      3.004,
                      -5.491,
                      0
                    ],
                    ti: [
                      -2.534,
                      -1.314,
                      0
                    ]
                  },
                  {
                    t: 277.99953769034,
                    s: [
                      2570.049,
                      1248,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  298,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 250,
                    s: [
                      50.51,
                      51.664,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 251,
                    s: [
                      54.47,
                      56.342,
                      100
                    ]
                  },
                  {
                    t: 277.99953769034,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            w: 1132,
            h: 596,
            ip: 250.000010182709,
            op: 7051.00028719312,
            st: -141.000005743048,
            bm: 0
          },
          {
            ddd: 0,
            ind: 23,
            ty: 2,
            nm: "Which product will.png",
            cl: "png",
            refId: "image_24",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.008
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 273,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 307.999757662274,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 273,
                    s: [
                      2570.049,
                      1026,
                      0
                    ],
                    to: [
                      0,
                      -39.5,
                      0
                    ],
                    ti: [
                      0,
                      39.5,
                      0
                    ]
                  },
                  {
                    t: 307.999757662274,
                    s: [
                      2570.049,
                      789,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  527,
                  78,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 273.000011119518,
            op: 7050.00028715239,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 24,
            ty: 2,
            nm: "Group 5.png",
            cl: "png",
            refId: "image_25",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.008
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 273,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 307.999757662274,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 273,
                    s: [
                      2570.049,
                      870,
                      0
                    ],
                    to: [
                      0,
                      -56,
                      0
                    ],
                    ti: [
                      0,
                      56,
                      0
                    ]
                  },
                  {
                    t: 307.999757662274,
                    s: [
                      2570.049,
                      534,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  570,
                  83,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 273.000011119518,
            op: 7050.00028715239,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 25,
            ty: 2,
            nm: "Group 17.png",
            cl: "png",
            parent: 27,
            refId: "image_26",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667
                      ],
                      y: [
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333
                      ],
                      y: [
                        0
                      ]
                    },
                    t: 239,
                    s: [
                      2
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667
                      ],
                      y: [
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333
                      ],
                      y: [
                        0
                      ]
                    },
                    t: 244,
                    s: [
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667
                      ],
                      y: [
                        0.963
                      ]
                    },
                    o: {
                      x: [
                        0.333
                      ],
                      y: [
                        0
                      ]
                    },
                    t: 250,
                    s: [
                      98
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667
                      ],
                      y: [
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333
                      ],
                      y: [
                        -37.505
                      ]
                    },
                    t: 251.998,
                    s: [
                      1
                    ]
                  },
                  {
                    t: 272.99963514294,
                    s: [
                      1
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.704,
                      y: 0.408
                    },
                    o: {
                      x: 0.384,
                      y: 0
                    },
                    t: 239,
                    s: [
                      47.429,
                      -159.758,
                      0
                    ],
                    to: [
                      3.766,
                      0,
                      0
                    ],
                    ti: [
                      -3.815,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.673,
                      y: 0.461
                    },
                    o: {
                      x: 0.343,
                      y: 0.298
                    },
                    t: 246,
                    s: [
                      60.817,
                      -159.758,
                      0
                    ],
                    to: [
                      3.678,
                      0,
                      0
                    ],
                    ti: [
                      -7.953,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.443,
                      y: 1
                    },
                    o: {
                      x: 0.228,
                      y: 0.353
                    },
                    t: 249,
                    s: [
                      74.05,
                      -159.758,
                      0
                    ],
                    to: [
                      43.177,
                      0,
                      0
                    ],
                    ti: [
                      -19.712,
                      0,
                      0
                    ]
                  },
                  {
                    t: 278.000488862254,
                    s: [
                      210.079,
                      -159.758,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  825,
                  652.5,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.275,
                        0.275,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 238,
                    s: [
                      20,
                      20,
                      100
                    ]
                  },
                  {
                    t: 278.000488862254,
                    s: [
                      23,
                      23,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 239.00000973467,
            op: 282.000011486096,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 26,
            ty: 2,
            nm: "Group 16.png",
            cl: "png",
            parent: 27,
            refId: "image_27",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 239,
                    s: [
                      47.429,
                      -159.758,
                      0
                    ],
                    to: [
                      27.108,
                      0,
                      0
                    ],
                    ti: [
                      -27.108,
                      0,
                      0
                    ]
                  },
                  {
                    t: 278.000488862254,
                    s: [
                      210.079,
                      -159.758,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  826.5,
                  652.5,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 238,
                    s: [
                      20,
                      20,
                      100
                    ]
                  },
                  {
                    t: 278.000488862254,
                    s: [
                      23,
                      23,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 239.00000973467,
            op: 244.000009938324,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 27,
            ty: 4,
            nm: "Shape Layer 8",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 239,
                    s: [
                      2144.15,
                      870,
                      0
                    ],
                    to: [
                      87.225,
                      35,
                      0
                    ],
                    ti: [
                      -87.225,
                      -35,
                      0
                    ]
                  },
                  {
                    t: 278.000488862254,
                    s: [
                      2667.498,
                      1080,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  271.579,
                  -157.758,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.41,
                        0.41,
                        0.667
                      ],
                      y: [
                        1.001,
                        1.001,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 239,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    t: 278.000488862254,
                    s: [
                      338.142,
                      349.206,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 1,
                      k: [
                        {
                          i: {
                            x: [
                              0.667,
                              0.667
                            ],
                            y: [
                              1,
                              1
                            ]
                          },
                          o: {
                            x: [
                              0.333,
                              0.333
                            ],
                            y: [
                              0,
                              0
                            ]
                          },
                          t: 239,
                          s: [
                            562.875,
                            468.382
                          ]
                        },
                        {
                          t: 278.000488862254,
                          s: [
                            980.875,
                            497.382
                          ]
                        }
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    r: {
                      a: 1,
                      k: [
                        {
                          i: {
                            x: [
                              0.667
                            ],
                            y: [
                              1
                            ]
                          },
                          o: {
                            x: [
                              0.333
                            ],
                            y: [
                              0
                            ]
                          },
                          t: 239,
                          s: [
                            35
                          ]
                        },
                        {
                          t: 278.000488862254,
                          s: [
                            0
                          ]
                        }
                      ],
                      ix: 4
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        46.429,
                        -157.758
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        80,
                        80
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 239.00000973467,
            op: 2876.12111714681,
            st: -142.000005783779,
            bm: 0
          },
          {
            ddd: 0,
            ind: 28,
            ty: 2,
            nm: "Rectangle.png",
            cl: "png",
            refId: "image_28",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        0.995
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 0,
                    s: [
                      0
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 20,
                    s: [
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 278.721,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 309.690012613932,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920.203,
                  1098.237,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1392,
                  766,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.997,
                        0.997,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 0,
                    s: [
                      56.859,
                      56.674,
                      100
                    ]
                  },
                  {
                    t: 20.0004881193241,
                    s: [
                      100,
                      99.674,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 278.721011352539,
            op: 2742.25511169434,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 29,
            ty: 2,
            nm: "Line Copy 2.png",
            cl: "png",
            refId: "image_29",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1919.5,
                  1394,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0.5,
                  267,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 203,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 223.000121387668,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 203.000008268359,
            op: 234.000009531015,
            st: -172.000007005704,
            bm: 0
          },
          {
            ddd: 0,
            ind: 30,
            ty: 2,
            nm: "Group.png",
            cl: "png",
            refId: "image_30",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  845.75,
                  784.25,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  174.75,
                  0.25,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 203,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 223.000121387668,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 203.000008268359,
            op: 234.000009531015,
            st: -172.000007005704,
            bm: 0
          },
          {
            ddd: 0,
            ind: 31,
            ty: 2,
            nm: "Group 2.png",
            cl: "png",
            refId: "image_31",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  3027,
                  784.75,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  93,
                  0.75,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 203,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 223.000121387668,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 203.000008268359,
            op: 234.000009531015,
            st: -172.000007005704,
            bm: 0
          },
          {
            ddd: 0,
            ind: 32,
            ty: 2,
            nm: "Day 3.png",
            cl: "png",
            refId: "image_32",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  3036,
                  445,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  87,
                  23,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 193,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 211.999535978668,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 193.000007861051,
            op: 234.000009531015,
            st: -167.000006802049,
            bm: 0
          },
          {
            ddd: 0,
            ind: 33,
            ty: 2,
            nm: "Day 2.png",
            cl: "png",
            refId: "image_33",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1740,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  87,
                  22,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 193,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 211.999535978668,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 193.000007861051,
            op: 234.000009531015,
            st: -167.000006802049,
            bm: 0
          },
          {
            ddd: 0,
            ind: 34,
            ty: 2,
            nm: "Day 1.png",
            cl: "png",
            refId: "image_34",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  832,
                  445,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  80,
                  22,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 193,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 211.999535978668,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 193.000007861051,
            op: 234.000009531015,
            st: -167.000006802049,
            bm: 0
          },
          {
            ddd: 0,
            ind: 35,
            ty: 2,
            nm: "Group 16.png",
            cl: "png",
            parent: 36,
            refId: "image_27",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  47.429,
                  -159.758,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  826.5,
                  652.5,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  20,
                  20,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 201.000008186898,
            op: 239.00000973467,
            st: -159.000006476203,
            bm: 0
          },
          {
            ddd: 0,
            ind: 36,
            ty: 4,
            nm: "Shape Layer 7",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1919,
                  1393,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  46.429,
                  365.242,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 201,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 221.000487517159,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 0,
                      k: [
                        562.875,
                        468.382
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 35,
                      ix: 4
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        46.429,
                        -157.758
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        80,
                        80
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 201.000008186898,
            op: 239.00000973467,
            st: -159.000006476203,
            bm: 0
          },
          {
            ddd: 0,
            ind: 37,
            ty: 0,
            nm: "Whole chart",
            refId: "comp_9",
            sr: 0.65,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667
                      ],
                      y: [
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333
                      ],
                      y: [
                        0
                      ]
                    },
                    t: 233.3,
                    s: [
                      100
                    ]
                  },
                  {
                    t: 258.65031724624,
                    s: [
                      0
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833,
                        0.833,
                        0.833
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 233.3,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    t: 258.65031724624,
                    s: [
                      125,
                      125,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            tm: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: -14.35,
                  s: [
                    0
                  ]
                },
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 182,
                  s: [
                    10.077
                  ]
                },
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 200,
                  s: [
                    11.927
                  ]
                },
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 204,
                  s: [
                    12.541
                  ]
                },
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 206.999,
                  s: [
                    12.694
                  ]
                },
                {
                  t: 4660.4504309129,
                  s: [
                    239.973
                  ]
                }
              ],
              ix: 2
            },
            w: 3840,
            h: 2160,
            ip: 63.0000025660426,
            op: 4660.45018982402,
            st: -14.3500005844875,
            bm: 0
          }
        ]
      },
      {
        id: "comp_1",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "Shape Layer 3",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 71,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 73.000002973351,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1983.747,
                  1063.919,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  -110.253,
                  263.919,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.267,
                        0.667,
                        0.667
                      ],
                      y: [
                        1.008,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0,
                        0
                      ]
                    },
                    t: 71,
                    s: [
                      0,
                      100,
                      100
                    ]
                  },
                  {
                    t: 101.000004113814,
                    s: [
                      115.402,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 0,
                      k: [
                        797.078,
                        19.839
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        293.539,
                        263.919
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        101.318,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 71.0000028918893,
            op: 3642.0001483417,
            st: 46.0000018736184,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: "Shape Layer 2",
            sr: 0.5,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 66,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 67.0000027289659,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1678.747,
                  1063.919,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  -110.253,
                  263.919,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.267,
                        0.667,
                        0.667
                      ],
                      y: [
                        1.039,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0,
                        0
                      ]
                    },
                    t: 66,
                    s: [
                      0,
                      100,
                      100
                    ]
                  },
                  {
                    t: 81.0000032991976,
                    s: [
                      24.746,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 0,
                      k: [
                        797.078,
                        19.839
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        293.539,
                        263.919
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        101.318,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 66.0000026882351,
            op: 1851.50007541314,
            st: 53.5000021790997,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: "Shape Layer 1",
            sr: 0.5,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 61,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 62.0000025253118,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1346.747,
                  1063.919,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  -110.253,
                  263.919,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.267,
                        0.667,
                        0.667
                      ],
                      y: [
                        1.05,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0,
                        0
                      ]
                    },
                    t: 61,
                    s: [
                      0,
                      100,
                      100
                    ]
                  },
                  {
                    t: 76.0000030955435,
                    s: [
                      19.297,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 0,
                      k: [
                        797.078,
                        19.839
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        293.539,
                        263.919
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        101.318,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 61.0000024845809,
            op: 1846.50007520949,
            st: 48.5000019754455,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 5,
            nm: "Train high-performing sales teams.",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 50,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 52.0000021180034,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 50,
                    s: [
                      2668.372,
                      832.5,
                      0
                    ],
                    to: [
                      -73.719,
                      0,
                      0
                    ],
                    ti: [
                      73.719,
                      0,
                      0
                    ]
                  },
                  {
                    t: 65.0000026475043,
                    s: [
                      2226.056,
                      832.5,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  -14,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ef: [
              {
                ty: 5,
                nm: "Linear Color Key",
                np: 9,
                mn: "ADBE Linear Color Key2",
                ix: 1,
                en: 1,
                ef: [
                  {
                    ty: 6,
                    nm: "Preview",
                    mn: "ADBE Linear Color Key2-0001",
                    ix: 1,
                    v: 0
                  },
                  {
                    ty: 7,
                    nm: "View",
                    mn: "ADBE Linear Color Key2-0002",
                    ix: 2,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 2
                    }
                  },
                  {
                    ty: 2,
                    nm: "Key Color",
                    mn: "ADBE Linear Color Key2-0003",
                    ix: 3,
                    v: {
                      a: 0,
                      k: [
                        1,
                        0,
                        0,
                        1
                      ],
                      ix: 3
                    }
                  },
                  {
                    ty: 7,
                    nm: "Match colors",
                    mn: "ADBE Linear Color Key2-0004",
                    ix: 4,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 4
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Tolerance",
                    mn: "ADBE Linear Color Key2-0005",
                    ix: 5,
                    v: {
                      a: 0,
                      k: 0,
                      ix: 5
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Softness",
                    mn: "ADBE Linear Color Key2-0006",
                    ix: 6,
                    v: {
                      a: 0,
                      k: 10,
                      ix: 6
                    }
                  },
                  {
                    ty: 7,
                    nm: "Key Operation",
                    mn: "ADBE Linear Color Key2-0007",
                    ix: 7,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 7
                    }
                  }
                ]
              }
            ],
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        2331.4287109375,
                        546
                      ],
                      ps: [
                        -1465.92858886719,
                        22.5
                      ],
                      s: 210,
                      f: "Gordita-Medium",
                      t: "Train high-performing sales teams.",
                      j: 2,
                      tr: 0,
                      lh: 273,
                      ls: 0,
                      fc: [
                        1,
                        0,
                        0
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 50.0000020365418,
            op: 230.000009368092,
            st: 50.0000020365418,
            bm: 0
          },
          {
            ddd: 0,
            ind: 5,
            ty: 5,
            nm: "Train high-performing sales teams 4",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 45,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 47.0000019143492,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 45,
                    s: [
                      2668.372,
                      832.5,
                      0
                    ],
                    to: [
                      -73.719,
                      0,
                      0
                    ],
                    ti: [
                      73.719,
                      0,
                      0
                    ]
                  },
                  {
                    t: 60.0000024438501,
                    s: [
                      2226.056,
                      832.5,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ef: [
              {
                ty: 5,
                nm: "Linear Color Key",
                np: 9,
                mn: "ADBE Linear Color Key2",
                ix: 1,
                en: 1,
                ef: [
                  {
                    ty: 6,
                    nm: "Preview",
                    mn: "ADBE Linear Color Key2-0001",
                    ix: 1,
                    v: 0
                  },
                  {
                    ty: 7,
                    nm: "View",
                    mn: "ADBE Linear Color Key2-0002",
                    ix: 2,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 2
                    }
                  },
                  {
                    ty: 2,
                    nm: "Key Color",
                    mn: "ADBE Linear Color Key2-0003",
                    ix: 3,
                    v: {
                      a: 0,
                      k: [
                        1,
                        0,
                        0,
                        1
                      ],
                      ix: 3
                    }
                  },
                  {
                    ty: 7,
                    nm: "Match colors",
                    mn: "ADBE Linear Color Key2-0004",
                    ix: 4,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 4
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Tolerance",
                    mn: "ADBE Linear Color Key2-0005",
                    ix: 5,
                    v: {
                      a: 0,
                      k: 0,
                      ix: 5
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Softness",
                    mn: "ADBE Linear Color Key2-0006",
                    ix: 6,
                    v: {
                      a: 0,
                      k: 10,
                      ix: 6
                    }
                  },
                  {
                    ty: 7,
                    nm: "Key Operation",
                    mn: "ADBE Linear Color Key2-0007",
                    ix: 7,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 7
                    }
                  }
                ]
              }
            ],
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        2331.4287109375,
                        546
                      ],
                      ps: [
                        -1465.92858886719,
                        22.5
                      ],
                      s: 210,
                      f: "Gordita-Medium",
                      t: "Train high-performing sales teams",
                      j: 2,
                      tr: 0,
                      lh: 273,
                      ls: 0,
                      fc: [
                        1,
                        0,
                        0
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 45.0000018328876,
            op: 225.000009164438,
            st: 45.0000018328876,
            bm: 0
          },
          {
            ddd: 0,
            ind: 6,
            ty: 5,
            nm: "Train high-performing sales teams 3",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 40,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 42.0000017106951,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 40,
                    s: [
                      2668.372,
                      832.5,
                      0
                    ],
                    to: [
                      -73.719,
                      0,
                      0
                    ],
                    ti: [
                      73.719,
                      0,
                      0
                    ]
                  },
                  {
                    t: 55.0000022401959,
                    s: [
                      2226.056,
                      832.5,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ef: [
              {
                ty: 5,
                nm: "Linear Color Key",
                np: 9,
                mn: "ADBE Linear Color Key2",
                ix: 1,
                en: 1,
                ef: [
                  {
                    ty: 6,
                    nm: "Preview",
                    mn: "ADBE Linear Color Key2-0001",
                    ix: 1,
                    v: 0
                  },
                  {
                    ty: 7,
                    nm: "View",
                    mn: "ADBE Linear Color Key2-0002",
                    ix: 2,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 2
                    }
                  },
                  {
                    ty: 2,
                    nm: "Key Color",
                    mn: "ADBE Linear Color Key2-0003",
                    ix: 3,
                    v: {
                      a: 0,
                      k: [
                        1,
                        0,
                        0,
                        1
                      ],
                      ix: 3
                    }
                  },
                  {
                    ty: 7,
                    nm: "Match colors",
                    mn: "ADBE Linear Color Key2-0004",
                    ix: 4,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 4
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Tolerance",
                    mn: "ADBE Linear Color Key2-0005",
                    ix: 5,
                    v: {
                      a: 0,
                      k: 0,
                      ix: 5
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Softness",
                    mn: "ADBE Linear Color Key2-0006",
                    ix: 6,
                    v: {
                      a: 0,
                      k: 10,
                      ix: 6
                    }
                  },
                  {
                    ty: 7,
                    nm: "Key Operation",
                    mn: "ADBE Linear Color Key2-0007",
                    ix: 7,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 7
                    }
                  }
                ]
              }
            ],
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        2331.4287109375,
                        546
                      ],
                      ps: [
                        -1465.92858886719,
                        22.5
                      ],
                      s: 210,
                      f: "Gordita-Medium",
                      t: "Train high-performing sales teams",
                      j: 2,
                      tr: 0,
                      lh: 273,
                      ls: 0,
                      fc: [
                        1,
                        0,
                        0
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 40.0000016292334,
            op: 220.000008960784,
            st: 40.0000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 7,
            ty: 5,
            nm: "Train high-performing sales teams 2",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 35,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 37.0000015070409,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 35,
                    s: [
                      2668.372,
                      832.5,
                      0
                    ],
                    to: [
                      -73.719,
                      0,
                      0
                    ],
                    ti: [
                      73.719,
                      0,
                      0
                    ]
                  },
                  {
                    t: 50.0000020365418,
                    s: [
                      2226.056,
                      832.5,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ef: [
              {
                ty: 5,
                nm: "Linear Color Key",
                np: 9,
                mn: "ADBE Linear Color Key2",
                ix: 1,
                en: 1,
                ef: [
                  {
                    ty: 6,
                    nm: "Preview",
                    mn: "ADBE Linear Color Key2-0001",
                    ix: 1,
                    v: 0
                  },
                  {
                    ty: 7,
                    nm: "View",
                    mn: "ADBE Linear Color Key2-0002",
                    ix: 2,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 2
                    }
                  },
                  {
                    ty: 2,
                    nm: "Key Color",
                    mn: "ADBE Linear Color Key2-0003",
                    ix: 3,
                    v: {
                      a: 0,
                      k: [
                        1,
                        0,
                        0,
                        1
                      ],
                      ix: 3
                    }
                  },
                  {
                    ty: 7,
                    nm: "Match colors",
                    mn: "ADBE Linear Color Key2-0004",
                    ix: 4,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 4
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Tolerance",
                    mn: "ADBE Linear Color Key2-0005",
                    ix: 5,
                    v: {
                      a: 0,
                      k: 0,
                      ix: 5
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Softness",
                    mn: "ADBE Linear Color Key2-0006",
                    ix: 6,
                    v: {
                      a: 0,
                      k: 10,
                      ix: 6
                    }
                  },
                  {
                    ty: 7,
                    nm: "Key Operation",
                    mn: "ADBE Linear Color Key2-0007",
                    ix: 7,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 7
                    }
                  }
                ]
              }
            ],
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        2331.4287109375,
                        546
                      ],
                      ps: [
                        -1465.92858886719,
                        22.5
                      ],
                      s: 210,
                      f: "Gordita-Medium",
                      t: "Train high-performing sales teams",
                      j: 2,
                      tr: 0,
                      lh: 273,
                      ls: 0,
                      fc: [
                        1,
                        0,
                        0
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 35.0000014255792,
            op: 215.00000875713,
            st: 35.0000014255792,
            bm: 0
          },
          {
            ddd: 0,
            ind: 8,
            ty: 5,
            nm: "Train high-performing sales teams",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 30,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 32.0000013033867,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 30,
                    s: [
                      2668.372,
                      832.5,
                      0
                    ],
                    to: [
                      -73.719,
                      0,
                      0
                    ],
                    ti: [
                      73.719,
                      0,
                      0
                    ]
                  },
                  {
                    t: 45.0000018328876,
                    s: [
                      2226.056,
                      832.5,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ef: [
              {
                ty: 5,
                nm: "Linear Color Key",
                np: 9,
                mn: "ADBE Linear Color Key2",
                ix: 1,
                en: 1,
                ef: [
                  {
                    ty: 6,
                    nm: "Preview",
                    mn: "ADBE Linear Color Key2-0001",
                    ix: 1,
                    v: 0
                  },
                  {
                    ty: 7,
                    nm: "View",
                    mn: "ADBE Linear Color Key2-0002",
                    ix: 2,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 2
                    }
                  },
                  {
                    ty: 2,
                    nm: "Key Color",
                    mn: "ADBE Linear Color Key2-0003",
                    ix: 3,
                    v: {
                      a: 0,
                      k: [
                        1,
                        0,
                        0,
                        1
                      ],
                      ix: 3
                    }
                  },
                  {
                    ty: 7,
                    nm: "Match colors",
                    mn: "ADBE Linear Color Key2-0004",
                    ix: 4,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 4
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Tolerance",
                    mn: "ADBE Linear Color Key2-0005",
                    ix: 5,
                    v: {
                      a: 0,
                      k: 0,
                      ix: 5
                    }
                  },
                  {
                    ty: 0,
                    nm: "Matching Softness",
                    mn: "ADBE Linear Color Key2-0006",
                    ix: 6,
                    v: {
                      a: 0,
                      k: 10,
                      ix: 6
                    }
                  },
                  {
                    ty: 7,
                    nm: "Key Operation",
                    mn: "ADBE Linear Color Key2-0007",
                    ix: 7,
                    v: {
                      a: 0,
                      k: 1,
                      ix: 7
                    }
                  }
                ]
              }
            ],
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        2331.4287109375,
                        546
                      ],
                      ps: [
                        -1465.92858886719,
                        22.5
                      ],
                      s: 210,
                      f: "Gordita-Medium",
                      t: "Train high-performing sales teams",
                      j: 2,
                      tr: 0,
                      lh: 273,
                      ls: 0,
                      fc: [
                        1,
                        1,
                        1
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 30.0000012219251,
            op: 210.000008553475,
            st: 30.0000012219251,
            bm: 0
          },
          {
            ddd: 0,
            ind: 9,
            ty: 2,
            nm: "01.png",
            cl: "png",
            refId: "image_0",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1302,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1920,
                  1319,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 3596.00014646808,
            st: 0,
            bm: 0
          }
        ]
      },
      {
        id: "comp_2",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "Shape Layer 10",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  3000.033,
                  1048.62,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  330,
                  32,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  77.445,
                  84,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        o: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        v: [
                          [
                            257.116,
                            32.52
                          ],
                          [
                            311.886,
                            77.021
                          ],
                          [
                            406.887,
                            -20.789
                          ]
                        ],
                        c: false
                      },
                      ix: 2
                    },
                    nm: "Path 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 22,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Shape 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              },
              {
                ty: "tm",
                s: {
                  a: 0,
                  k: 0,
                  ix: 1
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [
                          0
                        ],
                        y: [
                          1.002
                        ]
                      },
                      o: {
                        x: [
                          0.167
                        ],
                        y: [
                          0.167
                        ]
                      },
                      t: 844,
                      s: [
                        0
                      ]
                    },
                    {
                      t: 869.000035395096,
                      s: [
                        100
                      ]
                    }
                  ],
                  ix: 2
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3
                },
                m: 1,
                ix: 2,
                nm: "Trim Paths 1",
                mn: "ADBE Vector Filter - Trim",
                hd: false
              }
            ],
            ip: 844.000034376825,
            op: 3248.00013229375,
            st: 279.000011363903,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: "cursor-quiz.png",
            cl: "png",
            refId: "image_1",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 774,
                    s: [
                      0
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 780,
                    s: [
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 846,
                    s: [
                      100
                    ]
                  },
                  {
                    t: 852.000034702671,
                    s: [
                      0
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 751,
                    s: [
                      1854.96,
                      2243.867,
                      0
                    ],
                    to: [
                      185.007,
                      -143.245,
                      0
                    ],
                    ti: [
                      -185.007,
                      143.245,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 0.667
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 797,
                    s: [
                      2965,
                      1384.399,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 835,
                    s: [
                      2965,
                      1384.399,
                      0
                    ],
                    to: [
                      200,
                      -33.333,
                      0
                    ],
                    ti: [
                      -200,
                      33.333,
                      0
                    ]
                  },
                  {
                    t: 870.000035435826,
                    s: [
                      4165,
                      1184.399,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  19,
                  20,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 815,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 821,
                    s: [
                      47,
                      47,
                      100
                    ]
                  },
                  {
                    t: 835.000034010247,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 739.000030100087,
            op: 7516.00030613296,
            st: 324.000013196791,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: "Redeemed!.png",
            cl: "png",
            refId: "image_2",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        0.997
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 838,
                    s: [
                      1
                    ]
                  },
                  {
                    t: 864.000035191441,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 838,
                    s: [
                      3001.584,
                      1268,
                      0
                    ],
                    to: [
                      0,
                      -10,
                      0
                    ],
                    ti: [
                      0,
                      10,
                      0
                    ]
                  },
                  {
                    t: 864.000035191441,
                    s: [
                      3001.584,
                      1208,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  195,
                  33,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 838.00003413244,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: "Recognition Item 2 Copy 3.png",
            cl: "png",
            refId: "image_3",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.833
                      ],
                      y: [
                        0.833
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 822,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 840.000034213901,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  3000.25,
                  1127.75,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  295,
                  408,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 822.000033480746,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          }
        ]
      },
      {
        id: "comp_3",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 5,
            nm: "360",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "360",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 82.0000033399285,
            op: 2359.6300961097,
            st: -8.00000032584668,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 5,
            nm: "361",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "360",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 81.0000032991976,
            op: 82.0000033399285,
            st: 49.0000019958109,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 5,
            nm: "358",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "358",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 80.0000032584668,
            op: 81.0000032991976,
            st: 49.0000019958109,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 5,
            nm: "356",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "356",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 79.000003217736,
            op: 80.0000032584668,
            st: 49.0000019958109,
            bm: 0
          },
          {
            ddd: 0,
            ind: 5,
            ty: 5,
            nm: "354",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "354",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 78.0000031770051,
            op: 79.000003217736,
            st: 46.0000018736184,
            bm: 0
          },
          {
            ddd: 0,
            ind: 6,
            ty: 5,
            nm: "352",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "352",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 77.0000031362743,
            op: 78.0000031770051,
            st: 46.0000018736184,
            bm: 0
          },
          {
            ddd: 0,
            ind: 7,
            ty: 5,
            nm: "350",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "350",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 76.0000030955435,
            op: 77.0000031362743,
            st: 46.0000018736184,
            bm: 0
          },
          {
            ddd: 0,
            ind: 8,
            ty: 5,
            nm: "348",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "348",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 75.0000030548126,
            op: 76.0000030955435,
            st: 43.0000017514259,
            bm: 0
          },
          {
            ddd: 0,
            ind: 9,
            ty: 5,
            nm: "346",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "346",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 74.0000030140818,
            op: 75.0000030548126,
            st: 43.0000017514259,
            bm: 0
          },
          {
            ddd: 0,
            ind: 10,
            ty: 5,
            nm: "344",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "344",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 73.000002973351,
            op: 74.0000030140818,
            st: 43.0000017514259,
            bm: 0
          },
          {
            ddd: 0,
            ind: 11,
            ty: 5,
            nm: "342",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "342",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 72.0000029326201,
            op: 73.000002973351,
            st: 40.0000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 12,
            ty: 5,
            nm: "340",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "340",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 71.0000028918893,
            op: 72.0000029326201,
            st: 40.0000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 13,
            ty: 5,
            nm: "338",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "338",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 70.0000028511585,
            op: 71.0000028918893,
            st: 40.0000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 14,
            ty: 5,
            nm: "336",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "336",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 69.0000028104276,
            op: 70.0000028511585,
            st: 37.0000015070409,
            bm: 0
          },
          {
            ddd: 0,
            ind: 15,
            ty: 5,
            nm: "334",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "334",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 68.0000027696968,
            op: 69.0000028104276,
            st: 37.0000015070409,
            bm: 0
          },
          {
            ddd: 0,
            ind: 16,
            ty: 5,
            nm: "332",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "332",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 67.0000027289659,
            op: 68.0000027696968,
            st: 37.0000015070409,
            bm: 0
          },
          {
            ddd: 0,
            ind: 17,
            ty: 5,
            nm: "330",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "330",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 66.0000026882351,
            op: 67.000002728966,
            st: 34.0000013848484,
            bm: 0
          },
          {
            ddd: 0,
            ind: 18,
            ty: 5,
            nm: "328",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "328",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 65.0000026475043,
            op: 66.0000026882351,
            st: 34.0000013848484,
            bm: 0
          },
          {
            ddd: 0,
            ind: 19,
            ty: 5,
            nm: "326",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "326",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 64.0000026067734,
            op: 65.0000026475043,
            st: 34.0000013848484,
            bm: 0
          },
          {
            ddd: 0,
            ind: 20,
            ty: 5,
            nm: "324",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "324",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 63.0000025660426,
            op: 64.0000026067734,
            st: 31.0000012626559,
            bm: 0
          },
          {
            ddd: 0,
            ind: 21,
            ty: 5,
            nm: "322",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "322",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 62.0000025253118,
            op: 63.0000025660426,
            st: 31.0000012626559,
            bm: 0
          },
          {
            ddd: 0,
            ind: 22,
            ty: 5,
            nm: "320",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "320",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 61.0000024845809,
            op: 62.0000025253118,
            st: 31.0000012626559,
            bm: 0
          },
          {
            ddd: 0,
            ind: 23,
            ty: 5,
            nm: "318",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "318",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 60.0000024438501,
            op: 61.0000024845809,
            st: 28.0000011404634,
            bm: 0
          },
          {
            ddd: 0,
            ind: 24,
            ty: 5,
            nm: "316",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "316",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 59.0000024031193,
            op: 60.0000024438501,
            st: 28.0000011404634,
            bm: 0
          },
          {
            ddd: 0,
            ind: 25,
            ty: 5,
            nm: "314",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "314",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 58.0000023623884,
            op: 59.0000024031193,
            st: 28.0000011404634,
            bm: 0
          },
          {
            ddd: 0,
            ind: 26,
            ty: 5,
            nm: "312",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "312",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 57.0000023216576,
            op: 58.0000023623884,
            st: 25.0000010182709,
            bm: 0
          },
          {
            ddd: 0,
            ind: 27,
            ty: 5,
            nm: "310",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "310",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 56.0000022809268,
            op: 57.0000023216576,
            st: 25.0000010182709,
            bm: 0
          },
          {
            ddd: 0,
            ind: 28,
            ty: 5,
            nm: "308",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "308",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 55.0000022401959,
            op: 56.0000022809268,
            st: 25.0000010182709,
            bm: 0
          },
          {
            ddd: 0,
            ind: 29,
            ty: 5,
            nm: "306",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "306",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 54.0000021994651,
            op: 55.0000022401959,
            st: 22.0000008960784,
            bm: 0
          },
          {
            ddd: 0,
            ind: 30,
            ty: 5,
            nm: "304",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "304",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 53.0000021587343,
            op: 54.0000021994651,
            st: 22.0000008960784,
            bm: 0
          },
          {
            ddd: 0,
            ind: 31,
            ty: 5,
            nm: "302",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "302",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 52.0000021180034,
            op: 53.0000021587343,
            st: 22.0000008960784,
            bm: 0
          },
          {
            ddd: 0,
            ind: 32,
            ty: 5,
            nm: "300",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "300",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 51.0000020772726,
            op: 52.0000021180034,
            st: 19.0000007738859,
            bm: 0
          },
          {
            ddd: 0,
            ind: 33,
            ty: 5,
            nm: "298",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "298",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 50.0000020365418,
            op: 51.0000020772726,
            st: 19.0000007738859,
            bm: 0
          },
          {
            ddd: 0,
            ind: 34,
            ty: 5,
            nm: "296",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "296",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 49.0000019958109,
            op: 50.0000020365418,
            st: 19.0000007738859,
            bm: 0
          },
          {
            ddd: 0,
            ind: 35,
            ty: 5,
            nm: "294",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "294",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 48.0000019550801,
            op: 49.0000019958109,
            st: 16.0000006516934,
            bm: 0
          },
          {
            ddd: 0,
            ind: 36,
            ty: 5,
            nm: "292",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "292",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 47.0000019143492,
            op: 48.0000019550801,
            st: 16.0000006516934,
            bm: 0
          },
          {
            ddd: 0,
            ind: 37,
            ty: 5,
            nm: "290",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "290",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 46.0000018736184,
            op: 47.0000019143492,
            st: 16.0000006516934,
            bm: 0
          },
          {
            ddd: 0,
            ind: 38,
            ty: 5,
            nm: "288",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "288",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 45.0000018328876,
            op: 46.0000018736184,
            st: 13.0000005295009,
            bm: 0
          },
          {
            ddd: 0,
            ind: 39,
            ty: 5,
            nm: "286",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "286",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 44.0000017921567,
            op: 45.0000018328876,
            st: 13.0000005295009,
            bm: 0
          },
          {
            ddd: 0,
            ind: 40,
            ty: 5,
            nm: "284",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "284",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 43.0000017514259,
            op: 44.0000017921567,
            st: 13.0000005295009,
            bm: 0
          },
          {
            ddd: 0,
            ind: 41,
            ty: 5,
            nm: "282",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "282",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 42.0000017106951,
            op: 43.0000017514259,
            st: 10.0000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 42,
            ty: 5,
            nm: "280",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "280",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 41.0000016699642,
            op: 42.0000017106951,
            st: 10.0000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 43,
            ty: 5,
            nm: "278",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "278",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 40.0000016292334,
            op: 41.0000016699642,
            st: 10.0000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 44,
            ty: 5,
            nm: "276",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "276",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 39.0000015885026,
            op: 40.0000016292334,
            st: 7.00000028511585,
            bm: 0
          },
          {
            ddd: 0,
            ind: 45,
            ty: 5,
            nm: "274",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "274",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 38.0000015477717,
            op: 39.0000015885026,
            st: 7.00000028511585,
            bm: 0
          },
          {
            ddd: 0,
            ind: 46,
            ty: 5,
            nm: "272",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "272",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 37.0000015070409,
            op: 38.0000015477717,
            st: 7.00000028511585,
            bm: 0
          },
          {
            ddd: 0,
            ind: 47,
            ty: 5,
            nm: "270",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "270",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 36.0000014663101,
            op: 37.0000015070409,
            st: 4.00000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 48,
            ty: 5,
            nm: "268",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "268",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 35.0000014255792,
            op: 36.0000014663101,
            st: 4.00000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 49,
            ty: 5,
            nm: "266",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "266",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 34.0000013848484,
            op: 35.0000014255792,
            st: 4.00000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 50,
            ty: 5,
            nm: "264",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "264",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 33.0000013441176,
            op: 34.0000013848484,
            st: 1.00000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 51,
            ty: 5,
            nm: "262",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "262",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 32.0000013033867,
            op: 33.0000013441175,
            st: 1.00000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 52,
            ty: 5,
            nm: "260",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "260",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 31.0000012626559,
            op: 32.0000013033867,
            st: 1.00000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 53,
            ty: 5,
            nm: "258",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "258",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 30.0000012219251,
            op: 31.0000012626559,
            st: -2.00000008146167,
            bm: 0
          },
          {
            ddd: 0,
            ind: 54,
            ty: 5,
            nm: "256",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "256",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 29.0000011811942,
            op: 30.000001221925,
            st: -2.00000008146167,
            bm: 0
          },
          {
            ddd: 0,
            ind: 55,
            ty: 5,
            nm: "254",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "254",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 28.0000011404634,
            op: 29.0000011811942,
            st: -2.00000008146167,
            bm: 0
          },
          {
            ddd: 0,
            ind: 56,
            ty: 5,
            nm: "252",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "252",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 27.0000010997325,
            op: 28.0000011404634,
            st: -5.00000020365417,
            bm: 0
          },
          {
            ddd: 0,
            ind: 57,
            ty: 5,
            nm: "250",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "250",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 26.0000010590017,
            op: 27.0000010997325,
            st: -5.00000020365417,
            bm: 0
          },
          {
            ddd: 0,
            ind: 58,
            ty: 5,
            nm: "248",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "248",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 25.0000010182709,
            op: 26.0000010590017,
            st: -5.00000020365417,
            bm: 0
          },
          {
            ddd: 0,
            ind: 59,
            ty: 5,
            nm: "246",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "246",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 24.00000097754,
            op: 25.0000010182709,
            st: -8.00000032584668,
            bm: 0
          },
          {
            ddd: 0,
            ind: 60,
            ty: 5,
            nm: "244",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "244",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 23.0000009368092,
            op: 24.00000097754,
            st: -8.00000032584668,
            bm: 0
          },
          {
            ddd: 0,
            ind: 61,
            ty: 5,
            nm: "242",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "242",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 22.0000008960784,
            op: 23.0000009368092,
            st: -8.00000032584668,
            bm: 0
          },
          {
            ddd: 0,
            ind: 62,
            ty: 5,
            nm: "240",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "240",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 0,
            op: 22.0000008960784,
            st: -8.00000032584668,
            bm: 0
          },
          {
            ddd: 0,
            ind: 63,
            ty: 2,
            nm: "Group 23.png",
            cl: "png",
            refId: "image_10",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  673,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  390,
                  78,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 64,
            ty: 2,
            nm: "1st.png",
            cl: "png",
            refId: "image_11",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 52.0000021180034,
            op: 7268.00029603171,
            st: 52.0000021180034,
            bm: 0
          },
          {
            ddd: 0,
            ind: 65,
            ty: 2,
            nm: "2nd.png",
            cl: "png",
            refId: "image_12",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 52.0000021180034,
            st: 0,
            bm: 0
          }
        ]
      },
      {
        id: "comp_4",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 5,
            nm: "300",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "300",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: -1.00700004101595,
            op: 2359.6300961097,
            st: -8.00000032584668,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: "Group 20.png",
            cl: "png",
            refId: "image_13",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  673,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  390,
                  78,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: "2nd.png",
            cl: "png",
            refId: "image_12",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 60.0000024438501,
            op: 7326.0002983941,
            st: 60.0000024438501,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: "1st.png",
            cl: "png",
            refId: "image_11",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 60.0000024438501,
            st: 8.00000032584668,
            bm: 0
          }
        ]
      },
      {
        id: "comp_5",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 5,
            nm: "210",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  871.44,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1003.231,
                  -22.845,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  27,
                  27,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1274.74072265625,
                        185.629638671875
                      ],
                      ps: [
                        -265,
                        -117.074073791504
                      ],
                      s: 227,
                      f: "Gordita-Bold",
                      t: "210",
                      j: 1,
                      tr: 20,
                      lh: 272.4,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: -1.00700004101595,
            op: 2359.6300961097,
            st: -8.00000032584668,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: "Group 22.png",
            cl: "png",
            refId: "image_14",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  673,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  390,
                  78,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: "3rd.png",
            cl: "png",
            refId: "image_15",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  566,
                  137,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          }
        ]
      },
      {
        id: "comp_6",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: "leaderboard.png",
            cl: "png",
            refId: "image_16",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 615,
                    s: [
                      579.471,
                      1837.246,
                      0
                    ],
                    to: [
                      0,
                      -242,
                      0
                    ],
                    ti: [
                      0,
                      242,
                      0
                    ]
                  },
                  {
                    t: 650.000026475043,
                    s: [
                      579.471,
                      385.246,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  -0.029,
                  0.246,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  99.814,
                  99.792,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 615.000025049463,
            op: 5959.00024271505,
            st: -1.00000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: "leaderboard.png",
            cl: "png",
            refId: "image_16",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 615,
                    s: [
                      579.471,
                      1837.246,
                      0
                    ],
                    to: [
                      0,
                      -242,
                      0
                    ],
                    ti: [
                      0,
                      242,
                      0
                    ]
                  },
                  {
                    t: 650.000026475043,
                    s: [
                      579.471,
                      385.246,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  -0.029,
                  0.246,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  99.814,
                  99.792,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 615.000025049463,
            op: 5959.00024271505,
            st: -1.00000004073083,
            bm: 0
          }
        ]
      },
      {
        id: "comp_7",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: "Walnut-1.png",
            cl: "png",
            refId: "image_20",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: -1.5,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  591,
                  632,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  1920,
                  1080,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  -78.181,
                  78,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: "Shape Layer 1",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  661,
                  696,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  -511.367,
                  16.191,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  641.008,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 0,
                      k: [
                        155.266,
                        1120.381
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        0.996078012504,
                        1,
                        0.901961023667,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        0.952941236309,
                        0.952941236309,
                        0.952941236309,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        -511.367,
                        16.191
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 11,
            ty: 2,
            nm: "camera.png",
            cl: "png",
            refId: "image_21",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  661,
                  696,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  661,
                  696,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  112.708,
                  107.471,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 0,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          }
        ]
      },
      {
        id: "comp_8",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: "Group 6.png",
            cl: "png",
            refId: "image_23",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  852,
                  456,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  852,
                  456,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: "a",
                pt: {
                  a: 0,
                  k: {
                    i: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    o: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    v: [
                      [
                        1144.759,
                        310
                      ],
                      [
                        572,
                        310
                      ],
                      [
                        572,
                        607.237
                      ],
                      [
                        1144.759,
                        607.237
                      ]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 3
                },
                x: {
                  a: 0,
                  k: 0,
                  ix: 4
                },
                nm: "Mask 1"
              }
            ],
            ip: 162.000006598395,
            op: 7207.00029354713,
            st: 15.0000006109625,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: "Group 6.png",
            cl: "png",
            refId: "image_23",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  264,
                  451,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  264,
                  451,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: "a",
                pt: {
                  a: 0,
                  k: {
                    i: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    o: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    v: [
                      [
                        558.108,
                        309
                      ],
                      [
                        -11,
                        309
                      ],
                      [
                        -11,
                        607.766
                      ],
                      [
                        558.108,
                        607.766
                      ]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 3
                },
                x: {
                  a: 0,
                  k: 0,
                  ix: 4
                },
                nm: "Mask 1"
              }
            ],
            ip: 157.000006394741,
            op: 7202.00029334347,
            st: 10.0000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: "Group 6.png",
            cl: "png",
            refId: "image_23",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  858,
                  134,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  858,
                  134,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: "a",
                pt: {
                  a: 0,
                  k: {
                    i: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    o: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    v: [
                      [
                        1138.425,
                        -8
                      ],
                      [
                        577,
                        -8
                      ],
                      [
                        577,
                        289.358
                      ],
                      [
                        1138.425,
                        289.358
                      ]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 3
                },
                x: {
                  a: 0,
                  k: 0,
                  ix: 4
                },
                nm: "Mask 1"
              }
            ],
            ip: 152.000006191087,
            op: 7197.00029313982,
            st: 5.00000020365417,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: "Group 6.png",
            cl: "png",
            refId: "image_23",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  262.68,
                  139.025,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  262.68,
                  139.025,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: "a",
                pt: {
                  a: 0,
                  k: {
                    i: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    o: [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    v: [
                      [
                        559.36,
                        -13
                      ],
                      [
                        -12,
                        -13
                      ],
                      [
                        -12,
                        294.05
                      ],
                      [
                        559.36,
                        294.05
                      ]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 3
                },
                x: {
                  a: 0,
                  k: 0,
                  ix: 4
                },
                nm: "Mask 1"
              }
            ],
            ip: 147.000005987433,
            op: 7192.00029293616,
            st: 0,
            bm: 0
          }
        ]
      },
      {
        id: "comp_9",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: "Line Copy 2.png",
            cl: "png",
            refId: "image_29",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1919.5,
                  1394,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0.5,
                  267,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 360,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 380.000015477717,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 421.000017147681,
            op: 7177.0002923252,
            st: -15.0000006109625,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: "Group.png",
            cl: "png",
            refId: "image_30",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  845.75,
                  784.25,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  174.75,
                  0.25,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 360,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 380.000015477717,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 421.000017147681,
            op: 7177.0002923252,
            st: -15.0000006109625,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: "Group 2.png",
            cl: "png",
            refId: "image_31",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  3027,
                  784.75,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  93,
                  0.75,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 360,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 380.000015477717,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 421.000017147681,
            op: 7177.0002923252,
            st: -15.0000006109625,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: "Day 3.png",
            cl: "png",
            refId: "image_32",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  3036,
                  445,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  87,
                  23,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 350,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 369.000015029678,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 421.000017147681,
            op: 7182.00029252886,
            st: -10.0000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 5,
            ty: 2,
            nm: "Day 2.png",
            cl: "png",
            refId: "image_33",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1740,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  87,
                  22,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 350,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 369.000015029678,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 421.000017147681,
            op: 7182.00029252886,
            st: -10.0000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 6,
            ty: 2,
            nm: "Day 1.png",
            cl: "png",
            refId: "image_34",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  832,
                  445,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  80,
                  22,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.999,
                        0.999,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 350,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 369.000015029678,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 421.000017147681,
            op: 7182.00029252886,
            st: -10.0000004073083,
            bm: 0
          },
          {
            ddd: 0,
            ind: 7,
            ty: 2,
            nm: "mainthree.png",
            cl: "png",
            refId: "image_35",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 301,
                    s: [
                      1920,
                      1080,
                      0
                    ],
                    to: [
                      0,
                      80.667,
                      0
                    ],
                    ti: [
                      0,
                      -74,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 375,
                    s: [
                      1920,
                      1564,
                      0
                    ],
                    to: [
                      0,
                      74,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 388,
                    s: [
                      1920,
                      1524,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      -6.667,
                      0
                    ]
                  },
                  {
                    t: 401.000016333065,
                    s: [
                      1920,
                      1564,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  558,
                  234,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.998,
                        0.998,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 140,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 160,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 210,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.833,
                        0.833,
                        0.833
                      ],
                      y: [
                        1,
                        1,
                        6.333
                      ]
                    },
                    o: {
                      x: [
                        0.333,
                        0.333,
                        0.333
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 300,
                    s: [
                      33,
                      33,
                      100
                    ]
                  },
                  {
                    t: 301.000012259981,
                    s: [
                      33,
                      33,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 140.000005702317,
            op: 7192.00029293616,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 8,
            ty: 2,
            nm: "01 Copy 21.png",
            cl: "png",
            refId: "image_36",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 247,
                    s: [
                      1920,
                      1080,
                      0
                    ],
                    to: [
                      186,
                      0,
                      0
                    ],
                    ti: [
                      -186,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 0.667
                    },
                    o: {
                      x: 0,
                      y: 0
                    },
                    t: 300,
                    s: [
                      3036,
                      1080,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 301,
                    s: [
                      3036,
                      1080,
                      0
                    ],
                    to: [
                      0,
                      -78.667,
                      0
                    ],
                    ti: [
                      0,
                      72,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 375,
                    s: [
                      3036,
                      608,
                      0
                    ],
                    to: [
                      0,
                      -72,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 388,
                    s: [
                      3036,
                      648,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      6.667,
                      0
                    ]
                  },
                  {
                    t: 401.000016333065,
                    s: [
                      3036,
                      608,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  78,
                  78,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 247,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 254.000010345632,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 247.000010060516,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 9,
            ty: 2,
            nm: "01 Copy 19.png",
            cl: "png",
            refId: "image_37",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 243,
                    s: [
                      1920,
                      1080,
                      0
                    ],
                    to: [
                      -186.667,
                      0,
                      0
                    ],
                    ti: [
                      186.667,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 0.667
                    },
                    o: {
                      x: 0,
                      y: 0
                    },
                    t: 296,
                    s: [
                      800,
                      1080,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 301,
                    s: [
                      800,
                      1080,
                      0
                    ],
                    to: [
                      0,
                      -78.667,
                      0
                    ],
                    ti: [
                      0,
                      72,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 375,
                    s: [
                      800,
                      608,
                      0
                    ],
                    to: [
                      0,
                      -72,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 388,
                    s: [
                      800,
                      648,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      6.667,
                      0
                    ]
                  },
                  {
                    t: 401.000016333065,
                    s: [
                      800,
                      608,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  78,
                  78,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 243,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 250.000010182709,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 243.000009897593,
            op: 7188.00029277324,
            st: -4.00000016292334,
            bm: 0
          },
          {
            ddd: 0,
            ind: 10,
            ty: 2,
            nm: "01 Copy 20.png",
            cl: "png",
            refId: "image_38",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: {
                      x: 0,
                      y: 1
                    },
                    o: {
                      x: 0.167,
                      y: 0.167
                    },
                    t: 247,
                    s: [
                      1920,
                      1080,
                      0
                    ],
                    to: [
                      -170.667,
                      0,
                      0
                    ],
                    ti: [
                      170.667,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 0.667
                    },
                    o: {
                      x: 0,
                      y: 0
                    },
                    t: 300,
                    s: [
                      896,
                      1080,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 301,
                    s: [
                      896,
                      1080,
                      0
                    ],
                    to: [
                      0,
                      -78.667,
                      0
                    ],
                    ti: [
                      0,
                      72,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 375,
                    s: [
                      896,
                      608,
                      0
                    ],
                    to: [
                      0,
                      -72,
                      0
                    ],
                    ti: [
                      0,
                      0,
                      0
                    ]
                  },
                  {
                    i: {
                      x: 0.667,
                      y: 1
                    },
                    o: {
                      x: 0.333,
                      y: 0
                    },
                    t: 388,
                    s: [
                      896,
                      648,
                      0
                    ],
                    to: [
                      0,
                      0,
                      0
                    ],
                    ti: [
                      0,
                      6.667,
                      0
                    ]
                  },
                  {
                    t: 401.000016333065,
                    s: [
                      896,
                      608,
                      0
                    ]
                  }
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  78,
                  78,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0.667,
                        0.667,
                        0.667
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        0
                      ]
                    },
                    t: 247,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    t: 254.000010345632,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 247.000010060516,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: "Shape Layer 6",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1876,
                  1072,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 1,
                      k: [
                        {
                          i: {
                            x: 0.833,
                            y: 0.833
                          },
                          o: {
                            x: 0.333,
                            y: 0
                          },
                          t: 300,
                          s: [
                            {
                              i: [
                                [
                                  -580,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -564,
                                  0
                                ],
                                [
                                  -556,
                                  0
                                ]
                              ],
                              o: [
                                [
                                  580,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  564,
                                  0
                                ],
                                [
                                  556,
                                  0
                                ]
                              ],
                              v: [
                                [
                                  -2052,
                                  8
                                ],
                                [
                                  -1040,
                                  8
                                ],
                                [
                                  36,
                                  8
                                ],
                                [
                                  1156,
                                  8
                                ],
                                [
                                  2140,
                                  8
                                ]
                              ],
                              c: false
                            }
                          ]
                        },
                        {
                          i: {
                            x: 0.667,
                            y: 1
                          },
                          o: {
                            x: 0.167,
                            y: 0.167
                          },
                          t: 361,
                          s: [
                            {
                              i: [
                                [
                                  -580,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -564,
                                  0
                                ],
                                [
                                  -556,
                                  0
                                ]
                              ],
                              o: [
                                [
                                  580,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  564,
                                  0
                                ],
                                [
                                  556,
                                  0
                                ]
                              ],
                              v: [
                                [
                                  -2052,
                                  444.068
                                ],
                                [
                                  -1056,
                                  -428.068
                                ],
                                [
                                  50.536,
                                  444.068
                                ],
                                [
                                  1156,
                                  -428.068
                                ],
                                [
                                  2140,
                                  444.068
                                ]
                              ],
                              c: false
                            }
                          ]
                        },
                        {
                          i: {
                            x: 0.833,
                            y: 0.833
                          },
                          o: {
                            x: 0.167,
                            y: 0
                          },
                          t: 375,
                          s: [
                            {
                              i: [
                                [
                                  -580,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -564,
                                  0
                                ],
                                [
                                  -556,
                                  0
                                ]
                              ],
                              o: [
                                [
                                  580,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  564,
                                  0
                                ],
                                [
                                  556,
                                  0
                                ]
                              ],
                              v: [
                                [
                                  -2052,
                                  488
                                ],
                                [
                                  -1040,
                                  -472
                                ],
                                [
                                  52,
                                  488
                                ],
                                [
                                  1156,
                                  -472
                                ],
                                [
                                  2140,
                                  488
                                ]
                              ],
                              c: false
                            }
                          ]
                        },
                        {
                          i: {
                            x: 0.667,
                            y: 1
                          },
                          o: {
                            x: 0.167,
                            y: 0.167
                          },
                          t: 388,
                          s: [
                            {
                              i: [
                                [
                                  -580,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -564,
                                  0
                                ],
                                [
                                  -556,
                                  0
                                ]
                              ],
                              o: [
                                [
                                  580,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  564,
                                  0
                                ],
                                [
                                  556,
                                  0
                                ]
                              ],
                              v: [
                                [
                                  -2052,
                                  444.068
                                ],
                                [
                                  -1056,
                                  -428.068
                                ],
                                [
                                  50.536,
                                  444.068
                                ],
                                [
                                  1156,
                                  -428.068
                                ],
                                [
                                  2140,
                                  444.068
                                ]
                              ],
                              c: false
                            }
                          ]
                        },
                        {
                          t: 401.000016333065,
                          s: [
                            {
                              i: [
                                [
                                  -580,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -620,
                                  0
                                ],
                                [
                                  -564,
                                  0
                                ],
                                [
                                  -556,
                                  0
                                ]
                              ],
                              o: [
                                [
                                  580,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  620,
                                  0
                                ],
                                [
                                  564,
                                  0
                                ],
                                [
                                  556,
                                  0
                                ]
                              ],
                              v: [
                                [
                                  -2052,
                                  488
                                ],
                                [
                                  -1040,
                                  -472
                                ],
                                [
                                  52,
                                  488
                                ],
                                [
                                  1156,
                                  -472
                                ],
                                [
                                  2140,
                                  488
                                ]
                              ],
                              c: false
                            }
                          ]
                        }
                      ],
                      ix: 2
                    },
                    nm: "Path 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 14,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Shape 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 300.00001221925,
            op: 7192.00029293617,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 12,
            ty: 2,
            nm: "newhires.png",
            cl: "png",
            refId: "image_39",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1920,
                  1440,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  209,
                  46,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.998,
                        0.998,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0.167,
                        16.667
                      ]
                    },
                    t: 140,
                    s: [
                      0,
                      0,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.833,
                        0.833,
                        0.833
                      ],
                      y: [
                        1,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0,
                        0,
                        0
                      ]
                    },
                    t: 160,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    i: {
                      x: [
                        0.833,
                        0.833,
                        0.833
                      ],
                      y: [
                        0.833,
                        0.833,
                        17.667
                      ]
                    },
                    o: {
                      x: [
                        1,
                        1,
                        0.333
                      ],
                      y: [
                        -0.002,
                        -0.002,
                        0
                      ]
                    },
                    t: 210,
                    s: [
                      100,
                      100,
                      100
                    ]
                  },
                  {
                    t: 230.000009368092,
                    s: [
                      0,
                      0,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 140.000005702317,
            op: 7192.00029293616,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: "Shape Layer 4",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  1914,
                  1084,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  0,
                  0,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0.667,
                        0.667
                      ],
                      y: [
                        0.999,
                        1,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0.167,
                        0,
                        0
                      ]
                    },
                    t: 210,
                    s: [
                      0,
                      100,
                      100
                    ]
                  },
                  {
                    t: 300.00001221925,
                    s: [
                      100,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        o: [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        v: [
                          [
                            -1944,
                            -4
                          ],
                          [
                            1956,
                            -4
                          ]
                        ],
                        c: false
                      },
                      ix: 2
                    },
                    nm: "Path 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 14,
                      ix: 5
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [
                        1,
                        1,
                        1,
                        1
                      ],
                      ix: 4
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Shape 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              }
            ],
            ip: 210.000008553475,
            op: 300.00001221925,
            st: 0,
            bm: 0
          }
        ]
      }
    ],
    fonts: {
      list: [
        {
          fName: "Gordita-Bold",
          fFamily: "Gordita",
          fStyle: "Bold",
          ascent: 81.409536132589
        },
        {
          fName: "Gordita-Medium",
          fFamily: "Gordita",
          fStyle: "Medium",
          ascent: 81.409536132589
        }
      ]
    },
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 0,
        nm: "Leaderboard and other graphics",
        refId: "comp_0",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 0,
                s: [
                  0
                ]
              },
              {
                t: 8.00000032584668,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              1631.25,
              916,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              1920,
              1080,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              84.82,
              84.82,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        w: 3840,
        h: 2160,
        ip: 0,
        op: 7051.80228722578,
        st: -139.000005661586,
        bm: 0
      },
      {
        ddd: 0,
        ind: 2,
        ty: 2,
        nm: "bg.png",
        cl: "png",
        refId: "image_40",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              1631.5,
              916.5,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              1866,
              1050,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              87.429,
              87.31,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 0,
        op: 536.000021831728,
        st: 0,
        bm: 0
      }
    ],
    markers: [
      {
        tm: 690.000028104276,
        cm: "1",
        dr: 0
      }
    ],
    chars: [
      {
        ch: "2",
        size: 227,
        style: "Bold",
        w: 67.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.921,
                          3.928
                        ],
                        [
                          0,
                          5.64
                        ],
                        [
                          5.136,
                          4.834
                        ],
                        [
                          8.459,
                          0
                        ],
                        [
                          5.338,
                          -3.223
                        ],
                        [
                          1.913,
                          -6.143
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.546,
                          0
                        ],
                        [
                          0,
                          -5.64
                        ],
                        [
                          1.913,
                          -2.417
                        ],
                        [
                          4.935,
                          -4.028
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.553,
                          -5.942
                        ],
                        [
                          3.021,
                          -3.928
                        ],
                        [
                          0,
                          -7.251
                        ],
                        [
                          -5.136,
                          -4.935
                        ],
                        [
                          -6.747,
                          0
                        ],
                        [
                          -5.237,
                          3.122
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.618,
                          -6.546
                        ],
                        [
                          6.445,
                          0
                        ],
                        [
                          0,
                          2.921
                        ],
                        [
                          -1.913,
                          2.417
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          4.733,
                          0
                        ],
                        [
                          62.439,
                          0
                        ],
                        [
                          62.439,
                          -14.905
                        ],
                        [
                          28.4,
                          -14.905
                        ],
                        [
                          41.693,
                          -25.479
                        ],
                        [
                          57.504,
                          -40.283
                        ],
                        [
                          62.036,
                          -54.584
                        ],
                        [
                          54.382,
                          -72.711
                        ],
                        [
                          34.039,
                          -80.063
                        ],
                        [
                          15.912,
                          -75.229
                        ],
                        [
                          5.237,
                          -61.23
                        ],
                        [
                          20.242,
                          -54.886
                        ],
                        [
                          33.939,
                          -64.755
                        ],
                        [
                          45.218,
                          -54.483
                        ],
                        [
                          42.398,
                          -46.527
                        ],
                        [
                          32.227,
                          -36.859
                        ],
                        [
                          4.733,
                          -14.301
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "1",
        size: 227,
        style: "Bold",
        w: 49.4,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          24.17,
                          -59.418
                        ],
                        [
                          24.17,
                          0
                        ],
                        [
                          40.485,
                          0
                        ],
                        [
                          40.485,
                          -78.854
                        ],
                        [
                          27.09,
                          -78.854
                        ],
                        [
                          0.302,
                          -63.144
                        ],
                        [
                          7.553,
                          -49.951
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "0",
        size: 227,
        style: "Bold",
        w: 76.8,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -7.251
                        ],
                        [
                          -5.237,
                          -7.654
                        ],
                        [
                          -11.179,
                          0
                        ],
                        [
                          0,
                          20.847
                        ],
                        [
                          10.474,
                          7.654
                        ],
                        [
                          7.15,
                          0
                        ],
                        [
                          5.237,
                          -3.928
                        ],
                        [
                          2.417,
                          -5.942
                        ]
                      ],
                      o: [
                        [
                          0,
                          10.574
                        ],
                        [
                          5.338,
                          7.654
                        ],
                        [
                          22.659,
                          0
                        ],
                        [
                          0,
                          -14.401
                        ],
                        [
                          -5.136,
                          -3.928
                        ],
                        [
                          -7.352,
                          0
                        ],
                        [
                          -5.237,
                          3.928
                        ],
                        [
                          -2.316,
                          5.942
                        ]
                      ],
                      v: [
                        [
                          4.935,
                          -39.478
                        ],
                        [
                          12.991,
                          -11.481
                        ],
                        [
                          38.571,
                          1.007
                        ],
                        [
                          72.51,
                          -39.88
                        ],
                        [
                          57.404,
                          -74.222
                        ],
                        [
                          38.873,
                          -80.063
                        ],
                        [
                          19.94,
                          -74.121
                        ],
                        [
                          8.459,
                          -59.317
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -3.122,
                          10.272
                        ],
                        [
                          -2.719,
                          2.719
                        ],
                        [
                          -3.928,
                          0
                        ],
                        [
                          -2.719,
                          -2.82
                        ],
                        [
                          3.122,
                          -10.574
                        ],
                        [
                          2.719,
                          -2.82
                        ],
                        [
                          3.928,
                          0
                        ],
                        [
                          2.719,
                          2.719
                        ]
                      ],
                      o: [
                        [
                          1.108,
                          -3.525
                        ],
                        [
                          2.719,
                          -2.82
                        ],
                        [
                          3.928,
                          0
                        ],
                        [
                          7.956,
                          7.956
                        ],
                        [
                          -1.108,
                          3.625
                        ],
                        [
                          -2.719,
                          2.719
                        ],
                        [
                          -3.928,
                          0
                        ],
                        [
                          -7.956,
                          -8.157
                        ]
                      ],
                      v: [
                        [
                          22.961,
                          -50.757
                        ],
                        [
                          28.702,
                          -60.223
                        ],
                        [
                          38.672,
                          -64.453
                        ],
                        [
                          48.642,
                          -60.223
                        ],
                        [
                          54.382,
                          -28.5
                        ],
                        [
                          48.642,
                          -18.832
                        ],
                        [
                          38.672,
                          -14.703
                        ],
                        [
                          28.702,
                          -18.832
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "0",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "3",
        size: 227,
        style: "Bold",
        w: 66.1,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          6.848
                        ],
                        [
                          8.762,
                          3.625
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          7.251
                        ],
                        [
                          5.035,
                          4.129
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          4.431,
                          -10.876
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -5.942,
                          0
                        ],
                        [
                          0,
                          -4.935
                        ],
                        [
                          6.949,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -5.942
                        ],
                        [
                          6.949,
                          0
                        ],
                        [
                          2.719,
                          5.74
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -13.797,
                          0
                        ],
                        [
                          -5.438,
                          4.532
                        ]
                      ],
                      o: [
                        [
                          0,
                          -8.762
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.15,
                          -3.827
                        ],
                        [
                          0,
                          -6.747
                        ],
                        [
                          -5.035,
                          -4.129
                        ],
                        [
                          -13.193,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.719,
                          -5.841
                        ],
                        [
                          7.15,
                          0
                        ],
                        [
                          0,
                          5.237
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.251,
                          0
                        ],
                        [
                          0,
                          5.539
                        ],
                        [
                          -7.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.733,
                          10.977
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          5.438,
                          -4.532
                        ]
                      ],
                      v: [
                        [
                          61.533,
                          -22.659
                        ],
                        [
                          49.246,
                          -40.686
                        ],
                        [
                          49.246,
                          -40.787
                        ],
                        [
                          60.022,
                          -57.404
                        ],
                        [
                          52.469,
                          -73.819
                        ],
                        [
                          32.831,
                          -80.063
                        ],
                        [
                          4.733,
                          -63.043
                        ],
                        [
                          19.336,
                          -56.094
                        ],
                        [
                          32.227,
                          -64.856
                        ],
                        [
                          43.607,
                          -56.296
                        ],
                        [
                          32.529,
                          -47.433
                        ],
                        [
                          25.076,
                          -47.433
                        ],
                        [
                          25.076,
                          -32.327
                        ],
                        [
                          33.435,
                          -32.327
                        ],
                        [
                          45.016,
                          -23.062
                        ],
                        [
                          33.435,
                          -13.898
                        ],
                        [
                          17.825,
                          -22.961
                        ],
                        [
                          3.525,
                          -15.61
                        ],
                        [
                          33.032,
                          1.208
                        ],
                        [
                          53.375,
                          -5.539
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "4",
        size: 227,
        style: "Bold",
        w: 72.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          41.995,
                          -15.61
                        ],
                        [
                          41.995,
                          0
                        ],
                        [
                          57.907,
                          0
                        ],
                        [
                          57.907,
                          -15.71
                        ],
                        [
                          69.086,
                          -15.71
                        ],
                        [
                          69.086,
                          -30.313
                        ],
                        [
                          57.907,
                          -30.313
                        ],
                        [
                          57.907,
                          -78.854
                        ],
                        [
                          38.47,
                          -78.854
                        ],
                        [
                          3.122,
                          -31.622
                        ],
                        [
                          3.122,
                          -15.61
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          41.995,
                          -59.72
                        ],
                        [
                          41.995,
                          -30.313
                        ],
                        [
                          20.041,
                          -30.313
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "4",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "6",
        size: 227,
        style: "Bold",
        w: 69.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          4.532,
                          -5.338
                        ],
                        [
                          -2.921,
                          4.129
                        ],
                        [
                          -5.841,
                          0
                        ],
                        [
                          -4.633,
                          -3.625
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          6.143,
                          -7.956
                        ],
                        [
                          0,
                          -12.891
                        ],
                        [
                          -4.431,
                          -5.74
                        ],
                        [
                          -10.373,
                          0
                        ],
                        [
                          -5.942,
                          4.935
                        ],
                        [
                          0,
                          8.258
                        ],
                        [
                          4.834,
                          4.834
                        ],
                        [
                          7.553,
                          0
                        ]
                      ],
                      o: [
                        [
                          0.302,
                          -6.244
                        ],
                        [
                          3.021,
                          -4.129
                        ],
                        [
                          4.834,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.747,
                          -5.237
                        ],
                        [
                          -11.481,
                          0
                        ],
                        [
                          -6.143,
                          7.956
                        ],
                        [
                          0,
                          12.186
                        ],
                        [
                          5.74,
                          7.352
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          5.942,
                          -4.935
                        ],
                        [
                          0,
                          -6.949
                        ],
                        [
                          -4.733,
                          -4.935
                        ],
                        [
                          -7.15,
                          0
                        ]
                      ],
                      v: [
                        [
                          22.256,
                          -43.304
                        ],
                        [
                          27.09,
                          -58.813
                        ],
                        [
                          40.384,
                          -65.057
                        ],
                        [
                          54.584,
                          -59.619
                        ],
                        [
                          63.043,
                          -72.107
                        ],
                        [
                          40.686,
                          -79.962
                        ],
                        [
                          14.2,
                          -68.079
                        ],
                        [
                          5.035,
                          -36.859
                        ],
                        [
                          11.682,
                          -10.071
                        ],
                        [
                          35.953,
                          1.007
                        ],
                        [
                          56.497,
                          -6.445
                        ],
                        [
                          65.359,
                          -26.285
                        ],
                        [
                          58.109,
                          -44.009
                        ],
                        [
                          39.679,
                          -51.361
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          3.323
                        ],
                        [
                          -7.251,
                          0
                        ],
                        [
                          0,
                          -7.15
                        ],
                        [
                          2.518,
                          -2.316
                        ],
                        [
                          3.726,
                          0
                        ],
                        [
                          2.719,
                          2.316
                        ]
                      ],
                      o: [
                        [
                          0,
                          -7.251
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          0,
                          3.323
                        ],
                        [
                          -2.518,
                          2.316
                        ],
                        [
                          -3.625,
                          0
                        ],
                        [
                          -2.719,
                          -2.316
                        ]
                      ],
                      v: [
                        [
                          22.256,
                          -25.781
                        ],
                        [
                          35.651,
                          -37.564
                        ],
                        [
                          48.944,
                          -25.681
                        ],
                        [
                          45.117,
                          -17.12
                        ],
                        [
                          35.751,
                          -13.696
                        ],
                        [
                          26.285,
                          -17.221
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "6",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "8",
        size: 227,
        style: "Bold",
        w: 68.6,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          7.05
                        ],
                        [
                          9.164,
                          3.928
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          5.136,
                          4.129
                        ],
                        [
                          8.359,
                          0
                        ],
                        [
                          5.136,
                          -4.129
                        ],
                        [
                          0,
                          -6.143
                        ],
                        [
                          -7.352,
                          -2.921
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.56
                        ],
                        [
                          -5.64,
                          -4.33
                        ],
                        [
                          -8.359,
                          0
                        ],
                        [
                          -5.64,
                          4.23
                        ]
                      ],
                      o: [
                        [
                          0,
                          -8.56
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.352,
                          -2.921
                        ],
                        [
                          0,
                          -6.143
                        ],
                        [
                          -5.136,
                          -4.129
                        ],
                        [
                          -8.359,
                          0
                        ],
                        [
                          -5.136,
                          4.129
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -9.164,
                          4.028
                        ],
                        [
                          0,
                          6.747
                        ],
                        [
                          5.64,
                          4.23
                        ],
                        [
                          8.359,
                          0
                        ],
                        [
                          5.64,
                          -4.23
                        ]
                      ],
                      v: [
                        [
                          63.95,
                          -22.256
                        ],
                        [
                          50.153,
                          -40.887
                        ],
                        [
                          50.153,
                          -40.988
                        ],
                        [
                          62.338,
                          -58.511
                        ],
                        [
                          54.684,
                          -73.92
                        ],
                        [
                          34.543,
                          -80.063
                        ],
                        [
                          14.301,
                          -73.92
                        ],
                        [
                          6.647,
                          -58.511
                        ],
                        [
                          18.832,
                          -40.988
                        ],
                        [
                          18.832,
                          -40.887
                        ],
                        [
                          5.035,
                          -21.954
                        ],
                        [
                          13.495,
                          -5.338
                        ],
                        [
                          34.442,
                          1.007
                        ],
                        [
                          55.49,
                          -5.338
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -5.64
                        ],
                        [
                          6.143,
                          0
                        ],
                        [
                          0,
                          5.942
                        ],
                        [
                          -6.747,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          5.942
                        ],
                        [
                          -6.042,
                          0
                        ],
                        [
                          0,
                          -5.539
                        ],
                        [
                          6.949,
                          0
                        ]
                      ],
                      v: [
                        [
                          46.024,
                          -56.497
                        ],
                        [
                          34.442,
                          -46.729
                        ],
                        [
                          22.961,
                          -56.598
                        ],
                        [
                          34.442,
                          -66.367
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -3.122
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          0,
                          5.942
                        ],
                        [
                          -6.949,
                          0
                        ],
                        [
                          -2.618,
                          -1.712
                        ]
                      ],
                      o: [
                        [
                          0,
                          6.042
                        ],
                        [
                          -7.654,
                          0
                        ],
                        [
                          0,
                          -6.143
                        ],
                        [
                          3.424,
                          0
                        ],
                        [
                          2.618,
                          1.712
                        ]
                      ],
                      v: [
                        [
                          47.534,
                          -23.264
                        ],
                        [
                          34.341,
                          -12.689
                        ],
                        [
                          21.451,
                          -23.264
                        ],
                        [
                          34.543,
                          -33.032
                        ],
                        [
                          43.607,
                          -30.414
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "8",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "5",
        size: 227,
        style: "Bold",
        w: 65.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          8.359
                        ],
                        [
                          4.834,
                          4.33
                        ],
                        [
                          7.251,
                          0
                        ],
                        [
                          4.33,
                          -3.525
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.928,
                          0
                        ],
                        [
                          0,
                          -6.747
                        ],
                        [
                          7.352,
                          0
                        ],
                        [
                          3.021,
                          5.64
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -13.596,
                          0
                        ],
                        [
                          -5.539,
                          4.935
                        ]
                      ],
                      o: [
                        [
                          0,
                          -7.956
                        ],
                        [
                          -4.733,
                          -4.431
                        ],
                        [
                          -5.136,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          3.223,
                          -3.122
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          0,
                          7.251
                        ],
                        [
                          -6.949,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.338,
                          10.272
                        ],
                        [
                          8.459,
                          0
                        ],
                        [
                          5.539,
                          -4.935
                        ]
                      ],
                      v: [
                        [
                          61.734,
                          -26.385
                        ],
                        [
                          54.483,
                          -44.916
                        ],
                        [
                          36.456,
                          -51.562
                        ],
                        [
                          22.256,
                          -46.326
                        ],
                        [
                          24.774,
                          -64.05
                        ],
                        [
                          58.209,
                          -64.05
                        ],
                        [
                          58.209,
                          -78.854
                        ],
                        [
                          11.783,
                          -78.854
                        ],
                        [
                          6.042,
                          -38.873
                        ],
                        [
                          21.35,
                          -32.629
                        ],
                        [
                          32.126,
                          -37.262
                        ],
                        [
                          45.319,
                          -25.983
                        ],
                        [
                          32.73,
                          -14.301
                        ],
                        [
                          17.825,
                          -22.76
                        ],
                        [
                          3.928,
                          -14.502
                        ],
                        [
                          32.327,
                          0.906
                        ],
                        [
                          53.375,
                          -6.445
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "7",
        size: 227,
        style: "Bold",
        w: 63.2,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          42.197,
                          -64.05
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          26.083,
                          0
                        ],
                        [
                          60.928,
                          -64.856
                        ],
                        [
                          60.928,
                          -78.854
                        ],
                        [
                          3.122,
                          -78.854
                        ],
                        [
                          3.122,
                          -64.05
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "7",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "7",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "9",
        size: 227,
        style: "Bold",
        w: 69.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -4.733,
                          5.539
                        ],
                        [
                          2.921,
                          -4.129
                        ],
                        [
                          5.74,
                          0
                        ],
                        [
                          4.633,
                          3.625
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.56,
                          0
                        ],
                        [
                          -5.841,
                          7.755
                        ],
                        [
                          0,
                          12.891
                        ],
                        [
                          4.532,
                          5.841
                        ],
                        [
                          10.876,
                          0
                        ],
                        [
                          5.942,
                          -5.035
                        ],
                        [
                          0,
                          -8.258
                        ],
                        [
                          -4.834,
                          -4.935
                        ],
                        [
                          -7.553,
                          0
                        ]
                      ],
                      o: [
                        [
                          -0.302,
                          6.244
                        ],
                        [
                          -2.921,
                          4.129
                        ],
                        [
                          -5.035,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          6.747,
                          5.237
                        ],
                        [
                          11.279,
                          0
                        ],
                        [
                          5.942,
                          -7.755
                        ],
                        [
                          0,
                          -12.387
                        ],
                        [
                          -4.935,
                          -6.445
                        ],
                        [
                          -7.755,
                          0
                        ],
                        [
                          -5.942,
                          4.935
                        ],
                        [
                          0,
                          6.949
                        ],
                        [
                          4.834,
                          4.935
                        ],
                        [
                          7.15,
                          0
                        ]
                      ],
                      v: [
                        [
                          48.138,
                          -35.651
                        ],
                        [
                          43.405,
                          -20.142
                        ],
                        [
                          30.414,
                          -13.998
                        ],
                        [
                          15.912,
                          -19.437
                        ],
                        [
                          7.452,
                          -6.848
                        ],
                        [
                          30.414,
                          1.007
                        ],
                        [
                          56.094,
                          -10.574
                        ],
                        [
                          64.957,
                          -41.592
                        ],
                        [
                          58.209,
                          -68.884
                        ],
                        [
                          34.039,
                          -79.962
                        ],
                        [
                          13.495,
                          -72.409
                        ],
                        [
                          4.633,
                          -52.57
                        ],
                        [
                          11.884,
                          -34.644
                        ],
                        [
                          30.414,
                          -27.292
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -3.323
                        ],
                        [
                          2.719,
                          -2.216
                        ],
                        [
                          3.625,
                          0
                        ],
                        [
                          0,
                          7.251
                        ],
                        [
                          -2.518,
                          2.316
                        ],
                        [
                          -3.625,
                          0
                        ],
                        [
                          -2.719,
                          -2.417
                        ]
                      ],
                      o: [
                        [
                          0,
                          3.625
                        ],
                        [
                          -2.719,
                          2.115
                        ],
                        [
                          -7.755,
                          0
                        ],
                        [
                          0,
                          -3.323
                        ],
                        [
                          2.618,
                          -2.417
                        ],
                        [
                          3.525,
                          0
                        ],
                        [
                          2.719,
                          2.417
                        ]
                      ],
                      v: [
                        [
                          47.736,
                          -52.972
                        ],
                        [
                          43.707,
                          -44.211
                        ],
                        [
                          34.241,
                          -40.988
                        ],
                        [
                          21.048,
                          -53.073
                        ],
                        [
                          24.875,
                          -61.633
                        ],
                        [
                          34.241,
                          -65.259
                        ],
                        [
                          43.607,
                          -61.633
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "9",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "T",
        size: 210,
        style: "Medium",
        w: 66,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          2.014,
                          -67.374
                        ],
                        [
                          26.99,
                          -67.374
                        ],
                        [
                          26.99,
                          0
                        ],
                        [
                          39.377,
                          0
                        ],
                        [
                          39.377,
                          -67.374
                        ],
                        [
                          64.453,
                          -67.374
                        ],
                        [
                          64.453,
                          -78.854
                        ],
                        [
                          2.014,
                          -78.854
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "T",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "T",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "r",
        size: 210,
        style: "Medium",
        w: 39.3,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          1.309,
                          0
                        ],
                        [
                          3.323,
                          -6.042
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -9.164,
                          0
                        ],
                        [
                          -1.007,
                          -0.201
                        ]
                      ],
                      o: [
                        [
                          -0.705,
                          -0.201
                        ],
                        [
                          -6.546,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -9.366
                        ],
                        [
                          1.309,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          37.463,
                          -53.979
                        ],
                        [
                          34.543,
                          -54.282
                        ],
                        [
                          19.739,
                          -45.218
                        ],
                        [
                          19.739,
                          -53.778
                        ],
                        [
                          8.057,
                          -53.778
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          20.041,
                          0
                        ],
                        [
                          20.041,
                          -27.292
                        ],
                        [
                          34.039,
                          -41.995
                        ],
                        [
                          37.463,
                          -41.693
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "r",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "r",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "a",
        size: 210,
        style: "Medium",
        w: 57.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -4.431
                        ],
                        [
                          3.323,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.488
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.424,
                          -2.921
                        ],
                        [
                          -5.237,
                          0
                        ],
                        [
                          -3.323,
                          5.539
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.208,
                          2.719
                        ],
                        [
                          10.071,
                          0
                        ],
                        [
                          4.23,
                          -9.164
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.143,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          3.021
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -9.467,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.633
                        ],
                        [
                          3.424,
                          2.921
                        ],
                        [
                          7.251,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -3.726
                        ],
                        [
                          -3.323,
                          -7.352
                        ],
                        [
                          -10.977,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.719,
                          -5.74
                        ],
                        [
                          6.747,
                          0
                        ]
                      ],
                      v: [
                        [
                          38.773,
                          -36.456
                        ],
                        [
                          33.737,
                          -31.924
                        ],
                        [
                          27.09,
                          -31.924
                        ],
                        [
                          4.733,
                          -15.509
                        ],
                        [
                          4.733,
                          -14.905
                        ],
                        [
                          9.869,
                          -3.525
                        ],
                        [
                          22.861,
                          0.906
                        ],
                        [
                          38.672,
                          -7.352
                        ],
                        [
                          38.672,
                          0
                        ],
                        [
                          50.455,
                          0
                        ],
                        [
                          50.455,
                          -34.241
                        ],
                        [
                          48.642,
                          -43.909
                        ],
                        [
                          28.5,
                          -54.886
                        ],
                        [
                          5.64,
                          -41.19
                        ],
                        [
                          14.804,
                          -35.953
                        ],
                        [
                          28.198,
                          -44.513
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          0,
                          4.431
                        ],
                        [
                          -2.115,
                          1.309
                        ],
                        [
                          -2.518,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          8.057
                        ],
                        [
                          -5.136,
                          0
                        ],
                        [
                          0,
                          -2.316
                        ],
                        [
                          2.216,
                          -1.309
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          38.672,
                          -21.753
                        ],
                        [
                          25.278,
                          -8.661
                        ],
                        [
                          16.718,
                          -16.013
                        ],
                        [
                          19.94,
                          -21.451
                        ],
                        [
                          27.09,
                          -23.465
                        ],
                        [
                          38.672,
                          -23.465
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "a",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "i",
        size: 210,
        style: "Medium",
        w: 27.8,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -3.122,
                          -3.122
                        ],
                        [
                          -3.122,
                          3.122
                        ],
                        [
                          3.122,
                          3.122
                        ],
                        [
                          3.122,
                          -3.223
                        ]
                      ],
                      o: [
                        [
                          3.122,
                          3.122
                        ],
                        [
                          3.122,
                          -3.122
                        ],
                        [
                          -3.122,
                          -3.223
                        ],
                        [
                          -3.122,
                          3.122
                        ]
                      ],
                      v: [
                        [
                          8.459,
                          -65.662
                        ],
                        [
                          19.537,
                          -65.662
                        ],
                        [
                          19.537,
                          -76.74
                        ],
                        [
                          8.459,
                          -76.74
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "i",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          20.041,
                          -53.778
                        ],
                        [
                          8.057,
                          -53.778
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          20.041,
                          0
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "i",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "i",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "n",
        size: 210,
        style: "Medium",
        w: 63.3,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          13.193,
                          0
                        ],
                        [
                          4.129,
                          -5.539
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.553,
                          0
                        ],
                        [
                          0,
                          -8.057
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.891
                        ],
                        [
                          -6.848,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.157
                        ],
                        [
                          7.956,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          56.296,
                          0
                        ],
                        [
                          56.296,
                          -33.133
                        ],
                        [
                          36.154,
                          -54.886
                        ],
                        [
                          19.739,
                          -46.527
                        ],
                        [
                          19.739,
                          -53.778
                        ],
                        [
                          8.057,
                          -53.778
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          20.041,
                          0
                        ],
                        [
                          20.041,
                          -29.91
                        ],
                        [
                          32.428,
                          -43.506
                        ],
                        [
                          44.312,
                          -30.112
                        ],
                        [
                          44.312,
                          0
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "n",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "n",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: " ",
        size: 210,
        style: "Medium",
        w: 26.4,
        data: {},
        fFamily: "Gordita"
      },
      {
        ch: "h",
        size: 210,
        style: "Medium",
        w: 63.3,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          13.193,
                          0
                        ],
                        [
                          4.129,
                          -5.338
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.553,
                          0
                        ],
                        [
                          0,
                          -8.057
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.891
                        ],
                        [
                          -6.546,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.157
                        ],
                        [
                          7.956,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          56.296,
                          0
                        ],
                        [
                          56.296,
                          -33.133
                        ],
                        [
                          36.154,
                          -54.886
                        ],
                        [
                          20.041,
                          -46.93
                        ],
                        [
                          20.041,
                          -81.976
                        ],
                        [
                          8.057,
                          -81.976
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          20.041,
                          0
                        ],
                        [
                          20.041,
                          -29.91
                        ],
                        [
                          32.428,
                          -43.506
                        ],
                        [
                          44.312,
                          -30.112
                        ],
                        [
                          44.312,
                          0
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "h",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "h",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "g",
        size: 210,
        style: "Medium",
        w: 67.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -7.553,
                          0
                        ],
                        [
                          -3.525,
                          1.511
                        ],
                        [
                          -1.913,
                          3.122
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          9.366,
                          0
                        ],
                        [
                          3.726,
                          7.553
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -13.092,
                          0
                        ],
                        [
                          -5.136,
                          4.935
                        ],
                        [
                          0,
                          8.459
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          3.424,
                          1.511
                        ],
                        [
                          2.921,
                          0
                        ],
                        [
                          4.935,
                          -5.64
                        ],
                        [
                          0,
                          -7.15
                        ],
                        [
                          -4.935,
                          -5.438
                        ]
                      ],
                      o: [
                        [
                          2.82,
                          0
                        ],
                        [
                          3.525,
                          -1.511
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          10.776
                        ],
                        [
                          -7.553,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.935,
                          10.876
                        ],
                        [
                          8.459,
                          0
                        ],
                        [
                          5.136,
                          -4.935
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.913,
                          -3.021
                        ],
                        [
                          -3.424,
                          -1.511
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          -4.834,
                          5.64
                        ],
                        [
                          0,
                          7.452
                        ],
                        [
                          4.935,
                          5.438
                        ]
                      ],
                      v: [
                        [
                          30.515,
                          0.403
                        ],
                        [
                          39.981,
                          -1.813
                        ],
                        [
                          48.138,
                          -8.661
                        ],
                        [
                          48.138,
                          -0.201
                        ],
                        [
                          31.824,
                          16.214
                        ],
                        [
                          14.905,
                          4.834
                        ],
                        [
                          4.935,
                          10.574
                        ],
                        [
                          32.025,
                          26.889
                        ],
                        [
                          52.368,
                          19.437
                        ],
                        [
                          60.123,
                          -0.705
                        ],
                        [
                          60.123,
                          -53.778
                        ],
                        [
                          48.441,
                          -53.778
                        ],
                        [
                          48.441,
                          -45.621
                        ],
                        [
                          40.384,
                          -52.368
                        ],
                        [
                          30.917,
                          -54.684
                        ],
                        [
                          11.581,
                          -46.225
                        ],
                        [
                          4.33,
                          -27.09
                        ],
                        [
                          11.682,
                          -7.755
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -4.733
                        ],
                        [
                          3.021,
                          -3.323
                        ],
                        [
                          4.733,
                          0
                        ],
                        [
                          3.021,
                          3.223
                        ],
                        [
                          0,
                          4.733
                        ],
                        [
                          -2.921,
                          3.122
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -2.921,
                          -3.223
                        ]
                      ],
                      o: [
                        [
                          0,
                          4.733
                        ],
                        [
                          -2.921,
                          3.223
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -2.921,
                          -3.323
                        ],
                        [
                          0,
                          -4.733
                        ],
                        [
                          2.921,
                          -3.223
                        ],
                        [
                          4.733,
                          0
                        ],
                        [
                          2.921,
                          3.223
                        ]
                      ],
                      v: [
                        [
                          48.34,
                          -27.292
                        ],
                        [
                          43.808,
                          -15.207
                        ],
                        [
                          32.327,
                          -10.373
                        ],
                        [
                          20.746,
                          -15.207
                        ],
                        [
                          16.315,
                          -27.292
                        ],
                        [
                          20.746,
                          -39.175
                        ],
                        [
                          32.327,
                          -44.009
                        ],
                        [
                          43.909,
                          -39.175
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "g",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "-",
        size: 210,
        style: "Medium",
        w: 46.4,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          6.949,
                          -26.587
                        ],
                        [
                          39.78,
                          -26.587
                        ],
                        [
                          39.78,
                          -38.773
                        ],
                        [
                          6.949,
                          -38.773
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "-",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "-",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "p",
        size: 210,
        style: "Medium",
        w: 68.1,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          7.553
                        ],
                        [
                          4.935,
                          5.64
                        ],
                        [
                          7.855,
                          0
                        ],
                        [
                          3.525,
                          -1.511
                        ],
                        [
                          2.014,
                          -3.323
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.525,
                          -1.511
                        ],
                        [
                          -2.719,
                          0
                        ],
                        [
                          -5.035,
                          5.539
                        ]
                      ],
                      o: [
                        [
                          0,
                          -7.15
                        ],
                        [
                          -4.834,
                          -5.64
                        ],
                        [
                          -3.021,
                          0
                        ],
                        [
                          -3.525,
                          1.511
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.913,
                          2.921
                        ],
                        [
                          3.525,
                          1.41
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          5.035,
                          -5.539
                        ]
                      ],
                      v: [
                        [
                          64.151,
                          -27.09
                        ],
                        [
                          56.799,
                          -46.326
                        ],
                        [
                          37.766,
                          -54.785
                        ],
                        [
                          27.997,
                          -52.57
                        ],
                        [
                          19.739,
                          -45.419
                        ],
                        [
                          19.739,
                          -53.778
                        ],
                        [
                          8.057,
                          -53.778
                        ],
                        [
                          8.057,
                          26.486
                        ],
                        [
                          20.041,
                          26.486
                        ],
                        [
                          20.041,
                          -7.855
                        ],
                        [
                          28.098,
                          -1.309
                        ],
                        [
                          37.463,
                          0.806
                        ],
                        [
                          56.598,
                          -7.452
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "p",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -2.921,
                          3.323
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -2.921,
                          -3.323
                        ],
                        [
                          0,
                          -4.733
                        ],
                        [
                          3.021,
                          -3.323
                        ],
                        [
                          4.733,
                          0
                        ],
                        [
                          3.021,
                          3.223
                        ],
                        [
                          0,
                          4.733
                        ]
                      ],
                      o: [
                        [
                          3.021,
                          -3.323
                        ],
                        [
                          4.733,
                          0
                        ],
                        [
                          3.021,
                          3.323
                        ],
                        [
                          0,
                          4.733
                        ],
                        [
                          -2.921,
                          3.223
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -2.921,
                          -3.323
                        ],
                        [
                          0,
                          -4.733
                        ]
                      ],
                      v: [
                        [
                          24.271,
                          -39.075
                        ],
                        [
                          35.953,
                          -44.009
                        ],
                        [
                          47.534,
                          -39.075
                        ],
                        [
                          52.066,
                          -26.99
                        ],
                        [
                          47.534,
                          -14.905
                        ],
                        [
                          35.953,
                          -10.071
                        ],
                        [
                          24.271,
                          -14.905
                        ],
                        [
                          19.839,
                          -26.99
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "p",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "p",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "e",
        size: 210,
        style: "Medium",
        w: 61.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.611
                        ],
                        [
                          4.633,
                          5.136
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          5.136,
                          -5.438
                        ],
                        [
                          0,
                          -7.755
                        ],
                        [
                          -5.338,
                          -5.338
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          -4.532,
                          8.359
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          6.747,
                          0
                        ],
                        [
                          3.122,
                          2.618
                        ],
                        [
                          0.101,
                          4.028
                        ]
                      ],
                      o: [
                        [
                          0.101,
                          -0.906
                        ],
                        [
                          0,
                          -8.359
                        ],
                        [
                          -4.633,
                          -5.136
                        ],
                        [
                          -7.755,
                          0
                        ],
                        [
                          -5.136,
                          5.438
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          5.438,
                          5.338
                        ],
                        [
                          9.668,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.921,
                          5.539
                        ],
                        [
                          -4.129,
                          0
                        ],
                        [
                          -3.122,
                          -2.719
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          57.504,
                          -23.364
                        ],
                        [
                          57.706,
                          -27.09
                        ],
                        [
                          50.757,
                          -47.232
                        ],
                        [
                          31.522,
                          -54.886
                        ],
                        [
                          12.186,
                          -46.729
                        ],
                        [
                          4.431,
                          -26.99
                        ],
                        [
                          12.488,
                          -6.949
                        ],
                        [
                          32.629,
                          1.007
                        ],
                        [
                          55.591,
                          -11.984
                        ],
                        [
                          46.93,
                          -17.725
                        ],
                        [
                          32.428,
                          -9.366
                        ],
                        [
                          21.552,
                          -13.293
                        ],
                        [
                          16.718,
                          -23.364
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "e",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -7.352,
                          0
                        ],
                        [
                          -0.403,
                          -7.553
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          7.654,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.108,
                          -7.352
                        ]
                      ],
                      v: [
                        [
                          31.522,
                          -44.614
                        ],
                        [
                          45.52,
                          -32.428
                        ],
                        [
                          17.322,
                          -32.428
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "e",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "e",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "f",
        size: 210,
        style: "Medium",
        w: 40.4,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          1.813,
                          0
                        ],
                        [
                          0,
                          -13.293
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.848,
                          0
                        ],
                        [
                          -1.208,
                          -0.302
                        ]
                      ],
                      o: [
                        [
                          -1.913,
                          -0.504
                        ],
                        [
                          -13.193,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -7.15
                        ],
                        [
                          1.712,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          39.276,
                          -81.473
                        ],
                        [
                          33.737,
                          -82.278
                        ],
                        [
                          12.589,
                          -61.331
                        ],
                        [
                          12.589,
                          -53.778
                        ],
                        [
                          2.719,
                          -53.778
                        ],
                        [
                          2.719,
                          -43.204
                        ],
                        [
                          12.589,
                          -43.204
                        ],
                        [
                          12.589,
                          0
                        ],
                        [
                          24.673,
                          0
                        ],
                        [
                          24.673,
                          -43.204
                        ],
                        [
                          38.974,
                          -43.204
                        ],
                        [
                          38.974,
                          -53.778
                        ],
                        [
                          24.673,
                          -53.778
                        ],
                        [
                          24.673,
                          -60.928
                        ],
                        [
                          34.946,
                          -71.603
                        ],
                        [
                          39.276,
                          -71.201
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "f",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "f",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "o",
        size: 210,
        style: "Medium",
        w: 65,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          7.755,
                          0
                        ],
                        [
                          5.539,
                          -5.338
                        ],
                        [
                          0,
                          -7.855
                        ],
                        [
                          -5.539,
                          -5.338
                        ],
                        [
                          -7.855,
                          0
                        ],
                        [
                          -5.438,
                          5.338
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          5.539,
                          5.338
                        ]
                      ],
                      o: [
                        [
                          -7.855,
                          0
                        ],
                        [
                          -5.539,
                          5.338
                        ],
                        [
                          0,
                          7.855
                        ],
                        [
                          5.539,
                          5.338
                        ],
                        [
                          7.956,
                          0
                        ],
                        [
                          5.539,
                          -5.438
                        ],
                        [
                          0,
                          -7.855
                        ],
                        [
                          -5.539,
                          -5.338
                        ]
                      ],
                      v: [
                        [
                          32.831,
                          -54.886
                        ],
                        [
                          12.689,
                          -46.829
                        ],
                        [
                          4.33,
                          -26.99
                        ],
                        [
                          12.589,
                          -7.15
                        ],
                        [
                          32.73,
                          0.906
                        ],
                        [
                          52.771,
                          -7.15
                        ],
                        [
                          61.029,
                          -27.191
                        ],
                        [
                          52.771,
                          -46.93
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "o",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -2.921,
                          3.223
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -2.921,
                          -3.323
                        ],
                        [
                          0,
                          -4.733
                        ],
                        [
                          2.921,
                          -3.323
                        ],
                        [
                          4.733,
                          0
                        ],
                        [
                          3.021,
                          3.323
                        ],
                        [
                          0,
                          4.733
                        ]
                      ],
                      o: [
                        [
                          3.021,
                          -3.323
                        ],
                        [
                          4.733,
                          0
                        ],
                        [
                          3.021,
                          3.323
                        ],
                        [
                          0,
                          4.733
                        ],
                        [
                          -2.921,
                          3.323
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -3.021,
                          -3.424
                        ],
                        [
                          0,
                          -4.733
                        ]
                      ],
                      v: [
                        [
                          21.048,
                          -39.075
                        ],
                        [
                          32.73,
                          -44.009
                        ],
                        [
                          44.312,
                          -39.075
                        ],
                        [
                          48.843,
                          -26.99
                        ],
                        [
                          44.412,
                          -14.905
                        ],
                        [
                          32.831,
                          -9.97
                        ],
                        [
                          21.149,
                          -15.005
                        ],
                        [
                          16.617,
                          -27.191
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "o",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "o",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "m",
        size: 210,
        style: "Medium",
        w: 97.4,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.352,
                          0
                        ],
                        [
                          0,
                          -8.157
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          12.991,
                          0
                        ],
                        [
                          5.539,
                          -6.042
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          4.23,
                          -5.64
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.251,
                          0
                        ],
                        [
                          0,
                          -8.057
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.157
                        ],
                        [
                          7.956,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.891
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          -3.323,
                          -5.942
                        ],
                        [
                          -6.445,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.157
                        ],
                        [
                          7.956,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          55.289,
                          0
                        ],
                        [
                          55.289,
                          -30.011
                        ],
                        [
                          67.172,
                          -43.607
                        ],
                        [
                          78.653,
                          -30.313
                        ],
                        [
                          78.653,
                          0
                        ],
                        [
                          90.637,
                          0
                        ],
                        [
                          90.637,
                          -33.234
                        ],
                        [
                          71.1,
                          -54.987
                        ],
                        [
                          52.368,
                          -46.124
                        ],
                        [
                          35.751,
                          -54.987
                        ],
                        [
                          19.739,
                          -46.527
                        ],
                        [
                          19.739,
                          -53.778
                        ],
                        [
                          8.057,
                          -53.778
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          20.041,
                          0
                        ],
                        [
                          20.041,
                          -29.91
                        ],
                        [
                          31.824,
                          -43.607
                        ],
                        [
                          43.405,
                          -30.212
                        ],
                        [
                          43.405,
                          0
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "m",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "m",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "s",
        size: 210,
        style: "Medium",
        w: 50.2,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -10.675,
                          0
                        ],
                        [
                          -3.726,
                          3.122
                        ],
                        [
                          0,
                          4.935
                        ],
                        [
                          12.186,
                          2.719
                        ],
                        [
                          0,
                          3.525
                        ],
                        [
                          -3.726,
                          0
                        ],
                        [
                          -2.417,
                          -4.834
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          9.668,
                          0
                        ],
                        [
                          3.928,
                          -3.122
                        ],
                        [
                          0,
                          -4.834
                        ],
                        [
                          -2.518,
                          -2.719
                        ],
                        [
                          -5.942,
                          -1.208
                        ],
                        [
                          0,
                          -3.122
                        ],
                        [
                          4.532,
                          0
                        ],
                        [
                          2.921,
                          5.74
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          6.042,
                          0
                        ],
                        [
                          3.726,
                          -3.122
                        ],
                        [
                          0,
                          -8.359
                        ],
                        [
                          -6.949,
                          -1.511
                        ],
                        [
                          0,
                          -2.921
                        ],
                        [
                          5.136,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.726,
                          -8.057
                        ],
                        [
                          -5.237,
                          0
                        ],
                        [
                          -3.928,
                          3.122
                        ],
                        [
                          0,
                          3.827
                        ],
                        [
                          2.921,
                          3.122
                        ],
                        [
                          7.352,
                          1.511
                        ],
                        [
                          0,
                          3.625
                        ],
                        [
                          -5.74,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.129,
                          8.963
                        ]
                      ],
                      v: [
                        [
                          25.479,
                          0.906
                        ],
                        [
                          40.182,
                          -3.726
                        ],
                        [
                          45.822,
                          -15.811
                        ],
                        [
                          27.191,
                          -32.73
                        ],
                        [
                          17.221,
                          -39.578
                        ],
                        [
                          24.774,
                          -44.815
                        ],
                        [
                          36.053,
                          -37.564
                        ],
                        [
                          45.319,
                          -42.801
                        ],
                        [
                          25.177,
                          -54.886
                        ],
                        [
                          11.481,
                          -50.253
                        ],
                        [
                          5.64,
                          -38.37
                        ],
                        [
                          9.467,
                          -28.601
                        ],
                        [
                          22.861,
                          -22.156
                        ],
                        [
                          33.939,
                          -15.106
                        ],
                        [
                          25.479,
                          -9.164
                        ],
                        [
                          12.589,
                          -17.825
                        ],
                        [
                          3.223,
                          -12.589
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "s",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "s",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "l",
        size: 210,
        style: "Medium",
        w: 27.8,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          8.057,
                          0
                        ],
                        [
                          20.041,
                          0
                        ],
                        [
                          20.041,
                          -81.976
                        ],
                        [
                          8.057,
                          -81.976
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "l",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "l",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "t",
        size: 210,
        style: "Medium",
        w: 41.6,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -11.783,
                          0
                        ],
                        [
                          -1.913,
                          0.705
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.208,
                          0
                        ],
                        [
                          0,
                          6.445
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          11.682
                        ],
                        [
                          2.316,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.309,
                          0.302
                        ],
                        [
                          -6.143,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          37.363,
                          -43.002
                        ],
                        [
                          37.363,
                          -53.778
                        ],
                        [
                          24.17,
                          -53.778
                        ],
                        [
                          24.17,
                          -70.395
                        ],
                        [
                          12.186,
                          -70.395
                        ],
                        [
                          12.186,
                          -53.778
                        ],
                        [
                          2.316,
                          -53.778
                        ],
                        [
                          2.316,
                          -43.002
                        ],
                        [
                          12.186,
                          -43.002
                        ],
                        [
                          12.186,
                          -17.322
                        ],
                        [
                          30.817,
                          0.604
                        ],
                        [
                          37.262,
                          -0.504
                        ],
                        [
                          37.262,
                          -10.675
                        ],
                        [
                          33.435,
                          -10.272
                        ],
                        [
                          24.17,
                          -19.235
                        ],
                        [
                          24.17,
                          -43.002
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "t",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "t",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: ".",
        size: 210,
        style: "Medium",
        w: 29.2,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -4.935
                        ],
                        [
                          -4.834,
                          0
                        ],
                        [
                          0,
                          4.834
                        ],
                        [
                          4.935,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          4.733
                        ],
                        [
                          4.834,
                          0
                        ],
                        [
                          0,
                          -4.935
                        ],
                        [
                          -4.834,
                          0
                        ]
                      ],
                      v: [
                        [
                          5.942,
                          -8.258
                        ],
                        [
                          14.703,
                          0.504
                        ],
                        [
                          23.465,
                          -8.258
                        ],
                        [
                          14.703,
                          -17.12
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: ".",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: ".",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      }
    ]
  }