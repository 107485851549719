import { motion } from "framer-motion";
import React, { useRef } from "react";
import LayoutStyles from "../../App.styles";
import { useApp } from "../../context/AppContext";
import useOnScreen from "../../hooks/useOnScreen";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import BlackArrow from "../../assets/icons/buttonarrows/black-arrow.png";
import WhiteArrow from "../../assets/icons/buttonarrows/white-arrow.png";

const SlideButton = ({
  text,
  path,
  blackButton,
  clickFunction
}: {
  text: string;
  path?: string;
  blackButton?: boolean;
  clickFunction?: () => void;
}) => {
  const { setShowScheduler } = useApp();
  const navigate = useNavigate();
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);

  if (path && path.includes("#")) {
    return (
      <HashLink to={path}>
        <LayoutStyles.ImageAndTextBox.SlideButton
          // initial={{ width: 53.2 }}
          // animate={{ width: isOnScreen ? 196 : 53.2 }}
          // transition={{ delay: 0.1, duration: 0.65 }}
          style={{ overflow: "hidden", cursor: "pointer" }}
          ref={ref}
          $blackButton={blackButton}
        >
          <LayoutStyles.ImageAndTextBox.ArrowIcon $blackButton={blackButton}>
            <img
              src={blackButton ? WhiteArrow : BlackArrow}
              style={{ width: "32px" }}
              alt=""
            />
          </LayoutStyles.ImageAndTextBox.ArrowIcon>
          <motion.p>{text}</motion.p>
        </LayoutStyles.ImageAndTextBox.SlideButton>
      </HashLink>
    );
  }

    return (
      <LayoutStyles.ImageAndTextBox.SlideButton
        onClick={() => {
          if (!path && clickFunction) {
            clickFunction();
          }
          if (text.includes('Schedule') || text.includes('schedule') || text.includes('demo')) {
            setShowScheduler(true);
          }
          if (path) {
            navigate(path);
          }
        }}
        // initial={{ width: 53.2 }}
        // animate={{ width: isOnScreen ? 196 : 53.2 }}
        // transition={{ delay: 0.1, duration: 0.65 }}
        style={{ overflow: "hidden", cursor: "pointer" }}
        ref={ref}
        $blackButton={blackButton}
      >
        <LayoutStyles.ImageAndTextBox.ArrowIcon $blackButton={blackButton}>
          <img
            src={blackButton ? WhiteArrow : BlackArrow}
            style={{ width: "32px" }}
            alt=""
          />
        </LayoutStyles.ImageAndTextBox.ArrowIcon>
        <motion.p>{text}</motion.p>
      </LayoutStyles.ImageAndTextBox.SlideButton>
    );

};

export default SlideButton;
