import React from "react";
import Pill from "../Common/Pill";
import LayoutStyles from "../App.styles";
import Tag from "../Common/Tag";

type Props = {
  textFirst?: boolean;
  tag?: string;
  data?: {
    headline: string;
    subtitle?: string;
  };
};

const TextFirstHero: React.FC<Props> = ({ textFirst, data, tag }) => {
  return (
    <LayoutStyles.TextFirstHero.Container $textFirst={textFirst}>
      <>
        {tag && <Tag text={tag} center />}
        <LayoutStyles.PageTitle>
          {data ? data.headline : "This page discusses customer engagement"}
        </LayoutStyles.PageTitle>
        <LayoutStyles.Body style={{  marginBottom: "0", textAlign: "center" }}>
          {data?.subtitle && data.subtitle}
        </LayoutStyles.Body>
      </>
    </LayoutStyles.TextFirstHero.Container>
  );
};

export default TextFirstHero;
