import React, { useEffect, useState, useRef } from "react";
import dataJSON from "../../data.json";
import { useApp } from "../../context/AppContext";
import XurealLogo from "../../assets/images/black-logo.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import BackArrow from "../../assets/icons/buttonarrows/gray-back-arrow.png";
import LayoutStyles from "../../App.styles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faHouse,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "../../assets/icons/close.svg";
import ArrowButton from "../Button/ArrowButton";
import theme from "../../theme";

const Header = () => {
  const { setCurrentPage, currentPage, setShowScheduler } = useApp();
  const navigate = useNavigate();
  const anchorEl = useRef<null | HTMLDivElement>(null);
  const [, toggleSolutions] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dropDownOpen, setDropDown] = useState(false);
  const [hover, setHover] = useState(false);
  const [menuPage, setMenuPage] = useState<
    "metaverse" | "enablement" | "training"
  >("metaverse");
  const [drawerPage, setDrawerPage] = useState<
    "metaverse" | "enablement" | "training" | ""
  >("metaverse");
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down(828));
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(location.pathname.substring(1));
  }, [location]);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setDrawerPage("");
  };

  const handleNavClick = (navigateTo: string) => {
    setDropDown(false);
    setCurrentPage(navigateTo);
    handleDrawerClose();
  };

  const handleDrawerClick = (
    navigateTo: "metaverse" | "sales" | "training" | ""
  ) => {
    navigate(navigateTo);
    setDrawerOpen(false);
  };

  const updateHover = (
    color: string,
    page: "metaverse" | "training" | "enablement"
  ) => {
    setHover(true);
    setMenuPage(page);
  };

  return (
    <LayoutStyles.Header.Container>
      <LayoutStyles.Header.InnerWrapper ref={anchorEl}>
        <a href={window.location.origin} style={{ marginRight: "20px" }}>
          <LayoutStyles.Header.Logo src={XurealLogo} alt="logo" />
        </a>
        {!isSmallScreen && (
          <LayoutStyles.Header.NavButtonGroup>
            <LayoutStyles.Header.NavButton
              onClick={() => {
                setDropDown((prev) => !prev);
              }}
              $selected={dropDownOpen}
              style={{ width: "100px", fontWeight: "500" }}
            >
              Solutions
              <FontAwesomeIcon
                size="xs"
                style={{ marginLeft: "8px" }}
                color={"#151418"}
                icon={dropDownOpen ? faChevronUp : faChevronDown}
              />
            </LayoutStyles.Header.NavButton>
            <Menu
              sx={{
                "& .MuiList-root": {
                  display: "flex",
                  backgroundColor: theme.palette.neutral.light,
                },
              }}
              anchorEl={anchorEl.current}
              onClose={() => setDropDown(false)}
              open={dropDownOpen}
            >
              <MenuItem
                disableRipple
                className={`page-choices`}
                sx={{ backgroundColor: "#ffffff" }}
              >
                <LayoutStyles.Header.DropDownPage
                  onMouseEnter={() =>
                    updateHover(theme.palette.warning.main, "metaverse")
                  }
                  onMouseLeave={() => setHover(false)}
                  id="yellow-option"
                  className="page-title"
                >
                  AI + Metaverse
                  <FontAwesomeIcon
                    color={
                      menuPage === "metaverse" && hover
                        ? theme.palette.primary.main
                        : "#000000"
                    }
                    icon={faChevronRight}
                  />
                </LayoutStyles.Header.DropDownPage>
                <LayoutStyles.Header.DropDownPage
                  onMouseEnter={() =>
                    updateHover(theme.palette.success.main, "enablement")
                  }
                  onMouseLeave={() => setHover(false)}
                  id="green-option"
                  className="page-title"
                >
                  Sales Innovation
                  <FontAwesomeIcon
                    color={
                      menuPage === "enablement" && hover
                        ? theme.palette.primary.main
                        : "#000000"
                    }
                    icon={faChevronRight}
                  />
                </LayoutStyles.Header.DropDownPage>
                <LayoutStyles.Header.DropDownPage
                  onMouseEnter={() =>
                    updateHover(theme.palette.secondary.main, "training")
                  }
                  onMouseLeave={() => setHover(false)}
                  id="red-option"
                  className="page-title"
                >
                  Immersive Training
                  <FontAwesomeIcon
                    color={
                      menuPage === "training" && hover
                        ? theme.palette.primary.main
                        : "#000000"
                    }
                    icon={faChevronRight}
                  />
                </LayoutStyles.Header.DropDownPage>
              </MenuItem>
              <MenuItem
                sx={{
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
                disableRipple
                className="inner-page-options"
                onClick={() => handleNavClick("engagement")}
              >
                <Link
                  style={{ width: "100%" }}
                  to={dataJSON.menu[menuPage].page_link.path}
                  onClick={() => handleNavClick("immersive")}
                >
                  <LayoutStyles.Header.MenuMainLink>
                    <div>
                      <LayoutStyles.Header.PageTitle className="page-title">
                        {dataJSON.menu[menuPage].page_link.title}
                      </LayoutStyles.Header.PageTitle>
                      <LayoutStyles.Header.PageDesc>
                        {dataJSON.menu[menuPage].page_link.body}
                      </LayoutStyles.Header.PageDesc>
                    </div>
                  </LayoutStyles.Header.MenuMainLink>
                </Link>
                <Grid container spacing={1} style={{ width: "100%" }}>
                  {menuPage === "metaverse" &&
                    dataJSON.menu.metaverse.sub_links.map(
                      (link: any, index: number) => {
                        return (
                          <Grid
                            item
                            sm={6}
                            md={4}
                            key={`link-path-${link.path}-${index}`}
                          >
                            <HashLink
                              style={{ width: "100%" }}
                              to={link.path}
                              onClick={() => handleNavClick(link.page)}
                            >
                              <LayoutStyles.Header.MenuGridItem>
                                <LayoutStyles.Header.GridTitle className="page-option">
                                  {link.title}
                                </LayoutStyles.Header.GridTitle>
                                <LayoutStyles.Header.GridSubTitle>
                                  {link.body}
                                </LayoutStyles.Header.GridSubTitle>
                              </LayoutStyles.Header.MenuGridItem>
                            </HashLink>
                          </Grid>
                        );
                      }
                    )}
                  {menuPage === "training" &&
                    dataJSON.menu.training.sub_links.map((link: any) => {
                      return (
                        <Grid item sm={6} md={4} key={`link-path-${link.path}`}>
                          <HashLink
                            style={{ width: "100%" }}
                            to={link.path}
                            onClick={() => handleNavClick(link.page)}
                          >
                            <LayoutStyles.Header.MenuGridItem>
                              <LayoutStyles.Header.GridTitle className="page-option">
                                {link.title}
                              </LayoutStyles.Header.GridTitle>
                              <LayoutStyles.Header.GridSubTitle>
                                {link.body}
                              </LayoutStyles.Header.GridSubTitle>
                            </LayoutStyles.Header.MenuGridItem>
                          </HashLink>
                        </Grid>
                      );
                    })}
                  {menuPage === "enablement" &&
                    dataJSON.menu.enablement.sub_links.map((link: any) => {
                      return (
                        <Grid item sm={6} md={4} key={`link-path-${link.path}`}>
                          <HashLink
                            style={{ width: "100%" }}
                            to={link.path}
                            onClick={() => handleNavClick(link.page)}
                          >
                            <LayoutStyles.Header.MenuGridItem>
                              <LayoutStyles.Header.GridTitle className="page-option">
                                {link.title}
                              </LayoutStyles.Header.GridTitle>
                              <LayoutStyles.Header.GridSubTitle>
                                {link.body}
                              </LayoutStyles.Header.GridSubTitle>
                            </LayoutStyles.Header.MenuGridItem>
                          </HashLink>
                        </Grid>
                      );
                    })}
                </Grid>
              </MenuItem>
            </Menu>
            <Link to="/enterprise" onClick={() => handleNavClick("enterprise")}>
              <LayoutStyles.Header.NavButton
                style={{ fontWeight: "500" }}
                $selected={currentPage === "enterprise" && !dropDownOpen}
              >
                Enterprise
              </LayoutStyles.Header.NavButton>
            </Link>
            <Link to="/about" onClick={() => handleNavClick("about")}>
              <LayoutStyles.Header.NavButton
                style={{ fontWeight: "500" }}
                $selected={currentPage === "about" && !dropDownOpen}
              >
                Company
              </LayoutStyles.Header.NavButton>
            </Link>
            <Link to="/resources" onClick={() => handleNavClick("resources")}>
              <LayoutStyles.Header.NavButton
                style={{ fontWeight: "500" }}
                $selected={currentPage === "resources" && !dropDownOpen}
              >
                Blog
              </LayoutStyles.Header.NavButton>
            </Link>
          </LayoutStyles.Header.NavButtonGroup>
        )}

        {isSmallScreen && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setDrawerOpen(true)}
          >
            <LayoutStyles.Header.DrawerIconLine
              style={{ marginBottom: "6px" }}
            />
            <LayoutStyles.Header.DrawerIconLine
              style={{ marginBottom: "6px" }}
            />
            <LayoutStyles.Header.DrawerIconLine />
          </div>
        )}
        <Drawer
          style={{ width: "100%", height: "100%" }}
          open={drawerOpen}
          anchor="right"
          onClose={() => setDrawerOpen(false)}
        >
          <LayoutStyles.Header.DrawerTopBar>
            <Link to="/" onClick={() => handleNavClick("home")}>
              <img src={XurealLogo} style={{ width: "97px" }} />
            </Link>
            <img onClick={handleDrawerClose} src={CloseIcon} alt="close menu" />
          </LayoutStyles.Header.DrawerTopBar>
          <LayoutStyles.Header.DrawerTitle>
            SOLUTIONS
          </LayoutStyles.Header.DrawerTitle>
          <List>
            <LayoutStyles.Header.DrawerOptionBox
              onClick={() => handleDrawerClick("metaverse")}
              $bgColor={theme.palette.warning.main}
            >
              AI + Metaverse
              <FontAwesomeIcon
                icon={faChevronRight}
                color="#ffffff"
                size="sm"
              />
            </LayoutStyles.Header.DrawerOptionBox>
            <LayoutStyles.Header.DrawerOptionBox
              onClick={() => handleDrawerClick("training")}
              $bgColor={theme.palette.secondary.main}
            >
              Immersive Training
              <FontAwesomeIcon
                icon={faChevronRight}
                color="#ffffff"
                size="sm"
              />
            </LayoutStyles.Header.DrawerOptionBox>
            <LayoutStyles.Header.DrawerOptionBox
              onClick={() => handleDrawerClick("sales")}
              style={{ marginBottom: "25px" }}
              $bgColor={theme.palette.success.main}
            >
              Sales Innovation
              <FontAwesomeIcon
                icon={faChevronRight}
                color="#ffffff"
                size="sm"
              />
            </LayoutStyles.Header.DrawerOptionBox>

            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "rgba(78,25,72,0.15)",
              }}
            />

            <Link to="/enterprise" onClick={() => handleNavClick("enterprise")}>
              <ListItem>Enterprise</ListItem>
            </Link>
            <Link to="/about" onClick={() => handleNavClick("about")}>
              <ListItem>Company</ListItem>
            </Link>
            <Link to="/resources" onClick={() => handleNavClick("resources")}>
              <ListItem>Blog</ListItem>
            </Link>
          </List>
          {isSmallScreen && (
            <div
              style={{
                width: "100%",
                height: 98,
                borderTop: "1px solid #E3DDCF",
                position: "absolute",
                bottom: 0,
                margin: "0 -24px",
                padding: "0 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ArrowButton
                clickFunction={() => {
                  setDrawerOpen(false);
                  navigate('/contact')
                }}
                text="Contact us"
                bgColor={theme.palette.primary.main}
                width="100%"
              />
            </div>
          )}
        </Drawer>
        {!isSmallScreen && (
          <>
            <div>
              {/* <LayoutStyles.Header.SignInButton href="https://portal.xureal.com">
              Sign in
            </LayoutStyles.Header.SignInButton> */}
              <ArrowButton
                clickFunction={() => navigate('/contact')}
                text="Contact us"
                bgColor={theme.palette.neutral.black}
              />
            </div>
          </>
        )}
      </LayoutStyles.Header.InnerWrapper>
    </LayoutStyles.Header.Container>
  );
};

export default Header;
