import React from "react";
import LayoutStyles from "../App.styles";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useApp } from "../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductViewerModal: React.FC = () => {
  const { setShowProduct } = useApp();
  return (
    <LayoutStyles.VideoModal.Container>
      <LayoutStyles.VideoModal.CloseButton
        onClick={() => setShowProduct(false)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </LayoutStyles.VideoModal.CloseButton>
      <LayoutStyles.VideoModal.IframeModal src="https://preview.xureal.com/promo/product-360-preview/?product=havitat" />
    </LayoutStyles.VideoModal.Container>
  );
};

export default ProductViewerModal;
