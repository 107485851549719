import React, { useRef } from "react";
import LayoutStyles from "../App.styles";
import useOnScreen from "../hooks/useOnScreen";
import TestImage from "../assets/images/Homepage/fern.png";
import { useTheme, useMediaQuery } from "@mui/material";
type ImageAndTextBlockProps = {
  lottieAnimation?: object;
  data1: {
    tag: string;
    headline: string;
    body: string;
    subject?: string;
    icon?: string;
  };
  data2: {
    tag: string;
    headline: string;
    body: string;
    subject?: string;
    icon?: string;
  };
  height?: string;
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  textColor?: string;
  backgroundColor?: string;
  image1: string;
  image2: string;
  imageSize?: [number, number];
  hideTag?: boolean;
  hideButton?: boolean;
  buttonType?: "default" | "animated";
  lineColor?: string;
  altColor?: boolean;
};

const DualTxtImg: React.FC<ImageAndTextBlockProps> = ({
  lottieAnimation,
  data1,
  data2,
  height,
  width,
  alignItems,
  justifyContent,
  padding,
  margin,
  image1,
  image2,
  hideButton = false,
  buttonType = "default",
  hideTag = false,
  backgroundColor,
  textColor,
  lineColor,
  altColor,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  return (
    <LayoutStyles.FlexboxRow
      $overflow="hidden"
      $height={height}
      $width={width}
      $padding={padding}
      $margin={margin}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $overrideCenter
      $gap={'10%'}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      $flexDirection={isMediumScreen ? "column" : "row"}
      style={{
        margin: "auto",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <LayoutStyles.TextText.Container
        $altColor={altColor}
      >
        {!isSmallScreen && (
          <LayoutStyles.TextText.TopImg
            $isSmallScreen={isSmallScreen}
            src={image1}
            alt=""
          />
        )}
        {!hideTag && (
          <LayoutStyles.ImageAndTextBox.Tag>
            {data1.tag.toUpperCase()}
          </LayoutStyles.ImageAndTextBox.Tag>
        )}
        <LayoutStyles.TextText.Headline $isSmallScreen={isSmallScreen}>
          {data1.headline}
        </LayoutStyles.TextText.Headline>
        {isSmallScreen && (
          <LayoutStyles.TextText.TopImg
            $isSmallScreen={isSmallScreen}
            src={image1}
            alt=""
          />
        )}
        <LayoutStyles.FlexboxRow
          $alignItems="flex-start"
          $justifyContent={isMediumScreen ? "flex-start !important" : undefined}
        >
          <LayoutStyles.TextText.Body>{data1.body}</LayoutStyles.TextText.Body>
        </LayoutStyles.FlexboxRow>
      </LayoutStyles.TextText.Container>

      <LayoutStyles.TextText.Container $altColor={altColor}>
        {!isSmallScreen && (
          <LayoutStyles.TextText.TopImg
            $isSmallScreen={isSmallScreen}
            src={image2}
            alt=""
          />
        )}
        {!hideTag && (
          <LayoutStyles.ImageAndTextBox.Tag>
            {data2.tag.toUpperCase()}
          </LayoutStyles.ImageAndTextBox.Tag>
        )}
        <LayoutStyles.TextText.Headline
          $isSmallScreen={isSmallScreen}
        >
          {data2.headline}
        </LayoutStyles.TextText.Headline>
        {isSmallScreen && (
          <LayoutStyles.TextText.TopImg
            $isSmallScreen={isSmallScreen}
            src={image2}
            alt=""
          />
        )}
        <LayoutStyles.FlexboxRow
          $alignItems="flex-start"
          $justifyContent={isMediumScreen ? "flex-start !important" : undefined}
        >
          <LayoutStyles.TextText.Body>{data2.body}</LayoutStyles.TextText.Body>
        </LayoutStyles.FlexboxRow>
      </LayoutStyles.TextText.Container>
    </LayoutStyles.FlexboxRow>
  );
};

export default DualTxtImg;
