import React, { useState } from "react";
import LayoutStyles from "../../App.styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const SubMenu: React.FC = () => {
  const { SubMenu } = LayoutStyles;
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  return (
    <SubMenu.Container>
      {!isSmallScreen && (
        <SubMenu.InnerWrapper>
          <LayoutStyles.FlexboxRow $height="100%" $width="auto">
          <SubMenu.Title>Energy & Utilities</SubMenu.Title>
          <SubMenu.OptionBox>
            Overview
          </SubMenu.OptionBox>
          <SubMenu.OptionBox>
            Client Story
          </SubMenu.OptionBox>
          <SubMenu.OptionBox>
            Features
          </SubMenu.OptionBox>
          <SubMenu.OptionBox>
            Thought Leadership
          </SubMenu.OptionBox>
          </LayoutStyles.FlexboxRow>
          <div style={{
            height: '36px',
            width: '112px',
            fontSize: '13px',
            color: '#ffffff',
            borderRadius: '18px',
            backgroundColor: '#3d3d3d',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 500
          }}>
            Subscribe
          </div>
        </SubMenu.InnerWrapper>
      )}

      {isSmallScreen && (
        <Accordion expanded={expanded} id="mobile-sub-menu">
          <AccordionSummary
            onClick={() => setExpanded((prev) => !prev)}
            expandIcon={
              <FontAwesomeIcon color={"#ffffff"} icon={faChevronDown} />
            }
            className="sub-menu-accordion"
          >
          </AccordionSummary>
          <AccordionDetails className="sub-menu-accordion">
            
          </AccordionDetails>
        </Accordion>
      )}
    </SubMenu.Container>
  );
};

export default SubMenu;
