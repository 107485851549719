import React from "react";
import LayoutStyles from "../App.styles";
import { useTheme, useMediaQuery } from "@mui/material";

import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Solar assets
import SolarPanel from '../assets/images/solar/hero-solar-panel.png';
import SolarEngagement from '../assets/images/solar/engagement.png';

const LandingHero: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  return (
    <>
      <LayoutStyles.FlexboxRow style={{ marginBottom: "64px" }}>
        <LayoutStyles.LandingHero.Title>
          Shaping the future of solar with immerging technologies.
        </LayoutStyles.LandingHero.Title>
        <LayoutStyles.LandingHero.Body>
          One challenge the solar industry faces is the difficulty of
          visualizing and planning solar installations in complex or densely
          populated areas. With Xureal, solar experts can accurately assess the
          performance
        </LayoutStyles.LandingHero.Body>
      </LayoutStyles.FlexboxRow>

      <LayoutStyles.FlexboxRow $height="702px" $gap="16px" $width="100%">
        <LayoutStyles.LandingHero.GridBox
          style={{ flex: 1, backgroundColor: "turquoise" }}
        ></LayoutStyles.LandingHero.GridBox>
        <LayoutStyles.FlexboxRow
          $gap="16px"
          $height="100%"
          $width="362px"
          $flexDirection="column"
        >
          <LayoutStyles.LandingHero.GridBox
            $color="#ffffff"
            $background="linear-gradient(41.76deg, #16A085 0%, #F4D03F 100%);"
            style={{ flex: 1 }}
          >
            <LayoutStyles.LandingHero.BoxHeader $color="#ffffff">
              TRAINING
            </LayoutStyles.LandingHero.BoxHeader>
            A rapdily evolving energy landscape

            <LayoutStyles.FlexboxRow $justifyContent="flex-end">
                <FontAwesomeIcon icon={faCirclePlus} size="lg" />
            </LayoutStyles.FlexboxRow>
          </LayoutStyles.LandingHero.GridBox>
          <LayoutStyles.LandingHero.GridBox
            $align="flex-start"
            style={{ flex: 1, textAlign: "left" }}
          >
            <img style={{
                position: 'absolute',
                width: '75%',
                top: 0,
                right: 0
            }} src={SolarPanel} />
            <LayoutStyles.LandingHero.BoxHeader $color="#5C5C5C">
              SALES
            </LayoutStyles.LandingHero.BoxHeader>
            <LayoutStyles.FlexboxRow $alignItems="flex-end">
            Reengergize your revenue
            <FontAwesomeIcon color="rgba(151,148,171,0.4)" icon={faCirclePlus} size="lg" />
            </LayoutStyles.FlexboxRow>
          </LayoutStyles.LandingHero.GridBox>
        </LayoutStyles.FlexboxRow>
      </LayoutStyles.FlexboxRow>
      <LayoutStyles.FlexboxRow
        $margin="16px 0 0 0"
        $height="345px"
        $gap="16px"
        $width="100%"
      >
        <LayoutStyles.LandingHero.GridBox style={{ width: "456px" }}>
          <div>
            <LayoutStyles.LandingHero.BoxHeader
              style={{ borderRadius: "15px", background: "linear-gradient(" }}
            >
              AI + METAVERSE
            </LayoutStyles.LandingHero.BoxHeader>
          </div>
          Fuel innovation with emerging technologies.
          <div></div>
        </LayoutStyles.LandingHero.GridBox>
        <LayoutStyles.LandingHero.GridBox
          $backgroundImage={SolarEngagement}
          $color="#ffffff"
        >
          <LayoutStyles.LandingHero.BoxHeader $color="#ffffff">
            ENGAGEMENT
          </LayoutStyles.LandingHero.BoxHeader>
          Customer experiences that sell.
          <LayoutStyles.FlexboxRow $justifyContent="flex-end">
                <FontAwesomeIcon icon={faCirclePlus} size="lg" />
            </LayoutStyles.FlexboxRow>
        </LayoutStyles.LandingHero.GridBox>
      </LayoutStyles.FlexboxRow>
    </>
  );
};

export default LandingHero;
