import React from "react";
import LayoutStyles from "../App.styles";
import { useTheme } from "@mui/material";

// images
import img_1 from "../assets/images/about/img_1.png";
import img_2 from "../assets/images/about/img_2.png";
import img_3 from "../assets/images/about/img_3.png";
import img_4 from "../assets/images/about/img_4.png";
import img_5 from "../assets/images/about/img_5.png";
import img_6 from "../assets/images/about/img_6.png";
import img_7 from "../assets/images/about/img_7.png";
import img_8 from "../assets/images/about/img_8.png";

const AboutSpreadMobile = ({
  data,
}: {
  data: {
    headline: string;
    body: string;
  };
}) => {
  const theme = useTheme();
  return (
    <LayoutStyles.SpreadAnimation.Container
      style={{ height: "fit-content", backgroundColor: theme.palette.neutral.light }}
    >
      <LayoutStyles.SectionContainer
        $padding="0 0 64px 0"
        $margin="0 auto"
        $width="fit-content"
      >
        <img
          style={{
            maxHeight: 415,
            maxWidth: 504,
            width: "90vw",
          }}
          src={img_1}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding="0 0 64px 0"
        $margin="0 auto"
        $width="fit-content"
      >
        <LayoutStyles.SpreadAnimation.AboutText>
          {"We value purpose"}
        </LayoutStyles.SpreadAnimation.AboutText>
        <LayoutStyles.SpreadAnimation.Body style={{maxWidth: '85%', margin: '20px auto 0'}}>
          {"We believe the human experience and sense of purpose within an organization drive confidence, growth, and success."}
        </LayoutStyles.SpreadAnimation.Body>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding="0 0 64px 0"
        $margin="0 auto"
        $width="fit-content"
      >
        <LayoutStyles.SpreadAnimation.InitialImageState
          style={{
            maxHeight: "106vw",
            maxWidth: 504,
            width: "90vw",
          }}
        >
          <img
            src={img_2}
            style={{
              position: "absolute",
              //   height: `${(257 / 420) * 100}%`,
              width: "60%",
              top: "0%",
              left: 0,
            }}
          />
          <img
            src={img_3}
            style={{
              position: "absolute",
              height: `${(186 / 420) * 100}%`,
              bottom: 0,
              right: 0,
            }}
          />

          <img
            src={img_4}
            style={{
              position: "absolute",
              width: `${(138 / 313) * 100}%`,
              top: 29,
              right: 18,
            }}
          />
        </LayoutStyles.SpreadAnimation.InitialImageState>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding="0 0 64px 0"
        $margin="0 auto"
        $width="fit-content"
      >
        <LayoutStyles.SpreadAnimation.AboutText>
          {"We center collaboration"}
        </LayoutStyles.SpreadAnimation.AboutText>
        <LayoutStyles.SpreadAnimation.Body style={{maxWidth: '85%', margin: '20px auto 0'}}>
          {"With a sense of collective awareness and common vision, teams can achieve the unachievable—and individuals renew a sense of personal fulfillment and happiness."}
        </LayoutStyles.SpreadAnimation.Body>
      </LayoutStyles.SectionContainer>
      <img
        style={{
          maxHeight: 415,
          maxWidth: 504,
          width: "90vw",
        }}
        src={img_5}
      />
      <LayoutStyles.SectionContainer
        $padding="64px 0 64px 0"
        $margin="0 auto"
        $width="fit-content"
      >
        <LayoutStyles.SpreadAnimation.AboutText>
          {"We believe in giving back"}
        </LayoutStyles.SpreadAnimation.AboutText>
        <LayoutStyles.SpreadAnimation.Body style={{maxWidth: '85%', margin: '20px auto 0'}}>
          {"We’re convinced that being engaged in community influences our innovative and collaborative capacities, while making a difference."}
        </LayoutStyles.SpreadAnimation.Body>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SpreadAnimation.InitialImageState
        style={{
          maxHeight: "107vw",
          maxWidth: 504,
          width: "90vw",
        }}
      >
        <img
          alt='Company photo'
          src={img_7}
          style={{
            position: "absolute",
            width: "60%",
            bottom: 0,
            right: 0,
          }}
        />
        <img
          alt='Company photo'
          src={img_6}
          style={{
            position: "absolute",
            width: "60%",
            top: 0,
            left: 0,
          }}
        />
      </LayoutStyles.SpreadAnimation.InitialImageState>
      <LayoutStyles.SectionContainer
        $padding="64px 0 64px 0"
        $margin="0 auto"
        $width="fit-content"
      >
        <LayoutStyles.SpreadAnimation.AboutText>
          {"We empower growth"}
        </LayoutStyles.SpreadAnimation.AboutText>
        <LayoutStyles.SpreadAnimation.Body style={{maxWidth: '85%', margin: '20px auto 0'}}>
          {"With a sense of collective awareness and common vision, teams can achieve the unachievable—and individuals renew a sense of personal fulfillment and happiness."}
        </LayoutStyles.SpreadAnimation.Body>
      </LayoutStyles.SectionContainer>
      <img
      alt='Company photo'
        style={{
          maxHeight: 415,
          maxWidth: 504,
          width: "90vw",
        }}
        src={img_8}
      />
      <LayoutStyles.SectionContainer
        $padding="64px 0 64px 0"
        $margin="0 auto"
        $width="fit-content"
      >
        <LayoutStyles.SpreadAnimation.AboutText>
          {"We’re always innovating"}
        </LayoutStyles.SpreadAnimation.AboutText>
        <LayoutStyles.SpreadAnimation.Body style={{maxWidth: '85%', margin: '20px auto 0'}}>
          {"“So-so” isn’t our style. We like challenging conventional wisdom and bringing groundbreaking concepts to the table."}
        </LayoutStyles.SpreadAnimation.Body>
      </LayoutStyles.SectionContainer>
    </LayoutStyles.SpreadAnimation.Container>
  );
};

export default AboutSpreadMobile;
