import React, { useEffect } from "react";
import LayoutStyles from "../../App.styles";
import { useApp } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme, useMediaQuery } from "@mui/material";

const ScheduleModal: React.FC = () => {
  const { setShowScheduler } = useApp();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm))

  const schedulerURL = "https://meetings.hubspot.com/xureal/demo";

  return (
    <LayoutStyles.Scheduler.Container>
      <LayoutStyles.Scheduler.Wrapper>
      <LayoutStyles.VideoModal.CloseButton
        style={{right: isSmallScreen ? '0' : '-24px', top: isSmallScreen ? '0' : '-24px'}}
        onClick={() => setShowScheduler(false)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </LayoutStyles.VideoModal.CloseButton>
      <LayoutStyles.Scheduler.Scheduler id="scheduler-iframe" src={schedulerURL} />
      </LayoutStyles.Scheduler.Wrapper>
    </LayoutStyles.Scheduler.Container>
  );
};

export default ScheduleModal;
