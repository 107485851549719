import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";
import img_7 from "./images/img_7.png";

export default {
    v: "5.7.4",
    fr: 30,
    ip: 0,
    op: 90,
    w: 1428,
    h: 1200,
    nm: "Sales training-Analytics",
    ddd: 0,
    assets: [
      {
        id: "image_0",
        w: 385,
        h: 103,
        u: "",
        p: img_0,
        e: 0
      },
      {
        id: "image_1",
        w: 1253,
        h: 208,
        u: "",
        p: img_1,
        e: 0
      },
      {
        id: "image_2",
        w: 198,
        h: 55,
        u: "",
        p: img_2,
        e: 0
      },
      {
        id: "image_3",
        w: 793,
        h: 208,
        u: "",
        p: img_3,
        e: 0
      },
      {
        id: "image_4",
        w: 853,
        h: 1098,
        u: "",
        p: img_4,
        e: 0
      },
      {
        id: "image_5",
        w: 205,
        h: 40,
        u: "",
        p: img_5,
        e: 0
      },
      {
        id: "image_6",
        w: 1483,
        h: 430,
        u: "",
        p: img_6,
        e: 0
      },
      {
        id: "image_7",
        w: 1373,
        h: 978,
        u: "",
        p: img_7,
        e: 0
      },
      {
        id: "comp_0",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "Shape Layer 7",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2409,
                  674.7,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  489,
                  -405.3,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    d: 1,
                    ty: "el",
                    s: {
                      a: 0,
                      k: [
                        386.077,
                        386.077
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    nm: "Ellipse Path 1",
                    mn: "ADBE Vector Shape - Ellipse",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        0.886274569642,
                        0.850980451995,
                        0.819607902976,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 35,
                      ix: 5
                    },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        486.952,
                        -405.3
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Ellipse 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              },
              {
                ty: "tm",
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [
                          0
                        ],
                        y: [
                          0.999
                        ]
                      },
                      o: {
                        x: [
                          0.167
                        ],
                        y: [
                          0.167
                        ]
                      },
                      t: 330.33,
                      s: [
                        5
                      ]
                    },
                    {
                      t: 390.39039039039,
                      s: [
                        84.7
                      ]
                    }
                  ],
                  ix: 1
                },
                e: {
                  a: 0,
                  k: 95,
                  ix: 2
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3
                },
                m: 1,
                ix: 2,
                nm: "Trim Paths 2",
                mn: "ADBE Vector Filter - Trim",
                hd: false
              }
            ],
            ip: 330.33033033033,
            op: 3599.5995995996,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: "Shape Layer 8",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2409,
                  674.7,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  489,
                  -405.3,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  100,
                  100,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    d: 1,
                    ty: "el",
                    s: {
                      a: 0,
                      k: [
                        386.077,
                        386.077
                      ],
                      ix: 2
                    },
                    p: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 3
                    },
                    nm: "Ellipse Path 1",
                    mn: "ADBE Vector Shape - Ellipse",
                    hd: false
                  },
                  {
                    ty: "st",
                    c: {
                      a: 0,
                      k: [
                        0.062745098039,
                        0.352941176471,
                        0.215686289469,
                        1
                      ],
                      ix: 3
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4
                    },
                    w: {
                      a: 0,
                      k: 35,
                      ix: 5
                    },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: "Stroke 1",
                    mn: "ADBE Vector Graphic - Stroke",
                    hd: false
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [
                        486.952,
                        -405.3
                      ],
                      ix: 2
                    },
                    a: {
                      a: 0,
                      k: [
                        0,
                        0
                      ],
                      ix: 1
                    },
                    s: {
                      a: 0,
                      k: [
                        100,
                        100
                      ],
                      ix: 3
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5
                    },
                    nm: "Transform"
                  }
                ],
                nm: "Ellipse 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false
              },
              {
                ty: "tm",
                s: {
                  a: 0,
                  k: 0,
                  ix: 1
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [
                          0
                        ],
                        y: [
                          0.97
                        ]
                      },
                      o: {
                        x: [
                          0.167
                        ],
                        y: [
                          0.167
                        ]
                      },
                      t: 330.33,
                      s: [
                        0
                      ]
                    },
                    {
                      t: 390.39039039039,
                      s: [
                        80
                      ]
                    }
                  ],
                  ix: 2
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3
                },
                m: 1,
                ix: 2,
                nm: "Trim Paths 1",
                mn: "ADBE Vector Filter - Trim",
                hd: false
              }
            ],
            ip: 330.33033033033,
            op: 3599.5995995996,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 5,
            nm: "Completed",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2404.762,
                  663.5,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  513.607,
                  80.398,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  30,
                  30,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ef: [
              {
                ty: 5,
                nm: "Slider Control",
                np: 3,
                mn: "ADBE Slider Control",
                ix: 1,
                en: 1,
                ef: [
                  {
                    ty: 0,
                    nm: "Slider",
                    mn: "ADBE Slider Control-0001",
                    ix: 1,
                    v: {
                      a: 1,
                      k: [
                        {
                          i: {
                            x: [
                              0.088
                            ],
                            y: [
                              0.999
                            ]
                          },
                          o: {
                            x: [
                              0.167
                            ],
                            y: [
                              0.167
                            ]
                          },
                          t: 330.33,
                          s: [
                            0
                          ]
                        },
                        {
                          t: 390.39039039039,
                          s: [
                            80
                          ]
                        }
                      ],
                      ix: 1
                    }
                  }
                ]
              }
            ],
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        113.037200927734
                      ],
                      ps: [
                        -265,
                        320.333343505859
                      ],
                      s: 80,
                      f: "Gordita-Regular",
                      t: "Completed",
                      j: 2,
                      tr: 0,
                      lh: 96,
                      ls: 0,
                      fc: [
                        0.614,
                        0.614,
                        0.614
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 0
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 330.323323323323,
            op: 2693.32332332332,
            st: 323.323323323323,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 5,
            nm: "0%",
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  2404.762,
                  663.5,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  513.607,
                  80.398,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 0,
                k: [
                  30,
                  30,
                  100
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ef: [
              {
                ty: 5,
                nm: "Slider Control",
                np: 3,
                mn: "ADBE Slider Control",
                ix: 1,
                en: 1,
                ef: [
                  {
                    ty: 0,
                    nm: "Slider",
                    mn: "ADBE Slider Control-0001",
                    ix: 1,
                    v: {
                      a: 1,
                      k: [
                        {
                          i: {
                            x: [
                              0.088
                            ],
                            y: [
                              0.999
                            ]
                          },
                          o: {
                            x: [
                              0.167
                            ],
                            y: [
                              0.167
                            ]
                          },
                          t: 330.33,
                          s: [
                            0
                          ]
                        },
                        {
                          t: 390.39039039039,
                          s: [
                            80
                          ]
                        }
                      ],
                      ix: 1
                    }
                  }
                ]
              }
            ],
            t: {
              d: {
                k: [
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "0%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 330.33033033033
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "2%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 331.331331331331
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "4%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 332.332332332332
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "7%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 333.333333333333
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "10%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 334.334334334334
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "14%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 335.335335335335
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "19%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 336.336336336336
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "23%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 337.337337337337
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "27%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 338.338338338338
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "31%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 339.339339339339
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "35%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 340.34034034034
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "38%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 341.341341341341
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "42%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 342.342342342342
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "44%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 343.343343343343
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "47%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 344.344344344344
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "49%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 345.345345345345
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "52%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 346.346346346346
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "54%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 347.347347347347
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "55%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 348.348348348348
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "57%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 349.349349349349
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "59%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 350.35035035035
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "60%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 351.351351351351
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "62%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 352.352352352352
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "63%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 353.353353353353
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "64%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 354.354354354354
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "65%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 355.355355355355
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "67%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 356.356356356356
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "68%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 357.357357357357
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "69%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 358.358358358358
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "70%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 360.36036036036
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "71%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 361.361361361361
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "72%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 362.362362362362
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "73%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 364.364364364364
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "74%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 365.365365365365
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "75%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 367.367367367367
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "76%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 370.37037037037
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "77%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 371.371371371371
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "78%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 374.374374374374
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "79%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 377.377377377377
                  },
                  {
                    s: {
                      sz: [
                        1552.5185546875,
                        959.703857421875
                      ],
                      ps: [
                        -265,
                        -43
                      ],
                      s: 320,
                      f: "Gordita-Bold",
                      t: "80%",
                      j: 2,
                      tr: 20,
                      lh: 384,
                      ls: 0,
                      fc: [
                        0.098,
                        0.11,
                        0.141
                      ],
                      sc: [
                        0,
                        0,
                        0
                      ],
                      sw: 0.00999999977648,
                      of: false
                    },
                    t: 383.383383383383
                  }
                ]
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [
                    0,
                    0
                  ],
                  ix: 2
                }
              },
              a: []
            },
            ip: 330.323323323323,
            op: 2693.32332332332,
            st: 323.323323323323,
            bm: 0
          }
        ]
      },
      {
        id: "comp_1",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: "Graph.png",
            cl: "png",
            refId: "image_6",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.002
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 30,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 60,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  678,
                  909,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  741.5,
                  430,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.264,
                        0.992,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0,
                        0.167,
                        0
                      ]
                    },
                    t: 30,
                    s: [
                      93.796,
                      5.581,
                      100
                    ]
                  },
                  {
                    t: 60,
                    s: [
                      93.796,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 30,
            op: 167,
            st: 30,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: "Graph.png",
            cl: "png",
            refId: "image_6",
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.002
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 30,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 60,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 11
              },
              r: {
                a: 0,
                k: 0,
                ix: 10
              },
              p: {
                a: 0,
                k: [
                  678,
                  909,
                  0
                ],
                ix: 2,
                l: 2
              },
              a: {
                a: 0,
                k: [
                  741.5,
                  430,
                  0
                ],
                ix: 1,
                l: 2
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0,
                        0,
                        0.667
                      ],
                      y: [
                        0.264,
                        0.992,
                        1
                      ]
                    },
                    o: {
                      x: [
                        0.167,
                        0.167,
                        0.167
                      ],
                      y: [
                        0,
                        0.167,
                        0
                      ]
                    },
                    t: 30,
                    s: [
                      93.796,
                      5.581,
                      100
                    ]
                  },
                  {
                    t: 60,
                    s: [
                      93.796,
                      100,
                      100
                    ]
                  }
                ],
                ix: 6,
                l: 2
              }
            },
            ao: 0,
            ip: 30,
            op: 167,
            st: 30,
            bm: 0
          }
        ]
      }
    ],
    fonts: {
      list: [
        {
          fName: "Gordita-Bold",
          fFamily: "Gordita",
          fStyle: "Bold",
          ascent: 81.409536132589
        },
        {
          fName: "Gordita-Regular",
          fFamily: "Gordita",
          fStyle: "Regular",
          ascent: 81.409536132589
        }
      ]
    },
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 0,
        nm: "CHart Progress",
        refId: "comp_0",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.991
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 25,
                s: [
                  0
                ]
              },
              {
                t: 40.0001143721847,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              1051.682,
              716,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              2406.952,
              674.7,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0,
                    0,
                    0.667
                  ],
                  y: [
                    0.99,
                    0.99,
                    1
                  ]
                },
                o: {
                  x: [
                    0.167,
                    0.167,
                    0.167
                  ],
                  y: [
                    0.167,
                    0.167,
                    0.882
                  ]
                },
                t: 25,
                s: [
                  46,
                  46,
                  100
                ]
              },
              {
                t: 40.0001143721847,
                s: [
                  90,
                  90,
                  100
                ]
              }
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        w: 3840,
        h: 2160,
        ip: 25.015015015015,
        op: 3294.28428428428,
        st: -305.315315315315,
        bm: 0
      },
      {
        ddd: 0,
        ind: 2,
        ty: 2,
        nm: "Company Overview Tra Copy 3.png",
        cl: "png",
        refId: "image_0",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1.003
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 25,
                s: [
                  0
                ]
              },
              {
                t: 40,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 25,
                s: [
                  1062,
                  1052,
                  0
                ],
                to: [
                  0,
                  -6.667,
                  0
                ],
                ti: [
                  0,
                  6.667,
                  0
                ]
              },
              {
                t: 40,
                s: [
                  1062,
                  1012,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              192.5,
              51.5,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              80,
              80,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 25,
        op: 91,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 3,
        ty: 2,
        nm: "Group 38.png",
        cl: "png",
        refId: "image_1",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.989
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 10,
                s: [
                  0
                ]
              },
              {
                t: 40,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 10,
                s: [
                  664,
                  202,
                  0
                ],
                to: [
                  0,
                  -10,
                  0
                ],
                ti: [
                  0,
                  10,
                  0
                ]
              },
              {
                t: 40,
                s: [
                  664,
                  142,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              626.5,
              104,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              93,
              93,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 0,
        op: 91,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 4,
        ty: 2,
        nm: "Group 14.png",
        cl: "png",
        refId: "image_2",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1.006
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 34,
                s: [
                  0
                ]
              },
              {
                t: 54,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 34,
                s: [
                  494,
                  392,
                  0
                ],
                to: [
                  0,
                  -4.333,
                  0
                ],
                ti: [
                  0,
                  4.333,
                  0
                ]
              },
              {
                t: 54,
                s: [
                  494,
                  366,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              132,
              27.5,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              80,
              80,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 34,
        op: 125,
        st: 34,
        bm: 0
      },
      {
        ddd: 0,
        ind: 5,
        ty: 5,
        nm: "39",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1.001
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 30,
                s: [
                  0
                ]
              },
              {
                t: 59.999804491992,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 30,
                s: [
                  961.44,
                  -499,
                  0
                ],
                to: [
                  -13.333,
                  0,
                  0
                ],
                ti: [
                  13.333,
                  0,
                  0
                ]
              },
              {
                t: 59.999804491992,
                s: [
                  881.44,
                  -499,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              1003.231,
              -22.845,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              27,
              27,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "39",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 30.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "90",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 31.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "119",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 32.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "143",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 33.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "172",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 34.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "202",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 35.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "219",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 36.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "240",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 37.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "261",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 38.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "291",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 39.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "310",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 40.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "322",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 41.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "340",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 42.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "361",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 43.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "384",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 44.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "401",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 45.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "419",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 46.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "431",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 47.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "455",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 48.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "472",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 49.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "491",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 50.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "503",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 51.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "520",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 52.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "529",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 53.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "536",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 54.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "541",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 55.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "553",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 56.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "563",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 57.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "572",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 58.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 347,
                  f: "Gordita-Bold",
                  t: "573",
                  j: 0,
                  tr: 20,
                  lh: 416.4,
                  ls: 0,
                  fc: [
                    0.098,
                    0.11,
                    0.141
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 59.999804491992
              }
            ]
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 2
            }
          },
          a: []
        },
        ip: 30,
        op: 91,
        st: -8.00800800800801,
        bm: 0
      },
      {
        ddd: 0,
        ind: 6,
        ty: 2,
        nm: "02.png",
        cl: "png",
        refId: "image_3",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.999
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 25,
                s: [
                  0
                ]
              },
              {
                t: 55,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 25,
                s: [
                  1052,
                  414,
                  0
                ],
                to: [
                  0,
                  -6.667,
                  0
                ],
                ti: [
                  0,
                  6.667,
                  0
                ]
              },
              {
                t: 55,
                s: [
                  1052,
                  374,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              396.5,
              104,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              80,
              80,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 25,
        op: 91,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 7,
        ty: 2,
        nm: "Rectangle2.png",
        cl: "png",
        refId: "image_4",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.995
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 15,
                s: [
                  0
                ]
              },
              {
                t: 45,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 15,
                s: [
                  992,
                  716,
                  0
                ],
                to: [
                  10,
                  0,
                  0
                ],
                ti: [
                  -10,
                  0,
                  0
                ]
              },
              {
                t: 45,
                s: [
                  1052,
                  716,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              426.5,
              549,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              83,
              83,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 15,
        op: 91,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 8,
        ty: 2,
        nm: "Completed.png",
        cl: "png",
        refId: "image_5",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.992
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 37,
                s: [
                  0
                ]
              },
              {
                t: 67,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 37,
                s: [
                  294,
                  460,
                  0
                ],
                to: [
                  -10,
                  0,
                  0
                ],
                ti: [
                  10,
                  0,
                  0
                ]
              },
              {
                t: 67,
                s: [
                  234,
                  460,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              102.5,
              20,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 37,
        op: 98,
        st: 7,
        bm: 0
      },
      {
        ddd: 0,
        ind: 9,
        ty: 0,
        nm: "Graphs",
        refId: "comp_1",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              714,
              601,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              714,
              600,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        hasMask: true,
        masksProperties: [
          {
            inv: false,
            mode: "a",
            pt: {
              a: 0,
              k: {
                i: [
                  [
                    33.689,
                    0
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    0,
                    -33.689
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    -42,
                    0
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    0,
                    33.689
                  ],
                  [
                    0,
                    0
                  ]
                ],
                o: [
                  [
                    0,
                    0
                  ],
                  [
                    -33.689,
                    0
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    0,
                    38.521
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    33.689,
                    0
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    0,
                    -33.689
                  ]
                ],
                v: [
                  [
                    1204.905,
                    366
                  ],
                  [
                    113,
                    366
                  ],
                  [
                    52,
                    427
                  ],
                  [
                    52,
                    843.479
                  ],
                  [
                    110.75,
                    904.458
                  ],
                  [
                    1204.905,
                    904.479
                  ],
                  [
                    1265.905,
                    843.479
                  ],
                  [
                    1265.905,
                    427
                  ]
                ],
                c: true
              },
              ix: 1
            },
            o: {
              a: 0,
              k: 100,
              ix: 3
            },
            x: {
              a: 0,
              k: 0,
              ix: 4
            },
            nm: "Mask 1"
          }
        ],
        w: 1428,
        h: 1200,
        ip: 0,
        op: 91,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 10,
        ty: 2,
        nm: "Rectangle1.png",
        cl: "png",
        refId: "image_7",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.998
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 0,
                s: [
                  0
                ]
              },
              {
                t: 30,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 0,
                s: [
                  678,
                  562,
                  0
                ],
                to: [
                  0,
                  -13.333,
                  0
                ],
                ti: [
                  0,
                  13.333,
                  0
                ]
              },
              {
                t: 30,
                s: [
                  678,
                  482,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              686.5,
              489,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 0,
        op: 91,
        st: 0,
        bm: 0
      }
    ],
    markers: [],
    chars: [
      {
        ch: "0",
        size: 320,
        style: "Bold",
        w: 76.8,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -7.251
                        ],
                        [
                          -5.237,
                          -7.654
                        ],
                        [
                          -11.179,
                          0
                        ],
                        [
                          0,
                          20.847
                        ],
                        [
                          10.474,
                          7.654
                        ],
                        [
                          7.15,
                          0
                        ],
                        [
                          5.237,
                          -3.928
                        ],
                        [
                          2.417,
                          -5.942
                        ]
                      ],
                      o: [
                        [
                          0,
                          10.574
                        ],
                        [
                          5.338,
                          7.654
                        ],
                        [
                          22.659,
                          0
                        ],
                        [
                          0,
                          -14.401
                        ],
                        [
                          -5.136,
                          -3.928
                        ],
                        [
                          -7.352,
                          0
                        ],
                        [
                          -5.237,
                          3.928
                        ],
                        [
                          -2.316,
                          5.942
                        ]
                      ],
                      v: [
                        [
                          4.935,
                          -39.478
                        ],
                        [
                          12.991,
                          -11.481
                        ],
                        [
                          38.571,
                          1.007
                        ],
                        [
                          72.51,
                          -39.88
                        ],
                        [
                          57.404,
                          -74.222
                        ],
                        [
                          38.873,
                          -80.063
                        ],
                        [
                          19.94,
                          -74.121
                        ],
                        [
                          8.459,
                          -59.317
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -3.122,
                          10.272
                        ],
                        [
                          -2.719,
                          2.719
                        ],
                        [
                          -3.928,
                          0
                        ],
                        [
                          -2.719,
                          -2.82
                        ],
                        [
                          3.122,
                          -10.574
                        ],
                        [
                          2.719,
                          -2.82
                        ],
                        [
                          3.928,
                          0
                        ],
                        [
                          2.719,
                          2.719
                        ]
                      ],
                      o: [
                        [
                          1.108,
                          -3.525
                        ],
                        [
                          2.719,
                          -2.82
                        ],
                        [
                          3.928,
                          0
                        ],
                        [
                          7.956,
                          7.956
                        ],
                        [
                          -1.108,
                          3.625
                        ],
                        [
                          -2.719,
                          2.719
                        ],
                        [
                          -3.928,
                          0
                        ],
                        [
                          -7.956,
                          -8.157
                        ]
                      ],
                      v: [
                        [
                          22.961,
                          -50.757
                        ],
                        [
                          28.702,
                          -60.223
                        ],
                        [
                          38.672,
                          -64.453
                        ],
                        [
                          48.642,
                          -60.223
                        ],
                        [
                          54.382,
                          -28.5
                        ],
                        [
                          48.642,
                          -18.832
                        ],
                        [
                          38.672,
                          -14.703
                        ],
                        [
                          28.702,
                          -18.832
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "0",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "%",
        size: 320,
        style: "Bold",
        w: 88.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -4.935
                        ],
                        [
                          -3.525,
                          -3.525
                        ],
                        [
                          -4.935,
                          0
                        ],
                        [
                          -3.525,
                          3.525
                        ],
                        [
                          0,
                          4.935
                        ],
                        [
                          3.525,
                          3.525
                        ],
                        [
                          4.935,
                          0
                        ],
                        [
                          3.525,
                          -3.525
                        ]
                      ],
                      o: [
                        [
                          0,
                          4.935
                        ],
                        [
                          3.525,
                          3.525
                        ],
                        [
                          4.935,
                          0
                        ],
                        [
                          3.525,
                          -3.525
                        ],
                        [
                          0,
                          -4.935
                        ],
                        [
                          -3.525,
                          -3.525
                        ],
                        [
                          -4.935,
                          0
                        ],
                        [
                          -3.525,
                          3.525
                        ]
                      ],
                      v: [
                        [
                          3.525,
                          -61.835
                        ],
                        [
                          8.762,
                          -49.246
                        ],
                        [
                          21.35,
                          -44.009
                        ],
                        [
                          33.939,
                          -49.246
                        ],
                        [
                          39.175,
                          -61.835
                        ],
                        [
                          33.939,
                          -74.524
                        ],
                        [
                          21.35,
                          -79.761
                        ],
                        [
                          8.762,
                          -74.524
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "%",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          12.186,
                          0
                        ],
                        [
                          25.882,
                          0
                        ],
                        [
                          77.142,
                          -78.854
                        ],
                        [
                          63.547,
                          -78.854
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "%",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          3.323
                        ],
                        [
                          -3.525,
                          0
                        ],
                        [
                          0,
                          -3.525
                        ],
                        [
                          3.323,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          -3.525
                        ],
                        [
                          3.525,
                          0
                        ],
                        [
                          0,
                          3.323
                        ],
                        [
                          -3.323,
                          0
                        ]
                      ],
                      v: [
                        [
                          15.106,
                          -61.835
                        ],
                        [
                          21.35,
                          -68.079
                        ],
                        [
                          27.594,
                          -61.835
                        ],
                        [
                          21.35,
                          -55.692
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "%",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -4.935
                        ],
                        [
                          -3.525,
                          -3.525
                        ],
                        [
                          -4.935,
                          0
                        ],
                        [
                          -3.525,
                          3.424
                        ],
                        [
                          0,
                          4.935
                        ],
                        [
                          3.525,
                          3.525
                        ],
                        [
                          4.935,
                          0
                        ],
                        [
                          3.525,
                          -3.525
                        ]
                      ],
                      o: [
                        [
                          0,
                          4.935
                        ],
                        [
                          3.525,
                          3.424
                        ],
                        [
                          4.935,
                          0
                        ],
                        [
                          3.525,
                          -3.525
                        ],
                        [
                          0,
                          -4.935
                        ],
                        [
                          -3.525,
                          -3.525
                        ],
                        [
                          -4.935,
                          0
                        ],
                        [
                          -3.525,
                          3.525
                        ]
                      ],
                      v: [
                        [
                          50.354,
                          -17.322
                        ],
                        [
                          55.591,
                          -4.733
                        ],
                        [
                          68.179,
                          0.403
                        ],
                        [
                          80.768,
                          -4.733
                        ],
                        [
                          86.005,
                          -17.322
                        ],
                        [
                          80.768,
                          -30.011
                        ],
                        [
                          68.179,
                          -35.248
                        ],
                        [
                          55.591,
                          -30.011
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "%",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 4,
                  ty: "sh",
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          3.323
                        ],
                        [
                          -3.525,
                          0
                        ],
                        [
                          0,
                          -3.525
                        ],
                        [
                          3.323,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          -3.525
                        ],
                        [
                          3.525,
                          0
                        ],
                        [
                          0,
                          3.323
                        ],
                        [
                          -3.323,
                          0
                        ]
                      ],
                      v: [
                        [
                          61.935,
                          -17.322
                        ],
                        [
                          68.179,
                          -23.566
                        ],
                        [
                          74.423,
                          -17.322
                        ],
                        [
                          68.179,
                          -11.179
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "%",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "%",
              np: 8,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "2",
        size: 320,
        style: "Bold",
        w: 67.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.921,
                          3.928
                        ],
                        [
                          0,
                          5.64
                        ],
                        [
                          5.136,
                          4.834
                        ],
                        [
                          8.459,
                          0
                        ],
                        [
                          5.338,
                          -3.223
                        ],
                        [
                          1.913,
                          -6.143
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.546,
                          0
                        ],
                        [
                          0,
                          -5.64
                        ],
                        [
                          1.913,
                          -2.417
                        ],
                        [
                          4.935,
                          -4.028
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.553,
                          -5.942
                        ],
                        [
                          3.021,
                          -3.928
                        ],
                        [
                          0,
                          -7.251
                        ],
                        [
                          -5.136,
                          -4.935
                        ],
                        [
                          -6.747,
                          0
                        ],
                        [
                          -5.237,
                          3.122
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.618,
                          -6.546
                        ],
                        [
                          6.445,
                          0
                        ],
                        [
                          0,
                          2.921
                        ],
                        [
                          -1.913,
                          2.417
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          4.733,
                          0
                        ],
                        [
                          62.439,
                          0
                        ],
                        [
                          62.439,
                          -14.905
                        ],
                        [
                          28.4,
                          -14.905
                        ],
                        [
                          41.693,
                          -25.479
                        ],
                        [
                          57.504,
                          -40.283
                        ],
                        [
                          62.036,
                          -54.584
                        ],
                        [
                          54.382,
                          -72.711
                        ],
                        [
                          34.039,
                          -80.063
                        ],
                        [
                          15.912,
                          -75.229
                        ],
                        [
                          5.237,
                          -61.23
                        ],
                        [
                          20.242,
                          -54.886
                        ],
                        [
                          33.939,
                          -64.755
                        ],
                        [
                          45.218,
                          -54.483
                        ],
                        [
                          42.398,
                          -46.527
                        ],
                        [
                          32.227,
                          -36.859
                        ],
                        [
                          4.733,
                          -14.301
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "4",
        size: 320,
        style: "Bold",
        w: 72.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          41.995,
                          -15.61
                        ],
                        [
                          41.995,
                          0
                        ],
                        [
                          57.907,
                          0
                        ],
                        [
                          57.907,
                          -15.71
                        ],
                        [
                          69.086,
                          -15.71
                        ],
                        [
                          69.086,
                          -30.313
                        ],
                        [
                          57.907,
                          -30.313
                        ],
                        [
                          57.907,
                          -78.854
                        ],
                        [
                          38.47,
                          -78.854
                        ],
                        [
                          3.122,
                          -31.622
                        ],
                        [
                          3.122,
                          -15.61
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          41.995,
                          -59.72
                        ],
                        [
                          41.995,
                          -30.313
                        ],
                        [
                          20.041,
                          -30.313
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "4",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "7",
        size: 320,
        style: "Bold",
        w: 63.2,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          42.197,
                          -64.05
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          26.083,
                          0
                        ],
                        [
                          60.928,
                          -64.856
                        ],
                        [
                          60.928,
                          -78.854
                        ],
                        [
                          3.122,
                          -78.854
                        ],
                        [
                          3.122,
                          -64.05
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "7",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "7",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "1",
        size: 320,
        style: "Bold",
        w: 49.4,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          24.17,
                          -59.418
                        ],
                        [
                          24.17,
                          0
                        ],
                        [
                          40.485,
                          0
                        ],
                        [
                          40.485,
                          -78.854
                        ],
                        [
                          27.09,
                          -78.854
                        ],
                        [
                          0.302,
                          -63.144
                        ],
                        [
                          7.553,
                          -49.951
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "9",
        size: 320,
        style: "Bold",
        w: 69.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -4.733,
                          5.539
                        ],
                        [
                          2.921,
                          -4.129
                        ],
                        [
                          5.74,
                          0
                        ],
                        [
                          4.633,
                          3.625
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.56,
                          0
                        ],
                        [
                          -5.841,
                          7.755
                        ],
                        [
                          0,
                          12.891
                        ],
                        [
                          4.532,
                          5.841
                        ],
                        [
                          10.876,
                          0
                        ],
                        [
                          5.942,
                          -5.035
                        ],
                        [
                          0,
                          -8.258
                        ],
                        [
                          -4.834,
                          -4.935
                        ],
                        [
                          -7.553,
                          0
                        ]
                      ],
                      o: [
                        [
                          -0.302,
                          6.244
                        ],
                        [
                          -2.921,
                          4.129
                        ],
                        [
                          -5.035,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          6.747,
                          5.237
                        ],
                        [
                          11.279,
                          0
                        ],
                        [
                          5.942,
                          -7.755
                        ],
                        [
                          0,
                          -12.387
                        ],
                        [
                          -4.935,
                          -6.445
                        ],
                        [
                          -7.755,
                          0
                        ],
                        [
                          -5.942,
                          4.935
                        ],
                        [
                          0,
                          6.949
                        ],
                        [
                          4.834,
                          4.935
                        ],
                        [
                          7.15,
                          0
                        ]
                      ],
                      v: [
                        [
                          48.138,
                          -35.651
                        ],
                        [
                          43.405,
                          -20.142
                        ],
                        [
                          30.414,
                          -13.998
                        ],
                        [
                          15.912,
                          -19.437
                        ],
                        [
                          7.452,
                          -6.848
                        ],
                        [
                          30.414,
                          1.007
                        ],
                        [
                          56.094,
                          -10.574
                        ],
                        [
                          64.957,
                          -41.592
                        ],
                        [
                          58.209,
                          -68.884
                        ],
                        [
                          34.039,
                          -79.962
                        ],
                        [
                          13.495,
                          -72.409
                        ],
                        [
                          4.633,
                          -52.57
                        ],
                        [
                          11.884,
                          -34.644
                        ],
                        [
                          30.414,
                          -27.292
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -3.323
                        ],
                        [
                          2.719,
                          -2.216
                        ],
                        [
                          3.625,
                          0
                        ],
                        [
                          0,
                          7.251
                        ],
                        [
                          -2.518,
                          2.316
                        ],
                        [
                          -3.625,
                          0
                        ],
                        [
                          -2.719,
                          -2.417
                        ]
                      ],
                      o: [
                        [
                          0,
                          3.625
                        ],
                        [
                          -2.719,
                          2.115
                        ],
                        [
                          -7.755,
                          0
                        ],
                        [
                          0,
                          -3.323
                        ],
                        [
                          2.618,
                          -2.417
                        ],
                        [
                          3.525,
                          0
                        ],
                        [
                          2.719,
                          2.417
                        ]
                      ],
                      v: [
                        [
                          47.736,
                          -52.972
                        ],
                        [
                          43.707,
                          -44.211
                        ],
                        [
                          34.241,
                          -40.988
                        ],
                        [
                          21.048,
                          -53.073
                        ],
                        [
                          24.875,
                          -61.633
                        ],
                        [
                          34.241,
                          -65.259
                        ],
                        [
                          43.607,
                          -61.633
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "9",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "3",
        size: 320,
        style: "Bold",
        w: 66.1,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          6.848
                        ],
                        [
                          8.762,
                          3.625
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          7.251
                        ],
                        [
                          5.035,
                          4.129
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          4.431,
                          -10.876
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -5.942,
                          0
                        ],
                        [
                          0,
                          -4.935
                        ],
                        [
                          6.949,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -5.942
                        ],
                        [
                          6.949,
                          0
                        ],
                        [
                          2.719,
                          5.74
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -13.797,
                          0
                        ],
                        [
                          -5.438,
                          4.532
                        ]
                      ],
                      o: [
                        [
                          0,
                          -8.762
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.15,
                          -3.827
                        ],
                        [
                          0,
                          -6.747
                        ],
                        [
                          -5.035,
                          -4.129
                        ],
                        [
                          -13.193,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.719,
                          -5.841
                        ],
                        [
                          7.15,
                          0
                        ],
                        [
                          0,
                          5.237
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.251,
                          0
                        ],
                        [
                          0,
                          5.539
                        ],
                        [
                          -7.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.733,
                          10.977
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          5.438,
                          -4.532
                        ]
                      ],
                      v: [
                        [
                          61.533,
                          -22.659
                        ],
                        [
                          49.246,
                          -40.686
                        ],
                        [
                          49.246,
                          -40.787
                        ],
                        [
                          60.022,
                          -57.404
                        ],
                        [
                          52.469,
                          -73.819
                        ],
                        [
                          32.831,
                          -80.063
                        ],
                        [
                          4.733,
                          -63.043
                        ],
                        [
                          19.336,
                          -56.094
                        ],
                        [
                          32.227,
                          -64.856
                        ],
                        [
                          43.607,
                          -56.296
                        ],
                        [
                          32.529,
                          -47.433
                        ],
                        [
                          25.076,
                          -47.433
                        ],
                        [
                          25.076,
                          -32.327
                        ],
                        [
                          33.435,
                          -32.327
                        ],
                        [
                          45.016,
                          -23.062
                        ],
                        [
                          33.435,
                          -13.898
                        ],
                        [
                          17.825,
                          -22.961
                        ],
                        [
                          3.525,
                          -15.61
                        ],
                        [
                          33.032,
                          1.208
                        ],
                        [
                          53.375,
                          -5.539
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "5",
        size: 320,
        style: "Bold",
        w: 65.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          8.359
                        ],
                        [
                          4.834,
                          4.33
                        ],
                        [
                          7.251,
                          0
                        ],
                        [
                          4.33,
                          -3.525
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.928,
                          0
                        ],
                        [
                          0,
                          -6.747
                        ],
                        [
                          7.352,
                          0
                        ],
                        [
                          3.021,
                          5.64
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -13.596,
                          0
                        ],
                        [
                          -5.539,
                          4.935
                        ]
                      ],
                      o: [
                        [
                          0,
                          -7.956
                        ],
                        [
                          -4.733,
                          -4.431
                        ],
                        [
                          -5.136,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          3.223,
                          -3.122
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          0,
                          7.251
                        ],
                        [
                          -6.949,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.338,
                          10.272
                        ],
                        [
                          8.459,
                          0
                        ],
                        [
                          5.539,
                          -4.935
                        ]
                      ],
                      v: [
                        [
                          61.734,
                          -26.385
                        ],
                        [
                          54.483,
                          -44.916
                        ],
                        [
                          36.456,
                          -51.562
                        ],
                        [
                          22.256,
                          -46.326
                        ],
                        [
                          24.774,
                          -64.05
                        ],
                        [
                          58.209,
                          -64.05
                        ],
                        [
                          58.209,
                          -78.854
                        ],
                        [
                          11.783,
                          -78.854
                        ],
                        [
                          6.042,
                          -38.873
                        ],
                        [
                          21.35,
                          -32.629
                        ],
                        [
                          32.126,
                          -37.262
                        ],
                        [
                          45.319,
                          -25.983
                        ],
                        [
                          32.73,
                          -14.301
                        ],
                        [
                          17.825,
                          -22.76
                        ],
                        [
                          3.928,
                          -14.502
                        ],
                        [
                          32.327,
                          0.906
                        ],
                        [
                          53.375,
                          -6.445
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "8",
        size: 320,
        style: "Bold",
        w: 68.6,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          7.05
                        ],
                        [
                          9.164,
                          3.928
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          5.136,
                          4.129
                        ],
                        [
                          8.359,
                          0
                        ],
                        [
                          5.136,
                          -4.129
                        ],
                        [
                          0,
                          -6.143
                        ],
                        [
                          -7.352,
                          -2.921
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.56
                        ],
                        [
                          -5.64,
                          -4.33
                        ],
                        [
                          -8.359,
                          0
                        ],
                        [
                          -5.64,
                          4.23
                        ]
                      ],
                      o: [
                        [
                          0,
                          -8.56
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.352,
                          -2.921
                        ],
                        [
                          0,
                          -6.143
                        ],
                        [
                          -5.136,
                          -4.129
                        ],
                        [
                          -8.359,
                          0
                        ],
                        [
                          -5.136,
                          4.129
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -9.164,
                          4.028
                        ],
                        [
                          0,
                          6.747
                        ],
                        [
                          5.64,
                          4.23
                        ],
                        [
                          8.359,
                          0
                        ],
                        [
                          5.64,
                          -4.23
                        ]
                      ],
                      v: [
                        [
                          63.95,
                          -22.256
                        ],
                        [
                          50.153,
                          -40.887
                        ],
                        [
                          50.153,
                          -40.988
                        ],
                        [
                          62.338,
                          -58.511
                        ],
                        [
                          54.684,
                          -73.92
                        ],
                        [
                          34.543,
                          -80.063
                        ],
                        [
                          14.301,
                          -73.92
                        ],
                        [
                          6.647,
                          -58.511
                        ],
                        [
                          18.832,
                          -40.988
                        ],
                        [
                          18.832,
                          -40.887
                        ],
                        [
                          5.035,
                          -21.954
                        ],
                        [
                          13.495,
                          -5.338
                        ],
                        [
                          34.442,
                          1.007
                        ],
                        [
                          55.49,
                          -5.338
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -5.64
                        ],
                        [
                          6.143,
                          0
                        ],
                        [
                          0,
                          5.942
                        ],
                        [
                          -6.747,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          5.942
                        ],
                        [
                          -6.042,
                          0
                        ],
                        [
                          0,
                          -5.539
                        ],
                        [
                          6.949,
                          0
                        ]
                      ],
                      v: [
                        [
                          46.024,
                          -56.497
                        ],
                        [
                          34.442,
                          -46.729
                        ],
                        [
                          22.961,
                          -56.598
                        ],
                        [
                          34.442,
                          -66.367
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -3.122
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          0,
                          5.942
                        ],
                        [
                          -6.949,
                          0
                        ],
                        [
                          -2.618,
                          -1.712
                        ]
                      ],
                      o: [
                        [
                          0,
                          6.042
                        ],
                        [
                          -7.654,
                          0
                        ],
                        [
                          0,
                          -6.143
                        ],
                        [
                          3.424,
                          0
                        ],
                        [
                          2.618,
                          1.712
                        ]
                      ],
                      v: [
                        [
                          47.534,
                          -23.264
                        ],
                        [
                          34.341,
                          -12.689
                        ],
                        [
                          21.451,
                          -23.264
                        ],
                        [
                          34.543,
                          -33.032
                        ],
                        [
                          43.607,
                          -30.414
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "8",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "6",
        size: 320,
        style: "Bold",
        w: 69.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          4.532,
                          -5.338
                        ],
                        [
                          -2.921,
                          4.129
                        ],
                        [
                          -5.841,
                          0
                        ],
                        [
                          -4.633,
                          -3.625
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          6.143,
                          -7.956
                        ],
                        [
                          0,
                          -12.891
                        ],
                        [
                          -4.431,
                          -5.74
                        ],
                        [
                          -10.373,
                          0
                        ],
                        [
                          -5.942,
                          4.935
                        ],
                        [
                          0,
                          8.258
                        ],
                        [
                          4.834,
                          4.834
                        ],
                        [
                          7.553,
                          0
                        ]
                      ],
                      o: [
                        [
                          0.302,
                          -6.244
                        ],
                        [
                          3.021,
                          -4.129
                        ],
                        [
                          4.834,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.747,
                          -5.237
                        ],
                        [
                          -11.481,
                          0
                        ],
                        [
                          -6.143,
                          7.956
                        ],
                        [
                          0,
                          12.186
                        ],
                        [
                          5.74,
                          7.352
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          5.942,
                          -4.935
                        ],
                        [
                          0,
                          -6.949
                        ],
                        [
                          -4.733,
                          -4.935
                        ],
                        [
                          -7.15,
                          0
                        ]
                      ],
                      v: [
                        [
                          22.256,
                          -43.304
                        ],
                        [
                          27.09,
                          -58.813
                        ],
                        [
                          40.384,
                          -65.057
                        ],
                        [
                          54.584,
                          -59.619
                        ],
                        [
                          63.043,
                          -72.107
                        ],
                        [
                          40.686,
                          -79.962
                        ],
                        [
                          14.2,
                          -68.079
                        ],
                        [
                          5.035,
                          -36.859
                        ],
                        [
                          11.682,
                          -10.071
                        ],
                        [
                          35.953,
                          1.007
                        ],
                        [
                          56.497,
                          -6.445
                        ],
                        [
                          65.359,
                          -26.285
                        ],
                        [
                          58.109,
                          -44.009
                        ],
                        [
                          39.679,
                          -51.361
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          3.323
                        ],
                        [
                          -7.251,
                          0
                        ],
                        [
                          0,
                          -7.15
                        ],
                        [
                          2.518,
                          -2.316
                        ],
                        [
                          3.726,
                          0
                        ],
                        [
                          2.719,
                          2.316
                        ]
                      ],
                      o: [
                        [
                          0,
                          -7.251
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          0,
                          3.323
                        ],
                        [
                          -2.518,
                          2.316
                        ],
                        [
                          -3.625,
                          0
                        ],
                        [
                          -2.719,
                          -2.316
                        ]
                      ],
                      v: [
                        [
                          22.256,
                          -25.781
                        ],
                        [
                          35.651,
                          -37.564
                        ],
                        [
                          48.944,
                          -25.681
                        ],
                        [
                          45.117,
                          -17.12
                        ],
                        [
                          35.751,
                          -13.696
                        ],
                        [
                          26.285,
                          -17.221
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "6",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "C",
        size: 80,
        style: "Regular",
        w: 83.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -11.078,
                          0
                        ],
                        [
                          -6.143,
                          4.733
                        ],
                        [
                          -2.316,
                          6.747
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          13.998,
                          0
                        ],
                        [
                          5.841,
                          6.143
                        ],
                        [
                          0,
                          9.366
                        ],
                        [
                          -5.942,
                          6.042
                        ],
                        [
                          -8.56,
                          0
                        ],
                        [
                          -4.532,
                          -3.827
                        ],
                        [
                          -1.913,
                          -5.539
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          6.143,
                          4.733
                        ],
                        [
                          9.064,
                          0
                        ],
                        [
                          7.755,
                          -7.654
                        ],
                        [
                          0,
                          -11.783
                        ],
                        [
                          -7.553,
                          -7.553
                        ]
                      ],
                      o: [
                        [
                          8.963,
                          0
                        ],
                        [
                          6.244,
                          -4.733
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -4.532,
                          12.387
                        ],
                        [
                          -8.963,
                          0
                        ],
                        [
                          -5.841,
                          -6.143
                        ],
                        [
                          0,
                          -9.366
                        ],
                        [
                          6.042,
                          -6.042
                        ],
                        [
                          7.553,
                          0
                        ],
                        [
                          4.633,
                          3.726
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.115,
                          -6.647
                        ],
                        [
                          -6.143,
                          -4.733
                        ],
                        [
                          -10.977,
                          0
                        ],
                        [
                          -7.755,
                          7.553
                        ],
                        [
                          0,
                          11.682
                        ],
                        [
                          7.553,
                          7.452
                        ]
                      ],
                      v: [
                        [
                          44.714,
                          0.806
                        ],
                        [
                          67.474,
                          -6.244
                        ],
                        [
                          80.365,
                          -23.465
                        ],
                        [
                          72.913,
                          -26.99
                        ],
                        [
                          45.016,
                          -7.05
                        ],
                        [
                          22.861,
                          -16.315
                        ],
                        [
                          14.099,
                          -39.679
                        ],
                        [
                          23.062,
                          -62.741
                        ],
                        [
                          44.916,
                          -71.805
                        ],
                        [
                          63.043,
                          -66.064
                        ],
                        [
                          72.812,
                          -52.066
                        ],
                        [
                          80.264,
                          -55.49
                        ],
                        [
                          67.877,
                          -72.51
                        ],
                        [
                          45.117,
                          -79.66
                        ],
                        [
                          17.02,
                          -68.179
                        ],
                        [
                          5.438,
                          -39.175
                        ],
                        [
                          16.718,
                          -10.373
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "C",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "C",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "o",
        size: 80,
        style: "Regular",
        w: 65,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -7.755,
                          0
                        ],
                        [
                          -5.338,
                          5.338
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          5.338,
                          5.338
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          5.338,
                          -5.338
                        ],
                        [
                          0,
                          -7.956
                        ],
                        [
                          -5.338,
                          -5.338
                        ]
                      ],
                      o: [
                        [
                          7.855,
                          0
                        ],
                        [
                          5.338,
                          -5.338
                        ],
                        [
                          0,
                          -7.755
                        ],
                        [
                          -5.338,
                          -5.338
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          -5.338,
                          5.338
                        ],
                        [
                          0,
                          7.654
                        ],
                        [
                          5.338,
                          5.338
                        ]
                      ],
                      v: [
                        [
                          32.629,
                          0.906
                        ],
                        [
                          52.469,
                          -7.15
                        ],
                        [
                          60.526,
                          -27.09
                        ],
                        [
                          52.469,
                          -46.628
                        ],
                        [
                          32.831,
                          -54.684
                        ],
                        [
                          12.891,
                          -46.628
                        ],
                        [
                          4.935,
                          -26.688
                        ],
                        [
                          12.891,
                          -7.15
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "o",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          5.64,
                          0
                        ],
                        [
                          3.726,
                          4.129
                        ],
                        [
                          0,
                          5.74
                        ],
                        [
                          -3.726,
                          4.028
                        ],
                        [
                          -5.74,
                          0
                        ],
                        [
                          -3.726,
                          -4.129
                        ],
                        [
                          0,
                          -5.74
                        ],
                        [
                          3.726,
                          -4.028
                        ]
                      ],
                      o: [
                        [
                          -5.74,
                          0
                        ],
                        [
                          -3.726,
                          -4.129
                        ],
                        [
                          0,
                          -5.74
                        ],
                        [
                          3.726,
                          -4.028
                        ],
                        [
                          5.74,
                          0
                        ],
                        [
                          3.726,
                          4.129
                        ],
                        [
                          0,
                          5.74
                        ],
                        [
                          -3.726,
                          4.028
                        ]
                      ],
                      v: [
                        [
                          32.831,
                          -6.042
                        ],
                        [
                          18.53,
                          -12.186
                        ],
                        [
                          12.991,
                          -27.09
                        ],
                        [
                          18.53,
                          -41.794
                        ],
                        [
                          32.629,
                          -47.836
                        ],
                        [
                          46.829,
                          -41.592
                        ],
                        [
                          52.469,
                          -26.688
                        ],
                        [
                          46.829,
                          -12.085
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "o",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "o",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "m",
        size: 80,
        style: "Regular",
        w: 97.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.719,
                          3.122
                        ],
                        [
                          -4.532,
                          0
                        ],
                        [
                          0,
                          -9.668
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          12.589,
                          0
                        ],
                        [
                          4.633,
                          -7.15
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          4.532,
                          -6.949
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.719,
                          3.323
                        ],
                        [
                          -4.532,
                          0
                        ],
                        [
                          0,
                          -9.366
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.733
                        ],
                        [
                          2.719,
                          -3.223
                        ],
                        [
                          9.366,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.891
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          -3.323,
                          -7.15
                        ],
                        [
                          -7.654,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.633
                        ],
                        [
                          2.719,
                          -3.323
                        ],
                        [
                          9.265,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          53.275,
                          0
                        ],
                        [
                          53.275,
                          -30.917
                        ],
                        [
                          57.303,
                          -42.7
                        ],
                        [
                          68.179,
                          -47.534
                        ],
                        [
                          82.077,
                          -31.32
                        ],
                        [
                          82.077,
                          0
                        ],
                        [
                          89.932,
                          0
                        ],
                        [
                          89.932,
                          -33.234
                        ],
                        [
                          70.093,
                          -54.684
                        ],
                        [
                          51.16,
                          -44.009
                        ],
                        [
                          33.939,
                          -54.785
                        ],
                        [
                          16.617,
                          -44.412
                        ],
                        [
                          16.617,
                          -53.778
                        ],
                        [
                          8.862,
                          -53.778
                        ],
                        [
                          8.862,
                          0
                        ],
                        [
                          16.718,
                          0
                        ],
                        [
                          16.718,
                          -30.615
                        ],
                        [
                          20.746,
                          -42.599
                        ],
                        [
                          31.622,
                          -47.635
                        ],
                        [
                          45.52,
                          -31.522
                        ],
                        [
                          45.52,
                          0
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "m",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "m",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "p",
        size: 80,
        style: "Regular",
        w: 67.3,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          7.553
                        ],
                        [
                          4.733,
                          5.539
                        ],
                        [
                          7.352,
                          0
                        ],
                        [
                          5.338,
                          -8.359
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.762,
                          0
                        ],
                        [
                          -4.733,
                          5.539
                        ]
                      ],
                      o: [
                        [
                          0,
                          -7.352
                        ],
                        [
                          -4.733,
                          -5.539
                        ],
                        [
                          -8.56,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.035,
                          7.956
                        ],
                        [
                          7.352,
                          0
                        ],
                        [
                          4.733,
                          -5.539
                        ]
                      ],
                      v: [
                        [
                          62.741,
                          -26.99
                        ],
                        [
                          55.591,
                          -46.326
                        ],
                        [
                          37.463,
                          -54.684
                        ],
                        [
                          16.617,
                          -42.096
                        ],
                        [
                          16.617,
                          -53.778
                        ],
                        [
                          8.862,
                          -53.778
                        ],
                        [
                          8.862,
                          26.486
                        ],
                        [
                          16.718,
                          26.486
                        ],
                        [
                          16.718,
                          -11.078
                        ],
                        [
                          37.463,
                          0.806
                        ],
                        [
                          55.591,
                          -7.452
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "p",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          5.74,
                          0
                        ],
                        [
                          3.625,
                          4.129
                        ],
                        [
                          0,
                          5.74
                        ],
                        [
                          -3.726,
                          4.129
                        ],
                        [
                          -5.438,
                          0
                        ],
                        [
                          -3.424,
                          -4.129
                        ],
                        [
                          0,
                          -5.841
                        ],
                        [
                          3.424,
                          -3.928
                        ]
                      ],
                      o: [
                        [
                          -5.539,
                          0
                        ],
                        [
                          -3.625,
                          -4.129
                        ],
                        [
                          0,
                          -5.539
                        ],
                        [
                          3.726,
                          -4.129
                        ],
                        [
                          5.74,
                          0
                        ],
                        [
                          3.424,
                          4.028
                        ],
                        [
                          0,
                          5.942
                        ],
                        [
                          -3.323,
                          3.928
                        ]
                      ],
                      v: [
                        [
                          35.852,
                          -6.143
                        ],
                        [
                          22.055,
                          -12.286
                        ],
                        [
                          16.617,
                          -27.09
                        ],
                        [
                          22.156,
                          -41.492
                        ],
                        [
                          35.852,
                          -47.736
                        ],
                        [
                          49.548,
                          -41.592
                        ],
                        [
                          54.684,
                          -26.788
                        ],
                        [
                          49.548,
                          -11.984
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "p",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "p",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "l",
        size: 80,
        style: "Regular",
        w: 25.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          16.818,
                          -82.077
                        ],
                        [
                          8.862,
                          -82.077
                        ],
                        [
                          8.862,
                          0
                        ],
                        [
                          16.818,
                          0
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "l",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "l",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "e",
        size: 80,
        style: "Regular",
        w: 60.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.208
                        ],
                        [
                          4.431,
                          5.136
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          5.035,
                          -5.539
                        ],
                        [
                          0,
                          -7.452
                        ],
                        [
                          -5.237,
                          -5.438
                        ],
                        [
                          -7.755,
                          0
                        ],
                        [
                          -4.532,
                          8.862
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.553,
                          0
                        ],
                        [
                          3.625,
                          3.525
                        ],
                        [
                          0.302,
                          5.237
                        ]
                      ],
                      o: [
                        [
                          0.101,
                          -0.504
                        ],
                        [
                          0,
                          -8.157
                        ],
                        [
                          -4.431,
                          -5.136
                        ],
                        [
                          -7.352,
                          0
                        ],
                        [
                          -5.035,
                          5.539
                        ],
                        [
                          0,
                          7.755
                        ],
                        [
                          5.237,
                          5.338
                        ],
                        [
                          9.668,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.122,
                          6.445
                        ],
                        [
                          -5.237,
                          0
                        ],
                        [
                          -3.625,
                          -3.525
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          56.396,
                          -24.472
                        ],
                        [
                          56.497,
                          -27.09
                        ],
                        [
                          49.85,
                          -46.93
                        ],
                        [
                          31.119,
                          -54.684
                        ],
                        [
                          12.488,
                          -46.426
                        ],
                        [
                          4.935,
                          -26.99
                        ],
                        [
                          12.79,
                          -7.15
                        ],
                        [
                          32.227,
                          0.906
                        ],
                        [
                          54.987,
                          -12.991
                        ],
                        [
                          49.146,
                          -16.818
                        ],
                        [
                          32.126,
                          -6.042
                        ],
                        [
                          18.832,
                          -11.279
                        ],
                        [
                          12.891,
                          -24.472
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "e",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          -3.424,
                          3.223
                        ],
                        [
                          -4.532,
                          0
                        ],
                        [
                          -1.108,
                          -10.071
                        ]
                      ],
                      o: [
                        [
                          0.705,
                          -4.633
                        ],
                        [
                          3.424,
                          -3.323
                        ],
                        [
                          10.071,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          12.991,
                          -31.119
                        ],
                        [
                          19.135,
                          -42.902
                        ],
                        [
                          31.119,
                          -47.836
                        ],
                        [
                          48.541,
                          -31.119
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "e",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "e",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "t",
        size: 80,
        style: "Regular",
        w: 38,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -10.172,
                          0
                        ],
                        [
                          -1.712,
                          0.504
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.115,
                          0
                        ],
                        [
                          0,
                          5.035
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          2.316,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.108,
                          0.302
                        ],
                        [
                          -5.841,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          9.567
                        ]
                      ],
                      v: [
                        [
                          27.695,
                          0.403
                        ],
                        [
                          33.838,
                          -0.403
                        ],
                        [
                          33.838,
                          -6.949
                        ],
                        [
                          29.004,
                          -6.546
                        ],
                        [
                          20.242,
                          -14.099
                        ],
                        [
                          20.242,
                          -46.829
                        ],
                        [
                          33.838,
                          -46.829
                        ],
                        [
                          33.838,
                          -53.778
                        ],
                        [
                          20.242,
                          -53.778
                        ],
                        [
                          20.242,
                          -69.287
                        ],
                        [
                          12.488,
                          -69.287
                        ],
                        [
                          12.488,
                          -53.778
                        ],
                        [
                          2.518,
                          -53.778
                        ],
                        [
                          2.518,
                          -46.829
                        ],
                        [
                          12.488,
                          -46.829
                        ],
                        [
                          12.488,
                          -13.898
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "t",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "t",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "d",
        size: 80,
        style: "Regular",
        w: 67.3,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -7.352
                        ],
                        [
                          -4.633,
                          -5.438
                        ],
                        [
                          -7.452,
                          0
                        ],
                        [
                          -4.935,
                          8.157
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.359,
                          0
                        ],
                        [
                          4.733,
                          -5.539
                        ]
                      ],
                      o: [
                        [
                          0,
                          7.654
                        ],
                        [
                          4.733,
                          5.438
                        ],
                        [
                          8.963,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -5.539,
                          -8.157
                        ],
                        [
                          -7.352,
                          0
                        ],
                        [
                          -4.733,
                          5.539
                        ]
                      ],
                      v: [
                        [
                          5.035,
                          -26.99
                        ],
                        [
                          11.984,
                          -7.352
                        ],
                        [
                          30.212,
                          0.806
                        ],
                        [
                          51.16,
                          -11.481
                        ],
                        [
                          51.16,
                          0
                        ],
                        [
                          58.813,
                          0
                        ],
                        [
                          58.813,
                          -81.976
                        ],
                        [
                          51.059,
                          -81.976
                        ],
                        [
                          51.059,
                          -42.499
                        ],
                        [
                          30.212,
                          -54.684
                        ],
                        [
                          12.085,
                          -46.326
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "d",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          5.539,
                          0
                        ],
                        [
                          3.424,
                          3.928
                        ],
                        [
                          0,
                          5.74
                        ],
                        [
                          -3.424,
                          3.928
                        ],
                        [
                          -5.74,
                          0
                        ],
                        [
                          -3.726,
                          -4.129
                        ],
                        [
                          0,
                          -5.539
                        ],
                        [
                          3.726,
                          -4.129
                        ]
                      ],
                      o: [
                        [
                          -5.74,
                          0
                        ],
                        [
                          -3.424,
                          -4.028
                        ],
                        [
                          0,
                          -5.942
                        ],
                        [
                          3.424,
                          -4.028
                        ],
                        [
                          5.438,
                          0
                        ],
                        [
                          3.726,
                          4.129
                        ],
                        [
                          0,
                          5.74
                        ],
                        [
                          -3.625,
                          4.129
                        ]
                      ],
                      v: [
                        [
                          31.824,
                          -6.143
                        ],
                        [
                          18.127,
                          -12.085
                        ],
                        [
                          12.991,
                          -26.788
                        ],
                        [
                          18.127,
                          -41.693
                        ],
                        [
                          31.824,
                          -47.736
                        ],
                        [
                          45.52,
                          -41.492
                        ],
                        [
                          51.16,
                          -27.09
                        ],
                        [
                          45.621,
                          -12.286
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "d",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "d",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      }
    ]
  }