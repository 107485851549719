import React from "react";
import Box from "@mui/material/Box";
import LayoutStyles from "../App.styles";
import { useMediaQuery, useTheme } from "@mui/material";
import Chart from "../assets/icons/barchart.png";
import Cloud from "../assets/icons/cloud.png";
import Security from "../assets/icons/security.png";
import Gears from "../assets/icons/gears.png";
import Document from "../assets/icons/document.png";
import Users from "../assets/images/enterprise-users.png";
import Analytics from "../assets/images/enterprise-analytics.png";

type Props = {
  data: { title: string; body: string }[];
};

const iconArr = [Analytics, Gears, Security, Users, Cloud, Document];

const FeatureGrid: React.FC<Props> = ({ data }) => {
  const theme = useTheme();
  const showTabletLayout = useMediaQuery(theme.breakpoints.down(828));
  return (
    <LayoutStyles.FeatureGrid.Container>
      <LayoutStyles.ImageAndTextBox.Tag
        style={{ justifyContent: "center", color: theme.palette.neutral.main }}
      >
        ENTERPRISE FEATURES
      </LayoutStyles.ImageAndTextBox.Tag>
      <LayoutStyles.FeatureGrid.Headline $centered>
        More visibility, more control
      </LayoutStyles.FeatureGrid.Headline>
      {!showTabletLayout && (
        <LayoutStyles.FlexboxRow $height="552px" $gap="10px" $width="100%">
          <LayoutStyles.FlexboxRow
            $height="100%"
            $flexDirection="column"
            style={{ flex: 1 }}
          >
            <LayoutStyles.FeatureGrid.GridBox
              style={{ marginBottom: "10px", height: "190px" }}
            >
              <LayoutStyles.FeatureGrid.Icon src={Cloud} />
              <LayoutStyles.FeatureGrid.Title>
                Dedicated Cloud Hosting
              </LayoutStyles.FeatureGrid.Title>
            </LayoutStyles.FeatureGrid.GridBox>
            <LayoutStyles.FeatureGrid.GridBox
              style={{ background: "rgba(59,46,201,0.53)", flex: 1 }}
            >
              <img
                style={{ height: "auto", width: "100%" }}
                alt="Cutom Analytics"
                src={Users}
              />
              <LayoutStyles.FeatureGrid.Title>
                User & Admin Management
              </LayoutStyles.FeatureGrid.Title>
            </LayoutStyles.FeatureGrid.GridBox>
          </LayoutStyles.FlexboxRow>
          <div
            style={{
              height: "100%",
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              flex: 2,
            }}
          >
            <LayoutStyles.FlexboxRow $gap="10px" style={{ flex: 1 }}>
              <LayoutStyles.FeatureGrid.GridBox style={{ width: "32.5%" }}>
                <LayoutStyles.FeatureGrid.Icon src={Security} />
                <LayoutStyles.FeatureGrid.Title>
                  Security
                </LayoutStyles.FeatureGrid.Title>
              </LayoutStyles.FeatureGrid.GridBox>
              <LayoutStyles.FeatureGrid.GridBox
                style={{ background: "rgba(36,27,108,0.53)", flex: 1 }}
              >
                <img
                  style={{ height: "auto", width: "100%" }}
                  src={Analytics}
                  alt="User & Admin Management"
                />
                <LayoutStyles.FeatureGrid.Title>
                  Custom Analytics
                </LayoutStyles.FeatureGrid.Title>
              </LayoutStyles.FeatureGrid.GridBox>
            </LayoutStyles.FlexboxRow>
            <LayoutStyles.FlexboxRow $gap="10px" style={{ height: "195px" }}>
              <LayoutStyles.FeatureGrid.GridBox>
                <LayoutStyles.FeatureGrid.Icon src={Cloud} />
                <LayoutStyles.FeatureGrid.Title>
                  Software Customizations
                </LayoutStyles.FeatureGrid.Title>
              </LayoutStyles.FeatureGrid.GridBox>
              <LayoutStyles.FeatureGrid.GridBox>
                <LayoutStyles.FeatureGrid.Icon src={Cloud} />
                <LayoutStyles.FeatureGrid.Title>
                  SLA
                </LayoutStyles.FeatureGrid.Title>
              </LayoutStyles.FeatureGrid.GridBox>
            </LayoutStyles.FlexboxRow>
          </div>
        </LayoutStyles.FlexboxRow>
      )}
      {showTabletLayout && (
        <LayoutStyles.FlexboxRow $gap="20px" $flexWrap $justifyContent="center">
          {data.map((feature, index) => {
            return (
              <LayoutStyles.FeatureGrid.GridBox style={{ background: index === 0 ? "rgba(59,46,201,0.53)" : index === 3 ? "rgba(36, 27, 108, 0.53)" : "#2B2F34", height: "297px" }}>
                {(index !== 0 && index !== 3) && <LayoutStyles.FeatureGrid.Icon src={iconArr[index]} />}
                {(index === 0 || index === 3) && <img style={{maxWidth: '100%', height: '75%'}} src={iconArr[index]} />}
                <LayoutStyles.FeatureGrid.Title>
                  {feature.title}
                </LayoutStyles.FeatureGrid.Title>
                {feature.body && (
                  <LayoutStyles.FeatureGrid.Body>
                    {feature.body}
                  </LayoutStyles.FeatureGrid.Body>
                )}
              </LayoutStyles.FeatureGrid.GridBox>
            );
          })}
        </LayoutStyles.FlexboxRow>
      )}
    </LayoutStyles.FeatureGrid.Container>
  );
};

export default FeatureGrid;
