import React, { useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutStyles from "../App.styles";
import { useApp } from "../context/AppContext";
import ReactPlayer from "react-player";
import VideoButton from "../Common/Button/VideoButton";
import { useTheme, useMediaQuery } from "@mui/material";

const VideoModal: React.FC<{ videoURL: string }> = ({ videoURL }) => {
  const { setShowVideo, setVideoURL } = useApp();
  const [playing, setPlaying] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  return (
    <LayoutStyles.VideoModal.Container>
      <LayoutStyles.VideoModal.CloseButton
        onClick={() => {
          setShowVideo(false);
          setVideoURL("");
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </LayoutStyles.VideoModal.CloseButton>
      <div
        onClick={() => setPlaying((prev) => !prev)}
        style={{
          pointerEvents: "auto",
          borderRadius: "21px",
          overflow: "hidden",
          position: "relative",
          width: "965px",
          height: isSmallScreen ? "250px" : "543px",
          maxWidth: "85%",
        }}
      >
        {!playing && (
          <div
            style={{
              height: "100%",
              width: "100%",
              maxHeight: "532px",
              maxWidth: "946px",
              backgroundColor: "rgba(0,0,0,0.42",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              borderRadius: "12px",
              cursor: "pointer",
            }}
          >
            <VideoButton text="See how it works" clickFunction={() => {}} />
          </div>
        )}
        <ReactPlayer
          controls
          style={{
            maxHeight: "532px",
            maxWidth: "946px",
            borderRadius: "15px",
            border: "none",
            overflow: "hidden",
          }}
          playing={playing}
          width="100%"
          height="100%"
          url={videoURL}
          allowFullScreen
        />
      </div>
    </LayoutStyles.VideoModal.Container>
  );
};

export default VideoModal;
