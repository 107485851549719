import {
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import WhiteArrow from "../../assets/icons/buttonarrows/white-arrow.png";
import BlackArrow from "../../assets/icons/buttonarrows/black-arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React from "react";
import LayoutStyles from "../../App.styles";

const ArrowButton = ({
  text,
  bgColor,
  color,
  reverse = false,
  fontSize,
  overrideCenter,
  clickFunction,
  chevrons,
  type,
  width,
}: {
  text: string;
  shiftColor?: boolean;
  bgColor?: string;
  color?: string;
  reverse?: boolean;
  fontSize?: number;
  overrideCenter?: boolean;
  chevrons?: boolean;
  type?: "button" | "submit" | "reset";
  width?: string;
  clickFunction?: () => void;
}) => {
  if (reverse) {
    return (
      <LayoutStyles.ImageAndTextBox.ArrowButton
        $overrideCenter={overrideCenter}
        $color={color}
        onClick={clickFunction}
        $bgColor={bgColor}
        $width={width}
        type={type ? type : 'button'}
        style={{ fontSize: fontSize ?? 16, maxWidth: "100%" }}
        startIcon={
          <FontAwesomeIcon icon={chevrons ? faChevronLeft : faArrowLeft} />
        }
      >
        <motion.div>{text}</motion.div>
      </LayoutStyles.ImageAndTextBox.ArrowButton>
    );
  } else
    return (
      <LayoutStyles.ImageAndTextBox.ArrowButton
        $color={color}
        $overrideCenter={overrideCenter}
        onClick={clickFunction}
        $bgColor={bgColor}
        $width={width}
        style={{ fontSize: fontSize ?? 16, maxWidth: "100%" }}
        endIcon={
          chevrons ? (
            <FontAwesomeIcon
              icon={faChevronRight}
              color={
                bgColor === "#FFFFFF" || bgColor === "#ffffff"
                  ? "#000000"
                  : "#ffffff"
              }
            />
          ) : (
            <img
              src={
                bgColor === "#FFFFFF" || bgColor === "#ffffff"
                  ? BlackArrow
                  : WhiteArrow
              }
              style={{ width: "32px" }}
              alt=""
            />
          )
        }
      >
        <motion.div>{text}</motion.div>
      </LayoutStyles.ImageAndTextBox.ArrowButton>
    );
};

export default ArrowButton;
