import { motion } from "framer-motion";
import React, { useRef } from "react";
import Lottie from "react-lottie";
import LayoutStyles from "../App.styles";

const MultiLayerAnimation = ({
  lotties,
  isOnScreen,
  avatarPosition,
  avatarSize,
  isBackgroundImg,
  transform,
  margin,
  loop
}: {
  lotties: any[];
  isOnScreen: boolean;
  avatarPosition: [string, string, string, string];
  avatarSize: [string, string];
  isBackgroundImg?: boolean[] | undefined;
  transform?: string;
  margin?: string;
  loop?: boolean;
}) => {
  const layerRefs = useRef<Array<HTMLImageElement | Lottie | null>>([]);
  const translateRef = useRef(transform);
  return (
    <LayoutStyles.AnimationContainer style={{margin: margin ? margin : '0'}}>
      {lotties.map((element, index) => {
        const defaultOptions = {
          loop: loop ? true : false,
          autoplay: isOnScreen,
          animationData: element,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        };
        if (typeof element !== "string")
          return (
            <Lottie
              key={index}
              options={defaultOptions}
              style={{
                width: "100%",
                height: "100%",
                top: 0,
                zIndex: index,
                position: index === 0 ? "static" : "absolute",
                transform: 'translateY(40px) !important',
              
              }}
              ref={(el) => (layerRefs.current[index] = el)}
              isPaused={!isOnScreen}
            />
          );
        else if (isBackgroundImg === undefined || !isBackgroundImg[index])
          return (
            <motion.img
              src={element}
              key={index}
              ref={(el) => (layerRefs.current[index] = el)}
              style={{
                height: avatarSize[1],
                top: avatarPosition[0],
                right: avatarPosition[1],
                bottom: avatarPosition[2],
                left: avatarPosition[3],
                zIndex: index,
                position: index === 0 ? "static" : "absolute",
                transform: translateRef.current ? translateRef.current : 'unset !important',
              }}
              initial={{
                scale: 0,
              }}
              animate={{
                scale: isOnScreen ? 1 : 0,
              }}
              transition={{
                duration: 0.6,
              }}
            />
          );
        else
          return (
            <motion.img
              src={element}
              key={index}
              ref={(el) => (layerRefs.current[index] = el)}
              style={{
                width: "100%",
                zIndex: index,
                position: index === 0 ? "static" : "absolute",
              }}
              initial={{
                scale: 1,
              }}
              animate={{
                scale: isOnScreen ? 1 : 1,
              }}
              transition={{
                duration: 0.6,
              }}
            />
          );
      })}
    </LayoutStyles.AnimationContainer>
  );
};

export default MultiLayerAnimation;
