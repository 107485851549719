import {
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import LayoutStyles from "../App.styles";
import { useNavigate } from "react-router-dom";

import WhiteArrow from "../assets/icons/buttonarrows/white-arrow.png";
import { useMediaQuery } from "@mui/material";
import theme from "../theme";

type PropTypes = {
  bgColor: string;
  image: string;
  caption?: string;
  buttonText?: string;
  bullets?: string[];
  path: string;
};

const ImageCaption = ({
  bgColor,
  image,
  caption = "",
  bullets,
  buttonText,
  path,
}: PropTypes) => {
  
  const navigate = useNavigate();

  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  return (
    <LayoutStyles.ImageCaption.Container
      style={{
        height: isSmallScreen ? bullets && "auto" : isMediumScreen ? bullets && "891px" : bullets && "909.5px",
      }}
      $bgColor={bgColor}
    >
      <LayoutStyles.ImageCaption.ImageArea
        style={{
          height: bullets && "389px",
        }}
        $image={image}
      />
      <LayoutStyles.ImageCaption.CaptionArea
        style={{
          height: isMediumScreen
            ? bullets && "fit-content"
            : bullets && "496px",
        }}
      >
        <div>
        {caption && (
          <LayoutStyles.ImageCaption.Caption>
            {caption}
          </LayoutStyles.ImageCaption.Caption>
        )}
        {bullets && (
          <div>
            {bullets.map((bullet, index) => {
              if (index === 0) return;
              return (
                <LayoutStyles.FlexboxRow
                  style={{
                    fontSize: isMediumScreen ? "14px" : "16px",
                  }}
                  $margin="0 0 19px 0"
                  $justifyContent="flex-start !important"
                >
                  <FontAwesomeIcon
                    color={theme.palette.primary.main}
                    size="xl"
                    style={{ marginRight: "16px" }}
                    icon={faCheckCircle}
                  />
                  {bullet}
                </LayoutStyles.FlexboxRow>
              );
            })}
          </div>
        )}
        </div>
        <LayoutStyles.ImageCaption.Button
          onClick={() => navigate(path)}
        >
          <p style={{ fontWeight: "500" }}>{buttonText}</p>
          <img src={WhiteArrow} style={{ width: "32px" }} alt="" />
        </LayoutStyles.ImageCaption.Button>
      </LayoutStyles.ImageCaption.CaptionArea>
    </LayoutStyles.ImageCaption.Container>
  );
};

export default ImageCaption;
