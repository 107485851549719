import React, { useRef } from "react";
import LayoutStyles from "../App.styles";
import { useTheme, useMediaQuery } from "@mui/material";
import LandingHero from "../PageBlocks/LandingHero";
import FaqSection from "../PageBlocks/FaqSection";
import SubMenu from "../Common/SubMenu";
import CaseStudies from "../PageBlocks/CaseStudies";
import LandingSpread from "../PageBlocks/LandingSpread";
import BlogSwiper from "../PageBlocks/BlogSwiper";
import Subscribe from "../PageBlocks/Subscribe";

const Landing: React.FC<{ containerRef: React.RefObject<HTMLDivElement> }> = ({
  containerRef,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const spreadData = [
    {
        headline: "Use AI to gain an edge over your competitors.",
        body: "With AI, you can understand your target audience better, personalize your marketing messages, and optimize your campaigns for maximum impact."
    },
    {
        headline: "Boost sales with 3D product visualizations",
        body: "With 3D product visualizations, you can create immersive experiences for your customers and showcase your products and services in unique ways."
    },
    {
        headline: "Reat-time business intelligence.",
        body: "Xureal's real-time analytics help you make informed decisions swiftly and efficiently—improving operational efficiency, heightened customer satisfaction, and a competitive edge in the market."
    },
    {
        headline: "Keep marketing assets at your fingertips.",
        body: "With Xureal, teams spend less time searching for content so they can focus on selling. We make it easy for teams to access the most relevant content in their flow of work through personalized recommendations."
    }
  ]
  return (
    <>
      <SubMenu />
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "0" : "100px 35px"}
        $height="fit-content"
        $backgroundColor={theme.palette.neutral.light}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <LandingHero />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "0" : "90px 35px 70px"}
        $backgroundColor="#151418"
      >
        <CaseStudies />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $backgroundColor={theme.palette.neutral.light}
        $padding={isSmallScreen ? "60px 0" : "110px 0 60px 0"}
      >
        <LayoutStyles.ImageAndTextBox.Tag
          style={{
            justifyContent: "center",
            marginBottom: "0",
          }}
        >
          BENEFITS
        </LayoutStyles.ImageAndTextBox.Tag>
        <LayoutStyles.PageTitle style={{fontSize: '45px', maxWidth: '75%', margin: '30px auto 0'}}>
          {"Stay ahead of your competitors and increase sales with xureal."}
        </LayoutStyles.PageTitle>
      </LayoutStyles.SectionContainer>
      <LandingSpread
        containerRef={containerRef}
        data={{
          headline:
            "Stay ahead of your competitors and increase sales with Xureal.",
        }}
      />
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "0" : "100px 35px"}
        $height="fit-content"
        $backgroundColor={theme.palette.neutral.white}
      >
        <div style={{boxShadow: '0 16px 40px 0 rgba(124,141,157,0.15);'}}>
        <BlogSwiper />
        </div>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer $height='449px' $backgroundColor="linear-gradient(270deg, #B7CAFF 0%, #E2E9FB 100%)">
        <Subscribe />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "0" : "100px 35px"}
        $height="fit-content"
        $backgroundColor={theme.palette.neutral.light}
      >
        <FaqSection />
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default Landing;
