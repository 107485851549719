import React from "react";
import LayoutStyles from "../../App.styles";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery, useTheme } from "@mui/material";

const Pill: React.FC<{ text: string; dark?: boolean, icon?: string }> = ({ text, dark, icon }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm))
  return (
    <LayoutStyles.Pill $dark={dark}>
      {icon ? (<img style={{height: '24px', marginRight: '12px'}} src={icon} />) :
      (<FontAwesomeIcon
        style={{ marginRight: "12px" }}
        color={"#1CBE52"}
        icon={faCheck}
      />)
}
    <div style={{maxWidth: isSmallScreen ? '77px' : 'unset', lineHeight: '12px'}}>
      {text}
      </div>
    </LayoutStyles.Pill>
  );
};

export default Pill;
