import React, { useState, useRef, useEffect } from "react";
import LayoutStyles from "../App.styles";

import QuizAnimation from "../assets/animations/homepage/quiz/quiz";
import ImageTextBlock from "../PageBlocks/ImageText";
import Logos from "../PageBlocks/Logos";
import TextText from "../PageBlocks/TextText";
import dataJson from "../data.json";
import { useTheme, useMediaQuery, Typography } from "@mui/material";
import VideoBlock from "../PageBlocks/VideoBlock";
import ArrowButton from "../Common/Button/ArrowButton";
import HomepageVideo from "../assets/videos/homepage_hero.webm";
import { useNavigate } from "react-router-dom";
import { textTextData } from "../utils/data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCreative, Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

// Group image assetsß
import AnalyticsAnim from "../assets/animations/sales/sales-analytics/sales-analytics";
import Brian from "../assets/images/brian_gill.png";
import BrianAv from "../assets/images/brian_avatar.png";
import HeroSales from "../assets/images/Homepage/hero-sales.png";
import HeroMetaverse from "../assets/images/Homepage/hero-metaverse.png";
import HeroAI from "../assets/images/Homepage/hero-ai.png";
import HeroTraining from "../assets/images/Homepage/hero-training.png";
import HeroEmployee from "../assets/images/Homepage/hero-employee.png";

const Homepage = ({
  containerRef,
}: {
  containerRef: React.MutableRefObject<null>;
}) => {
  const roladexRef = useRef<SwiperType | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const heroImageSwiper = useRef<SwiperType | null>(null);

  // useEffect(() => {
  //   setScrollingDisabled(true);
  //   return () => setScrollingDisabled(false);
  // }, []);

  // Roladex data
  const keywords = ["AI", "metaverse", "sales", "training", "employee"];
  const heroImages = [
    HeroAI,
    HeroMetaverse,
    HeroSales,
    HeroTraining,
    HeroEmployee,
  ];
  const wordWidths = isSmallScreen
    ? [53, 171, 90, 130, 163]
    : isMediumScreen
    ? [85, 276, 146, 207, 259]
    : [99, 327, 171, 250, 313];
  const [currentWidth, setCurrentWidth] = useState(wordWidths[currentIndex]);

  const handleImageSwiper = (swiper: any) => {
    heroImageSwiper.current = swiper;
  };

  const handleWordSwiper = (swiper: any) => {
    roladexRef.current = swiper;
  };

  useEffect(() => {
    console.log("roladex", roladexRef.current?.width);
    setCurrentWidth(wordWidths[(currentIndex !== 6 ? currentIndex : 1) - 1]);
    if (heroImageSwiper.current) {
      heroImageSwiper.current?.slideToLoop(
        (currentIndex !== 6 ? currentIndex : 1) - 1
      );
    }
  }, [currentIndex, isMediumScreen, isSmallScreen]);

  return (
    <>
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "0" : "0 35px"}
        $height="fit-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      ></LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isMediumScreen ? "90px 25px" : "45px 0 17px"}
      >
        <LayoutStyles.Header.InnerWrapper>
          <LayoutStyles.FlexboxRow>
            <div
              style={{
                width: "100%",
                maxWidth: "540px",
                marginRight: isMediumScreen ? "0" : "60px",
              }}
            >
              <Typography variant="h1">Unlock the power</Typography>
              <Typography
                sx={{
                  overflow: "hidden",
                  height: isSmallScreen
                    ? "55px"
                    : isMediumScreen
                    ? "82px"
                    : "97px",
                }}
                variant="h1"
              >
                <span
                  style={{
                    marginRight: isSmallScreen
                      ? "8px"
                      : isMediumScreen
                      ? "12px"
                      : "18px",
                  }}
                >
                  of
                </span>
                <Swiper
                  onSwiper={handleWordSwiper}
                  loop
                  noSwiping
                  speed={1000}
                  id="word-roladex"
                  spaceBetween={25}
                  autoplay={{
                    delay: 3000,
                  }}
                  onActiveIndexChange={(event) => {
                    setCurrentIndex(event.activeIndex);
                  }}
                  direction="vertical"
                  style={{
                    flex: 1,
                    height: "100%",
                    pointerEvents: "none",
                    width: currentWidth,
                  }}
                  modules={[Autoplay]}
                >
                  {keywords.map((word, index) => {
                    return (
                      <SwiperSlide
                        className="roladex-word"
                        key={`hero-keyword-${index}`}
                        style={{ height: "100%" }}
                      >
                        <LayoutStyles.HeroRoladex>
                          {word}
                        </LayoutStyles.HeroRoladex>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Typography>
              <Typography style={{ marginBottom: "48px" }} variant="h1">
                experiences.
              </Typography>
              <Typography variant="body1">
                Xureal provides businesses turnkey infrastructure to quickly
                deploy virtual experiences that are scalable, accessible and
                measurable in real-time.
              </Typography>
              <a
                href="https://www.producthunt.com/posts/xureal?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-xureal"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=395058&theme=dark"
                  alt="Xureal - The&#0032;power&#0032;of&#0032;AI&#0032;&#0043;&#0032;3D&#0032;applied&#0032;to&#0032;sales&#0032;and&#0032;marketing | Product Hunt"
                  style={{ width: "250px", height: "54px" }}
                  width="250"
                  height="54"
                />
              </a>
            </div>
            {!isMediumScreen && (
              <Swiper
                onSwiper={handleImageSwiper}
                onDestroy={() => {
                  heroImageSwiper.current = null;
                }}
                loop
                speed={1000}
                className="hero-images"
                style={{
                  maxWidth: "42%",
                  height: "100%",
                  pointerEvents: "none",
                  marginRight: "0",
                }}
                modules={[EffectCreative]}
                effect={"creative"}
                creativeEffect={{
                  prev: {
                    shadow: true,
                    translate: [0, 0, -2800],
                  },
                  next: {
                    translate: ["100%", 0, 0],
                  },
                }}
              >
                {heroImages.map((image, index) => {
                  return (
                    <SwiperSlide
                      className="roladex-image"
                      key={`hero-image-${index}`}
                    >
                      <img src={image} style={{ maxWidth: "100%" }} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </LayoutStyles.FlexboxRow>
        </LayoutStyles.Header.InnerWrapper>
      </LayoutStyles.SectionContainer>

      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "0px 45px 55px" : "0"}
      >
        <LayoutStyles.SectionContainer $fullWidth>
          <Logos logoType="clients" hideHeader />
        </LayoutStyles.SectionContainer>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "30px 0 0 0" : "102px 0 0 0"}
      >
        <VideoBlock
          headline={`How it works`}
          subtitle="Xureal can help you put AI + Metaverse into action by focusing on the areas of your business where they can deliver real benefits quickly."
          bgColor="#000000"
          video={HomepageVideo}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $minHeight={isMediumScreen ? "unset" : "1000px"}
        $padding={
          isSmallScreen ? "100px 0" : isMediumScreen ? "150px 0" : "201px 0"
        }
        $backgroundColor={theme.palette.neutral.black}
      >
        <ImageTextBlock
          data={dataJson.homepage.customer_engagement}
          padding={!isMediumScreen ? "0 75px" : "0"}
          alignItems="center"
          reverse={true}
          height={isMediumScreen ? "auto" : "597px"}
          video="https://player.vimeo.com/video/812114042?h=95c304698e"
          imageSize={["100%", "100%"]}
          enableMobileMaxWidth
          videoAspectRatio={1 / 1}
          buttonType="animated"
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isMediumScreen ? "65px 0 100px 0" : "197px 0 201px"}
        $minHeight="unset"
      >
        <ImageTextBlock
          data={dataJson.homepage.training}
          alignItems={"space-between"}
          reverse={false}
          padding={!isMediumScreen ? "0 75px" : "0"}
          color={theme.palette.primary.main}
          height={"auto"}
          removeTxtPadding
          justifyContent="space-between"
          transform={"translateY(40px)"}
          lottieAnimation={QuizAnimation}
          enableMobileMaxWidth
          imageSize={[isMediumScreen ? "auto" : 608, "85%"]}
          buttonType="animated"
          altColor={false}
        />
      </LayoutStyles.SectionContainer>

      <LayoutStyles.SectionContainer
        $backgroundColor={theme.palette.neutral.black}
        $padding={isSmallScreen ? "60px 0 100px" : "165px 0"}
        id="analytics"
      >
        <ImageTextBlock
          data={dataJson.homepage.analytics_homepage}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          sectionID="analytics"
          imageSize={[isMediumScreen ? "auto" : 814, "100%"]}
          lottieAnimation={AnalyticsAnim}
          reverse
          hideButton
          color={"#F9F9F9"}
          height={"100%"}
          altColor={true}
        />
      </LayoutStyles.SectionContainer>

      <LayoutStyles.SectionContainer
        $padding={!isMediumScreen ? "0 75px 175px" : "0"}
        $backgroundColor={"#EEF5FE"}
      >
        <TextText
          hideButton
          height={"auto"}
          data1={dataJson.engagement.security}
          data2={dataJson.engagement.integrations}
          padding={isMediumScreen ? "100px 0" : "175px 0 0"}
          buttonType="animated"
          lineColor="#FDEFCA"
          alignItems="flex-start"
          justifyContent={isMediumScreen ? "center" : "space-between"}
          iconRow1={textTextData.homepage.security.iconRow}
          iconRow2={textTextData.homepage.integrations.iconRow}
        ></TextText>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isMediumScreen ? "100px 0" : "189px 0 203px"}
      >
        <ImageTextBlock
          testimonial
          lottieAnimation={[Brian, !isSmallScreen && BrianAv]}
          data={dataJson.homepage.testimonials}
          alignItems="center"
          justifyContent="space-between"
          padding={!isMediumScreen ? "0 75px" : "unset"}
          avatarSize={["auto", "85%"]}
          avatarPosition={
            isSmallScreen
              ? ["25%", "auto", "auto", "60%"]
              : ["25%", "auto", "auto", "70%"]
          }
          imageSize={isSmallScreen ? ["90%", "auto"] : [433, "auto"]}
          gap={isSmallScreen ? "40px" : "80px"}
          hideTag
          removeTxtPadding
          quote
          isBackgroundImg={[true, false]}
          hideButton
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default Homepage;
