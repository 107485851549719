import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";
import img_7 from "./images/img_7.png";
import img_8 from "./images/img_8.png";

export default {
  v: "5.7.4",
  fr: 29.9700012207031,
  ip: 0,
  op: 100.000004073084,
  w: 1091,
  h: 1004,
  nm: "Quiz",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 108,
      h: 144,
      u: "",
      p: img_0,
      e: 0
    },
    {
      id: "image_1",
      w: 40,
      h: 40,
      u: "",
      p: img_1,
      e: 0
    },
    {
      id: "image_2",
      w: 546,
      h: 350,
      u: "",
      p: img_2,
      e: 0
    },
    {
      id: "image_3",
      w: 358,
      h: 182,
      u: "",
      p: img_3,
      e: 0
    },
    {
      id: "image_4",
      w: 248,
      h: 24,
      u: "",
      p: img_4,
      e: 0
    },
    {
      id: "image_5",
      w: 360,
      h: 184,
      u: "",
      p: img_5,
      e: 0
    },
    {
      id: "image_6",
      w: 546,
      h: 84,
      u: "",
      p: img_6,
      e: 0
    },
    {
      id: "image_7",
      w: 748,
      h: 114,
      u: "",
      p: img_7,
      e: 0
    },
    {
      id: "image_8",
      w: 920,
      h: 966,
      u: "",
      p: img_8,
      e: 0
    },
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "cursor-quiz.png",
          cl: "png",
          refId: "image_0",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 10,
                  s: [
                    0
                  ]
                },
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 16,
                  s: [
                    100
                  ]
                },
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 82,
                  s: [
                    100
                  ]
                },
                {
                  t: 89.0000036250443,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.667,
                    y: 1
                  },
                  o: {
                    x: 0.333,
                    y: 0
                  },
                  t: 10,
                  s: [
                    576.96,
                    961.867,
                    0
                  ],
                  to: [
                    53.104,
                    -80.192,
                    0
                  ],
                  ti: [
                    -53.104,
                    80.192,
                    0
                  ]
                },
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 58,
                  s: [
                    895.587,
                    480.718,
                    0
                  ],
                  to: [
                    0,
                    0,
                    0
                  ],
                  ti: [
                    0,
                    0,
                    0
                  ]
                },
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.333,
                    y: 0
                  },
                  t: 72,
                  s: [
                    895.587,
                    480.718,
                    0
                  ],
                  to: [
                    36.5,
                    -33,
                    0
                  ],
                  ti: [
                    -36.5,
                    33,
                    0
                  ]
                },
                {
                  t: 90.0000036657751,
                  s: [
                    1114.587,
                    282.718,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                19,
                20,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.667,
                      0.667,
                      0.667
                    ],
                    y: [
                      1,
                      1,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.333,
                      0.333,
                      0.333
                    ],
                    y: [
                      0,
                      0,
                      0
                    ]
                  },
                  t: 58,
                  s: [
                    60,
                    60,
                    100
                  ]
                },
                {
                  i: {
                    x: [
                      0.667,
                      0.667,
                      0.667
                    ],
                    y: [
                      1,
                      1,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.333,
                      0.333,
                      0.333
                    ],
                    y: [
                      0,
                      0,
                      0
                    ]
                  },
                  t: 64,
                  s: [
                    20,
                    20,
                    100
                  ]
                },
                {
                  t: 75.0000030548126,
                  s: [
                    60,
                    60,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 10.0000004073083,
          op: 6765.0002755441,
          st: -427.000017392067,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "Static.png",
          cl: "png",
          refId: "image_1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 22,
                  s: [
                    0
                  ]
                },
                {
                  t: 45.0000018328876,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                500.257,
                673.16,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                20,
                20,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.991,
                      0.991,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0
                    ]
                  },
                  t: 22,
                  s: [
                    50,
                    50,
                    100
                  ]
                },
                {
                  t: 45.0000018328876,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 22.0000008960784,
          op: 5416.0002205982,
          st: 22.0000008960784,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "Static.png",
          cl: "png",
          refId: "image_1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 18,
                  s: [
                    0
                  ]
                },
                {
                  t: 41.0000016699642,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                248.251,
                799.117,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                20,
                20,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.726,
                      0.726,
                      0.603
                    ],
                    y: [
                      0.668,
                      0.668,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.486,
                      0.486,
                      0.265
                    ],
                    y: [
                      0.136,
                      0.136,
                      0
                    ]
                  },
                  t: 18,
                  s: [
                    50,
                    50,
                    100
                  ]
                },
                {
                  i: {
                    x: [
                      0.183,
                      0.183,
                      0.685
                    ],
                    y: [
                      0.99,
                      0.99,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.077,
                      0.077,
                      0.235
                    ],
                    y: [
                      0.438,
                      0.438,
                      0
                    ]
                  },
                  t: 20,
                  s: [
                    65.485,
                    65.485,
                    100
                  ]
                },
                {
                  t: 41.0000016699642,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 18.000000733155,
          op: 5412.00022043528,
          st: 18.000000733155,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "Static.png",
          cl: "png",
          refId: "image_1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 15,
                  s: [
                    0
                  ]
                },
                {
                  t: 38.0000015477717,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                165.797,
                639.516,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                20,
                20,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.991,
                      0.991,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0
                    ]
                  },
                  t: 15,
                  s: [
                    50,
                    50,
                    100
                  ]
                },
                {
                  t: 38.0000015477717,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 15.0000006109625,
          op: 5409.00022031309,
          st: 15.0000006109625,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "Bitmap.png",
          cl: "png",
          refId: "image_2",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      1.014
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 0,
                  s: [
                    0
                  ]
                },
                {
                  t: 20.0000008146167,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 0,
                  s: [
                    547.854,
                    970.451,
                    0
                  ],
                  to: [
                    -42.667,
                    0,
                    0
                  ],
                  ti: [
                    42.667,
                    0,
                    0
                  ]
                },
                {
                  t: 20.0000008146167,
                  s: [
                    291.854,
                    970.451,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                269,
                345.5,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 0,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 0,
          nm: "Quiz-Subcomp",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                698.076,
                506.5,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                718.076,
                486.5,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                87,
                87,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          w: 1184,
          h: 1109,
          ip: 0,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        }
      ]
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 10",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                908.617,
                495.62,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                330,
                32,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                47.942,
                52,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          257.116,
                          32.52
                        ],
                        [
                          311.886,
                          77.021
                        ],
                        [
                          406.887,
                          -20.789
                        ]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.996078431373,
                      1,
                      0.901960784314,
                      1
                    ],
                    ix: 3
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 4
                  },
                  w: {
                    a: 0,
                    k: 22,
                    ix: 5
                  },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: {
                    a: 0,
                    k: [
                      0,
                      0
                    ],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [
                      0,
                      0
                    ],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [
                      100,
                      100
                    ],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: "Transform"
                }
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "tm",
              s: {
                a: 0,
                k: 0,
                ix: 1
              },
              e: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [
                        0
                      ],
                      y: [
                        1.002
                      ]
                    },
                    o: {
                      x: [
                        0.167
                      ],
                      y: [
                        0.167
                      ]
                    },
                    t: 74,
                    s: [
                      0
                    ]
                  },
                  {
                    t: 99.0000040323527,
                    s: [
                      100
                    ]
                  }
                ],
                ix: 2
              },
              o: {
                a: 0,
                k: 0,
                ix: 3
              },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            }
          ],
          ip: 74.0000030140818,
          op: 2478.00010093101,
          st: -491.00001999884,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "Check.png",
          cl: "png",
          refId: "image_3",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 64,
                  s: [
                    0
                  ]
                },
                {
                  t: 70.0000028511585,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                906.5,
                494.5,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                179,
                91,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 64.0000026067734,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "Question 4 of 15.png",
          cl: "png",
          refId: "image_4",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 20,
                  s: [
                    0
                  ]
                },
                {
                  t: 35.0000014255792,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                709.165,
                859.554,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                124,
                12,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                80,
                80,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 20.0000008146167,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "Mask Copy.png",
          cl: "png",
          refId: "image_5",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 16,
                  s: [
                    0
                  ]
                },
                {
                  t: 39.0000015885026,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                904.876,
                703.195,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                180,
                92,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.991,
                      0.991,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.327
                    ]
                  },
                  t: 16,
                  s: [
                    50,
                    50,
                    100
                  ]
                },
                {
                  t: 39.0000015885026,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 16.0000006516934,
          op: 5400.00021994651,
          st: 6.00000024438501,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "Mask Copy.png",
          cl: "png",
          refId: "image_5",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 14,
                  s: [
                    0
                  ]
                },
                {
                  t: 37.0000015070409,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                516.876,
                703.195,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                180,
                92,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.991,
                      0.991,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.327
                    ]
                  },
                  t: 14,
                  s: [
                    50,
                    50,
                    100
                  ]
                },
                {
                  t: 37.0000015070409,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 14.0000005702317,
          op: 5398.00021986505,
          st: 4.00000016292334,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: "Mask Copy.png",
          cl: "png",
          refId: "image_5",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 12,
                  s: [
                    0
                  ]
                },
                {
                  t: 35.0000014255792,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                904.876,
                493.195,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                180,
                92,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.991,
                      0.991,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.327
                    ]
                  },
                  t: 12,
                  s: [
                    50,
                    50,
                    100
                  ]
                },
                {
                  t: 35.0000014255792,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 12.00000048877,
          op: 5396.00021978359,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: "Mask Copy.png",
          cl: "png",
          refId: "image_5",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 10,
                  s: [
                    0
                  ]
                },
                {
                  t: 33.0000013441176,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                516.876,
                493.195,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                180,
                92,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.991,
                      0.991,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.327
                    ]
                  },
                  t: 10,
                  s: [
                    50,
                    50,
                    100
                  ]
                },
                {
                  t: 33.0000013441176,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 10.0000004073083,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: "Which are the follow.png",
          cl: "png",
          refId: "image_6",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 10,
                  s: [
                    0
                  ]
                },
                {
                  t: 33.0000013441176,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 10,
                  s: [
                    712.076,
                    334.519,
                    0
                  ],
                  to: [
                    0,
                    -6.667,
                    0
                  ],
                  ti: [
                    0,
                    6.667,
                    0
                  ]
                },
                {
                  i: {
                    x: 0,
                    y: 0
                  },
                  o: {
                    x: 0,
                    y: 0
                  },
                  t: 33,
                  s: [
                    712.076,
                    294.519,
                    0
                  ],
                  to: [
                    0,
                    0,
                    0
                  ],
                  ti: [
                    0,
                    0,
                    0
                  ]
                },
                {
                  t: 82.0000033399285,
                  s: [
                    712.076,
                    294.519,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                273,
                45,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                110,
                110,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 10.0000004073083,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: "Group 5.png",
          cl: "png",
          refId: "image_7",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.997
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 7,
                  s: [
                    0
                  ]
                },
                {
                  t: 30.0000012219251,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 7,
                  s: [
                    712.946,
                    163.837,
                    0
                  ],
                  to: [
                    0,
                    -6.667,
                    0
                  ],
                  ti: [
                    0,
                    6.667,
                    0
                  ]
                },
                {
                  i: {
                    x: 0,
                    y: 0
                  },
                  o: {
                    x: 0,
                    y: 0
                  },
                  t: 30,
                  s: [
                    712.946,
                    123.837,
                    0
                  ],
                  to: [
                    0,
                    0,
                    0
                  ],
                  ti: [
                    0,
                    0,
                    0
                  ]
                },
                {
                  t: 82.0000033399285,
                  s: [
                    712.946,
                    123.837,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                374,
                57,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 7.00000028511585,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: "Rectangle.png",
          cl: "png",
          refId: "image_8",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.996
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 0,
                  s: [
                    0
                  ]
                },
                {
                  t: 20.0000008146167,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.784,
                    y: 0
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 0,
                  s: [
                    718.076,
                    646.5,
                    0
                  ],
                  to: [
                    0,
                    -2.231,
                    0
                  ],
                  ti: [
                    0,
                    3.783,
                    0
                  ]
                },
                {
                  i: {
                    x: 0.08,
                    y: 1
                  },
                  o: {
                    x: 0.098,
                    y: 0.173
                  },
                  t: 1,
                  s: [
                    718.076,
                    643.359,
                    0
                  ],
                  to: [
                    0,
                    -24.478,
                    0
                  ],
                  ti: [
                    0,
                    14.436,
                    0
                  ]
                },
                {
                  t: 20.0000008146167,
                  s: [
                    718.076,
                    486.5,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                460,
                483,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 0,
          op: 5394.00021970212,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "Everything for quiz",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [
            576.935,
            481.38,
            0
          ],
          ix: 2,
          l: 2
        },
        a: {
          a: 0,
          k: [
            592,
            554.5,
            0
          ],
          ix: 1,
          l: 2
        },
        s: {
          a: 0,
          k: [
            100,
            100,
            100
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      w: 1184,
      h: 1109,
      ip: 0,
      op: 5394.00021970212,
      st: 0,
      bm: 0
    }
  ],
  markers: []
}