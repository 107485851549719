import { useState, useEffect } from "react";
import { motion, useScroll } from "framer-motion";
import LayoutStyles from "../App.styles";
import { useTheme, useMediaQuery, Grid } from "@mui/material";
import SmallBlogCard from "../Common/Cards/SmallBlogCard";
import { useApp } from "../context/AppContext";
import Destiny from "../assets/images/TestImages/destiny.png";
import Horizon from "../assets/images/TestImages/horizon.jpg";
import BackArrow from "../assets/icons/buttonarrows/dk-blue-arrow.png";
import Twitter from "../assets/icons/wh-twitter.png";
import Instagram from "../assets/icons/wh-instagram.png";
import Linkedin from "../assets/icons/wh-linkedin.png";
import BlueTwitter from "../assets/icons/blue-twitter.png";
import BlueInstagram from "../assets/icons/blue-instagram.png";
import BlueLinkedin from "../assets/icons/blue-linkedin.png";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../utils/data";
import {
  InstapaperIcon,
  InstapaperShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon
} from 'react-share';

const BlogPost = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { blogArray, setActiveBlog } = useApp();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const { activeBlog } = useApp();

  const convertStringToHTML = (htmlString: string) => {
    const html = new DOMParser().parseFromString(htmlString, "text/html");

    return html.body;
  };

  useEffect(() => {
    if (activeBlog) convertStringToHTML(activeBlog.postBody);
  }, [activeBlog]);

  useEffect(() => {
    let blogID =
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ];
    console.log("blogtodisplayID", window.location.pathname);
    let blogToDisplay = blogArray.find((blog) => blog.id === blogID);
    console.log("blogtodisplay", blogToDisplay);
    if (blogToDisplay) setActiveBlog(blogToDisplay);
  }, []);

  return (
    <>
      <LayoutStyles.SectionContainer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        $padding="46px 0 0 0"
      >
        <LayoutStyles.SectionContainer $width="100%" $maxWidth="1142px">
          <LayoutStyles.BlogPost.BackButon
            onClick={() => navigate("/resources")}
          >
            <img
              src={BackArrow}
              style={{ height: "13px", width: "auto", marginRight: "6px" }}
              alt="back"
            />
            Back
          </LayoutStyles.BlogPost.BackButon>
        </LayoutStyles.SectionContainer>
        <LayoutStyles.BlogPost.HeroSection>
          <LayoutStyles.BlogPost.Headline>
            {activeBlog?.name}
          </LayoutStyles.BlogPost.Headline>
          <LayoutStyles.FlexboxRow $overrideCenter $flexDirection={isSmallScreen ? "column" : "row"} $alignItems="flex-start">
            <LayoutStyles.FlexboxRow $justifyContent="space-between" $width="fit-content">
              <LayoutStyles.BlogPost.BlogDetails>
                {/* By {activeBlog?.authorName} */}
                By Xureal
              </LayoutStyles.BlogPost.BlogDetails>
              <span style={{ margin: "0 8px" }}>&#x2022;</span>
              <LayoutStyles.BlogPost.BlogDetails>
                {formatDate(activeBlog?.publishDate)}
              </LayoutStyles.BlogPost.BlogDetails>
              {/* <span style={{ margin: "0 8px" }}>&#x2022;</span> */}
              {/* <LayoutStyles.BlogPost.BlogDetails>
                10 min read
              </LayoutStyles.BlogPost.BlogDetails> */}
            </LayoutStyles.FlexboxRow>
            <LayoutStyles.FlexboxRow $margin={isSmallScreen ? "21px 0 0 0" : "0"}  $width="fit-content">
              <LinkedinShareButton title={activeBlog.htmlTitle} summary={activeBlog.htmlTitle} url={window.location.href}>
              <LayoutStyles.BlogPost.Social src={Linkedin} />
              </LinkedinShareButton>
              <TwitterShareButton title={activeBlog.htmlTitle} url={window.location.href}>
              <LayoutStyles.BlogPost.Social src={Twitter} />
              </TwitterShareButton>
            </LayoutStyles.FlexboxRow>
          </LayoutStyles.FlexboxRow>
          {/* <LayoutStyles.BlogPost.HeroImg src={activeBlog?.featuredImage} /> */}
        </LayoutStyles.BlogPost.HeroSection>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer>
        {activeBlog && (
          <LayoutStyles.BlogPost.ContentWrapper
            id="post-body"
            dangerouslySetInnerHTML={{ __html: activeBlog?.postBody }}
          />
        )}
      </LayoutStyles.SectionContainer>

      <LayoutStyles.BlogPost.ContentWrapper>
        <LayoutStyles.BlogPost.BottomBar>
          <LayoutStyles.BlogPost.BackButon
            onClick={() => navigate("/resources")}
          >
            <img
              src={BackArrow}
              style={{ height: "13px", width: "auto", marginRight: "6px" }}
              alt="back"
            />
            Back
          </LayoutStyles.BlogPost.BackButon>
          <LayoutStyles.FlexboxRow $width="fit-content">
          <LinkedinShareButton title={activeBlog.htmlTitle} summary={activeBlog.htmlTitle} url={window.location.href}>
              <LayoutStyles.BlogPost.Social src={Linkedin} />
              </LinkedinShareButton>
              <TwitterShareButton title={activeBlog.htmlTitle} url={window.location.href}>
              <LayoutStyles.BlogPost.Social src={Twitter} />
              </TwitterShareButton>
          </LayoutStyles.FlexboxRow>
        </LayoutStyles.BlogPost.BottomBar>
      </LayoutStyles.BlogPost.ContentWrapper>
    </>
  );
};

export default BlogPost;
