import { motion } from "framer-motion";
import CSS from 'csstype';
import React from "react";

const FadeIn = ({
  children,
  isOnScreen,
  customStyle
}: {
  children: React.ReactNode;
  isOnScreen: boolean;
  customStyle?: CSS.Properties;
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: isOnScreen ? 1 : 0, y: isOnScreen ? 0 : 50 }}
      transition={{ duration: 0.4 }}
      style={{flex: '1', alignSelf: 'stretch', ...customStyle}}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
