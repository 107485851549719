import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import axios from "axios";
import TextField from "@mui/material/TextField";
import LayoutStyles from "../App.styles";
import ArrowButton from "../Common/Button/ArrowButton";
import { useMediaQuery, useTheme } from "@mui/material";
import PaperPlane from "../assets/images/contact-submit.png";
import { ContactFormSchema } from "../utils/validation";

import { Grid } from "@mui/material";

const ContactForm: React.FC<{
  currentStep: number;
  updateStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({ currentStep, updateStep }) => {
  const [currentSize, setSize] = useState("");
  const [requests, updateRequests] = useState<string[]>([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const [resultsError, setResultsError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const hubSpotURL = "https://portal.xureal.com/promo-api/xrleads";
  const blockedEmails = ['gmail.com', 'yahoo.com', 'hotmail.com']
  const submitForm = (values: any) => {
    axios
      .post(
        hubSpotURL,
        {
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          phone: "1234567890",
          company: values.company,
          size: values.size,
          request: values.request.filter((item: string) => item !== ""),
          website: values.email.split("@")[1],
          jobtitle: values.title,
        },
        {
          headers: {
            "xr-api-key": "3c0ac05391166d59f49ba71f8527fc39",
          },
        }
      )
      .then((res) => {
        console.log("results", res);
        updateStep(4);
      });
  };

  useEffect(() => {
    console.log('reason array', requests)
  }, [requests])

  return (
    <>
      <Formik
        style={{ height: "100%", width: "100%" }}
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          title: "",
          company: "",
          request: [""],
          size: "",
        }}
        validationSchema={ContactFormSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={submitForm}
      >
        {({ values, errors, validateForm, handleSubmit, handleChange }) => {
          const selectSize = (size: string) => {
            setSize(size);
            values.size = size;
          };

          const handleReasonSelect = (reason: string) => {
            if (requests.includes(reason)) {
              console.log('here 1')
              let newReasonArr = requests.filter((entry) => entry !== reason);
              values.request = newReasonArr;
              updateRequests(newReasonArr);
              return;
            }
            if (!requests.includes(reason)) {
              console.log('here 2')
              setResultsError("");
              let newReasonArr = requests.length === 0 ? [reason] : [...requests, reason];
              values.request = newReasonArr;
              updateRequests(newReasonArr);
              return
            }
          };

          return (
            <Form>
              {currentStep === 0 && (
                <>
                  <div
                    style={{
                      fontSize: isSmallScreen ? "32px" : "45px",
                      textAlign: isSmallScreen ? "center" : "left",
                      marginBottom: isSmallScreen ? "16px" : "25px",
                    }}
                  >
                    💬
                  </div>
                  <LayoutStyles.Contact.ScheduleHeadline
                    style={{ marginBottom: isSmallScreen ? "32px" : "61.5px" }}
                  >
                    Not ready to talk? <br /> Let us send you more information.
                  </LayoutStyles.Contact.ScheduleHeadline>
                  <div style={{ display: "flex" }}>
                    <ArrowButton
                      width="235px"
                      text="Fill out our form"
                      bgColor="#000000"
                      clickFunction={() => updateStep(1)}
                    />
                  </div>
                </>
              )}

              {currentStep === 1 && (
                <>
                  <LayoutStyles.Contact.FormTitle>
                    How big is your team?
                  </LayoutStyles.Contact.FormTitle>
                  <LayoutStyles.Contact.FormSubtitle>
                    Select one and click next.
                  </LayoutStyles.Contact.FormSubtitle>
                  <div style={{ position: "relative" }}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() => {
                            setSizeError("");
                            selectSize("1 - 250");
                          }}
                          $selected={values.size === "1 - 250"}
                        >
                          1 - 250
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() => {
                            setSizeError("");
                            selectSize("251 - 5K");
                          }}
                          $selected={values.size === "251 - 5K"}
                        >
                          251 - 5K
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() => {
                            setSizeError("");
                            selectSize("5K - 20K");
                          }}
                          $selected={values.size === "5K - 20K"}
                        >
                          5K - 20K
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() => {
                            setSizeError("");
                            selectSize("20K - 50K");
                          }}
                          $selected={values.size === "20K - 50K"}
                        >
                          20K - 50K
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() => {
                            setSizeError("");
                            selectSize("50K - 100K");
                          }}
                          $selected={values.size === "50K - 100K"}
                        >
                          50K - 100K
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() => {
                            setSizeError("");
                            selectSize("100K+");
                          }}
                          $selected={values.size === "100K+"}
                        >
                          100K+
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                    </Grid>
                    {sizeError && (
                      <LayoutStyles.Contact.FormError>
                        {sizeError}
                      </LayoutStyles.Contact.FormError>
                    )}
                  </div>
                  {!isSmallScreen && (
                    <LayoutStyles.FlexboxRow
                      $flexDirection=""
                      $margin="25px 0 0"
                      $overrideCenter
                      $justifyContent="space-between"
                    >
                      <div style={{ width: "127px" }}></div>
                      <LayoutStyles.Contact.StepCount>
                        STEP 1 OF 3
                      </LayoutStyles.Contact.StepCount>
                      <ArrowButton
                        overrideCenter
                        chevrons
                        bgColor="#000000"
                        width="127px"
                        text="Next"
                        clickFunction={() => {
                          if (!values.size) {
                            console.log("here");
                            setSizeError("Please select one option");
                          }
                          if (values.size) {
                            updateStep(2);
                          }
                        }}
                      />
                    </LayoutStyles.FlexboxRow>
                  )}
                  {isSmallScreen && (
                    <LayoutStyles.FlexboxRow
                      $margin="40px 0 0"
                      $flexDirection="column"
                    >
                      <LayoutStyles.FlexboxRow
                        $overrideCenter
                        $justifyContent="flex-end"
                        $margin="0 0 12px 0"
                      >
                        <ArrowButton
                          overrideCenter
                          chevrons
                          bgColor="#000000"
                          width="127px"
                          text="Next"
                          clickFunction={() => {
                            if (!values.size) {
                              setSizeError("Please select one option");
                            }
                            if (values.size) {
                              updateStep(2);
                            }
                          }}
                        />
                      </LayoutStyles.FlexboxRow>
                      <LayoutStyles.Contact.StepCount>
                        STEP 1 OF 3
                      </LayoutStyles.Contact.StepCount>
                    </LayoutStyles.FlexboxRow>
                  )}
                </>
              )}

              {currentStep === 2 && (
                <>
                  <LayoutStyles.Contact.FormTitle>
                    How can we best help you?
                  </LayoutStyles.Contact.FormTitle>
                  <LayoutStyles.Contact.FormSubtitle>
                    Select all that apply.
                  </LayoutStyles.Contact.FormSubtitle>
                  <div style={{ position: "relative" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={{'&.Mui-GridItem': {
                        width: '100% !important'}}}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() =>
                            handleReasonSelect("Increase sales + conversion")
                          }
                          $selected={values.request.includes(
                            "Increase sales + conversion"
                          )}
                        >
                          Increase sales + conversion
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                      <Grid item xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() =>
                            handleReasonSelect("Employee training + engagement")
                          }
                          $selected={values.request.includes(
                            "Employee training + engagement"
                          )}
                        >
                          Employee training + engagement
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                      <Grid item xs={12}>
                        <LayoutStyles.Contact.AnswerBox
                          onClick={() =>
                            handleReasonSelect("Marketing + customer experience")
                          }
                          $selected={values.request.includes(
                            "Marketing + customer experience"
                          )}
                        >
                          Marketing + customer experience
                        </LayoutStyles.Contact.AnswerBox>
                      </Grid>
                    </Grid>
                    {resultsError && (
                      <LayoutStyles.Contact.FormError>
                        {resultsError}
                      </LayoutStyles.Contact.FormError>
                    )}
                  </div>
                  {!isSmallScreen && (
                    <LayoutStyles.FlexboxRow $overrideCenter $margin="25px 0 0">
                      <ArrowButton
                        overrideCenter
                        reverse
                        chevrons
                        bgColor="#D7E1FD"
                        width="127px"
                        text="Back"
                        color="#000000"
                        clickFunction={() => updateStep(1)}
                      />
                      <LayoutStyles.Contact.StepCount>
                        STEP 2 OF 3
                      </LayoutStyles.Contact.StepCount>
                      <ArrowButton
                        overrideCenter
                        chevrons
                        bgColor="#000000"
                        width="127px"
                        text="Next"
                        clickFunction={() => {
                          if (values.request.length < 1) {
                            setResultsError(
                              "Please select at least one option"
                            );
                          }
                          if (values.request.length >= 1) {
                            updateStep(3);
                          }
                        }}
                      />
                    </LayoutStyles.FlexboxRow>
                  )}
                  {isSmallScreen && (
                    <LayoutStyles.FlexboxRow
                      $margin="40px 0 0"
                      $flexDirection="column"
                    >
                      <LayoutStyles.FlexboxRow
                        $overrideCenter
                        $justifyContent="space-between"
                        $margin="0 0 12px 0"
                      >
                        <ArrowButton
                          overrideCenter
                          chevrons
                          reverse
                          bgColor="#D7E1FD"
                          width="127px"
                          text="Back"
                          color="#000000"
                          clickFunction={() => updateStep(1)}
                        />
                        <ArrowButton
                          overrideCenter
                          chevrons
                          bgColor="#000000"
                          width="127px"
                          text="Next"
                          clickFunction={() => {
                            if (values.request.length < 1) {
                              setResultsError(
                                "Please select at least one option"
                              );
                            }
                            if (values.request.length >= 1) {
                              updateStep(3);
                            }
                          }}
                        />
                      </LayoutStyles.FlexboxRow>
                      <LayoutStyles.Contact.StepCount>
                        STEP 2 OF 3
                      </LayoutStyles.Contact.StepCount>
                    </LayoutStyles.FlexboxRow>
                  )}
                </>
              )}

              {currentStep === 3 && (
                <>
                  <LayoutStyles.Contact.FormTitle>
                    Tell us about yourself
                  </LayoutStyles.Contact.FormTitle>
                  <LayoutStyles.Contact.FormSubtitle>
                    Fill out the form and our team will get back to you shortly.
                  </LayoutStyles.Contact.FormSubtitle>
                  {resultsError && (
                      <LayoutStyles.Contact.FormError style={{top: 71}}>
                        {resultsError}
                      </LayoutStyles.Contact.FormError>
                    )}
                  <LayoutStyles.FlexboxRow
                    $flexDirection={isSmallScreen ? "column" : "row"}
                    $gap={isSmallScreen ? "0" : "12px"}
                  >
                    <Field
                      component={TextField}
                      variant="filled"
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      placeholder="First Name"
                      error={errors.firstname}
                      sx={{
                        input: {
                          color: errors.firstname && !values.firstname ? "red" : "default",
                          "&::placeholder": {
                            opacity: errors.firstname ? 1 : "default",
                          },
                        },
                      }}
                      name="firstname"
                      id="firstname"
                      type="text"
                    />
                    <Field
                      component={TextField}
                      variant="filled"
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      placeholder="Last Name"
                      error={errors.lastname}
                      sx={{
                        input: {
                          color: errors.lastname && !values.lastname ? "red" : "default",
                          "&::placeholder": {
                            opacity: errors.lastname ? 1 : "default",
                          },
                        },
                      }}
                      name="lastname"
                      id="lastname"
                      type="text"
                    />
                  </LayoutStyles.FlexboxRow>
                  <Field
                    component={TextField}
                    variant="filled"
                    onChange={(e: any) => {
                      if (blockedEmails.includes(e.target.value.split('@')[1])) {
                        setResultsError('Please enter a business email')
                      } else {
                        setResultsError('')
                      }
                      handleChange(e);
                    }}
                    placeholder="Work Email"
                    error={errors.email}
                    sx={{
                        input: {
                          color: errors.email ? "red" : "default",
                          "&::placeholder": {
                            opacity: errors.email ? 1 : "default",
                          },
                        },
                      }}
                    name="email"
                    id="email"
                    type="email"
                  />
                  <Field
                    component={TextField}
                    variant="filled"
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    placeholder="Company Name"
                    error={errors.company}
                    sx={{
                        input: {
                          color: errors.company && !values.company ? "red" : "default",
                          "&::placeholder": {
                            opacity: errors.company ? 1 : "default",
                          },
                        },
                      }}
                    name="company"
                    id="company"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    variant="filled"
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    placeholder="Job Title"
                    error={errors.title}
                    sx={{
                        input: {
                          color: errors.title && !values.title ? "red" : "default",
                          "&::placeholder": {
                            opacity: errors.title ? 1 : "default",
                          },
                        },
                      }}
                    name="title"
                    id="title"
                    type="text"
                  />
                  {!isSmallScreen && (
                    <LayoutStyles.FlexboxRow $overrideCenter>
                      <ArrowButton
                        reverse
                        bgColor="#D7E1FD"
                        width="127px"
                        chevrons
                        text="Back"
                        color="#000000"
                        clickFunction={() => updateStep(2)}
                      />
                      <LayoutStyles.Contact.StepCount>
                        STEP 3 OF 3
                      </LayoutStyles.Contact.StepCount>
                      <ArrowButton
                        bgColor="#000000"
                        width="141px"
                        chevrons
                        text="Submit"
                        clickFunction={() => {
                            console.log('errors', errors)
                          if (Object.keys(errors).length > 0 || resultsError) return;
                          handleSubmit();
                        }}
                      />
                    </LayoutStyles.FlexboxRow>
                  )}
                  {isSmallScreen && (
                    <LayoutStyles.FlexboxRow
                      $margin="40px 0 0"
                      $flexDirection="column"
                    >
                      <LayoutStyles.FlexboxRow
                        $overrideCenter
                        $justifyContent="space-between"
                        $margin="0 0 12px 0"
                      >
                        <ArrowButton
                          reverse
                          chevrons
                          bgColor="#D7E1FD"
                          width="127px"
                          text="Back"
                          color="#000000"
                          clickFunction={() => updateStep(2)}
                        />
                        <ArrowButton
                          bgColor="#000000"
                          chevrons
                          width="141px"
                          type="submit"
                          text="Submit"
                          clickFunction={() => {
                            if (Object.keys(errors).length > 0) return;
                            handleSubmit();
                          }}
                        />
                      </LayoutStyles.FlexboxRow>
                      <LayoutStyles.Contact.StepCount>
                        STEP 3 OF 3
                      </LayoutStyles.Contact.StepCount>
                    </LayoutStyles.FlexboxRow>
                  )}
                </>
              )}

              {currentStep === 4 && (
                <LayoutStyles.FlexboxRow
                  $flexDirection="column"
                  $alignItems="center"
                >
                  <img
                    src={PaperPlane}
                    style={{
                      marginBottom: isSmallScreen ? "32px" : "34px",
                      height: isSmallScreen ? "83px" : "104px",
                    }}
                  />
                  <LayoutStyles.Contact.ScheduleHeadline
                    style={{
                      marginBottom: isSmallScreen ? "34px" : "91px",
                      textAlign: isSmallScreen ? "left" : "center",
                    }}
                  >
                    Your form has been submitted!
                  </LayoutStyles.Contact.ScheduleHeadline>
                  <LayoutStyles.Contact.FormSubtitle>
                    Thank you for reaching out to us! We will be in touch
                    shortly.
                  </LayoutStyles.Contact.FormSubtitle>
                </LayoutStyles.FlexboxRow>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactForm;
