import React from "react";

import LayoutStyles from "../App.styles";
import Tag from "../Common/Tag";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { useMediaQuery, useTheme } from "@mui/material";

// Avatars
import A1 from "../assets/images/AvatarSlider/avatar_1.png";
import A2 from "../assets/images/AvatarSlider/avatar_2.png";
import A3 from "../assets/images/AvatarSlider/avatar_3.png";
import A4 from "../assets/images/AvatarSlider/avatar_4.png";
import A5 from "../assets/images/AvatarSlider/avatar_5.png";
import A6 from "../assets/images/AvatarSlider/avatar_6.png";
import A7 from "../assets/images/AvatarSlider/avatar_7.png";
import A8 from "../assets/images/AvatarSlider/avatar_8.png";
import A9 from "../assets/images/AvatarSlider/avatar_9.png";
import A10 from "../assets/images/AvatarSlider/avatar_10.png";
import A11 from "../assets/images/AvatarSlider/avatar_11.png";
import A12 from "../assets/images/AvatarSlider/avatar_12.png";
import A13 from "../assets/images/AvatarSlider/avatar_13.png";
import A14 from "../assets/images/AvatarSlider/avatar_14.png";
import A15 from "../assets/images/AvatarSlider/avatar_15.png";
import A16 from "../assets/images/AvatarSlider/avatar_16.png";
import A17 from "../assets/images/AvatarSlider/avatar_17.png";
import A18 from "../assets/images/AvatarSlider/avatar_18.png";
import A19 from "../assets/images/AvatarSlider/avatar_19.png";

type Props = {
  tag?: string;
  data?: {
    headline: string;
    subtitle?: string;
  };
};

const Avatars: React.FC<Props> = ({ data, tag }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const avatarArr = [
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14,
    A15,
    A16,
    A17,
    A18,
    A19,
  ];
  return (
    <LayoutStyles.TextFirstHero.Container
      style={{ alignItems: "center", paddingRight: "0", paddingLeft: "0" }}
      $textFirst
    >
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={isSmallScreen ? 3 : 5}
        centerInsufficientSlides={false}
        watchOverflow
        observer
        observeParents
        autoplay
        loop
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 50,
          scale: 0.85,
          modifier: 1,
          slideShadows: false,
        }}
        modules={[EffectCoverflow, Autoplay]}
        className="mySwiper"
        style={{ marginBottom: "50px" }}
      >
        {avatarArr.map((avatar, index) => {
          return (
            <SwiperSlide className="avatar-swiper-slide" key={`avatar-${index}`}>
              <img src={avatar} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <>
        <LayoutStyles.ImageAndTextBox.Tag
          style={{ marginTop: "30px", width: "auto", color: "#ffffff" }}
        >
          AVATARS THAT MEAN BUSINESS
        </LayoutStyles.ImageAndTextBox.Tag>
        <LayoutStyles.PageTitle style={{ color: "#ffffff" }}>
          The business value of 3D avatars
        </LayoutStyles.PageTitle>
        <LayoutStyles.Body style={{ maxWidth: '90%', textAlign: "center", color: "#ffffff" }}>
          Using 3D avatars for your business can help you build stronger
          customer relationships by providing a more personalized experience.
          This could lead to new ways of doing business while giving your
          company an edge over your competitors.
        </LayoutStyles.Body>
      </>
    </LayoutStyles.TextFirstHero.Container>
  );
};

export default Avatars;
