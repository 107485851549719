import BinaryImg from "../assets/images/binarylock.png";
import SSOImg from "../assets/images/ssoicon.png";
import SalesForce from "../assets/images/integrations/Salesforce.png";
import Slack from "../assets/images/integrations/Slack.png";
import Microsoft from "../assets/images/integrations/Microsoft.png";
import Hubspot from "../assets/images/integrations/Hubspot.png";

export const textTextData = {
  homepage: {
    security: {
      iconRow: [
        {
          icon: BinaryImg,
          subtitle: 'End-to-end encryption'
        },
        {
          icon: SSOImg,
          subtitle: 'Single Sign On'
        },
        {
          icon: '',
          subtitle: ''
        },
      ]
    },
    integrations: {
      iconRow: [
        {
          icon: SalesForce,
          subtitle: ''
        },
        {
          icon: Slack,
          subtitle: ''
        },
        {
          icon: Microsoft,
          subtitle: ''
        },
        {
          icon: Hubspot,
          subtitle: ''
        },
      ]
    }
  }
}

export const parsePhrase = (phrase: string, text: string) => {
  if (!text.includes(phrase)) return text;
  const stringArr = text.split(new RegExp(phrase));;
  const spanEl = document.createElement('span')
  console.log('array', stringArr)
  const newArr = stringArr.map(item => {
    if (item !== phrase) return item;
    if (item === phrase) {
      spanEl.innerText = phrase;
      spanEl.style.whiteSpace = 'nowrap'
       return spanEl
  }})
  return newArr.join("");
}

// Date formatting

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}