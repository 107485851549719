import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";

export default {
    v: "5.7.4",
    fr: 30,
    ip: 0,
    op: 210,
    w: 1462,
    h: 1586,
    nm: "Edit Hotspots",
    ddd: 0,
    assets: [
      {
        id: "image_0",
        w: 108,
        h: 144,
        u: "",
        p: img_0,
        e: 0
      },
      {
        id: "image_1",
        w: 1462,
        h: 1228,
        u: "",
        p: img_1,
        e: 0
      },
      {
        id: "image_2",
        w: 1462,
        h: 1228,
        u: "",
        p: img_2,
        e: 0
      },
      {
        id: "image_3",
        w: 1462,
        h: 1228,
        u: "",
        p: img_3,
        e: 0
      },
      {
        id: "image_4",
        w: 1462,
        h: 1228,
        u: "",
        p: img_4,
        e: 0
      },
      {
        id: "image_5",
        w: 816,
        h: 870,
        u: "",
        p: img_5,
        e: 0
      },
      {
        id: "image_6",
        w: 1316,
        h: 1228,
        u: "",
        p: img_6,
        e: 0
      }
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 2,
        nm: "cursor-quiz.png",
        cl: "png",
        refId: "image_0",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 10.01,
                s: [
                  0
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 16.016,
                s: [
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 186,
                s: [
                  100
                ]
              },
              {
                t: 201.000306947573,
                s: [
                  0
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1
                },
                o: {
                  x: 0.333,
                  y: 0
                },
                t: 10,
                s: [
                  399.804,
                  1462.166,
                  0
                ],
                to: [
                  103.288,
                  -109.612,
                  0
                ],
                ti: [
                  -103.288,
                  109.612,
                  0
                ]
              },
              {
                i: {
                  x: 0.667,
                  y: 0.667
                },
                o: {
                  x: 0.333,
                  y: 0.333
                },
                t: 60,
                s: [
                  1019.533,
                  804.494,
                  0
                ],
                to: [
                  0,
                  0,
                  0
                ],
                ti: [
                  0,
                  0,
                  0
                ]
              },
              {
                i: {
                  x: 0.667,
                  y: 1
                },
                o: {
                  x: 0.333,
                  y: 0
                },
                t: 105,
                s: [
                  1019.533,
                  804.494,
                  0
                ],
                to: [
                  -41.315,
                  31.619,
                  0
                ],
                ti: [
                  41.315,
                  -31.619,
                  0
                ]
              },
              {
                i: {
                  x: 0.667,
                  y: 0.667
                },
                o: {
                  x: 0.333,
                  y: 0.333
                },
                t: 124,
                s: [
                  771.641,
                  994.207,
                  0
                ],
                to: [
                  0,
                  0,
                  0
                ],
                ti: [
                  0,
                  0,
                  0
                ]
              },
              {
                i: {
                  x: 0.667,
                  y: 1
                },
                o: {
                  x: 0.333,
                  y: 0
                },
                t: 141,
                s: [
                  771.641,
                  994.207,
                  0
                ],
                to: [
                  54.806,
                  18.971,
                  0
                ],
                ti: [
                  -54.806,
                  -18.971,
                  0
                ]
              },
              {
                t: 169.000306947573,
                s: [
                  1100.477,
                  1108.035,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              19,
              20,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.667,
                    0.667,
                    0.667
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.333,
                    0.333,
                    0.333
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 77,
                s: [
                  60,
                  60,
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.667,
                    0.667,
                    0.667
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.333,
                    0.333,
                    0.333
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 83.006,
                s: [
                  20,
                  20,
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.833,
                    0.833,
                    0.833
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.167,
                    0.167,
                    0.167
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 94.018,
                s: [
                  60,
                  60,
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.667,
                    0.667,
                    0.667
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.333,
                    0.333,
                    0.333
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 124,
                s: [
                  60,
                  60,
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.667,
                    0.667,
                    0.667
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.333,
                    0.333,
                    0.333
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 130.006,
                s: [
                  20,
                  20,
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.833,
                    0.833,
                    0.833
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.167,
                    0.167,
                    0.167
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 141.018,
                s: [
                  60,
                  60,
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.667,
                    0.667,
                    0.667
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.333,
                    0.333,
                    0.333
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 169,
                s: [
                  60,
                  60,
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.667,
                    0.667,
                    0.667
                  ],
                  y: [
                    1,
                    1,
                    1
                  ]
                },
                o: {
                  x: [
                    0.333,
                    0.333,
                    0.333
                  ],
                  y: [
                    0,
                    0,
                    0
                  ]
                },
                t: 175.006,
                s: [
                  20,
                  20,
                  100
                ]
              },
              {
                t: 186.017885072573,
                s: [
                  60,
                  60,
                  100
                ]
              }
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 10.01001001001,
        op: 210,
        st: -427.427427427427,
        bm: 0
      },
      {
        ddd: 0,
        ind: 2,
        ty: 2,
        nm: "Animation-5.png",
        cl: "png",
        refId: "image_1",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 179,
                s: [
                  0
                ]
              },
              {
                t: 184,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              731,
              793,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              731,
              614,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 179,
        op: 210,
        st: 119,
        bm: 0
      },
      {
        ddd: 0,
        ind: 3,
        ty: 2,
        nm: "Animation-4.png",
        cl: "png",
        refId: "image_2",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 127,
                s: [
                  0
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 132,
                s: [
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 187,
                s: [
                  100
                ]
              },
              {
                t: 198,
                s: [
                  0
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              731,
              793,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              731,
              614,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 127,
        op: 210,
        st: 67,
        bm: 0
      },
      {
        ddd: 0,
        ind: 4,
        ty: 2,
        nm: "Animation-3.png",
        cl: "png",
        refId: "image_3",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 95,
                s: [
                  0
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 100,
                s: [
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 135,
                s: [
                  100
                ]
              },
              {
                t: 146,
                s: [
                  0
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              731,
              793,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              731,
              614,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 95,
        op: 210,
        st: 35,
        bm: 0
      },
      {
        ddd: 0,
        ind: 5,
        ty: 2,
        nm: "Animation-2.png",
        cl: "png",
        refId: "image_4",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 49,
                s: [
                  0
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 54,
                s: [
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 104,
                s: [
                  100
                ]
              },
              {
                t: 115,
                s: [
                  0
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              731,
              793,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              731,
              614,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 49,
        op: 199,
        st: -11,
        bm: 0
      },
      {
        ddd: 0,
        ind: 6,
        ty: 2,
        nm: "ModalInsert.png",
        cl: "png",
        refId: "image_5",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.995
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 0,
                s: [
                  0
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 25,
                s: [
                  100
                ]
              },
              {
                i: {
                  x: [
                    0.833
                  ],
                  y: [
                    0.833
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 58,
                s: [
                  100
                ]
              },
              {
                t: 69,
                s: [
                  0
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 0,
                s: [
                  1054,
                  1038,
                  0
                ],
                to: [
                  0,
                  -36.667,
                  0
                ],
                ti: [
                  0,
                  36.667,
                  0
                ]
              },
              {
                t: 25,
                s: [
                  1054,
                  818,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              408,
              435,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 0,
        op: 210,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 7,
        ty: 2,
        nm: "Environment.png",
        cl: "png",
        refId: "image_6",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1.005
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 0,
                s: [
                  0
                ]
              },
              {
                t: 25,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 0,
                s: [
                  658,
                  623,
                  0
                ],
                to: [
                  0,
                  28.333,
                  0
                ],
                ti: [
                  0,
                  -28.333,
                  0
                ]
              },
              {
                t: 25,
                s: [
                  658,
                  793,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              658,
              614,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 0,
        op: 210,
        st: 0,
        bm: 0
      }
    ],
    markers: []
  }