import React from "react";
import LayoutStyles from "../App.styles";
import { useTheme, useMediaQuery, Grid } from "@mui/material";
import { motion } from "framer-motion";

// Logos
import ThreeM from "../assets/images/Logo/3m.png";
import Bayer from "../assets/images/Logo/bayer.png";
import Comcast from "../assets/images/Logo/comcast.png";
import OTC from "../assets/images/Logo/otc.png";
import Smuckers from "../assets/images/Logo/smuckers.png";
import ADT from "../assets/images/Logo/adt.png";

import OEC from "../assets/images/oec.png";
import MBE from "../assets/images/mbe.png";
import Metacenter from "../assets/images/Metacenter.png";
import VRAssoc from "../assets/images/vrassoc.png";

type Props = {
  logoType: "clients" | "associations";
  hideHeader?: boolean;
};

const tickerLeft = {
  animate: {
    x: "-330%",
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 36,
        ease: "linear",
      },
    },
  },
};

const tickerRight = {
  animate: {
    x: "330%",
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 36,
        ease: "linear",
      },
    },
  },
};

const Logos: React.FC<Props> = ({ logoType, hideHeader }) => {
  const { breakpoints } = useTheme();
  const isMediumScreen = useMediaQuery(breakpoints.down(breakpoints.values.md));
  const isSmallScreen = useMediaQuery(breakpoints.down(breakpoints.values.sm));

  return (
    <>
      {!hideHeader && (
        <LayoutStyles.LogoHeader>
          {logoType === "clients"
            ? "TRUSTED BY COMPANIES ACROSS THE GLOBE"
            : "PROUD TO BE A PART OF"}
        </LayoutStyles.LogoHeader>
      )}

      {!isMediumScreen ? (
        logoType === "clients" ? (
          <LayoutStyles.FlexboxRow
            $justifyContent="space-around"
            $overrideCenter
            $height={"107px"}
            style={{
              maxWidth: '1172px'
            }}
            $margin="0 auto"
          >
            <LayoutStyles.Logo src={Comcast} />
            <LayoutStyles.Logo src={Bayer} />
            <LayoutStyles.Logo src={ThreeM} />
            <LayoutStyles.Logo src={ADT} />
            <LayoutStyles.Logo src={Smuckers} />
            <LayoutStyles.Logo style={{height: '89px'}} src={OTC} />
          </LayoutStyles.FlexboxRow>
        ) : (
          <LayoutStyles.FlexboxRow
            $justifyContent="space-around"
            $overrideCenter
            $height={"107px"}
            $width="1172px"
            $margin="0 auto"
          >
            <LayoutStyles.Logo
              style={{ height: "150px" }}
              $inColor
              src={VRAssoc}
              alt="VR AR Association logo"
            />
            <LayoutStyles.Logo
              style={{ height: "150px" }}
              $inColor
              src={OEC}
              alt="OEC logo"
            />
            <LayoutStyles.Logo
              style={{ height: "150px" }}
              $inColor
              src={MBE}
              alt="MBE logo"
            />
            <LayoutStyles.Logo
              style={{ height: "150px" }}
              $inColor
              src={Metacenter}
              alt="Metacenter logo"
            />
          </LayoutStyles.FlexboxRow>
        )
      ) : logoType === "clients" ? (
        <Grid container spacing={0} columnSpacing={"23px"} rowSpacing={"10px"}>
          <Grid item textAlign="center" xs={6}>
            <LayoutStyles.Logo src={Comcast} />
          </Grid>
          <Grid item textAlign="center" xs={6}>
            <LayoutStyles.Logo src={Bayer} />
          </Grid>
          <Grid item textAlign="center" xs={6}>
            <LayoutStyles.Logo src={ThreeM} />
          </Grid>
          <Grid item textAlign="center" xs={6}>
            <LayoutStyles.Logo src={ADT} />
          </Grid>
          <Grid item textAlign="center" xs={6}>
            <LayoutStyles.Logo src={Smuckers} />
          </Grid>
          <Grid item textAlign="center" xs={6}>
            <LayoutStyles.Logo src={OTC} />
          </Grid>
        </Grid>
      ) : (
        <LayoutStyles.GridContainer container>
          <Grid
            style={{ justifyContent: "center", width: "50%" }}
            container
            item
            xs={6}
          >
            <LayoutStyles.Logo
              $inColor
              src={VRAssoc}
              alt="VR AR Association logo"
            />
          </Grid>
          <Grid
            style={{ justifyContent: "center", width: "50%" }}
            container
            item
            xs={6}
          >
            <LayoutStyles.Logo $inColor src={OEC} alt="OEC logo" />
          </Grid>
          <Grid
            style={{ justifyContent: "center", width: "50%" }}
            container
            item
            xs={6}
          >
            <LayoutStyles.Logo $inColor src={MBE} alt="MBE logo" />
          </Grid>
          <Grid
            style={{ justifyContent: "center", width: "50%" }}
            container
            item
            xs={6}
          >
            <LayoutStyles.Logo
              $inColor
              src={Metacenter}
              alt="Metacenter logo"
            />
          </Grid>
        </LayoutStyles.GridContainer>
      )}
    </>
  );
};

export default Logos;
