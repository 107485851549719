import PlayIcon from '../../assets/icons/play.png';
import React from "react";
import LayoutStyles from "../../App.styles";

const VideoButton = ({
  text,
  fontSize,
  clickFunction,
  fullWidth,
  bgColor
}: {
  text: string;
  fontSize?: number;
  fullWidth?: boolean;
  bgColor?: string;
  clickFunction?: () => void;
}) => {
  return (
    <LayoutStyles.ImageAndTextBox.VideoButton
      $bgColor={bgColor}
      $fullWidth={fullWidth}
      onClick={clickFunction}
      style={{ fontSize: fontSize ?? 16 }}
      startIcon={<img style={{height: '26px', width: '26px'}} src={PlayIcon} alt='Play video' />}
    >
      {text}
    </LayoutStyles.ImageAndTextBox.VideoButton>
  );
};

export default VideoButton;
