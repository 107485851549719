import React, { useState } from "react";
import TextFirstHero from "../PageBlocks/TextFirstHero";
import LayoutStyles from "../App.styles";
import ArrowButton from "../Common/Button/ArrowButton";
import ContactForm from "../PageBlocks/ContactForm";
import { useApp } from "../context/AppContext";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  faPhone,
  faLocationPin,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormSuccess: React.FC = () => {
  const theme = useTheme();
  const [currentStep, updateStep] = useState(0);
  const { setShowScheduler } = useApp();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  return (
    <>
      <LayoutStyles.SectionContainer $margin="60px 0 137px 0">
        <LayoutStyles.FlexboxRow
          $gap="17px"
          $flexDirection={isSmallScreen ? "column" : "row"}
          $margin="0 0 20px 0"
        >
          <LayoutStyles.Contact.ScheduleBox>
            <div
              style={{
                fontSize: isSmallScreen ? "32px" : "56px",
                marginBottom: isSmallScreen ? "16px" : "25px",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              📅
            </div>
            <LayoutStyles.Contact.ScheduleHeadline>
              Ready for a demo now?
            </LayoutStyles.Contact.ScheduleHeadline>
            <LayoutStyles.Contact.ScheduleSub>
              Let's get it scheduled.
            </LayoutStyles.Contact.ScheduleSub>
            <ArrowButton
              text="Schedule a demo"
              bgColor="#ffffff"
              color="#000000"
              width="235px"
              clickFunction={() => setShowScheduler(true)}
            />
          </LayoutStyles.Contact.ScheduleBox>
          <LayoutStyles.Contact.ContactFormContainer>
            <ContactForm currentStep={4} updateStep={updateStep} />
          </LayoutStyles.Contact.ContactFormContainer>
        </LayoutStyles.FlexboxRow>
        <LayoutStyles.FlexboxRow
          $flexDirection={isSmallScreen ? "column" : "row"}
          $backgroundColor="#EEF5FE"
          $height={isSmallScreen ? "auto" : "132px"}
          $padding={isSmallScreen ? "40px" : "0"}
          style={{ borderRadius: "16px" }}
        >
          <LayoutStyles.FlexboxRow
            $flexDirection={isSmallScreen ? "column" : "row"}
            $justifyContent="center"
          >
            <FontAwesomeIcon size="2x" icon={faPhone} />
            <LayoutStyles.Contact.Box>
              <LayoutStyles.Contact.Header>PHONE</LayoutStyles.Contact.Header>
              <LayoutStyles.Contact.Body>
                407.704.6636
              </LayoutStyles.Contact.Body>
            </LayoutStyles.Contact.Box>
          </LayoutStyles.FlexboxRow>
          <LayoutStyles.FlexboxRow
            $flexDirection={isSmallScreen ? "column" : "row"}
            $justifyContent="center"
          >
            <FontAwesomeIcon size="2x" icon={faLocationPin} />
            <LayoutStyles.Contact.Box>
              <LayoutStyles.Contact.Header>ADDRESS</LayoutStyles.Contact.Header>
              <LayoutStyles.Contact.Body>
                {isSmallScreen
                  ? `2474 E Michigan St \n Orlando FL 32806`
                  : `2474 E Michigan St, Orlando, FL 32806`}
              </LayoutStyles.Contact.Body>
            </LayoutStyles.Contact.Box>
          </LayoutStyles.FlexboxRow>
          <LayoutStyles.FlexboxRow
            $flexDirection={isSmallScreen ? "column" : "row"}
            $justifyContent="center"
          >
            <FontAwesomeIcon size="2x" icon={faEnvelope} />
            <LayoutStyles.Contact.Box>
              <LayoutStyles.Contact.Header>EMAIL</LayoutStyles.Contact.Header>
              <LayoutStyles.Contact.Body>
                hello@xureal.com
              </LayoutStyles.Contact.Body>
            </LayoutStyles.Contact.Box>
          </LayoutStyles.FlexboxRow>
        </LayoutStyles.FlexboxRow>
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default FormSuccess;
