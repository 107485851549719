import { useMotionValue } from "framer-motion";
import React, { useState, useEffect } from "react";

const useOnScroll = ({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollValue = useMotionValue(0);

  const handleScroll = () => {
    const position = containerRef?.current?.scrollTop ?? 0;
    setScrollPosition(position);
    scrollValue.set(position);
  };

  useEffect(() => {
    if (containerRef.current)
      containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      if (containerRef.current)
        containerRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { scrollValue, scrollPosition };
};

export default useOnScroll;
