import React, { useEffect, useRef, useState } from "react";
import LayoutStyles from "../App.styles";

import storyImg from "../assets/images/about/story.png";
import avatarsIntro from "../assets/images/about/avatars_intro.png";
import avatarsBlink from "../assets/images/about/avatars_blink.png";

import ImageTextBlock from "../PageBlocks/ImageText";
import TextFirstHero from "../PageBlocks/TextFirstHero";
import AboutGroup from "../assets/images/TeamAvatars/About_Group.png";
import AboutMobile from "../assets/images/TeamAvatars/About_Group_Mobile.png";
import Logos from "../PageBlocks/Logos";
import dataJson from "../data.json";
import { useApp } from "../context/AppContext";
import { useTheme, useMediaQuery } from "@mui/material";
import AboutHassan from "../assets/images/TeamAvatars/about-hassan.jpg";
import Hassan from "../assets/images/TeamAvatars/Hassan.png";
import AboutSpread from "../PageBlocks/AboutSpread";
import AboutSpreadMobile from "../PageBlocks/AboutSpreadMobile";
import useOnScreen from "../hooks/useOnScreen";
import Awards, { AwardsMobile } from "../PageBlocks/Awards";

const About = ({
  containerRef,
}: {
  containerRef: React.MutableRefObject<null>;
}) => {
  const [introFinished, setIntroFinished] = useState(false);
  const avatarsRef = useRef(null);
  const isOnScreen = useOnScreen(avatarsRef);
  const { tabRefs, setCurrentPage } = useApp();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  )

  const spreadAnimationBreakpoint = useMediaQuery(theme.breakpoints.down(1160));

  useEffect(() => {
    setCurrentPage("about");
  }, [setCurrentPage]);

  useEffect(() => {
    if (isOnScreen) {
      let animationTimer = setTimeout(() => {
        setIntroFinished(true);
      }, 9000);
      return () => clearTimeout(animationTimer);
    }
  }, [isOnScreen]);

  return (
    <>
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "0 0 15px" : "0 0 0"}
      >
        <TextFirstHero textFirst data={dataJson.about.hero} />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer>
        <img style={{maxWidth: '100%'}} src={isSmallScreen ? AboutMobile : AboutGroup} alt="the team" />
        <div style={{backgroundColor: '#000000', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)', width: '100vw', height: '21%', zIndex: '1'}} />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer $backgroundColor="#000000" $padding={isSmallScreen ? "0 0 30px 0" : isMediumScreen ? "0 0 60px 0" : "0 0 115px 0"}>
        <Logos hideHeader logoType="associations" />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $maxWidth="1282px"
        $padding={isSmallScreen ? "60px 25px 100px" : "184px 22px 205px 22px"}
        $backgroundColor={"#EEF5FE"}
      >
        <div ref={avatarsRef} style={{ color: "#F9F9F9" }}>
          <ImageTextBlock
            altColor={false}
            data={dataJson.about.story}
            alignItems="center"
            padding={isMediumScreen ? "0px" : "0 75px"}
            removeTxtPadding
            animationMargin={"0 0 31px 0"}
            lottieAnimation={[
              storyImg,
              isSmallScreen ? null : introFinished ? avatarsBlink : avatarsIntro,
            ]}
            avatarPosition={isMediumScreen ? ["51%", "auto", "auto", "33%"] : ["45%", "auto", "auto", "19%"]}
            avatarSize={isMediumScreen ? ["auto", "55%"] : ["auto", "68.5%"]}
            hideButton
            imageSize={[isSmallScreen ? '100%' : 560, "auto"]}
            isBackgroundImg={[true, false]}
          />
        </div>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "60px 0 100px" : "120px 0"}
        $backgroundColor={"#FFFFFF"}
      >
        {!isSmallScreen ? <Awards /> : <AwardsMobile />}
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $backgroundColor={theme.palette.neutral.light}
        $padding={isSmallScreen ? "60px 0" : "110px 0 60px 0"}
      >
        <LayoutStyles.ImageAndTextBox.Tag
          style={{
            justifyContent: "center",
            marginBottom: "0",
          }}
        >
          OUR CULTURE
        </LayoutStyles.ImageAndTextBox.Tag>
        <LayoutStyles.PageTitle style={{maxWidth: 'unset', marginTop: '30px'}}>
          {"This is us"}
        </LayoutStyles.PageTitle>
      </LayoutStyles.SectionContainer>
      
      {!spreadAnimationBreakpoint ? (
        <AboutSpread
          containerRef={containerRef}
          data={dataJson.homepage.statement}
        />
      ) : (
        <AboutSpreadMobile data={dataJson.homepage.statement} />
      )}

      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "104px 25px 90px" : "176px 0 154px"}
        ref={(el) => (tabRefs.current[0] = el)}
        $backgroundColor={"#F5F5F5"}
        color={theme.palette.primary.dark}
      >
        <ImageTextBlock
          lottieAnimation={[AboutHassan, !isSmallScreen && Hassan]}
          data={dataJson.about.quote}
          alignItems="center"
          padding={isMediumScreen ? "0" : "0 75px"}
          avatarSize={["auto", "85%"]}
          avatarPosition={
            isSmallScreen
              ? ["25%", "auto", "auto", "60%"]
              : ["25%", "auto", "auto", "70%"]
          }
          imageSize={
            isMediumScreen
              ? ['90%', "auto"]
              : [433, "auto"]
          }
          gap={isSmallScreen ? "40px" : "80px"}
          hideTag
          quote
          removeTxtPadding
          isBackgroundImg={[true, false]}
          hideButton
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default About;
