import React from "react";
import LayoutStyles from "../App.styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const Privacy: React.FC = () => {
  const { palette } = useTheme();
  return (
    <div
      style={{
        width: "90%",
        maxWidth: "996px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "130px",
      }}
    >
      <LayoutStyles.PageTitle>Privacy Policy</LayoutStyles.PageTitle>
      <Typography
        style={{ textAlign: "center", color: palette.neutral.main }}
        variant="body1"
      >
        Last Updated July 18, 2022
      </Typography>
      <Typography variant="body1">
        PRIVACY POLICY Please read the following to learn more about our Privacy
        Policy. By using or visiting any Elevux, LLC or Xureal application,
        website, or any other linked pages, features, content, or any other
        services we offer, you acknowledge that you accept the practices and
        policies outlined in this Privacy Policy, and you hereby consent that we
        will collect, use, and share your information in the following ways. Any
        capitalized terms used herein without definition shall have the meaning
        given to them in our Terms and Conditions of User.
        <br />
        <br />
        This Privacy Policy covers our (Elevux, LLC and our respective
        affiliates and related entities) treatment of personally identifiable
        information ("Personal Information") that we gather when you are
        accessing or using the Site or Services (collectively, the “System”).
        This policy does not apply to the practices of companies that we do not
        own or control, or to individuals that we do not employ or manage. This
        policy also does not apply to information you choose to disclose
        publicly on the System.
      </Typography>
      <Typography variant="h4">Personal Information We Collect</Typography>
      <Typography variant="body1">
        We receive and store any information you provide to us. The types of
        Personal Information collected may include your real name, username,
        email address, IP address, location, browser information, browsing
        history, transactions, your communications to us, and any other
        information necessary for us to provide our services. If you provide any
        third-party account credentials to us, you understand some content
        and/or information in those accounts ("Third Party Account Information")
        may be transmitted into your account with us if you authorize such
        transmissions, and that Third Party Account Information transmitted to
        our Site is covered by this Privacy Policy. You can choose not to
        provide us with certain information, but then you may not be able to
        register with us or to take advantage of some of our features.
      </Typography>
      <Typography variant="h4">
        Personal Information Collect Automatically
      </Typography>
      <Typography variant="body1">
        Whenever you interact with the System, we automatically receive and
        record information on our server logs from your browser including, but
        not limited to, your IP address, "cookie" information, device
        information, certain location information, and the page you requested.
        "Cookies" are identifiers we transfer to your device through our web
        interface that allow certain functions to perform as designed within the
        System, and also to allow us to recognize your device and tell us how
        and when pages in our site are visited and by how many people. We may
        also record the referring page or service that linked you to us; other
        information about the type of web browser, computer, platform, related
        software, and settings you are using; any search terms you have entered
        on the System; and other usage activity and data logged by our servers.
        You may be able to change the preferences on your device to prevent or
        limit your device's acceptance of cookies, but this may prevent you from
        taking advantage of the System's best features. When we collect usage
        information (such as the numbers and frequency of visitors to the
        System), we only use this data in aggregate form, and not in a manner
        that would identify you personally. For example, this aggregate data
        tells us how often users use parts of the System, so that we can make
        the System appealing to as many users as possible. We may also provide
        this aggregate information to our partners, so that they can understand
        how often people use their services and our System, so that they, too,
        can provide you with an optimal online experience.
      </Typography>
      <Typography variant="h4">Uses of Personal Information</Typography>
      <Typography variant="body1">
        We may use the Personal Information we collect for our legitimate
        business purposes, including providing our services and products to you,
        improving the System, improving our customer service, processing
        transactions, marketing purposes, and managing our business needs. We do
        not sell your personal information to third-parties. We may also share
        Personal Information with our affiliates and trusted third parties and
        services, such as Google Analytics.
      </Typography>
      <Typography variant="h4">Communications</Typography>
      <Typography variant="body1">
        As part of the System, you may receive communications from us, whether
        by email or other means. We and our agents may send you email, contact
        you, or engage you in other communication that we or they determine
        relate to your use of the System.
      </Typography>
      <Typography variant="h4">
        Affiliated Businesses and Third-Parties
      </Typography>
      <Typography variant="body1">
        In certain situations, businesses or third-party websites with which we
        are affiliated may sell items or provide services to you through the
        Site (either alone or jointly with us). You can recognize when an
        affiliated business is associated with such a transaction or service,
        and we will share your Personal Information with that affiliated
        business only to the extent that it is related to such transaction or
        service. One such service may include the ability for you to choose to
        automatically transmit Third Party Account Information to your Site
        profile or to automatically transmit information in your Site profile to
        your third-party account. We have no control over the policies and
        practices of any third-party.
      </Typography>
      <Typography variant="h4">Protecting Us &amp; Others</Typography>
      <Typography variant="body1">
        We reserve the right to access, read, preserve, and disclose any
        information that we reasonably believe is necessary to comply with law
        or court order; enforce or apply our conditions of use and other
        agreements; or protect the rights, property, or safety of us, our
        employees, our users, or others. This includes exchanging information
        with other companies and organizations for fraud protection and credit
        risk reduction.
      </Typography>
      <Typography variant="h4">Personal Information Security</Typography>
      <Typography variant="body1">
        Your account may be protected by a password for your privacy and
        security. If you access your account via a third-party site or service,
        you may have additional or different sign-in protections via that
        third-party site or service. You need to prevent unauthorized access to
        your account and Personal Information by selecting and protecting your
        password and/or other sign-in mechanism appropriately. We endeavor to
        protect the privacy of your account and other Personal Information we
        hold in our records, but we cannot guarantee complete security.
        Unauthorized entry or use, hardware or software failure, and other
        factors, may compromise the security of user information at any time. In
        the event that we believe that the security of your Personal Information
        in our possession or control has been compromised, we will notify you of
        that development in accordance with applicable law. If a notification is
        appropriate, we will endeavor to do so as promptly as possible under the
        circumstances, and, we may do so by email. Using the System, you may
        find links to third-party websites or apps. We are not responsible for
        the privacy policies and/or practices on other sites or apps. When
        linking to another site or app, you should read the site or app’s
        privacy policy. User profile information, including without limitation
        your name, email address, and other information ("User Submissions") may
        be displayed to other users if you chose to enter such information on
        your user profile. Again, any content you voluntarily disclose for other
        users to view on the System is not Personal Information; it becomes
        publicly available and can be collected and used by others and may be
        redistributed through the internet and other media channels.
      </Typography>
      <Typography variant="h4">Your Rights</Typography>
      <Typography variant="body1">
        In certain circumstances, you have the following rights: the right to be
        told how we use your information and obtain access to your information;
        the right to have your information recti fi ed or erased or place
        restrictions on processing your information; the right to object to the
        processing of your information (e.g., for direct marketing purposes);
        the right to have any information you provided to us on an automated
        basis returned to you in a structured, commonly used and
        machine-readable format, or sent directly to another company, where
        technically feasible (“data portability”); where the processing of your
        information is based on your consent, the right to withdraw that consent
        subject to legal or contractual restrictions; the right to object to any
        decisions based on the automated processing of your personal data,
        including profiling; and the right to lodge a complaint with the
        supervisory authority responsible for data protection matters (e.g. in
        the UK, the Information Commissioner’s Office). If you request a copy of
        your information, you may be required to pay a statutory fee. If we hold
        any information about you that is incorrect or if there are any changes
        to your details, please let us know so that we can keep our records
        accurate and up to date. If you withdraw your consent to the use of your
        personal information for purposes set out in our Privacy Policy, we may
        not be able to provide you with access to all or parts of the System. We
        will retain your personal information for the duration of our business
        relationship and afterward for as long as is necessary and relevant for
        our legitimate business purposes, in accordance with applicable laws and
        regulation. In the event we no longer have a legitimate business purpose
        to retain your personal information, we will dispose of it in a secure
        manner (without further notice to you).
      </Typography>
      <Typography variant="h4">Your Rights (EU and UK Citizens)</Typography>
      <Typography variant="body1">
        If the General Data Protection Regulation applies to you because you are
        in the European Union (or the UK-GDPR for UK citizens), you have the
        following rights under data protection laws in relation to your personal
        data:
      </Typography>
      <ul style={{ marginBottom: "25px" }}>
        <li>
          The right to be informed – that’s an obligation on us to inform you
          how we use your personal data (and that’s what we’re doing in this
          Privacy Policy);
        </li>
        <li>
          The right of access – that’s a right to make what’s known as a “data
          subject access request” for copy of the personal data we hold about
          you;
        </li>
        <li>
          The right to rectification – that’s a right to make us correct
          personal data about you that may be incomplete or inaccurate;
        </li>
        <li>
          The right to erasure – that’s also known as the “right to be
          forgotten” where in certain circumstances you can ask us to delete the
          personal data we have about you (unless there’s an overriding legal
          reason we need to keep it);
        </li>
        <li>
          The right to restrict processing – that’s a right for you in certain
          circumstances to ask us to suspend processing personal data;
        </li>
        <li>
          The right to data portability – that’s a right for you to ask us for a
          copy of your personal data in a common format (for example, a .csv fi
          le);
        </li>
        <li>
          The right to object – that’s a right for you to object to us
          processing your personal data (for example, if you object to us
          processing your data for direct marketing); and
        </li>
        <li>
          Rights in relation to automated decision making and profiling – that’s
          a right you have for us to be transparent about any profiling we do,
          or any automated decision making.
        </li>
      </ul>
      <Typography variant="body1">
        These rights are subject to certain rules around when you can exercise
        them.
        <br />
        <br />
        If you wish to exercise any of the rights set out above, please contact
        us at mydata@xureal.com.
        <br />
        <br />
        You will not have to pay a fee to access your personal data (or to
        exercise any of the other rights), but we may charge a reasonable fee if
        your request is clearly unfounded, repetitive, or excessive.
        Alternatively, we may refuse to comply with your request in these
        circumstances.
        <br />
        <br />
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
        <br />
        <br />
        We try to respond to all legitimate requests within one month.
        Occasionally it may take us longer than a month if your request is
        particularly complex or you have made a number of requests. In this
        case, we will notify you and keep you updated.
        <br />
        <br />
        We have appointed a data privacy manager who is responsible for
        overseeing questions in relation to this Privacy Policy. If you have any
        questions about this Privacy Policy, including any requests to exercise
        your legal rights, please contact the data privacy manager using the
        details herein.
        <br />
        <br />
        If you are a UK citizen, you have the right to make a complaint at any
        time to the Information Commissioner's Office (“ICO”), the UK
        supervisory authority for data protection issues (www.ico.org.uk). We
        would, however, appreciate the chance to deal with your concerns before
        you approach the ICO so please contact us in the first instance.
      </Typography>
      <Typography variant="h4">Accessible Personal Information</Typography>
      <Typography variant="body1">
        Through your user profile, you may access and, in some cases, edit or
        delete the following information you have provided to us. This list may
        change as the System changes: real name, location, username, password,
        email address, and other account and user profile information. The
        information you can view, update, and delete through your user profile
        may change as the System changes. If you have any questions about
        viewing, updating, or removing information we have on fi le about you,
        please contact us at mydata@xureal.com.
      </Typography>
      <Typography variant="h4">Other Information</Typography>
      <Typography variant="body1">
        You may opt not to disclose information, but keep in mind that some
        information may be needed to register with us or to take advantage of
        some of our special features. You may be able to add, update, or delete
        certain information as explained above. When you do so, however, we may
        maintain a copy of the unrevised information in our records. We may use
        any aggregated data derived from or incorporating your Personal
        Information after you update or delete it, but not in a manner that
        would identify you personally. You may close your account pursuant to
        the terms of service or subscription agreement that applies to you.
        Please note that some information may remain in our private records
        after the closing of your account. If you do not wish to receive email
        or other mail from us, please email us at mydata@xureal.com. Please note
        that if you do not want to receive legal notices from us, such as this
        Privacy Policy, those legal notices will still govern your use of the
        System, and you are responsible for reviewing such legal notices for
        changes.
      </Typography>
      <Typography variant="h4">California Residents</Typography>
      <Typography variant="body1">
        Because we value the privacy of California residents, we have taken the
        necessary precautions to be in compliance with the California Online
        Privacy Protection Act as it pertains to us. We will not distribute your
        personal information to parties not mentioned in this Privacy Policy
        without your consent. We cannot guarantee the security of your personal
        information and expressly disclaim any such obligation. If we learn of a
        security breach, we will attempt to notify you so that you can take
        appropriate steps. Please click here for our Privacy Notice for
        California Residents, including information related to the California
        Consumer Privacy Act (CCPA).
      </Typography>
      <Typography variant="h4">
        How We Respond to Do Not Track Signals
      </Typography>
      <Typography variant="body1">
        Please note that we do not alter our data collection and use practices
        when we see a Do Not Track signal from your browser.
      </Typography>
      <Typography variant="h4">Access and Use by Minors</Typography>
      <Typography variant="body1">
        The System is not intended for use by minors under the age of 18. We ask
        that minors not use the System nor submit any personal information to
        us. We do not knowingly collect information from minors under 18. In the
        event that we learn we have collected any personal information from a
        minor under the age of 18, we will take appropriate steps to delete that
        information from our database. If you are a minor under the age of 18
        and you have acceded to this website, do not provide your personal
        information and please leave this website immediately.
      </Typography>
      <Typography variant="h4">Changes to this Privacy Policy</Typography>
      <Typography variant="body1">
        We may amend this Privacy Policy from time to time. Use of information
        we collect now is subject to the Privacy Policy in effect at the time
        such information is used. If we make changes in the way we use Personal
        Information, we will notify you by posting an announcement on our System
        or sending you an email. You are bound by any changes to the Privacy
        Policy when you use the System after such changes have been first
        posted.
      </Typography>
      <Typography variant="h4">Contact</Typography>
      <Typography variant="body1">
        If you have any questions about this privacy policy, you may contact us
        at mydata@xureal.com.
        <br />
        <br />
        Privacy Policy Last Updated on July 18, 2022
      </Typography>
    </div>
  );
};

export default Privacy;
