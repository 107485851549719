import React, {useRef} from 'react';
import LayoutStyles from '../App.styles';
import TestImage from '../assets/images/Homepage/coast.png';
import MultiLayerAnimation from './MultiLayerAnimation';
import useOnScreen from '../hooks/useOnScreen';

const HeroImage: React.FC<{bgColor?: string, image?: string, lottieAnimation?: object | any[]}> = ({bgColor, image, lottieAnimation}) => {
    const { HeroImage } = LayoutStyles;
    const elRef = useRef<HTMLDivElement | null>(null);
    const isOnScreen = useOnScreen(elRef);
    
    return(
        <HeroImage.Container ref={elRef}>
            {image && <HeroImage.HeroImg src={image ? image : TestImage} />}
            {lottieAnimation && (
                <MultiLayerAnimation
                      isOnScreen={isOnScreen}
                      avatarPosition={['', '', '', '']}
                      avatarSize={['', '']}
                      lotties={
                        !Array.isArray(lottieAnimation)
                          ? [lottieAnimation]
                          : lottieAnimation
                      }
                    />
            )}
            <HeroImage.ColorBlock $bgColor={bgColor ? bgColor : 'transparent'} />
        </HeroImage.Container>
    )
}

export default HeroImage;