import React, { useRef } from "react";
import LayoutStyles from "../App.styles";
import useOnScreen from "../hooks/useOnScreen";
import ArrowButton from "../Common/Button/ArrowButton";
import SlideButton from "../Common/Button/SlideButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";
import VRHeadset from "../assets/images/vrheadset.png";
import { useTheme, useMediaQuery } from "@mui/material";
type ImageAndTextBlockProps = {
  lottieAnimation?: object;
  data1: {
    tag: string;
    headline: string;
    body: string;
    button: string;
    subject?: string;
    icon?: string;
  };
  data2: {
    tag: string;
    headline: string;
    body: string;
    button: string;
    subject?: string;
    icon?: string;
  };
  height?: string;
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  reverse?: boolean;
  textColor?: string;
  backgroundColor?: string;
  image?: string;
  imageSize?: [number, number];
  hideTag?: boolean;
  hideButton?: boolean;
  buttonType?: "default" | "animated";
  lineColor?: string;
  altColor?: boolean;
  iconRow1?: {
    icon: string;
    subtitle?: string;
  }[];
  iconRow2?: {
    icon: string;
    subtitle?: string;
  }[];
};

const TextText: React.FC<ImageAndTextBlockProps> = ({
  lottieAnimation,
  data1,
  data2,
  height,
  width,
  alignItems,
  justifyContent,
  padding,
  margin,
  hideButton = false,
  buttonType = "default",
  hideTag = false,
  backgroundColor,
  textColor,
  reverse = false,
  lineColor,
  altColor,
  iconRow1,
  iconRow2,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  return (
    <LayoutStyles.FlexboxRow
      $overflow="hidden"
      $height={height}
      $width={width}
      $padding={padding}
      $margin={margin}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $overrideCenter
      $gap={'10%'}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      $flexDirection={
        isMediumScreen ? "column" : reverse ? "row-reverse" : "row"
      }
      style={{
        margin: "auto",
        justifyContent: "center",
      }}
    >
      <LayoutStyles.TextText.Container $altColor={altColor}>
        <div>
        {!hideTag && (
          <LayoutStyles.ImageAndTextBox.Tag>
            {data1.tag.toUpperCase()}
          </LayoutStyles.ImageAndTextBox.Tag>
        )}
        <LayoutStyles.TextText.Headline
          $isSmallScreen={isSmallScreen}
        >
          {data1.headline}
        </LayoutStyles.TextText.Headline>
        </div>
        <LayoutStyles.FlexboxRow
          $alignItems="flex-start"
          $justifyContent={isMediumScreen ? "flex-start !important" : undefined}
        >
          <LayoutStyles.TextText.Body>{data1.body}</LayoutStyles.TextText.Body>
        </LayoutStyles.FlexboxRow>
        {iconRow1 && iconRow1.length > 1 && (
          <LayoutStyles.FlexboxRow
            $height="145px"
            $justifyContent={"space-between"}
            $padding="30px 0 35px 0"
            $overrideCenter
            $margin={"30px 0 0"}
            style={{
              borderTop: isSmallScreen
                ? "1px solid rgba(78, 25, 72, 0.15)"
                : "2px solid rgba(78, 25, 72, 0.15)",
              minHeight: 145,
            }}
          >
            {iconRow1?.map((item) => {
              return (
                <div>
                  <LayoutStyles.TextText.IconRowImg src={item.icon} alt="" />
                  {item.subtitle && (
                    <LayoutStyles.TextText.IconSubtitle>
                      {item.subtitle}
                    </LayoutStyles.TextText.IconSubtitle>
                  )}
                </div>
              );
            })}
          </LayoutStyles.FlexboxRow>
        )}

        {buttonType === "default"
          ? !hideButton && <ArrowButton text={data1.button} />
          : !hideButton &&
            data1.button && (
              <SlideButton path="/enterprise" text={data1.button} />
            )}
      </LayoutStyles.TextText.Container>

      <LayoutStyles.TextText.Container $altColor={altColor}>
        <div>
        {!hideTag && (
          <LayoutStyles.ImageAndTextBox.Tag>
            {data2.tag.toUpperCase()}
          </LayoutStyles.ImageAndTextBox.Tag>
        )}
        <LayoutStyles.TextText.Headline
          $isSmallScreen={isSmallScreen}
        >
          {data2.headline}
        </LayoutStyles.TextText.Headline>
        </div>
        <LayoutStyles.FlexboxRow
          $alignItems="flex-start"
          $justifyContent={isMediumScreen ? "flex-start !important" : undefined}
        >
          <LayoutStyles.TextText.Body>{data2.body}</LayoutStyles.TextText.Body>
        </LayoutStyles.FlexboxRow>
        {iconRow2 && iconRow2.length > 1 && (
          <LayoutStyles.FlexboxRow
            $height="145px"
            $justifyContent="space-between"
            $margin={"30px 0 0"}
            $padding="30px 0 35px 0"
            $overrideCenter
            style={{
              borderTop: isSmallScreen
                ? "1px solid rgba(78, 25, 72, 0.15)"
                : "2px solid rgba(78, 25, 72, 0.15)",
              minHeight: 145,
            }}
          >
            {iconRow2?.map((item) => {
              return (
                <div>
                  <LayoutStyles.TextText.IconRowImg src={item.icon} alt="" />
                  {item.subtitle && (
                    <LayoutStyles.TextText.IconSubtitle>
                      {item.subtitle}
                    </LayoutStyles.TextText.IconSubtitle>
                  )}
                </div>
              );
            })}
          </LayoutStyles.FlexboxRow>
        )}
        {buttonType === "default"
          ? !hideButton && <ArrowButton text={data1.button} />
          : !hideButton &&
            data1.button && (
              <SlideButton path="/enterprise" text={data1.button} />
            )}
      </LayoutStyles.TextText.Container>
    </LayoutStyles.FlexboxRow>
  );
};

export default TextText;
