import React, { useState } from "react";
import dataJson from "../data.json";
import LayoutStyles from "../App.styles";
import { useTheme, useMediaQuery } from "@mui/material";

import ImageText from "../PageBlocks/ImageText";
import TestImage from "../assets/images/Homepage/coast.png";
import HeroImage from "../PageBlocks/HeroImage";
import TextFirstHero from "../PageBlocks/TextFirstHero";
import DualTxtImg from "../PageBlocks/DualTxtImg";

// Images
import Hero from "../assets/images/Training/hero.png";
import Onboarding from "../assets/images/Training/onboarding_journey.png";
import ProductInfo from "../assets/images/Training/product-learning.png";
import ProductInfoMobile from "../assets/images/Training/product-learning-mobile.png";
import Gamification from "../assets/images/Training/gamification.png";
import TrainingQuiz from "../assets/images/Training/training_quiz.png";
import PeerLearning from "../assets/images/Training/peer_learning.png";
import Playbook from "../assets/images/Training/playbook.png";
import Recognition from "../assets/images/Training/recognition.png";
import ProductLaunch from "../assets/images/Training/product-launch.png";
import SalesTrainingAnim from "../assets/animations/training/sales-training/sales-training";
import HeroAnimation from "../assets/animations/training/hero/hero";

const SalesTraining: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  return (
    <>
    <LayoutStyles.SectionContainer $margin="0 0 45px 0">
      <TextFirstHero textFirst data={dataJson.sales_training.hero} />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer style={{overflow: 'visible', maxWidth: '1300px', width: isSmallScreen ? '100%' : '75%', position: 'relative'}} >
      <HeroImage lottieAnimation={HeroAnimation} bgColor={theme.palette.neutral.black} />
      <LayoutStyles.VideoBlock.ColorBlock $bgColor={"#000000"} />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        id="onboarding"
        $backgroundColor={theme.palette.neutral.black}
        $padding={
          isSmallScreen ? "20px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
      >
        <ImageText
          image={Onboarding}
          sectionID="onboarding"
          alignItems={"center"}
          color={theme.palette.primary.main}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          imageSize={[isMediumScreen ? "auto" : 792, "100%"]}
          height={"100%"}
          data={dataJson.sales_training.onboarding}
          hideButton={true}
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $backgroundColor={theme.palette.neutral.white}
        $padding={
          isSmallScreen ? "100px 0" : isMediumScreen ? "150px 0" : "201px 0"
        }
        id="gamification"
      >
        <DualTxtImg
          data1={dataJson.sales_training.dualtxtimg.data1}
          data2={dataJson.sales_training.dualtxtimg.data2}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          alignItems={isMediumScreen ? "center" : "flex-start"}
          image1={Gamification}
          image2={Recognition}
          height={"100%"}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        id="product-visualization"
        $backgroundColor="#eef5fe"
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
      >
        <ImageText
          data={dataJson.sales_training.product}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          sectionID="product-visualization"
          height={"100%"}
          alignItems={"center"}
          image={ProductInfo}
          imageSize={[isMediumScreen ? "auto" : 830, "100%"]}
          color={theme.palette.primary.main}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
        $backgroundColor={theme.palette.neutral.white}
        id="sales-training"
        style={{ alignItems: "center" }}
      >
        <ImageText
          data={dataJson.sales_training.training}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          hideButton
          sectionID="sales-training"
          alignItems={"center"}
          imageSize={[isMediumScreen ? "auto" : 788, "100%"]}
          lottieAnimation={SalesTrainingAnim}
          reverse
          color={"#F9F9F9"}
          height={"100%"}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>

      <LayoutStyles.SectionContainer
        id="cms"
        $backgroundColor={theme.palette.neutral.black}
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
      >
        <ImageText
          data={dataJson.sales_training.cms}
          height={"100%"}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          sectionID="cms"
          color={theme.palette.primary.main}
          alignItems={"center"}
          image={TrainingQuiz}
          imageSize={[isMediumScreen ? "auto" : 774, "100%"]}
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
        $backgroundColor={theme.palette.neutral.white}
        style={{ alignItems: "center" }}
        id="peer-learning"
      >
        <ImageText
          data={dataJson.sales_training.peer_learning}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          hideButton
          reverse
          sectionID="peer-learning"
          alignItems={"center"}
          imageSize={[
            isMediumScreen ? "auto" : 787,
            "100%"
          ]}
          image={PeerLearning}
          color={"#F9F9F9"}
          height={"100%"}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $backgroundColor="#EEF5FE"
        $padding={
          isSmallScreen ? "60px 0 100px" : isMediumScreen ? "150px 0" : "201px 0"
        }
        id="launch-product"
      >
        <ImageText
          data={dataJson.sales_training.launch}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          hideButton
          sectionID="launch-product"
          alignItems={"center"}
          imageSize={[
            isMediumScreen ? "auto" : 721,
            "100%"
          ]}
          image={ProductLaunch}
          color={theme.palette.primary.main}
          height={"100%"}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default SalesTraining;
