import React from "react";

import LayoutStyles from "../App.styles";
import Tag from "../Common/Tag";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TerraTrak from "../assets/images/Logo/terratrak.png";
import Destiny from "../assets/images/TestImages/destiny.png";
import Tales from "../assets/images/TestImages/tales.png";
import Zelda from "../assets/images/TestImages/zelda.jpg";
import Layout from "../MainLayout";

type Props = {
  tag?: string;
  data?: {
    headline: string;
    subtitle?: string;
  };
};

const testImages = [Destiny, Tales, Zelda];

const CaseStudies: React.FC<Props> = ({ data, tag }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const { CaseStudies } = LayoutStyles;

  return (
    <LayoutStyles.FlexboxRow $height="673px">
      <LayoutStyles.SectionContainer
        style={{
          maxWidth: "1300px",
          flex: 1,
          position: "relative",
          height: "100%",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "20px",
        }}
      >
        <Swiper
          autoplay
          pagination={{
            el: ".case-pagination",
            renderBullet: (index, className) => {
              return `<span class='blog-bullets ${className}'></span>`;
            },
          }}
          navigation={{
            nextEl: ".case-next",
            prevEl: ".case-prev",
          }}
          loop
          modules={[Autoplay, Pagination, Navigation]}
          style={{ height: "100%" }}
        >
          {testImages.map((image, index) => {
            return (
              <SwiperSlide className="swiper-slide" key={`avatar-${index}`}>
                <CaseStudies.Swiper.Image src={image} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "20px",
            marginTop: "15px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px", cursor: 'pointer' }} className="case-prev">
              <FontAwesomeIcon
                color="#ffffff"
                size="2xl"
                icon={faCircleChevronLeft}
              />
            </div>
            <div className="case-next" style={{cursor: 'pointer'}}>
              <FontAwesomeIcon
                color="#ffffff"
                size="2xl"
                icon={faCircleChevronRight}
              />
            </div>
          </div>
          <div style={{ textAlign: "right" }} className="case-pagination" />
        </div>
      </LayoutStyles.SectionContainer>
      <div
        style={{
          flex: 1,
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CaseStudies.TextBox>
          <CaseStudies.CaseLogo src={TerraTrak} />
          <CaseStudies.CaseBody>
            TerraTrack leveraged the power of Augmented Reality and 3D
            visualizations to bring their products to life, creating immersive
            customer experiences that helped educate consumers about what it's
            like using a product they've never seen before.
          </CaseStudies.CaseBody>
          <div></div>
        </CaseStudies.TextBox>
        <div style={{ height: "67px", width: "100%" }}></div>
      </div>
    </LayoutStyles.FlexboxRow>
  );
};

export default CaseStudies;
