import React from "react";
import LayoutStyles from "../App.styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const TermsOfService: React.FC = () => {
  const { palette } = useTheme();
  return (
    <div
      style={{
        width: "90%",
        maxWidth: "996px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "130px",
      }}
    >
      <LayoutStyles.PageTitle>Terms And Conditions</LayoutStyles.PageTitle>
      <Typography
        style={{ textAlign: "center", color: palette.neutral.main }}
        variant="body1"
      >
        Last Updated July 18, 2022
      </Typography>
      <Typography variant="body1">
        These Terms and Conditions (“Agreement”) are agreed to between Elevux
        LLC (“Company”) and you, or, if you represent an entity or other
        organization, that entity or organization (in either case “You”).
        <br />
        <br />
        Company offers an online platform known as Xureal (the “Platform”) for
        the purpose of allowing users to explore Xureal Experiences in the
        metaverse that are created by other users (“Creators”), or for You to
        create Xureal Experiences for other Platform users to explore, as well
        as additional services, functionality, and resources made available
        through the Platform (“Services”).
        <br />
        <br />
        This Platform is the copyrighted property of Company or the copyrighted
        property of parties from whom Company has licensed such property. All
        rights in the Platform are reserved worldwide. It is strictly prohibited
        to retain, copy, distribute, publish, or use any portion of the Platform
        except as expressly allowed in these Terms and Conditions. Company
        reserves the right to add to, delete from, or modify any part of the
        Platform at any time without prior notice.
        <br />
        <br />
        YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR DETERMINING THE
        APPROPRIATENESS AND SUITABILITY OF THE PLATFORM FOR YOU AND FOR USING
        THE PLATFORM BASED ON YOUR PARTICULAR CIRCUMSTANCES.
        <br />
        <br />
        This Agreement is entered into as of the earliest date You first access
        or use the Platform (the “Effective Date”). This Agreement includes all
        terms and conditions below. This Agreement applies to the Platform,
        including all Services available through the Platform. This Agreement is
        the complete and exclusive agreement between You and Company regarding
        Your access to and use of the Platform or any Content (as defined below)
        or Services.
        <br />
        <br />
        This Agreement supersedes any prior agreement or proposal, oral or
        written, and any other communications between You and Company relating
        to Your use of the Platform or any Content or Services.
        <br />
        <br />
        PLEASE CAREFULLY READ THIS AGREEMENT. BY ACCESSING OR USING THE
        PLATFORM, INCLUDING ANY CONTENT OR SERVICES, OR BY CLICKING A BOX THAT
        STATES THAT YOU ACCEPT OR AGREE TO THESE TERMS, YOU AGREE THAT YOU HAVE
        READ AND AGREE TO BE BOUND BY THIS AGREEMENT.
      </Typography>
      <Typography variant="h4">1. Definitions</Typography>
      <Typography variant="body1">
        Terms used in this Agreement have the definitions given in this
        Agreement or, if not defined in this Agreement, have their plain English
        meaning as commonly interpreted in the United States.
      </Typography>
      <Typography variant="h4">2. Term</Typography>
      <Typography variant="body1">
        This Agreement will be effective upon the Effective Date and continue
        until such time as the Company or You terminate your use of the
        Platform.
      </Typography>
      <Typography variant="h4">3. Modifications</Typography>
      <Typography variant="body1">
        Company reserves the right, at any time, to modify the Platform and any
        portion of the Content or Services, with or without notice to You, by
        making those modifications available on the Platform. Company also
        reserves the right, at any time, to modify the terms of this Agreement.
        Company will inform You of the presence of any changes to this Agreement
        by posting those changes on the Platform or by providing You with notice
        through the Platform. Any modifications will be effective 30 days
        following posting on the Platform or delivery of such notice through the
        Platform, except in the case of a legally required modification, which
        will be effective upon posting. You may terminate this Agreement as set
        forth below if you object to any such modifications; however, You will
        be deemed to have agreed to any and all modifications through Your
        continued use of the Platform, Content, or Services following such
        notice period.
      </Typography>

      <Typography variant="h4">4. Eligibility</Typography>
      <Typography variant="body1">
        The Platform and all Content and Services are intended for use by
        individuals 18 years of age and older. If You are not 18 years of age or
        older, You agree not to access or use the Platform or any Content or
        Services. You agree not to allow any individual under 18 years of age to
        access or use the Platform, regardless of whether You are the parent or
        guardian of that individual. In the event You are using the Platform on
        behalf of company or organization, You must have the requisite authority
        to bind such company or organization to the terms and conditions of this
        Agreement. Your use of the Platform acknowledges this requisite
        authority.
      </Typography>

      <Typography variant="h4">5. Accounts</Typography>
      <Typography variant="h5">5.1 Accounts</Typography>
      <Typography variant="body1">
        Subject to the terms of this Agreement, You may be permitted to access
        certain Content and Services through the Platform without establishing
        an account on the Platform; however, access to the Platform generally
        requires that You register as a user of the Platform (a “User”) and
        establish a user account on the Platform (an “Account”). Approval of
        Your request to establish and maintain any Account will be at the sole
        discretion of Company.
      </Typography>
      <Typography variant="h5">5.2 Account IDs</Typography>
      <Typography variant="body1">
        You will be responsible for establishing and maintaining the user
        identification and password for Your Account (the “Account ID”). Each
        Account is for Your personal use and all Account IDs are personal in
        nature. Each Account ID may be used only by You alone. You may not share
        or transfer Your Account or Account ID or provide a third party with the
        right to access your Account or Account ID. You are solely responsible
        for all use of the Platform and all Content and Services through Your
        Account. You will ensure the security and confidentiality of Your
        Account ID and will notify Company immediately if Your Account ID is
        lost, stolen, or otherwise compromised. You are fully responsible for
        all liabilities and damages incurred through the use of Your Account or
        under Your Account ID (whether lawful or unlawful) and any actions taken
        through Your Account or under Your Account ID will be deemed to have
        been lawfully completed by You.
      </Typography>
      <Typography variant="h5">5.3 Account Information</Typography>
      <Typography variant="body1">
        In connection with establishing an Account, You will be asked to submit
        certain information about Yourself. You are solely responsible for the
        Account Information for Your Account. You agree that: (a) all Account
        Information You provide will be accurate, complete, and current; and (b)
        You will maintain and promptly update all Account Information as
        necessary to keep it accurate, complete, and current. You may not: (i)
        select or use an Account ID of another person with the intent to
        impersonate that person; and (ii) use an Account ID that Company, in its
        sole discretion, deems offensive.
      </Typography>
      <Typography variant="h5">5.4 Account Confirmation</Typography>
      <Typography variant="body1">
        You are solely responsible for confirming the set-up and configuration
        of Your account in all respects and for making all changes and updates
        thereto through this Agreement.
      </Typography>

      <Typography variant="h4">6. Access</Typography>
      <Typography variant="h5">6.1 To the Platform</Typography>
      <Typography variant="body1">
        You may access the Platform by using the online application (or mobile
        application, at such time as it may exist) provided by and on behalf of
        Company (each, an “Application,” and forming a part of the “Platform”
        for purposes of this Agreement). Subject to Your compliance with this
        Agreement, Company will permit You to access and use the Platform solely
        for lawful purposes and only in accordance with the terms of this
        Agreement. You are solely responsible for obtaining and maintaining all
        equipment, facilities, and connectivity required to access or use the
        Platform or Application, in each case as necessary to meet Your
        requirements based on Your particular circumstances.
      </Typography>
      <Typography variant="h5">6.2 To Applications</Typography>
      <Typography variant="body1">
        Subject to Your compliance with this Agreement and any other terms and
        conditions accompanying each Application, Company will permit You to
        access, or download and install (if applicable) Applications and operate
        those Applications solely for the purpose of using and accessing the
        Platform. You may use or install each Application only on computers,
        smart phones, tablets, or other applicable devices owned or controlled
        by You and used only for Your own personal and non-commercial purposes
        in accordance with this Agreement and any applicable terms and
        conditions accompanying the Application or otherwise provided to You by
        Company. Except as expressly set forth in the previous sentence, You are
        granted no licenses or other rights in or to any Application. You agree
        not to use, modify, reproduce, perform, display, create derivative works
        from, republish, post, transmit, participate in the transfer or sale of,
        distribute, or in any way exploit or utilize any Application other than
        as expressly permitted in this Agreement or any other agreement You are
        required to agree to before being given access to any Application.
      </Typography>
      <Typography variant="h5">6.3 To Other Users</Typography>
      <Typography variant="body1">
        The Platform may allow you to link, connect, or otherwise communicate
        with other users of the Platform, whether synchronously or
        asynchronously. By linking, connecting, or communicating with other
        Users, You are agreeing to allow those Users to communicate directly
        with You through the Platform. You agree that You are solely responsible
        for all communications between You and any other User through the
        Platform. Your extension or acceptance of a link, connection, or other
        communication with another User will serve as Your affirmative “opt in”
        to the disclosure of any of Your data or information (which may include
        Your personally identifiable information) that You provide to that other
        User. You agree that Your links, connections, or other communications
        with other Users through the Platform will not: (a) violate this
        Agreement, including, without limitation, the Privacy Policy; (b)
        violate any applicable international, federal, state, and local
        treaties, laws, rules, regulations, and ordinances (“Laws”), including,
        without limitation, any rules of professional conduct or of licensing
        bodies; (c) be libelous, defamatory, obscene, abusive, pornographic,
        sexually explicit, discriminatory, threatening in any manner,
        disruptive, or an invasion of privacy; (d) constitute an infringement,
        misappropriation or violation of the IPR (as defined below) or other
        rights of any third party; (e) be illegal in any way or advocate illegal
        activity; (f) be false, misleading or inaccurate; (g) be considered junk
        mail, spam, a part of a pyramid scheme, a disruptive commercial message
        or disruptive advertisement; or (h) cause the publication or release of
        any material non-public data or information.
      </Typography>
      <Typography variant="h5">6.4 To Content</Typography>
      <Typography variant="body1">
        You will be provided with access to a variety of content through the
        Platform, including, text, audio, video, photographs, maps,
        illustrations, graphics, and/or other data, information, and media
        (“Content”). Unless otherwise noted on the Platform, all Content is
        owned by Company and Company’s other third-parties. All Content is
        provided for informational purposes only, and You are solely responsible
        for verifying the accuracy, completeness, and applicability of all
        Content and for Your use. Subject to Your compliance with this
        Agreement, You may access the Content solely for Your own personal
        purposes in connection with Your use of the Platform. You will not, and
        will not permit any third party to: (a) alter, modify, reproduce, or
        create derivative works of any Content; (b) distribute, sell, resell,
        lend, loan, lease, license, sublicense, or transfer any Content; or (c)
        alter, obscure, or remove any copyright, trademark, or any other notices
        that are provided on or in connection with any Content. Company has not
        verified the accuracy of, and will not be responsible for any errors or
        omissions in, any Content. Without limiting the foregoing, Company will
        not be held liable to You or any other third party for any Content,
        including Your Content (as defined below), under a Federal Law called
        the Communications Decency Act or CDA, 47 U.S.C. § 230. Except as set
        forth in this Agreement, You are granted no licenses or other rights in
        or to any Content, or any IPR (as defined below) therein or related
        thereto. If You would like to use any Content in a manner not permitted
        by this Agreement, please contact Company.
      </Typography>
      <Typography variant="h5">6.5 To Third-Party Services</Typography>
      <Typography variant="body1">
        The Platform may provide You with the choice to access certain Services
        developed, provided, or maintained by other third party service
        providers (each, a “Third Party Service”). In addition to the terms of
        this Agreement, Your access to and use of any Third Party Service is
        also subject to any other agreement You may agree to before being given
        access to the Third Party Service (each, a “Third Party Service
        Agreement”). The terms of any Third Party Service Agreement (which may
        include payment of additional fees) will apply to the applicable Third
        Party Services provided under that Third Party Service Agreement in
        addition to the terms of this Agreement but will not apply to any other
        Services You may access through the Platform. COMPANY TAKES NO
        RESPONSIBILITY FOR ANY THIRD PARTY SERVICE AND YOUR ACCESS TO AND USE OF
        ANY THIRD PARTY SERVICE IS SOLELY AS SPECIFIED IN EACH APPLICABLE THIRD
        PARTY AGREEMENT.
      </Typography>
      <Typography variant="h5">6.6 To Third-Party Services</Typography>
      <Typography variant="body1">
        The Platform may provide You with certain access to data and other
        information about Users of the Platform and other third parties (“User
        Information”). The User Information may contain sensitive personal
        information regarding those Users and other third parties. You agree
        that You will only collect, use, and disclose User Information in strict
        accordance with this Agreement and the current Privacy Policy relating
        to the Platform available at (“Privacy Policy”). Except as expressly
        provided in this Agreement and the Privacy Policy, You are granted no
        licenses or rights in or to any User Information or any IPR therein or
        related thereto.
      </Typography>

      <Typography variant="h4">7. Your Content</Typography>
      <Typography variant="body1">
        You are solely responsible for all Content that You may provide or
        upload to the Platform or otherwise generate through Your use of or
        access to the Platform (“Your Content”). As between You and Company, You
        retain ownership of Your Content. Subject to any limitations included in
        the Privacy Policy, You grant Company a nonexclusive, royalty-free,
        perpetual, irrevocable, and fully sublicensable (including, without
        limitation, to Users and other third parties) right to use, copy, store,
        reproduce, modify, display, adapt, publish, translate, create derivative
        works from, distribute, and display (“Use”) Your Content for purposes of
        providing the Services to You and for use in making enhancements and
        improvements to the Platform. You represent and warrant that none of
        Your Content or the use of Your Content by Company: (1) violates this
        Agreement, the Privacy Policy, or any requirements under applicable
        Laws; (2) is libelous, defamatory, obscene, abusive, pornographic,
        sexually explicit, discriminatory, threatening in any manner,
        disruptive, or an invasion of privacy; (3) constitutes an infringement,
        misappropriation or violation of the IPR or other rights of any third
        party; (4) is illegal in any way or advocates illegal activity; (5) is
        an advertisement or solicitation of funds, goods, or services (unless
        You have entered into a separate agreement with Company); (6) is false,
        misleading, or inaccurate; or (7) is or could be considered junk mail,
        spam, a part of a pyramid scheme, a disruptive commercial message, or
        disruptive advertisement. Company is not responsible or liable for any
        deletion, correction, destruction, damage, loss, or failure to store,
        restore, or back-up any of Your Content, including any of Your Content
        Company may remove or delete without notice due to violation of this
        Section. You agree that You have all right, title, interest, and consent
        in Your Content necessary to allow Company to Use Your Content as set
        forth in the rights and licenses You grant to Company under this
        Agreement.
      </Typography>

      <Typography variant="h4">8. Platform Tokens</Typography>
      <Typography variant="body1">
        Entitlements are rights that Company licenses to you to access or use
        the Platform. Examples of Entitlements include access to digital or
        unlockable Content; additional or enhanced functionality; subscriptions;
        virtual assets; unlock keys or codes, serial codes or online
        authentication; in-game accomplishments; and virtual points, coins, or
        currencies. We refer to these virtual points, coins or currencies as
        "Platform Tokens." When you obtain Platform Tokens from us or our
        authorized partners, you receive a personal, limited, non-assignable,
        non-exclusive, revocable license to access and select the Entitlements
        that are expressly made available to you within the Platform. Platform
        Tokens have no monetary value and have no value outside of the Platform.
        Platform Tokens cannot be sold, traded, transferred, or exchanged for
        cash; they only may be redeemed for Entitlements available within the
        Platform. Platform Tokens are non-refundable, and you are not entitled
        to a refund for any unused Platform Tokens. Once you redeem Platform
        Tokens for an Entitlement, that Entitlement is not returnable,
        exchangeable, or refundable. Redeeming Entitlements through the Platform
        is solely for personal entertainment and does not create any legally
        enforceable contract between You and the Company or any Creator, and You
        do not acquire any enforceable legal rights as a result of such
        redemption.
      </Typography>

      <Typography variant="h4">9. Termination and Effect</Typography>
      <Typography variant="h5">9.1 Termination</Typography>
      <Typography variant="body1">
        This Agreement may be terminated by Company at any time, in Company’s
        sole discretion: (a) upon any breach or threatened breach by You of this
        Agreement; or (b) for any reason or no reason, upon notice to You. You
        may terminate this Agreement at any time upon notice to Company or by
        deleting Your Account as may be permitted through the Platform.
      </Typography>
      <Typography variant="h5">9.2 Effect</Typography>
      <Typography variant="body1">
        Termination of this Agreement will terminate all of Your Accounts on the
        Platform. Upon termination or expiration of this Agreement, except for
        your uncured breach of this Agreement, each of Your Accounts may be
        archived for a period of up to 60 days. Following such period, Company
        may thereafter forever delete Your Accounts and decide whether to keep
        Your Content active, delete, or archive any of Your Content in Company’s
        sole and absolute discretion. Upon termination or expiration of this
        Agreement for any reason: (a) all rights and subscriptions granted to
        You under this Agreement will terminate; (b) You will immediately cease
        all use of and access to the Platform, including all Content and
        Services; (c) You will immediately delete any Applications You have
        downloaded or installed prior to termination; and (d) You will
        immediately either return to Company or, at Company’s discretion,
        destroy any Content of Company and any other information related to this
        Agreement in Your possession or control.
      </Typography>

      <Typography variant="h4">10. Suspension</Typography>
      <Typography variant="body1">
        Without limiting Company’s right to terminate this Agreement, Company
        may also suspend Your access to Your Account and the Platform or any
        Content or Services (including Your Content), with or without notice to
        You, upon any actual, threatened, or suspected breach of this Agreement
        or applicable Law or upon any other conduct deemed by Company, in its
        sole discretion, to be inappropriate or detrimental to the Platform,
        Company, or other User or third party.
      </Typography>

      <Typography variant="h4">
        11. Platform Technology and Restrictions
      </Typography>
      <Typography variant="body1">
        The Platform, and the data, information, databases, software, hardware,
        and other technology used by or on behalf of Company to operate the
        Platform, and the structure, organization, and underlying data,
        information and software code thereof (collectively, the “Technology”),
        constitute valuable trade secrets of Company. You will not, and will not
        permit any third party to: (1) access or attempt to access the
        Technology except as expressly provided in this Agreement; (2) use the
        Technology in any unlawful manner or in any other manner that could
        damage, disable, overburden, or impair the Technology; (3) use, support,
        or develop bots, scrapers, software, scripts, crawlers, browser plugins,
        robots, or other automated methods including human-powered automation
        through link farming or any other crowd-sourced methods to access,
        scrape, or copy any portion of the Technology, or to add or download
        data, or send or redirect information or messages; (4) alter, modify,
        reproduce, or create derivative works of the Technology; (5) distribute,
        sell, resell, lend, loan, lease, license, sublicense, or transfer any of
        Your rights to access or use the Technology or otherwise make the
        Technology available to any third party; (6) reverse engineer,
        disassemble, decompile, or otherwise attempt to derive the method of
        operation of the Technology; (7) attempt to circumvent or overcome any
        technological protection measures intended to restrict access to any
        portion of the Technology; (8) monitor the availability, performance, or
        functionality of the Technology; (9) interfere with the operation or
        hosting of the Technology; (10) capture screenshots or make impressions,
        reproductions, distributions, or publications of any content contained
        within the Technology, such as images, logos, texts, recommendations,
        comments, and any other materials; or (11) perform any other action
        intended to circumvent the controls or access mechanisms within the
        Technology.
      </Typography>

      <Typography variant="h4">12. Ownership</Typography>
      <Typography variant="body1">
        Company retains all right, title, and interest, including, without
        limitation, all IPR (as defined below), in and to the Technology and any
        additions, improvements, updates, and modifications thereto. You receive
        no ownership interest in or to the Technology and You are not granted
        any right or license to use the Technology itself, apart from Your
        ability to access the Platform, under this Agreement. The Company name,
        logo, and all product and service names associated with the Platform are
        trademarks of Company and its providers and You are granted no right or
        license to use them. For purposes of this Agreement, “IPR” means all
        intellectual property rights, proprietary rights, rights of publicity,
        rights of privacy, and any and all other legal rights protecting data,
        information, or intangible property throughout the world, including,
        without limitation, any and all copyrights, trademarks, service marks,
        trade secrets, patent rights, moral rights, sui generis rights in
        databases, and contract rights.
      </Typography>

      <Typography variant="h4">13. Data Protection</Typography>
      <Typography variant="h5">13.1</Typography>
      <Typography variant="body1">
        In this Agreement, the following terms shall have the meanings given in
        Regulation 2016/679 or under UK-GDPR (GDPR, together with any relevant
        national implementing or supplement legislation, “Applicable Data
        Protection Law,“ “controller,“ “processor,“ “personal data,“ “data
        subject,” and “processing” (including “process“)).
      </Typography>
      <Typography variant="h5">13.2</Typography>
      <Typography variant="body1">
        You (the controller) appoint Company as a processor to process any
        personal data that is part of Your Content (the “Personal Data“) solely
        as necessary to perform its obligations under this Agreement and
        strictly in accordance with the instructions of You (the “Permitted
        Purpose“)
      </Typography>
      <Typography variant="h5">13.3</Typography>
      <Typography variant="body1">
        You acknowledge and agree that the Personal Data will be Processed
        within (and will be deemed to have been delivered to) the United States
        and that third party subprocessors agreed may be located (and may
        process Personal Data) outside the European Economic Area (“EEA”). If
        You are established in the EEA, the parties shall comply with the EU
        Commission’s “Controller-to Processor Model Clauses” (annexed to EU
        Commission Decision 2010/87/EU) which are deemed incorporated into this
        Agreement (completed consistently with this Agreement with You as “data
        exporter” and Company as “data importer.”
      </Typography>
      <Typography variant="h5">13.4</Typography>
      <Typography variant="body1">
        Company shall provide reasonable cooperation to You (at Your expense) in
        connection with any data protection impact assessment that You may be
        required to undertake under Applicable Data Protection Law.
      </Typography>
      <Typography variant="h5">13.5</Typography>
      <Typography variant="body1">
        Company shall (at Your expense) provide all reasonable and timely
        assistance to You to enable You to respond to: (i) any request from a
        data subject to exercise any of its rights under Applicable Data
        Protection Law; and (ii) any other correspondence, enquiry, or complaint
        received from a data subject, regulator, or other third party in
        connection with the processing of the Personal Data.
      </Typography>

      <Typography variant="h4">14. Representations and Warranties</Typography>
      <Typography variant="h5">14.1 To Company</Typography>
      <Typography variant="body1">
        You represent and warrant to Company that: (a) You have the legal right
        and authority to enter into this Agreement; (b) this Agreement forms a
        binding legal obligation on Your behalf; (c) You have the legal right
        and authority to perform Your obligations under this Agreement and to
        grant the rights and licenses described in this Agreement; (d) your use
        of the Platform will be in strict accordance with this Agreement, the
        Privacy Policy, and all applicable laws and regulations (including
        without limitation any local laws or regulations in Your country, state,
        city, or other governmental area, regarding online conduct and
        acceptable content); (e) Your Content will comply with all applicable
        state and federal laws, rules, and regulations and not violate any
        intellectual property rights or any person’s right of privacy or
        publicity; and (f) You are not located in a country that is subject to a
        U.S. Government embargo or that has been designated by the U.S.
        Government as a “terrorist supporting” country, and You are not listed
        on any U.S. Government list of prohibited or restricted parties
      </Typography>
      <Typography variant="h5">14.2 Compliance with Laws</Typography>
      <Typography variant="body1">
        You acknowledge that (a) the Platform is a general-purpose service and
        is not specifically designed to facilitate compliance with any specific
        Law; and (b) You will access and use the Platform, Services, and Content
        in compliance with all applicable Laws. Company is not responsible for
        notifying You of any such Law, enabling Your compliance with any such
        Law, or for Your failure to comply. You represent and warrant to Company
        that Your use of and access to the Platform, including any Content, will
        comply with all applicable Laws and will not cause Company itself or any
        other User to violate any applicable Laws, including, without
        limitation, the Health Insurance Portability and Accountability Act of
        1996 or the Health Information Technology for Economic and Clinical
        Health (HITECH) Act (enacted as part of the American Recovery and
        Reinvestment Act of 2009).
      </Typography>

      <Typography variant="h4">15. Disclaimers</Typography>
      <Typography variant="h5">15.1 No Warranties</Typography>
      <Typography variant="body1">
        THE PLATFORM, CONTENT, AND SERVICES ARE PROVIDED “AS IS” AND “AS
        AVAILABLE.” COMPANY AND ITS AFFILIATES DO NOT WARRANT OR GUARANTEE THE
        ACCURACY, COMPLETENESS, ADEQUACY, OR CURRENCY OF THE PLATFORM OR ANY
        CONTENT OR SERVICES AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE
        EXPRESSED IN THE CONTENT OR OTHER DATA, INFORMATION, OR CONTENT THAT MAY
        BE PROVIDED THROUGH THE PLATFORM. COMPANY AND ITS AFFILIATES EXPRESSLY
        DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH
        REGARD TO THE PLATFORM, CONTENT, SERVICES, AND OTHER SUBJECT MATTER OF
        THIS AGREEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
        WITHOUT LIMITATION, ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE,
        MERCHANTABILITY, TITLE, OR NON-INFRINGEMENT. NO ORAL OR WRITTEN
        INFORMATION OR ADVICE GIVEN BY COMPANY, ITS EMPLOYEES, AFFILIATES, OR
        AGENTS WILL INCREASE THE SCOPE OF, OR CREATE ANY NEW WARRANTIES IN
        ADDITION TO, THE WARRANTIES EXPRESSLY SET FORTH IN THIS SECTION.
      </Typography>
      <Typography variant="h5">15.2 No Creator Supervision</Typography>
      <Typography variant="body1">
        Company does not supervise, direct, control, or evaluate Creators or
        their Content in any way and is not responsible for any Entitlements
        issued by Creators. Company is not responsible for the qualifications,
        backgrounds, or willingness of a Creator to create a satisfactory Xureal
        Experience.
      </Typography>

      <Typography variant="h4">16. Indemnity</Typography>
      <Typography variant="body1">
        You hereby agree to indemnify, defend, and hold harmless Company and its
        officers, directors, shareholders, affiliates, employees, agents,
        contractors, assigns, Users, customers, providers, licensees, and
        successors in interest (“Indemnified Parties”) from any and against all
        claims, losses, liabilities, damages, fees, expenses, and costs
        (including attorneys’ fees, court costs, damage awards, and settlement
        amounts) that result from any and against all claim or allegation
        against any Indemnified Party arising in any manner from: (1) Your
        access to or use of the Platform, including any Content or Services; (2)
        Your content or any access to or use thereof; (3) any access to or use
        of Your Content by any other users; (4) Your collection, use, and
        disclosure of any User Information, and (5) Your breach of any provision
        of this Agreement, including any representation or warranty. Company
        will provide You with notice of any such claim or allegation, and
        Company will have the right to participate in the defense of any such
        claim at its expense.
      </Typography>

      <Typography variant="h4">17. Limitation on Liability</Typography>
      <Typography variant="body1">
        COMPANY WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER ANY
        THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
        (INCLUDING NEGLIGENCE OR OTHERWISE), ARISING IN CONNECTION WITH OR OUT
        OF THE USE OF THE PLATFORM, CONTENT, OR SERVICES, EVEN IF COMPANY HAS
        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING, WITHOUT
        LIMITATION, ANY LOSS OF YOUR CONTENT, OPPORTUNITY, REVENUES, PROFITS OR
        REPUTATION, BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE CONTENT,
        GOODS OR SERVICES. COMPANY’S TOTAL CUMULATIVE LIABILITY IN CONNECTION
        WITH THIS AGREEMENT AND ALL CONTENT AND SERVICES PROVIDED UNDER THIS
        AGREEMENT OR THROUGH THE PLATFORM, WHETHER IN CONTRACT OR TORT OR
        OTHERWISE, WILL NOT EXCEED $10 OR, IN THE CASE OF YOU HAVING PAID FEES
        TO COMPANY TO ACCESS THE PLATFORM OR ANY CONTENT OR SERVICES, THE
        AMOUNTS PAID BY YOU TO COMPANY IN THE THREE (3) MONTH PERIOD PRECEDING
        THE EVENTS GIVING RISE TO ANY LIABILITY. YOU AGREE THAT COMPANY WOULD
        NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS ON ITS
        LIABILITY. IN JURISDICTIONS WHERE LIMITATION OF LIABILITY FOR
        CONSEQUENTIAL OR INCIDENTAL DAMAGES IS NOT PERMITTED, COMPANY’S
        LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
      </Typography>

      <Typography variant="h4">18. Data Privacy</Typography>
      <Typography variant="body1">
        You expressly consent to the use and disclosure of your information and
        other data and information as described in the Privacy Policy.
        Notwithstanding anything in the Privacy Policy, Company will have the
        right to collect, extract, compile, synthesize, and analyze
        non-personally identifiable data or information (data or information
        that does not identify an entity or natural person as the source
        thereof) resulting from Your access to and use of the Platform,
        Services, or Content. To the extent any such non-personally identifiable
        data or information is collected or generated by Company, the data and
        information will be solely owned by Company and may be used by Company
        for any lawful business purpose without a duty of accounting to You,
        provided that the data and information is used only in an aggregated
        form, without directly identifying You or any other entity or natural
        person as a source thereof.
      </Typography>

      <Typography variant="h4">19. Claims of Infringement</Typography>
      <Typography variant="body1">
        Company respects Your copyrights and other intellectual property rights
        and those of other third parties. If You believe in good faith that Your
        copyrighted work has been reproduced on the Platform without Your
        authorization in a way that constitutes copyright infringement, You may
        submit a notification pursuant to the Digital Millennium Copyright Act
        ("DMCA") by notifying our designated copyright agent by mail as follows:
        <br />
        <br />
        Elevux LLC <br />
        Attn: Copyright Infringement Agent <br />
        2474 East Michigan St.
        <br />
        Orlando, FL 32806 <br />
        <br />
        If a copyright owner is under the age of 13, a DMCA notice must be
        submitted by a parent or other representative of the rightsholder,
        rather than directly by an under-13 individual. You acknowledge that if
        you fail to comply with all of the requirements of this Section, your
        DMCA notice may not be valid.
        <br />
        <br />
        You must provide the following information in writing (see 17 U.S.C
        512(c)(3) for further detail) to Company’s Copyright Infringement Agent:
        (1) the identity of the infringed work, and of the allegedly infringing
        work; (2) Your name, address, daytime phone number, and email address,
        if available; (3) a statement that You have a good-faith belief that the
        use of the copyrighted work is not authorized by the owner, his or her
        agent, or the law; (4) a statement that the information in the
        notification is accurate and, under penalty of perjury, that You are
        authorized to act on behalf of the owner; and (5) Your electronic or
        physical signature.
      </Typography>

      <Typography variant="h4">20. Disputes</Typography>
      <Typography variant="body1">
        Except as otherwise provided below, the parties will attempt to resolve
        all disputes, controversies, or claims arising under, out of, or
        relating to this Agreement, including the formation, validity, binding
        effect, interpretation, performance, breach, or termination, of this
        Agreement and the arbitrability of the issues submitted to arbitration
        hereunder and non-contractual claims relating to this Agreement (each, a
        “Dispute”), in accordance with the procedures set forth in this Section.
        If any Dispute cannot be resolved through negotiations between the
        parties within sixty (60) days of notice from one party to the other of
        the Dispute, such Dispute will be finally settled through binding
        arbitration under the arbitration rules of the American Arbitration
        Association (“AAA”) then in effect (the “Rules”). Either party may
        commence the arbitration by delivering a request for arbitration as
        specified in the Rules. The arbitration will be conducted before a sole
        neutral arbitrator selected by agreement of the parties. If the parties
        cannot agree on the appointment of a single arbitrator within thirty
        (30) days (the “Initial Period”) after either party to this Agreement
        delivers a request for arbitration, a neutral arbitrator will be
        selected as provided in the Rules. The arbitration will be conducted
        exclusively in the English language at a site specified by Company in
        Orange County, Florida U.S.A. The award of the arbitrator will be the
        exclusive remedy of the parties for all claims, counterclaims, issues,
        or accountings presented or pleaded to the arbitrator. The award of the
        arbitrators will require payment of the costs, fees, and expenses
        incurred by the prevailing party in any such arbitration by the
        non-prevailing party. Judgment upon the award may be entered in any
        court or governmental body having jurisdiction thereof. Any additional
        costs, fees, or expenses incurred in enforcing the award may be charged
        against the party that resists its enforcement.
      </Typography>

      <Typography variant="h4">21. NO CLASS ACTIONS: </Typography>
      <Typography variant="body1" style={{fontWeight: '600'}}>
          You may only bring individual claims. Under no circumstances are you
          allowed to bring a claim as a plaintiff or a class member in a class.
          Class action lawsuits, class-wide arbitrations, private
          attorney-general actions, and any other proceedings where someone acts
          in a representative capacity are not allowed. Any combining of
          individual proceedings must have the consent of all parties.
      </Typography>

      <Typography variant="h4">22. Governing Law and Venue</Typography>
      <Typography variant="body1">
        The interpretation of the rights and obligations of the parties under
        this Agreement, including, to the extent applicable, any negotiations,
        arbitrations or other proceedings hereunder, will be governed in all
        respects exclusively by the laws of the State of Florida U.S.A. as such
        laws apply to contracts between Florida residents performed entirely
        within Florida without regard to the conflict of laws provisions
        thereof. Subject to Section 20 (Disputes), each party will bring any
        action or proceeding arising from or relating to this Agreement
        exclusively in a federal court in the Middle District of Florida, U.S.A.
        or in state court in Orange County, Florida U.S.A., and You irrevocably
        submit to the personal jurisdiction and venue of any such courts in any
        such action or proceeding brought in such courts by Company.
      </Typography>

      <Typography variant="h4">23. Notices</Typography>
      <Typography variant="body1">
        Unless otherwise specified in this Agreement, any notices required or
        allowed under this Agreement will be provided to Company by postal mail
        to the address for Company listed on the Platform. Company may provide
        You with any notices required or allowed under this Agreement by sending
        You an email to any email address You provide to Company in connection
        with Your Account, provided that in the case of any notice applicable
        both to You and other Users of the Platform, Company may instead provide
        such notice by posting on the Platform. Notices provided to Company will
        be deemed given when actually received by Company. Notice provided to
        You will be deemed given twenty-four (24) hours after posting to the
        Platform or sending via e-mail, unless (as to e-mail) the sending party
        is notified that the e-mail address is invalid.
      </Typography>

      <Typography variant="h4">24. Linked Sites</Typography>
      <Typography variant="body1">
        The Platform may contain links to third-party sites or Content that are
        not under the control of Company. If you access a third-party site or
        Content from the Platform, then you do so at your own risk and Company
        is not responsible for any content on any linked site or content. You
        may not frame or otherwise incorporate into another site the content or
        other materials on the Platform without prior written consent.
      </Typography>

      <Typography variant="h4">25. Miscellaneous</Typography>
      <Typography variant="h5">25.1</Typography>
      <Typography variant="body1">
        This Agreement constitutes the entire agreement of the parties and
        supersedes all prior representations, proposals, discussions, and
        communications, whether oral or in writing. This Agreement may be
        modified only by a written instrument executed by both parties.
      </Typography>
      <Typography variant="h5">25.2</Typography>
      <Typography variant="body1">
        Neither this Agreement nor any rights or obligations of You hereunder
        may be assigned or transferred by You (in whole or in part and including
        by sale, merger, consolidation, or other operation of law) without the
        prior written approval of Company. Any assignment in violation of the
        foregoing will be null and void. Company may assign this Agreement to
        any party that assumes Company’s obligations hereunder.
      </Typography>
      <Typography variant="h5">25.3</Typography>
      <Typography variant="body1">
        This Agreement shall be binding on and inure to the benefit of the
        parties to this Agreement and their respective heirs, representatives,
        successors, and permitted assigns.
      </Typography>
      <Typography variant="h5">25.4</Typography>
      <Typography variant="body1">
        No failure or delay by either party in exercising any power or right
        under this Agreement shall operate as a waiver, nor does any single or
        partial exercise of any other power or right preclude any other or
        further exercise, or the exercise of any other power or right.
      </Typography>
      <Typography variant="h5">25.5</Typography>
      <Typography variant="body1">
        You agree that this Agreement is being accepted by a duly authorized
        representative authorized to bind You to the terms of this Agreement,
        and no consent from any third party is required. You acknowledge and
        represent that You have read this Agreement, understand it, have had
        adequate opportunity to consult with legal counsel with respect to it,
        and agree to be bound by all of its terms.
      </Typography>
      <Typography variant="h5">25.6</Typography>
      <Typography variant="body1">
        In the event that Company is required to bring an action in connection
        with the performance, breach, or interpretation of this Agreement, the
        prevailing party in that action shall be entitled to recover from the
        losing party all reasonable costs and expenses of litigation, including
        reasonable attorneys’ fees (and charges attributed to law clerks and
        paralegals), court costs, costs of investigation, accounting, and other
        costs reasonably related to the litigation in both the trial and
        appellate courts, in addition to any other relief to which the
        prevailing party may be entitled.
      </Typography>
      <Typography variant="h5">25.7</Typography>
      <Typography variant="body1">
        With the exception of obligations to pay any sums of money due under
        this Agreement, neither party shall be liable to the other for any delay
        in the performance of any of its obligations hereunder during the period
        of delay due to any cause beyond such party’s reasonable control or due
        to acts of god, acts of civil or military authorities, fires, labor
        disturbances, floods, pandemics or epidemics, governmental rules or
        regulations, war, riot, delays in transportation, shortages of raw
        materials, power outages, or unauthorized hacking on or through the
        internet.
      </Typography>
      <Typography variant="h5">25.8</Typography>
      <Typography variant="body1">
        If any term, covenant, condition, or provision of this Agreement, or the
        application of this Agreement to any person or circumstance, shall at
        any time or to any extent be invalid or unenforceable, the remainder of
        this Agreement, or the application of the term or provision to persons
        or circumstances other than those to which it is held invalid or
        unenforceable, shall not be affected by the invalidity or
        unenforceability, and the terms, covenants, and conditions of this
        Agreement shall be valid and enforced to the full extent permitted by
        law unless to do so would violate the objective intent of the parties.
      </Typography>
      <Typography variant="h5">25.9</Typography>
      <Typography variant="body1">
        The following Sections survive any expiration or termination of this
        Agreement: 1 (Definitions), 8 (Termination and Effect), 12 (Ownership),
        13 (Representations and Warranties), 15 (Disclaimers), 16 (Indemnity),
        17 (Limitation on Liability), 18 (Data Privacy), 20 (Disputes), 21 (No
        Class Actions), 22 (Governing Law and Venue), 23 (Notices), and 25
        (Miscellaneous).
      </Typography>
      <Typography variant="h5">25.10</Typography>
      <Typography variant="body1">
        The headings of the articles, paragraphs, and sections contained in this
        Agreement are for convenience only and do not define, limit, or construe
        the contents of such articles, paragraphs, and sections.
      </Typography>
    </div>
  );
};

export default TermsOfService;
