import React from "react";
import { createTheme } from "@mui/material/styles";
import { colorPalette } from "./App.styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }

  interface PaletteColor {
    white?: string;
    black?: string;
  }

  interface SimplePaletteColorOptions {
    white?: string;
    black?: string;
  }
}

let theme = createTheme({
  palette: {
    // BLUES
    primary: {
      main: "#2f50ff",
      dark: "#241b6c",
      light: "#DDE6FF",
    },
    // REDS
    secondary: {
      main: "#C1503A",
      dark: "#A2422F",
      light: "#FFE2E4",
    },
    // GREENS
    success: {
      main: "#105A37",
      dark: "#0D4230",
      light: "#C6F2E5",
    },
    // YELLOWS
    warning: {
      main: "#E6A128",
      dark: "#B76d10",
      light: "#F9B742",
    },
    neutral: {
      main: "#9794AB",
      dark: "#1D1D20",
      light: "#F9F9F9",
      white: "#FFFFFF",
      black: "#000000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768 + 1,
      md: 1024 + 1,
      lg: 1440 + 1,
      xl: 1536 + 1,
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontFamily: "inherit",
      fontSize: 18,
      "@media (max-width:1024px)": {
        fontSize: "17px",
      },

      "@media (max-width:490px)": {
        fontSize: "16px",
      },
    },
    h1: {
      fontSize: "56px",
      lineHeight: "61px",
      fontWeight: 500,
      letterSpacing: "-0.84px",
      display: 'flex',
      alignItems: 'center',
      width: "100%",
      fontFamily: "Gordita",
      "@media (max-width:1024px)": {
        fontSize: "46px",
        lineHeight: "46px",
      },
      "@media (max-width:768px)": {
        fontSize: "30px",
        lineHeight: "36px",
        maxWidth: "80% !important",
      },
      "@media (max-width:490px)": {
        maxWidth: "80% !important",
      },
      "@media (max-width:450px)": {
        maxWidth: "100% !important",
      },
    },
    h2: {
      fontSize: "56px",
      fontWeight: "700",
      lineHeight: "60px",
      letterSpacing: "0.31px",
      width: "100%",
      fontFamily: "inherit",
      "@media (max-width:1024px)": {
        fontSize: "36px",
        lineHeight: "36px",
      },
      "@media (max-width:768px)": {
        fontSize: "32px",
        lineHeight: "35px",
      },
      "@media (max-width:490px)": {
        fontSize: "27px",
        lineHeight: "30px",
      },
    },
    h3: {
      fontSize: "55px",
      color: colorPalette.neutral.black,
      fontWeight: "700",
      lineHeight: "57px",
      letterSpacing: "-0.77px",
      width: "100%",
      fontFamily: "Gordita",
      "@media (max-width:1024px)": {
        fontSize: "40px",
        lineHeight: "48px",
      },
      "@media (max-width:768px)": {
        fontSize: "28px",
        lineHeight: "39px",
        fonrWeight: "normal !important"
      },
      "@media (max-width:490px)": {
        fontSize: "27px",
      },
    },
    h4: {
      color: colorPalette.neutral.black,
      fontSize: "36px",
      fontWeight: "600",
      lineHeight: "42px",
      letterSpacing: "0.2px",
      fontFamily: "Gordita",
      width: "100%",
      marginBottom: "16px",
      "@media (max-width:1024px)": {
        fontSize: "26px",
        lineHeight: "29px",
      },
      "@media (max-width:768px)": {
        fontSize: "24px",
        lineHeight: "29px",
      },
      "@media (max-width:490px)": {
        fontSize: "20px",
        lineHeight: "25px",
      },
    },
    h5: {
      fontSize: "22px",
      fontFamily: "Gordita",
      fontWeight: "500",
      lineHeight: "28px",
      letterSpacing: "0.12px",
      width: "100%",
      marginBottom: "16px",
      "@media (max-width:1024px)": {
        fontSize: "20px",
        lineHeight: "24px",
      },
      "@media (max-width:768px)": {
        fontSize: "18px",
        lineHeight: "22px",
      },
      "@media (max-width:490px)": {
        fontSize: "16px",
        lineHeight: "20px",
      },
    },
    body1: {
      fontFamily: "Gordita",
      color: colorPalette.neutral.black,
      fontSize: "18px",
      fontWeight: "400",
      letterSpacing: "0.1px",
      lineHeight: "24px",
      width: "100%",
      marginBottom: "48px",
      "@media (max-width:1024px)": {
        fontSize: "17px",
        lineHeight: "22px",
      },
      "@media (max-width:490px)": {
        fontSize: "16px",
        lineHeight: "21px",
      },
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          height: "54px",
          backgroundColor: "#ffffff",
          borderRadius: "34px",
          marginBottom: "11px",
          "&.full-width": {
            width: "100%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        },
      },
    },
    // TextField and Input styling
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '100%'
        },
        notchedOutline: {
          border: 'none'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          border: "1px solid #E4E3E9",
          fontWeight: 500,
          borderRadius: "32px",
          "&.Mui-focused": {
            border: "1px solid #3B2EC9",
          },
          "&::before": {
            border: "none !important",
            "&.Mui-focused": {
              border: "none",
            },
          },
          "&:after": {
            border: "none",
          },
          "&.MuiInputBase-multiline": {
            marginBottom: "0",
          },
        },
        input: {
          paddingTop: "15px",
          paddingBottom: "15.5px",
          paddingLeft: "32px",
          fontSize: "16px",
          lineHieght: "22px",
          borderRadius: '32px'
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: "#777e91",
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, 4px) scale(0.75)",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          width: 'calc(100% - 2px)',
          margin: 'auto !important',
          position: 'absolute',
          left: '12px',
          right: '0',
          '&.Mui-error': {
            position: 'absolute',
            top: '100%',
            left: '24px',
            // bottom: 'auto',
            color: 'red',
            '&:after': {
              display: 'none !important'
            }
          },
          '&:focus-within': {
            border: `1px solid red`
          }
        }
      }
    },
    // Menu Styling
    MuiMenu: {
      styleOverrides: {
        root: {
          pointerEvents: 'none',
          fontFamily: "inherit",
          "& .MuiPaper-root": {
            pointerEvents: 'auto',
            borderTop: "1px solid #E4DECE",
            left: "0 !important",
            height: "auto",
            width: "100vw",
            right: '0 !important',
            margin: '0 auto',
            backgroundColor: "#FFFFFF",
            maxWidth: 'unset',
            borderRadius: "0",
            boxShadow: "none",
            "& .MuiMenu-list": {
              padding: "0",
            },
          },
          "& .MuiList-root": {
            maxWidth: "1300px",
            margin: "0 auto"
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            top: '88px',
            pointerEvents: 'auto',
          }
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          marginBottom: "0",
          "&.page-choices": {
            flexDirection: "column",
            maxWidth: "405px",
            padding: "48px 36px 89px 71px",
            borderRight: "1px solid #e4e3e9",
            alignItems: "flex-start",
            marginBottom: "0",
            cursor: "auto",
            "&:hover": {
              backgroundColor: "#ffffff"
            },
            "&:last-child": {
              borderRight: "none",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#FFFFFF",
            },
            "@media (max-width: 900px)": {
              padding: "48px 26px 0",
              maxWidth: "360px"
            },
          },
          "&.inner-page-options": {
            flex: "1",
            padding: "48px 32px",
            justifyContent: "flex-start",
            flexDirection: "column"
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: "85%",
          backgroundColor: "#FFFFFF",
          padding: '0 24px'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
        //   "& .MuiButton-endIcon": {
        //     transition: "0.35s ease",
        //   },
          "&:hover": {
            backgroundColor: "#ffffff",
            // "& .MuiButton-endIcon": {
            //   transform: "translateX(10px)",
            //   transition: ".25s",
            // },
          },
        //   "& .MuiTouchRipple-root": {
        //     display: "none",
        //   },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "27px",
          letterSpacing: "-0.22px",
          fontWeight: "500",
          padding: "12px 0",
          fontFamily: "Gordita",
          "&:active": {
            color: "inherit",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&#mobile-sub-menu": {
            height: "auto",
            width: "100%",
            backgroundColor: colorPalette.primary.main,
            color: "#ffffff",
            fontSize: "17px",
            lineHeight: "20px",
            letterSpacing: "-0.24px",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.sub-menu-accordion": {
            height: "100%",
            width: "100%",
            display: "flex",
          },
        },
        content: {
          justifyContent: "center",
          flexGrow: "0",
          marginRight: "8px",
          "&.Mui-expanded": {
            marginRight: "8px",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          "&.sub-menu-accordion": {
            backgroundColor: colorPalette.primary.main,
            paddingBottom: "45px",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          "@media (max-width: 827px)": {
            backdropFilter: "blur(4px)"
          }
          
        }
      }
    }
  },
});

export default theme;
