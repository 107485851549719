import React from "react";
import LayoutStyles from "../App.styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// award logos
import tellyImg from "../assets/images/about/telly.png";
import shortyImg from "../assets/images/about/shorty.png";
import gartnerImg from "../assets/images/about/gartner.png";
import cmaImg from "../assets/images/about/cma.png";

const Awards = ({ color }: { color?: string }) => {
  return (
    <>
      <LayoutStyles.ImageAndTextBox.Tag style={{width: '100%', justifyContent: 'center'}}>AWARDS</LayoutStyles.ImageAndTextBox.Tag>
      <LayoutStyles.Awards.Headline>
        Platform Awards and Recognition
      </LayoutStyles.Awards.Headline>
      <LayoutStyles.Awards.AwardsArea style={{ padding: "0 10px" }}>
        <LayoutStyles.Awards.AwardsArea>
          <LayoutStyles.Awards.AwardsBox $color={color}>
            <img
              src={tellyImg}
              style={{ margin: "40px 0 40px 0", height: 114, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Craft Use of VR - Silver
            </LayoutStyles.Awards.AwardTitle>
            <LayoutStyles.Awards.AwardTitle>
              Craft Use of VR - Bronze
            </LayoutStyles.Awards.AwardTitle>
            <LayoutStyles.Awards.AwardTitle>
              {"General Virtual Events &"}
              {"\tExperiences - Bronze"}
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
          <LayoutStyles.Awards.AwardsBox $color={color}>
            <img
              src={shortyImg}
              style={{ margin: "40px 0 40px 0", height: 128, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Community or Employee Engagement
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
        </LayoutStyles.Awards.AwardsArea>
        <LayoutStyles.Awards.AwardsArea>
          <LayoutStyles.Awards.AwardsBox $color={color}>
            <img
              src={gartnerImg}
              style={{ margin: "40px 0 40px 0", height: 114, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Excellence in Employee Engagement
            </LayoutStyles.Awards.AwardTitle>
            <LayoutStyles.Awards.AwardTitle>
              Most Innovative
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
          <LayoutStyles.Awards.AwardsBox $color={color}>
            <img
              src={cmaImg}
              style={{ margin: "40px 0 40px 0", height: 114, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Best Use of Technology as Part of Content Marketing Program
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
        </LayoutStyles.Awards.AwardsArea>
      </LayoutStyles.Awards.AwardsArea>
    </>
  );
};

export const AwardsMobile = ({ color }: { color?: string }) => {
  return (
    <>
      <LayoutStyles.Awards.Headline>
        Platform Awards and Recognition
      </LayoutStyles.Awards.Headline>
      <Carousel
        showArrows={false}
        width={"80%"}
        showStatus={false}
        renderIndicator={(clickHandler, isSelected) => (
          <Indicator isSelected={isSelected} />
        )}
      >
        <div style={{ width: "100vw", height: 400, margin: "0 -30vw 0 0" }}>
          <LayoutStyles.Awards.AwardsBox
            style={{ margin: "0 auto" }}
            $color={color}
          >
            <img
              src={tellyImg}
              style={{ margin: "40px 0 40px 0", height: 114, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Craft Use of VR - Silver
            </LayoutStyles.Awards.AwardTitle>
            <LayoutStyles.Awards.AwardTitle>
              Craft Use of VR - Bronze
            </LayoutStyles.Awards.AwardTitle>
            <LayoutStyles.Awards.AwardTitle>
              {"General Virtual Events &"}
              {"\tExperiences - Bronze"}
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
        </div>
        <div style={{ width: "100vw", height: 400, margin: "0 auto" }}>
          <LayoutStyles.Awards.AwardsBox
            style={{ margin: "0 auto" }}
            $color={color}
          >
            <img
              src={shortyImg}
              style={{ margin: "40px 0 40px 0", height: 128, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Community or Employee Engagement
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
        </div>

        <div style={{ width: "100vw", height: 400, margin: "0 auto" }}>
          <LayoutStyles.Awards.AwardsBox
            style={{ margin: "0 auto" }}
            $color={color}
          >
            <img
              src={gartnerImg}
              style={{ margin: "40px 0 40px 0", height: 114, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Excellence in Employee Engagement
            </LayoutStyles.Awards.AwardTitle>
            <LayoutStyles.Awards.AwardTitle>
              Most Innovative
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
        </div>

        <div style={{ width: "100vw", height: 400, margin: "0 auto" }}>
          <LayoutStyles.Awards.AwardsBox
            style={{ margin: "0 auto" }}
            $color={color}
          >
            <img
              src={cmaImg}
              style={{ margin: "40px 0 40px 0", height: 114, width: "auto" }}
            />
            <LayoutStyles.Awards.AwardTitle>
              Best Use of Technology as Part of Content Marketing Program
            </LayoutStyles.Awards.AwardTitle>
          </LayoutStyles.Awards.AwardsBox>
        </div>
      </Carousel>
    </>
  );
};

const Indicator = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <div
      style={{
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: isSelected ? "#2F50FF" : "#E4E3E9",
        display: "inline-block",
        margin: "0px 8px 0px 8px",
        position: "relative",
        top: 24,
      }}
    ></div>
  );
};

export default Awards;
