import React, {
  createContext,
  useState,
  useContext,
  useRef,
  Component,
  useEffect,
} from "react";
import SwiperCore from "swiper";
import data from '../data.json';
import axios from "axios";


interface ContextProps {
  children: React.ReactNode;
}

export type Blog = {
  archivedAt: number,
  archivedInDashboard: boolean,
  attachedStylesheets: any[],
  authorName: string,
  blogAuthorId: string,
  categoryId: number,
  conetentGroupId: string,
  contentTypeCategory: number,
  created: string,
  createdById: string,
  currentState: string,
  currentlyPublished: boolean,
  domain: string,
  enableGoogleAmpOutputOverride: boolean,
  featuredImage: string,
  featuredImageAltText: string,
  htmlTitle: string,
  id: string,
  language: string,
  layoutSections: any,
  metaDescription: string,
  name: string,
  postBody: string,
  postSummary: string,
  publicAccessRules: any[],
  publicAccessRulesEnabled: boolean,
  publishDate: string,
  rssBody: string,
  rssSummary: string,
  slug: string,
  state: string,
  tagIds: string[],
  translations: any,
  updated: string,
  updatedById: string,
  url: string,
  useFeaturedImage: boolean,
  widgetContainers: any,
  widgets: any
}

type AppContext = {
  slideOpen: boolean;
  toggleSlideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentSlide: "cms" | "analytics" | "gamification" | "";
  setCurrentSlide: React.Dispatch<React.SetStateAction<string>>;
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
  currentTab: 0 | 1 | 2;
  setCurrentTab: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
  tabRefs: React.MutableRefObject<(HTMLElement | null)[]>;
  scrollingDisabled: boolean;
  setScrollingDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  fullScreenOpen: boolean;
  toggleFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  currentEnvironment: number;
  setCurrentEnv: React.Dispatch<React.SetStateAction<number>>;
  swiper: SwiperCore;
  setSwiper: React.Dispatch<React.SetStateAction<SwiperCore>>;
  showVideo: boolean;
  setShowVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setShowProduct: React.Dispatch<React.SetStateAction<boolean>>;
  showProduct: boolean;
  homeHeroOver: boolean;
  setHomeHeroOver: React.Dispatch<React.SetStateAction<boolean>>;
  setShowScheduler: React.Dispatch<React.SetStateAction<boolean>>;
  showScheduler: boolean;
  setVideoURL: React.Dispatch<React.SetStateAction<string>>;
  videoURL: string;
  activeBlog: Blog;
  setActiveBlog: React.Dispatch<React.SetStateAction<Blog>>;
  blogArray: Blog[];
};
export const AppCtx = createContext<any>({});

const ApplicationProvider: React.FC<ContextProps> = ({ children }) => {
  
  const [slideOpen, toggleSlideOpen] = useState(false);
  // This is the current version of the sliding panel Component
  const [currentSlide, setCurrentSlide] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [showProduct, setShowProduct] = useState(false);
  // This is the current index of the active Swiper slide
  const [currentEnvironment, setCurrentEnv] = useState();
  const [fullScreenOpen, toggleFullScreen] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const tabRefs = useRef<Array<HTMLElement | null>>([]);
  const [scrollingDisabled, setScrollingDisabled] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [homeHeroOver, setHomeHeroOver] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [blogArray, setBlogArray] = useState<Blog[]>([]);
  const [activeBlog, setActiveBlog] = useState<Blog>(blogArray[0]); 

  const [currentPage, setCurrentPage] = useState(
    window.location.pathname.substring(1)
  );

  useEffect(() => {
    axios.get('https://portal.xureal.com/promo-api/hubspot/blogs')
    .then((res: any) => {
      console.log('blog results', res)
      setBlogArray(res.data.results)
      setActiveBlog(res.data.results[0])
    })
  }, [])

  return (
    <AppCtx.Provider
      value={{
        slideOpen,
        toggleSlideOpen,
        currentSlide,
        setCurrentSlide,
        currentPage,
        setCurrentPage,
        setCurrentTab,
        currentTab,
        tabRefs,
        scrollingDisabled,
        setScrollingDisabled,
        fullScreenOpen,
        toggleFullScreen,
        currentEnvironment,
        setCurrentEnv,
        swiper,
        setSwiper,
        setShowVideo,
        showVideo,
        setShowProduct,
        showProduct,
        setHomeHeroOver,
        homeHeroOver,
        showScheduler,
        setShowScheduler,
        setVideoURL,
        videoURL,
        activeBlog,
        setActiveBlog,
        blogArray
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};

export const useApp = () => {
  return useContext(AppCtx) as AppContext;
};
export default ApplicationProvider;
