import { useMediaQuery } from "@mui/material";
import { useSpring, useTransform } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import LayoutStyles from "../App.styles";
// images
import img_1 from "../assets/images/about/img_1.png";
import img_2 from "../assets/images/about/img_2.png";
import img_3 from "../assets/images/about/img_3.png";
import img_4 from "../assets/images/about/img_4.png";
import img_5 from "../assets/images/about/img_5.png";
import img_6 from "../assets/images/about/img_6.png";
import img_7 from "../assets/images/about/img_7.png";
import img_8 from "../assets/images/about/img_8.png";
import useOnScroll from "../hooks/useOnScroll";
import useWindowDimensions from "../hooks/useWindowDimensions";
import theme from "../theme";

const AboutSpread = ({
  containerRef,
  data,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  data: {
    headline: string;
    body: string;
  };
}) => {
  const targetRef = useRef<Array<HTMLDivElement | null>>([]);
  const [hookedYPostion, setHookedYPosition] = useState(0);
  const [offset, setOffset] = useState<
    {
      offsetTop: number;
      offsetHeight: number;
      offsetWidth: number;
      offsetLeft: number;
      offsetRight: number;
    }[]
  >([]);

  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const isMedScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const { scrollValue, scrollPosition } = useOnScroll({
    containerRef: containerRef,
  });

  const yDisplacementValues = [668, 1017, 1142, 1609, 1540, 1893, 2419, 3126];
  const xDisplacementValues = [141, -97, 225, 8, -191, 0, 11, 44];
  const OFFSET = 0;

  const springScrollY = useSpring(scrollValue, {
    stiffness: 500,
    damping: 50,
  });
  const FIRST_ELEMENT_REFRENCE =
    yDisplacementValues[0] + offset[0]?.offsetHeight ?? 0;

  useEffect(() => {
    // console.log(screenHeight);
    if (targetRef.current && !isMedScreen) {
      let offsetArr = [];
      const { offsetTop: parentOffsetTop, offsetLeft: parentOffsetLeft } =
        targetRef.current[8] as any;
      for (let i = 0; i < 8; i++) {
        const {
          offsetHeight,
          offsetWidth,
          offsetLeft: tempLeft,
        } = targetRef.current[i] as any;

        offsetArr.push({
          offsetTop: parentOffsetTop - screenHeight * 0.55,
          offsetHeight,
          offsetWidth,
          offsetLeft: tempLeft + parentOffsetLeft,
          offsetRight:
            screenWidth - (tempLeft + parentOffsetLeft + offsetWidth),
        });
      }
      console.log(offsetArr);
      setOffset(offsetArr);
    }
  }, [screenWidth, screenHeight, isMedScreen]);

  useEffect(() => {
    scrollValue.onChange((v) => setHookedYPosition(v));
  }, [scrollPosition, scrollValue]);

  const y0 = useTransform(
    springScrollY,
    [
      offset[0]?.offsetTop,
      offset[0]?.offsetTop + offset[0]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[0] + offset[0]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x0 = useTransform(
    springScrollY,
    [
      offset[0]?.offsetTop,
      offset[0]?.offsetTop + offset[0]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[0]?.offsetLeft - xDisplacementValues[0] < 0
        ? -offset[0]?.offsetLeft + 40
        : -xDisplacementValues[0],
    ]
  );

  const y1 = useTransform(
    springScrollY,
    [
      offset[1]?.offsetTop,
      offset[1]?.offsetTop + offset[1]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[1] + offset[1]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x1 = useTransform(
    springScrollY,
    [
      offset[1]?.offsetTop,
      offset[1]?.offsetTop + offset[1]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[1]?.offsetLeft - xDisplacementValues[1] < 0
        ? -offset[1]?.offsetLeft
        : -xDisplacementValues[1],
    ]
  );

  const y2 = useTransform(
    springScrollY,
    [
      offset[2]?.offsetTop,
      offset[2]?.offsetTop + offset[2]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[2] + offset[2]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x2 = useTransform(
    springScrollY,
    [
      offset[2]?.offsetTop,
      offset[2]?.offsetTop + offset[2]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[2]?.offsetLeft - xDisplacementValues[2] < 0
        ? -offset[2]?.offsetLeft
        : -xDisplacementValues[2],
    ]
  );

  const y3 = useTransform(
    springScrollY,
    [
      offset[3]?.offsetTop,
      offset[3]?.offsetTop + offset[3]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[3] + offset[3]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x3 = useTransform(
    springScrollY,
    [
      offset[3]?.offsetTop,
      offset[3]?.offsetTop + offset[3]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[3]?.offsetLeft - xDisplacementValues[3] < 0
        ? -offset[3]?.offsetLeft
        : -xDisplacementValues[3],
    ]
  );

  const y4 = useTransform(
    springScrollY,
    [
      offset[4]?.offsetTop,
      offset[4]?.offsetTop + offset[4]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[4] + offset[4]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x4 = useTransform(
    springScrollY,
    [
      offset[4]?.offsetTop,
      offset[4]?.offsetTop + offset[4]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[4]?.offsetRight + xDisplacementValues[4] <= 0
        ? offset[4]?.offsetLeft -
          offset[4]?.offsetWidth -
          xDisplacementValues[4]
        : -xDisplacementValues[4],
    ]
  );

  const y5 = useTransform(
    springScrollY,
    [
      offset[5]?.offsetTop,
      offset[5]?.offsetTop + offset[5]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[5] + offset[5]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x5 = useTransform(
    springScrollY,
    [
      offset[5]?.offsetTop,
      offset[5]?.offsetTop + offset[5]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[5]?.offsetLeft - xDisplacementValues[5] < 0
        ? -offset[5]?.offsetLeft
        : -xDisplacementValues[5],
    ]
  );

  const y6 = useTransform(
    springScrollY,
    [
      offset[6]?.offsetTop,
      offset[6]?.offsetTop + offset[6]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[6] + offset[6]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x6 = useTransform(
    springScrollY,
    [
      offset[6]?.offsetTop,
      offset[6]?.offsetTop + offset[6]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[6]?.offsetLeft - xDisplacementValues[6] < 0
        ? -offset[6]?.offsetLeft
        : -xDisplacementValues[6],
    ]
  );

  const y7 = useTransform(
    springScrollY,
    [
      offset[7]?.offsetTop,
      offset[7]?.offsetTop + offset[7]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[7] + offset[7]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x7 = useTransform(
    springScrollY,
    [
      offset[7]?.offsetTop,
      offset[7]?.offsetTop + offset[7]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[7]?.offsetLeft - xDisplacementValues[7] < 0
        ? -offset[7]?.offsetLeft
        : -xDisplacementValues[7],
    ]
  );

  return (
    <LayoutStyles.SpreadAnimation.Container

      style={{
        height: 4298 - FIRST_ELEMENT_REFRENCE + 200,
        paddingTop: 36,
        backgroundColor: theme.palette.neutral.light,
      }}
    >
      <LayoutStyles.SpreadAnimation.InitialImageState
        style={{ height: 936, width: 1043 }}
        ref={(el) => (targetRef.current[8] = el)}
      >
        <LayoutStyles.SpreadAnimation.Image
          $top={"59px"}
          $left={"129px"}
          style={{
            height: 402,
            width: 516,
            y: y0,
            x: x0,
            backgroundImage: `url(${img_1})`,
            zIndex: 6,
          }}
          ref={(el) => (targetRef.current[0] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[0]?.offsetTop + offset[0]?.offsetHeight * 0.8
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: 516 + 61, top: 120, width: 434 }}
          >
            {"We value purpose"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "We believe the human experience and sense of purpose within an organization drive confidence, growth, and success."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.SpreadAnimation.Image>
        <LayoutStyles.SpreadAnimation.Image
          $top={"0px"}
          $right={"169px"}
          style={{
            height: 496,
            width: 378,
            y: y1,
            x: x1,
            backgroundImage: `url(${img_2})`,
            zIndex: 3,
          }}
          ref={(el) => (targetRef.current[1] = el)}
        />
        <LayoutStyles.SpreadAnimation.Image
          $top={"329px"}
          $right={"196px"}
          style={{
            height: 386,
            width: 571,
            y: y2,
            x: x2,
            backgroundImage: `url(${img_3})`,
            zIndex: 7,
          }}
          ref={(el) => (targetRef.current[2] = el)}
        />
        <LayoutStyles.SpreadAnimation.Image
          $top={"158px"}
          $right={"36px"}
          style={{
            height: 317,
            width: 290,
            y: y3,
            x: x3,
            backgroundImage: `url(${img_4})`,
            zIndex: 5,
          }}
          ref={(el) => (targetRef.current[3] = el)}
        />
        <LayoutStyles.SpreadAnimation.Image
          $bottom={"0px"}
          $right={"140px"}
          style={{
            height: 335,
            width: 495,
            y: y4,
            x: x4,
            backgroundImage: `url(${img_5})`,
            zIndex: 2,
          }}
          ref={(el) => (targetRef.current[4] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[4]?.offsetTop + yDisplacementValues[4] * 0.9
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: -63 - 434, top: -51, width: 434 }}
          >
            {"We center collaboration"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "With a sense of collective awareness and common vision, teams can achieve the unachievable—and individuals renew a sense of personal fulfillment and happiness."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.SpreadAnimation.Image>
        <LayoutStyles.SpreadAnimation.Image
          $bottom={"76px"}
          $left={"51px"}
          style={{
            height: 612,
            width: 459,
            y: y5,
            x: x5,
            backgroundImage: `url(${img_6})`,
            zIndex: 1,
          }}
          ref={(el) => (targetRef.current[5] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[5]?.offsetTop + yDisplacementValues[5] * 0.9
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: 121 + 459, top: 225, width: 434 }}
          >
            {"We believe in giving back"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "We’re convinced that being engaged in community influences our innovative and collaborative capacities, while making a difference."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.SpreadAnimation.Image>
        <LayoutStyles.SpreadAnimation.Image
          $bottom={"76px"}
          $right={"0px"}
          style={{
            height: 564,
            width: 433,
            y: y6,
            x: x6,
            backgroundImage: `url(${img_7})`,
          }}
          ref={(el) => (targetRef.current[6] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[6]?.offsetTop + yDisplacementValues[6] * 0.9
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: -83 - 434, top: 240, width: 434 }}
          >
            {"We empower growth"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "With a sense of collective awareness and common vision, teams can achieve the unachievable—and individuals renew a sense of personal fulfillment and happiness."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.SpreadAnimation.Image>
        <LayoutStyles.SpreadAnimation.Image
          $bottom={"176px"}
          $left={"0px"}
          style={{
            height: 413,
            width: 610,
            y: y7,
            x: x7,
            backgroundImage: `url(${img_8})`,
            zIndex: 4,
          }}
          ref={(el) => (targetRef.current[7] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[7]?.offsetTop + yDisplacementValues[7] * 0.9
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: 96 + 610, top: 111, width: 434 }}
          >
            {"We’re always innovating"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "“So-so” isn’t our style. We like challenging conventional wisdom and bringing groundbreaking concepts to the table."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.SpreadAnimation.Image>
      </LayoutStyles.SpreadAnimation.InitialImageState>
    </LayoutStyles.SpreadAnimation.Container>
  );
};

export default AboutSpread;
