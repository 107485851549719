import Layout from "./MainLayout";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import ApplicationProvider from "./context/AppContext";

const App = () => {
  return (
    <ApplicationProvider>
      <ThemeProvider theme={theme}>
        <Layout />
      </ThemeProvider>
    </ApplicationProvider>
  );
};

export default App;
