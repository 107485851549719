import { Typography } from '@mui/material';
import React from 'react';
import LayoutStyles from '../App.styles';
import WhiteArrow from '../assets/icons/buttonarrows/white-arrow.png';
import TextField from '@mui/material/TextField';

const Subscribe = () => {
    return(
        <LayoutStyles.Subscribe.Box>
            <LayoutStyles.Subscribe.Title>Subscribe to get more updates and insights from us</LayoutStyles.Subscribe.Title>
            <LayoutStyles.Subscribe.Bar>
                <TextField placeholder='Enter your email' className='subscribe-field' />
                <LayoutStyles.Subscribe.SubscribeButton>
                    Subscribe
                    <img style={{width: '29px'}} src={WhiteArrow} alt='Subscribe' />
                </LayoutStyles.Subscribe.SubscribeButton>
            </LayoutStyles.Subscribe.Bar>
        </LayoutStyles.Subscribe.Box>
    )
}

export default Subscribe;