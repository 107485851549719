import React, { useEffect, useRef, useState } from "react";
import { useApp } from "../context/AppContext";
import LayoutStyles from "../App.styles";
import { Link } from "react-router-dom";
import useOnScreen from "../hooks/useOnScreen";
import FadeIn from "../Common/Animations/FadeIn";
import VideoButton from "../Common/Button/VideoButton";
import ReactPlayer from "react-player";
import SlideButton from "../Common/Button/SlideButton";
import checkIcon from "../assets/images/check.svg";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiLayerAnimation from "./MultiLayerAnimation";
import Quotes from "../assets/icons/Testimonial-Quotes.png";
import Pill from "../Common/Pill";
import theme from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";

type ImageAndTextBlockProps = {
  lottieAnimation?: object | any[];
  loopAnimation?: boolean;
  data: {
    tag?: string;
    headline: string;
    name?: string;
    title?: string;
    body: string;
    button1?: {
      text: string;
      path: string;
    };
    button2?: {
      text: string;
      path: string;
    };
    pills?: string[];
    list?: string[];
    subject?: string;
  };
  height?: string;
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  reverse?: boolean;
  removeTxtPadding?: boolean;
  backgroundColor?: string;
  color?: string;
  image?: string;
  video?: string;
  transform?: string;
  animationMargin?: string;
  imageSize?: [number | string, number | string];
  bgImage?: string;
  avatarPosition?: [string, string, string, string];
  avatarSize?: [string, string];
  hideTag?: boolean;
  hideButton?: boolean;
  buttonType?: "default" | "animated";
  quote?: boolean;
  customClickHandler?: () => void;
  isBackgroundImg?: boolean[] | undefined;
  gap?: string;
  altColor: boolean;
  sectionID?: string;
  videoButton?: boolean;
  enableMobileMaxWidth?: boolean;
  videoAspectRatio?: number;
  testimonial?: boolean;
};

const ImageTextBlock: React.FC<ImageAndTextBlockProps> = ({
  lottieAnimation,
  loopAnimation,
  data,
  height,
  width,
  alignItems,
  justifyContent,
  padding,
  margin,
  image,
  imageSize,
  bgImage,
  quote,
  transform,
  gap = "40px",
  hideButton = false,
  buttonType = "default",
  hideTag = false,
  backgroundColor = "transparent",
  color,
  reverse,
  removeTxtPadding,
  avatarPosition = ["auto", "auto", "auto", "auto"],
  avatarSize = ["100%", "100%"],
  isBackgroundImg,
  customClickHandler,
  animationMargin,
  videoButton,
  altColor,
  videoAspectRatio,
  sectionID,
  enableMobileMaxWidth,
  video,
  testimonial
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);
  const { setCurrentPage, setShowProduct } = useApp();
  const [playing, setPlaying] = useState(false);
  const handleNavClick = (navigateTo: string) => {
    setCurrentPage(navigateTo);
  };
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const playerRef = useRef<ReactPlayer | null>(null);

  return (
    <>
    {testimonial && <LayoutStyles.ImageAndTextBox.Headline style={{textAlign: 'center', marginBottom: isSmallScreen ? '61px' : '80px'}} $altTextColor={!altColor}
                    $isSmallScreen={isSmallScreen}>Customer Testimonials</LayoutStyles.ImageAndTextBox.Headline>}
    <LayoutStyles.FlexboxRow
      $height={height}
      $width={width}
      $padding={padding}
      $margin={margin}
      $alignItems={alignItems}
      $backgroundColor={backgroundColor}
      $textColor={color}
      $flexWrap={false}
      $justifyContent="space-between"
      $overrideCenter
      $gap={gap}
      $flexDirection={
        isMediumScreen ? "column" : reverse ? "row-reverse" : "row"
      }
      style={{
        margin: "auto",
        maxWidth: isMediumScreen ? "85%" : "1920px",
      }}
    >
      <LayoutStyles.ImageAndTextBox.ImageArea
        $reverse={reverse}
        ref={elementRef}
        style={{
          width: imageSize ? imageSize[0] : "unset",
          height: imageSize ? imageSize[1] : "unset",
          transform: transform ? transform : "unset",
          marginRight: reverse ? "0px" : isMediumScreen ? "0" : "30px",
        }}
      >
        {((lottieAnimation && !isMediumScreen) ||
          (lottieAnimation && quote)) && (
          <MultiLayerAnimation
            loop={loopAnimation}
            transform={transform}
            isOnScreen={isOnScreen}
            avatarPosition={avatarPosition}
            avatarSize={avatarSize}
            lotties={
              !Array.isArray(lottieAnimation)
                ? [lottieAnimation]
                : lottieAnimation
            }
            isBackgroundImg={isBackgroundImg}
          />
        )}
        {image && !isMediumScreen && (
          <img
            width={"100%"}
            height="auto"
            style={{ zIndex: "2" }}
            src={image}
            alt=""
          />
        )}
        {(video && !isMediumScreen) && (
          <div
          style={{
              pointerEvents: "auto",
              height: '100%',
              width: '100%',
              borderRadius: "21px",
              overflow: "hidden",
              position: "relative",
              zIndex: '3',
              paddingTop: videoAspectRatio ? `${videoAspectRatio * 100}%` : "0"
          }}>
            {!playing && (
            <div
            onClick={() => {
                if (!playing) setPlaying(true)
              }}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.42",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: '0',
                cursor: "pointer",
                zIndex: '2'
              }}
            >
              <VideoButton text="See how it works" clickFunction={() => {if (!playing) setPlaying(true)}} />
            </div>
          )}
            <ReactPlayer
              controls
              className="react-player"
              ref={playerRef}
              width="100%"
              height="100%"
              onStart={() => setPlaying(true)}
              onEnded={() => setPlaying(false)}
              onPause={() => setPlaying(false)}
              style={{ backgroundColor: theme.palette.neutral.black }}
              playing={playing}
              playIcon={<VideoButton text="See how it works" />}
              url={video}
            />
          </div>
        )}
      </LayoutStyles.ImageAndTextBox.ImageArea>

      <FadeIn
        customStyle={{
          flex: "unset",
          marginRight: !reverse
            ? "0px"
            : isSmallScreen
            ? "0"
            : isMediumScreen
            ? "25px"
            : "30px",
        }}
        isOnScreen={
          sectionID === window.location.hash.substring(1) ? true : isOnScreen
        }
      >
        <LayoutStyles.ImageAndTextBox.TextArea
          $removeTxtPadding={removeTxtPadding}
          $reverse={reverse}
          $quote={quote}
          style={quote && isMediumScreen ? { minWidth: "unset" } : {}}
        >
          {quote ? (
            <LayoutStyles.ImageAndTextBox.FlexContainer
              style={{
                marginLeft: isMediumScreen ? "0" : "35px",
                width: isSmallScreen ? "100%" : "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <LayoutStyles.ImageAndTextBox.QuoteIcon
                  src={Quotes}
                  alt="quotes"
                />
              </div>
              <LayoutStyles.ImageAndTextBox.Quote>
                {data.headline}
              </LayoutStyles.ImageAndTextBox.Quote>
              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid rgba(255,255,255,0.15)",
                  marginTop: "15px",
                }}
              >
                <LayoutStyles.ImageAndTextBox.Quotee>
                  {data.name}
                </LayoutStyles.ImageAndTextBox.Quotee>
                <LayoutStyles.ImageAndTextBox.Title>
                  {data.title}
                </LayoutStyles.ImageAndTextBox.Title>
              </div>
            </LayoutStyles.ImageAndTextBox.FlexContainer>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  {!hideTag && data.tag && (
                    <LayoutStyles.ImageAndTextBox.Tag $altTextColor={altColor}>
                      {data.tag.toUpperCase()}
                    </LayoutStyles.ImageAndTextBox.Tag>
                  )}

                  <LayoutStyles.ImageAndTextBox.Headline
                    $altTextColor={altColor}
                    $isSmallScreen={isSmallScreen}
                  >
                    {data.headline}
                  </LayoutStyles.ImageAndTextBox.Headline>
                  {lottieAnimation && isMediumScreen && (
                    <MultiLayerAnimation
                      margin={animationMargin}
                      loop={loopAnimation}
                      transform={transform}
                      isOnScreen={isOnScreen}
                      avatarPosition={avatarPosition}
                      avatarSize={avatarSize}
                      lotties={
                        !Array.isArray(lottieAnimation)
                          ? [lottieAnimation]
                          : lottieAnimation
                      }
                      isBackgroundImg={isBackgroundImg}
                    />
                  )}
                  {image && isMediumScreen && (
                    <img
                      width={"100%"}
                      height="auto"
                      style={{ marginBottom: "31px" }}
                      src={image}
                      alt=""
                    />
                  )}
                  {video && isMediumScreen && (
                    <div
                    style={{
                        pointerEvents: "auto",
                        height: '100%',
                        width: '100%',
                        borderRadius: "21px",
                        overflow: "hidden",
                        position: "relative",
                        marginBottom: "31px",
                        zIndex: '3',
                        paddingTop: videoAspectRatio ? `${videoAspectRatio * 100}%` : "0"
                    }}>
                      {!playing && (
                      <div
                      onClick={() => {
                          if (!playing) setPlaying(true)
                        }}
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundColor: "rgba(0,0,0,0.42",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          top: '0',
                          cursor: "pointer",
                          zIndex: '2'
                        }}
                      >
                        <VideoButton text="See how it works" clickFunction={() => {if (!playing) setPlaying(true)}} />
                      </div>
                    )}
                      <ReactPlayer
                        controls
                        className="react-player"
                        ref={playerRef}
                        width="100%"
                        height="100%"
                        onStart={() => setPlaying(true)}
                        onEnded={() => setPlaying(false)}
                        onPause={() => setPlaying(false)}
                        style={{ backgroundColor: theme.palette.neutral.black }}
                        playing={playing}
                        playIcon={<VideoButton text="See how it works" />}
                        url={video}
                      />
                    </div>
                  )}
                  <LayoutStyles.ImageAndTextBox.Body
                    $altTextColor={altColor}
                    style={
                      (!data.list && !hideButton) || data.pills
                        ? { marginBottom: 48 }
                        : undefined
                    }
                  >
                    {data.body}
                  </LayoutStyles.ImageAndTextBox.Body>
                </div>
                {videoButton && (
                  <LayoutStyles.SectionContainer
                    $padding={"0"}
                    $width={"fit-content"}
                    style={{
                      width: isMediumScreen ? "100%" : "unset",
                      order: isMediumScreen ? "5" : "unset",
                    }}
                  >
                    <SlideButton
                      blackButton={altColor ? false : true}
                      text="See it in action"
                      clickFunction={() => setShowProduct(true)}
                    />
                  </LayoutStyles.SectionContainer>
                )}
                {data.list && (
                  <LayoutStyles.ImageAndTextBox.List bulletImage={checkIcon}>
                    {data.list.map((element, index) => (
                      <LayoutStyles.ImageAndTextBox.ListElement
                        $altColor={altColor}
                        $first={index === 0}
                        key={index}
                      >
                        <FontAwesomeIcon
                          size="lg"
                          style={{ marginRight: "16px" }}
                          color={
                            altColor ? "#FFFFFF" : theme.palette.primary.main
                          }
                          icon={faCheckCircle}
                        />
                        <LayoutStyles.ImageAndTextBox.Bullet
                          $altColor={altColor}
                        >
                          {element}
                        </LayoutStyles.ImageAndTextBox.Bullet>
                      </LayoutStyles.ImageAndTextBox.ListElement>
                    ))}
                  </LayoutStyles.ImageAndTextBox.List>
                )}
                {data.pills && data?.pills?.length > 0 && (
                  <LayoutStyles.FlexboxRow
                    $flexWrap
                    $justifyContent="flex-start"
                    $gap="10px"
                  >
                    {data.pills.map((pill, index) => {
                      return <Pill text={pill} key={index} />;
                    })}
                  </LayoutStyles.FlexboxRow>
                )}
              </div>

              {!hideButton && (
                <div>
                  {data.button1?.text && (
                    <SlideButton
                      blackButton={!altColor}
                      text={data.button1?.text ? data.button1.text : ""}
                      path={data.button1?.path}
                    />
                  )}
                  {data.button2?.text && (
                    <SlideButton
                      blackButton={!altColor}
                      text={data.button2?.text ? data.button2.text : ""}
                      path={data.button2?.path}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </LayoutStyles.ImageAndTextBox.TextArea>
      </FadeIn>
    </LayoutStyles.FlexboxRow>
    </>
  );
};

export default ImageTextBlock;
