import React from "react";
import { useApp } from "../context/AppContext";
import LayoutStyles from "../App.styles";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../utils/data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  tag?: string;
  data?: {
    headline: string;
    subtitle?: string;
  };
};

const BlogSwiper: React.FC<Props> = ({ data, tag }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { blogArray } = useApp();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const { BlogSwiper } = LayoutStyles;

  const selectBlog = (blogId: string) => {
    navigate(`/resources/${blogId}`);
  };

  return (
    <LayoutStyles.TextFirstHero.Container
      style={{ alignItems: "center", paddingRight: "0", paddingLeft: "0" }}
      $textFirst
    >
      <LayoutStyles.SectionContainer
        style={{
          maxWidth: "1300px",
          width: "100%",
          position: "relative",
          height: "444px",
        }}
      >
        <Swiper
          autoplay
          watchOverflow
          noSwiping
          initialSlide={1}
          noSwipingClass="blog-swiper"
          pagination={{
            el: ".custom-pagination",
            renderBullet: (index, className) => {
              return `<span class='blog-bullets ${className}'></span>`;
            },
          }}
          navigation={{
            nextEl: ".next-nav",
            prevEl: ".prev-nav",
          }}
          loop
          modules={[Autoplay, Pagination, Navigation]}
          className="blog-swiper"
          style={{ height: "100%" }}
        >
          {blogArray.slice(0, 4).map((blog, index) => {
            return (
              <SwiperSlide key={`avatar-${index}`}>
                <BlogSwiper.Container onClick={() => selectBlog(blog.id)}>
                  <BlogSwiper.Image
                    draggable="false"
                    src={blog.featuredImage}
                  />
                  <BlogSwiper.TextBox>
                    <BlogSwiper.Headline>{blog.name}</BlogSwiper.Headline>
                    <BlogSwiper.Date>
                      {formatDate(blog.publishDate)}
                    </BlogSwiper.Date>
                  </BlogSwiper.TextBox>
                </BlogSwiper.Container>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <LayoutStyles.FlexboxRow $margin="38px 0 0 0">
          <div
            className="custom-pagination"
            style={{ width: "100%", textAlign: "left" }}
          />
          <LayoutStyles.FlexboxRow $width="fit-content">
            <div
              style={{
                marginRight: "15px",
                cursor: "pointer"
              }}
              className="prev-nav"
            >
              <FontAwesomeIcon size="2x" color="#fff" icon={faChevronLeft} />
            </div>
            <div
            style={{
              cursor: "pointer"
            }}
              className="next-nav"
            >
              <FontAwesomeIcon size="2x" color="#fff" icon={faChevronRight} />
            </div>
          </LayoutStyles.FlexboxRow>
        </LayoutStyles.FlexboxRow>
      </LayoutStyles.SectionContainer>
    </LayoutStyles.TextFirstHero.Container>
  );
};

export default BlogSwiper;
