import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";
import img_7 from "./images/img_7.png";
import img_8 from "./images/img_8.png";
import img_9 from "./images/img_9.png";
import img_10 from "./images/img_10.png";
import img_11 from "./images/img_11.png";
import img_12 from "./images/img_12.png";
import img_13 from "./images/img_13.png";
import img_14 from "./images/img_14.png";
import img_15 from "./images/img_15.png";
import img_16 from "./images/img_16.png";
import img_17 from "./images/img_17.png";
import img_18 from "./images/img_18.png";
import img_19 from "./images/img_19.png";

export default {
  v: "5.7.4",
  fr: 30,
  ip: 0,
  op: 255,
  w: 1315,
  h: 1509,
  nm: "Media-Library-New",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 514,
      h: 514,
      u: "",
      p: img_0,
      e: 0
    },
    {
      id: "image_1",
      w: 194,
      h: 192,
      u: "",
      p: img_1,
      e: 0
    },
    {
      id: "image_2",
      w: 220,
      h: 220,
      u: "",
      p: img_2,
      e: 0
    },
    {
      id: "image_3",
      w: 194,
      h: 192,
      u: "",
      p: img_3,
      e: 0
    },
    {
      id: "image_4",
      w: 194,
      h: 194,
      u: "",
      p: img_4,
      e: 0
    },
    {
      id: "image_5",
      w: 220,
      h: 220,
      u: "",
      p: img_5,
      e: 0
    },
    {
      id: "image_6",
      w: 218,
      h: 218,
      u: "",
      p: img_6,
      e: 0
    },
    {
      id: "image_7",
      w: 192,
      h: 192,
      u: "",
      p: img_7,
      e: 0
    },
    {
      id: "image_8",
      w: 220,
      h: 218,
      u: "",
      p: img_8,
      e: 0
    },
    {
      id: "image_9",
      w: 218,
      h: 218,
      u: "",
      p: img_9,
      e: 0
    },
    {
      id: "image_10",
      w: 194,
      h: 192,
      u: "",
      p: img_10,
      e: 0
    },
    {
      id: "image_11",
      w: 710,
      h: 710,
      u: "",
      p: img_11,
      e: 0
    },
    {
      id: "image_12",
      w: 420,
      h: 290,
      u: "",
      p: img_12,
      e: 0
    },
    {
      id: "image_13",
      w: 424,
      h: 290,
      u: "",
      p: img_13,
      e: 0
    },
    {
      id: "image_14",
      w: 418,
      h: 286,
      u: "",
      p: img_14,
      e: 0
    },
    {
      id: "image_15",
      w: 424,
      h: 290,
      u: "",
      p: img_15,
      e: 0
    },
    {
      id: "image_16",
      w: 420,
      h: 290,
      u: "",
      p: img_16,
      e: 0
    },
    {
      id: "image_17",
      w: 420,
      h: 290,
      u: "",
      p: img_17,
      e: 0
    },
    {
      id: "image_18",
      w: 878,
      h: 122,
      u: "",
      p: img_18,
      e: 0
    },
    {
      id: "image_19",
      w: 1104,
      h: 1358,
      u: "",
      p: img_19,
      e: 0
    },
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "Pulsewaves and Graphics 2",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.333
                    ],
                    y: [
                      0
                    ]
                  },
                  t: 199.382,
                  s: [
                    100
                  ]
                },
                {
                  t: 250.999710648148,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                657.5,
                810,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      1,
                      1,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.333,
                      0.333,
                      0.333
                    ],
                    y: [
                      0,
                      0,
                      0
                    ]
                  },
                  t: 189,
                  s: [
                    51,
                    51,
                    100
                  ]
                },
                {
                  t: 250.999710648148,
                  s: [
                    65,
                    65,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          w: 3840,
          h: 2160,
          ip: 132.314314314314,
          op: 256,
          st: -33.8518518518519,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "Media Library Animation",
          refId: "comp_5",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.82
                    ],
                    y: [
                      0.001
                    ]
                  },
                  t: 90,
                  s: [
                    100
                  ]
                },
                {
                  t: 135,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                657.5,
                810,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                553,
                800,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.573
                    ]
                  },
                  o: {
                    x: [
                      0.726,
                      0.726,
                      0.333
                    ],
                    y: [
                      0.014,
                      0.014,
                      0
                    ]
                  },
                  t: 90,
                  s: [
                    100,
                    100,
                    100
                  ]
                },
                {
                  t: 135,
                  s: [
                    40,
                    40,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          w: 1106,
          h: 1600,
          ip: 0,
          op: 135,
          st: 0,
          bm: 0
        }
      ]
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "multimedia.png",
          cl: "png",
          refId: "image_0",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                257,
                257,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      1,
                      1,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      16.667
                    ]
                  },
                  t: 166.166,
                  s: [
                    0,
                    0,
                    100
                  ]
                },
                {
                  t: 201.201201201201,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 166.166166166166,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "Inner Orbit 2",
          refId: "comp_2",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                936,
                935,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.994,
                      0.994,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.794
                    ]
                  },
                  t: 201.201,
                  s: [
                    20,
                    20,
                    100
                  ]
                },
                {
                  t: 241.241241241241,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          w: 1872,
          h: 1870,
          ip: 201.201201201201,
          op: 3615.61561561562,
          st: 16.016016016016,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: "Outer Orbit 2",
          refId: "comp_3",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                1250,
                1250,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0,
                      0,
                      0.667
                    ],
                    y: [
                      0.994,
                      0.994,
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.794
                    ]
                  },
                  t: 190.19,
                  s: [
                    20,
                    20,
                    100
                  ]
                },
                {
                  t: 230.23023023023,
                  s: [
                    100,
                    100,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          w: 2500,
          h: 2500,
          ip: 190.19019019019,
          op: 3604.6046046046,
          st: 5.00500500500501,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 0,
          nm: "Pulsewave",
          refId: "comp_4",
          sr: 2.5,
          ks: {
            o: {
              a: 0,
              k: 20,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                120,
                120,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          w: 3840,
          h: 2160,
          ip: 185.185185185185,
          op: 1368.86886886887,
          st: 185.185185185185,
          bm: 0
        }
      ]
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "01 Copy 5.png",
          cl: "png",
          refId: "image_1",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    955.92,
                    330.662,
                    0
                  ],
                  to: [
                    -310.1,
                    8.933,
                    0
                  ],
                  ti: [
                    16.267,
                    3.233,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    564.318,
                    463.262,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                97,
                96,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "01 Copy 4.png",
          cl: "png",
          refId: "image_2",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    1526.378,
                    806.764,
                    0
                  ],
                  to: [
                    -23.935,
                    -66.515,
                    0
                  ],
                  ti: [
                    167.935,
                    133.515,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    1304.767,
                    455.672,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                110,
                110,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "01 Copy 6.png",
          cl: "png",
          refId: "image_3",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    366.032,
                    729.574,
                    0
                  ],
                  to: [
                    -80.83,
                    237.659,
                    0
                  ],
                  ti: [
                    -1.17,
                    -71.659,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    373.052,
                    1159.526,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                97,
                96,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "01 Copy 3.png",
          cl: "png",
          refId: "image_4",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    1363.553,
                    1360.117,
                    0
                  ],
                  to: [
                    55.075,
                    -50.125,
                    0
                  ],
                  ti: [
                    -5.277,
                    169.745,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    1537.316,
                    978.338,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                97,
                97,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "01 Copy 7.png",
          cl: "png",
          refId: "image_5",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    485.553,
                    1342.117,
                    0
                  ],
                  to: [
                    77.075,
                    63.875,
                    0
                  ],
                  ti: [
                    -262.277,
                    18.745,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    961.316,
                    1532.338,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                110,
                110,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                936,
                935,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                338.031,
                94.031,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: {
                    a: 0,
                    k: [
                      1205.062,
                      1205.062
                    ],
                    ix: 2
                  },
                  p: {
                    a: 0,
                    k: [
                      0,
                      0
                    ],
                    ix: 3
                  },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.149019607843,
                      0.145098039216,
                      0.156862745098,
                      1
                    ],
                    ix: 3
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 4
                  },
                  w: {
                    a: 0,
                    k: 10,
                    ix: 5
                  },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: {
                    a: 0,
                    k: [
                      338.031,
                      94.031
                    ],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [
                      0,
                      0
                    ],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [
                      100,
                      100
                    ],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        }
      ]
    },
    {
      id: "comp_3",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "01 Copy 3.png",
          cl: "png",
          refId: "image_6",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    334,
                    1413.116,
                    0
                  ],
                  to: [
                    -46.869,
                    -271.193,
                    0
                  ],
                  ti: [
                    -143.131,
                    195.193,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    508.788,
                    685.958,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                109,
                109,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                110,
                110,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "01 Copy 4.png",
          cl: "png",
          refId: "image_7",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    1250,
                    2176,
                    0
                  ],
                  to: [
                    -603.333,
                    4,
                    0
                  ],
                  ti: [
                    75.333,
                    124,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    390,
                    1600,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                96,
                96,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                110,
                110,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "01 Copy 5.png",
          cl: "png",
          refId: "image_8",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    2159,
                    1484.687,
                    0
                  ],
                  to: [
                    -175.333,
                    418,
                    0
                  ],
                  ti: [
                    303.333,
                    -210,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    1587,
                    2144.687,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                110,
                109,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                110,
                110,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "01 Copy 6.png",
          cl: "png",
          refId: "image_9",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    1737.683,
                    459.361,
                    0
                  ],
                  to: [
                    426.667,
                    270.667,
                    0
                  ],
                  ti: [
                    -26.667,
                    -38.667,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    2185.683,
                    1191.361,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                109,
                109,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                110,
                110,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "01 Copy 7.png",
          cl: "png",
          refId: "image_10",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.833,
                    y: 0.833
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 185.185,
                  s: [
                    738.988,
                    464.703,
                    0
                  ],
                  to: [
                    152.833,
                    -71.167,
                    0
                  ],
                  ti: [
                    -393.333,
                    -84,
                    0
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    1479.988,
                    348.703,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                97,
                96,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                110,
                110,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 185.185185185185,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1250,
                1250,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                338.031,
                94.031,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: {
                    a: 0,
                    k: [
                      1858.062,
                      1858.062
                    ],
                    ix: 2
                  },
                  p: {
                    a: 0,
                    k: [
                      0,
                      0
                    ],
                    ix: 3
                  },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.149019607843,
                      0.145098039216,
                      0.156862745098,
                      1
                    ],
                    ix: 3
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 4
                  },
                  w: {
                    a: 0,
                    k: 10,
                    ix: 5
                  },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "tr",
                  p: {
                    a: 0,
                    k: [
                      338.031,
                      94.031
                    ],
                    ix: 2
                  },
                  a: {
                    a: 0,
                    k: [
                      0,
                      0
                    ],
                    ix: 1
                  },
                  s: {
                    a: 0,
                    k: [
                      100,
                      100
                    ],
                    ix: 3
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5
                  },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 3599.5995995996,
          st: 0,
          bm: 0
        }
      ]
    },
    {
      id: "comp_4",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 383.383,
                  s: [
                    100
                  ]
                },
                {
                  t: 402.402402402402,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 372.372,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 402.402402402402,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 372.372372372372,
          op: 402.402402402402,
          st: 187.187187187187,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 371.371,
                  s: [
                    100
                  ]
                },
                {
                  t: 390.39039039039,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 360.36,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 390.39039039039,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 360.36036036036,
          op: 390.39039039039,
          st: 175.175175175175,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 359.359,
                  s: [
                    100
                  ]
                },
                {
                  t: 378.378378378378,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 348.348,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 378.378378378378,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 348.348348348348,
          op: 378.378378378378,
          st: 163.163163163163,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 347.347,
                  s: [
                    100
                  ]
                },
                {
                  t: 366.366366366366,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 336.336,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 366.366366366366,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 336.336336336336,
          op: 366.366366366366,
          st: 151.151151151151,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 335.335,
                  s: [
                    100
                  ]
                },
                {
                  t: 354.354354354354,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 324.324,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 354.354354354354,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 324.324324324324,
          op: 354.354354354354,
          st: 139.139139139139,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 323.323,
                  s: [
                    100
                  ]
                },
                {
                  t: 342.342342342342,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 312.312,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 342.342342342342,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 312.312312312312,
          op: 342.342342342342,
          st: 127.127127127127,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 311.311,
                  s: [
                    100
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 300.3,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 330.33033033033,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 300.3003003003,
          op: 330.33033033033,
          st: 115.115115115115,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 299.299,
                  s: [
                    100
                  ]
                },
                {
                  t: 318.318318318318,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 288.288,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 318.318318318318,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 288.288288288288,
          op: 318.318318318318,
          st: 103.103103103103,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 287.287,
                  s: [
                    100
                  ]
                },
                {
                  t: 306.306306306306,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 276.276,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 306.306306306306,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 276.276276276276,
          op: 306.306306306306,
          st: 91.0910910910911,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 275.275,
                  s: [
                    100
                  ]
                },
                {
                  t: 294.294294294294,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 264.264,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 294.294294294294,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 264.264264264264,
          op: 294.294294294294,
          st: 79.0790790790791,
          bm: 0
        },
        {
          ddd: 0,
          ind: 11,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 263.263,
                  s: [
                    100
                  ]
                },
                {
                  t: 282.282282282282,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 252.252,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 282.282282282282,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 252.252252252252,
          op: 282.282282282282,
          st: 67.0670670670671,
          bm: 0
        },
        {
          ddd: 0,
          ind: 12,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 251.251,
                  s: [
                    100
                  ]
                },
                {
                  t: 270.27027027027,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 240.24,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 270.27027027027,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 240.24024024024,
          op: 270.27027027027,
          st: 55.055055055055,
          bm: 0
        },
        {
          ddd: 0,
          ind: 13,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 239.239,
                  s: [
                    100
                  ]
                },
                {
                  t: 258.258258258258,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 228.228,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 258.258258258258,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 228.228228228228,
          op: 258.258258258258,
          st: 43.043043043043,
          bm: 0
        },
        {
          ddd: 0,
          ind: 14,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 227.227,
                  s: [
                    100
                  ]
                },
                {
                  t: 246.246246246246,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 216.216,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 246.246246246246,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 216.216216216216,
          op: 246.246246246246,
          st: 31.031031031031,
          bm: 0
        },
        {
          ddd: 0,
          ind: 15,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 215.215,
                  s: [
                    100
                  ]
                },
                {
                  t: 234.234234234234,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 204.204,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 234.234234234234,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 204.204204204204,
          op: 234.234234234234,
          st: 19.019019019019,
          bm: 0
        },
        {
          ddd: 0,
          ind: 16,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 203.203,
                  s: [
                    100
                  ]
                },
                {
                  t: 222.222222222222,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 192.192,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 222.222222222222,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 192.192192192192,
          op: 222.222222222222,
          st: 7.00700700700701,
          bm: 0
        },
        {
          ddd: 0,
          ind: 17,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 191.191,
                  s: [
                    100
                  ]
                },
                {
                  t: 210.21021021021,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 180.18,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 210.21021021021,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 180.18018018018,
          op: 210.21021021021,
          st: -5.00500500500501,
          bm: 0
        },
        {
          ddd: 0,
          ind: 18,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 179.179,
                  s: [
                    100
                  ]
                },
                {
                  t: 198.198198198198,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 168.168,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 198.198198198198,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 168.168168168168,
          op: 198.198198198198,
          st: -17.017017017017,
          bm: 0
        },
        {
          ddd: 0,
          ind: 19,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 167.167,
                  s: [
                    100
                  ]
                },
                {
                  t: 186.186186186186,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 156.156,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 186.186186186186,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 156.156156156156,
          op: 186.186186186186,
          st: -29.029029029029,
          bm: 0
        },
        {
          ddd: 0,
          ind: 20,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 155.155,
                  s: [
                    100
                  ]
                },
                {
                  t: 174.174174174174,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 144.144,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 174.174174174174,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 144.144144144144,
          op: 174.174174174174,
          st: -41.041041041041,
          bm: 0
        },
        {
          ddd: 0,
          ind: 21,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 143.143,
                  s: [
                    100
                  ]
                },
                {
                  t: 162.162162162162,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 132.132,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 162.162162162162,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 132.132132132132,
          op: 162.162162162162,
          st: -53.053053053053,
          bm: 0
        },
        {
          ddd: 0,
          ind: 22,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 131.131,
                  s: [
                    100
                  ]
                },
                {
                  t: 150.15015015015,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 120.12,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 150.15015015015,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 120.12012012012,
          op: 150.15015015015,
          st: -65.0650650650651,
          bm: 0
        },
        {
          ddd: 0,
          ind: 23,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 119.119,
                  s: [
                    100
                  ]
                },
                {
                  t: 138.138138138138,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 108.108,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 138.138138138138,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 108.108108108108,
          op: 138.138138138138,
          st: -77.0770770770771,
          bm: 0
        },
        {
          ddd: 0,
          ind: 24,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 107.107,
                  s: [
                    100
                  ]
                },
                {
                  t: 126.126126126126,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 96.096,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 126.126126126126,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 96.0960960960961,
          op: 126.126126126126,
          st: -89.0890890890891,
          bm: 0
        },
        {
          ddd: 0,
          ind: 25,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 95.095,
                  s: [
                    100
                  ]
                },
                {
                  t: 114.114114114114,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 84.084,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 114.114114114114,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 84.0840840840841,
          op: 114.114114114114,
          st: -101.101101101101,
          bm: 0
        },
        {
          ddd: 0,
          ind: 26,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 83.083,
                  s: [
                    100
                  ]
                },
                {
                  t: 102.102102102102,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 72.072,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 102.102102102102,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 72.0720720720721,
          op: 102.102102102102,
          st: -113.113113113113,
          bm: 0
        },
        {
          ddd: 0,
          ind: 27,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 71.071,
                  s: [
                    100
                  ]
                },
                {
                  t: 90.0900900900901,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 60.06,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 90.0900900900901,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 60.0600600600601,
          op: 90.0900900900901,
          st: -125.125125125125,
          bm: 0
        },
        {
          ddd: 0,
          ind: 28,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 59.059,
                  s: [
                    100
                  ]
                },
                {
                  t: 78.0780780780781,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 48.048,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 78.0780780780781,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 48.0480480480481,
          op: 78.0780780780781,
          st: -137.137137137137,
          bm: 0
        },
        {
          ddd: 0,
          ind: 29,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 47.047,
                  s: [
                    100
                  ]
                },
                {
                  t: 66.0660660660661,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 36.036,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 66.0660660660661,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 36.036036036036,
          op: 66.0660660660661,
          st: -149.149149149149,
          bm: 0
        },
        {
          ddd: 0,
          ind: 30,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 35.035,
                  s: [
                    100
                  ]
                },
                {
                  t: 54.0540540540541,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 24.024,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 54.0540540540541,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 24.024024024024,
          op: 54.0540540540541,
          st: -161.161161161161,
          bm: 0
        },
        {
          ddd: 0,
          ind: 31,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 23.023,
                  s: [
                    100
                  ]
                },
                {
                  t: 42.042042042042,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 12.012,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 42.042042042042,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 12.012012012012,
          op: 42.042042042042,
          st: -173.173173173173,
          bm: 0
        },
        {
          ddd: 0,
          ind: 32,
          ty: 2,
          nm: "Pulsewave.png",
          cl: "png",
          refId: "image_11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833
                    ],
                    y: [
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 11.011,
                  s: [
                    100
                  ]
                },
                {
                  t: 30.03003003003,
                  s: [
                    0
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 0,
              k: [
                1920,
                1080,
                0
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                355,
                355,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  t: 0,
                  s: [
                    49,
                    49,
                    100
                  ]
                },
                {
                  t: 30.03003003003,
                  s: [
                    115,
                    115,
                    100
                  ]
                }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 0,
          op: 30.03003003003,
          st: -185.185185185185,
          bm: 0
        }
      ]
    },
    {
      id: "comp_5",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "Group 25.png",
          cl: "png",
          refId: "image_12",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      1.008
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 25,
                  s: [
                    0
                  ]
                },
                {
                  t: 70,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.667,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 25,
                  s: [
                    465.367,
                    245.681,
                    0
                  ],
                  to: [
                    -23.196,
                    50.115,
                    0
                  ],
                  ti: [
                    23.196,
                    -50.115,
                    0
                  ]
                },
                {
                  t: 70,
                  s: [
                    326.189,
                    546.368,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                210,
                145,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 25,
          op: 465,
          st: 15,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "Group 26.png",
          cl: "png",
          refId: "image_13",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      1.003
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 22,
                  s: [
                    0
                  ]
                },
                {
                  t: 67,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.667,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 22,
                  s: [
                    247.082,
                    245.681,
                    0
                  ],
                  to: [
                    13.518,
                    103.444,
                    0
                  ],
                  ti: [
                    -13.518,
                    -103.444,
                    0
                  ]
                },
                {
                  t: 67,
                  s: [
                    328.189,
                    866.348,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                212,
                145,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 22,
          op: 462,
          st: 12,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "Group 26 Copy.png",
          cl: "png",
          refId: "image_14",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      1.008
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 19,
                  s: [
                    0
                  ]
                },
                {
                  t: 64,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.667,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 19,
                  s: [
                    552.052,
                    225.45,
                    0
                  ],
                  to: [
                    37.852,
                    159.721,
                    0
                  ],
                  ti: [
                    -37.852,
                    -159.721,
                    0
                  ]
                },
                {
                  t: 64,
                  s: [
                    779.165,
                    1183.775,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                209,
                143,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 19,
          op: 459,
          st: 9,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "shutterstock_2076502891.png",
          cl: "png",
          refId: "image_15",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      1.009
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 16,
                  s: [
                    0
                  ]
                },
                {
                  t: 61,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.667,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 16,
                  s: [
                    860.376,
                    245.681,
                    0
                  ],
                  to: [
                    -14.035,
                    50.04,
                    0
                  ],
                  ti: [
                    14.035,
                    -50.04,
                    0
                  ]
                },
                {
                  t: 61,
                  s: [
                    776.165,
                    545.92,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                212,
                145,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 16,
          op: 456,
          st: 6,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "Video Thumbnail Copy 6.png",
          cl: "png",
          refId: "image_16",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      1.001
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 13,
                  s: [
                    0
                  ]
                },
                {
                  t: 58,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.667,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 13,
                  s: [
                    208.804,
                    245.681,
                    0
                  ],
                  to: [
                    19.564,
                    156.524,
                    0
                  ],
                  ti: [
                    -19.564,
                    -156.524,
                    0
                  ]
                },
                {
                  t: 58,
                  s: [
                    326.189,
                    1184.827,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                210,
                145,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 13,
          op: 453,
          st: 3,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: "Video Thumbnail Copy 5.png",
          cl: "png",
          refId: "image_17",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      0.996
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 10,
                  s: [
                    0
                  ]
                },
                {
                  t: 55,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.667,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 10,
                  s: [
                    813.891,
                    245.681,
                    0
                  ],
                  to: [
                    -5.954,
                    103.036,
                    0
                  ],
                  ti: [
                    5.954,
                    -103.036,
                    0
                  ]
                },
                {
                  t: 55,
                  s: [
                    778.165,
                    863.9,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                210,
                145,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 10,
          op: 450,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: "Group 4.png",
          cl: "png",
          refId: "image_18",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0
                    ],
                    y: [
                      1.005
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 5,
                  s: [
                    0
                  ]
                },
                {
                  t: 30,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 5,
                  s: [
                    551,
                    344,
                    0
                  ],
                  to: [
                    0,
                    -10,
                    0
                  ],
                  ti: [
                    0,
                    10,
                    0
                  ]
                },
                {
                  t: 30,
                  s: [
                    551,
                    284,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                439,
                61,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 5,
          op: 450,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: "Screenshot 2023-03-27 at 3.43.59 PM.png",
          cl: "43 59 png",
          refId: "image_19",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.667
                    ],
                    y: [
                      1
                    ]
                  },
                  o: {
                    x: [
                      0.167
                    ],
                    y: [
                      0.167
                    ]
                  },
                  t: 0,
                  s: [
                    0
                  ]
                },
                {
                  t: 20,
                  s: [
                    100
                  ]
                }
              ],
              ix: 11
            },
            r: {
              a: 0,
              k: 0,
              ix: 10
            },
            p: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0,
                    y: 1
                  },
                  o: {
                    x: 0.167,
                    y: 0.167
                  },
                  t: 0,
                  s: [
                    553,
                    1007.384,
                    0
                  ],
                  to: [
                    0,
                    -34.564,
                    0
                  ],
                  ti: [
                    0,
                    34.564,
                    0
                  ]
                },
                {
                  t: 20,
                  s: [
                    553,
                    800,
                    0
                  ]
                }
              ],
              ix: 2,
              l: 2
            },
            a: {
              a: 0,
              k: [
                552,
                679,
                0
              ],
              ix: 1,
              l: 2
            },
            s: {
              a: 0,
              k: [
                100,
                100,
                100
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 0,
          op: 450,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "Media Library 3",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [
            657.5,
            700.5,
            0
          ],
          ix: 2,
          l: 2
        },
        a: {
          a: 0,
          k: [
            657.5,
            810,
            0
          ],
          ix: 1,
          l: 2
        },
        s: {
          a: 0,
          k: [
            100,
            100,
            100
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      w: 1315,
      h: 1620,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    }
  ],
  markers: []
}