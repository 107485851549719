import { useMediaQuery } from "@mui/material";
import { useSpring, useTransform } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import LayoutStyles from "../App.styles";
// images
import img_1 from "../assets/images/solar/img1.png";
import img_2 from "../assets/images/solar/img2.png";
import img_3 from "../assets/images/solar/img3.png";
import img_4 from "../assets/images/solar/img4.png";
import img_5 from "../assets/images/about/img_5.png";
import img_6 from "../assets/images/about/img_6.png";
import img_7 from "../assets/images/about/img_7.png";
import img_8 from "../assets/images/about/img_8.png";
import useOnScroll from "../hooks/useOnScroll";
import useWindowDimensions from "../hooks/useWindowDimensions";
import theme from "../theme";

const LandingSpread = ({
  containerRef,
  data,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  data: {
    headline: string;
  };
}) => {
  const targetRef = useRef<Array<HTMLDivElement | null>>([]);
  const [hookedYPostion, setHookedYPosition] = useState(0);
  const [offset, setOffset] = useState<
    {
      offsetTop: number;
      offsetHeight: number;
      offsetWidth: number;
      offsetLeft: number;
      offsetRight: number;
    }[]
  >([]);

  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const isMedScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const { scrollValue, scrollPosition } = useOnScroll({
    containerRef: containerRef,
  });

  const yDisplacementValues = [668, 590, 1093, 1169];
  const xDisplacementValues = [141, -191, 0, 11];
  const OFFSET = 0;

  const springScrollY = useSpring(scrollValue, {
    stiffness: 500,
    damping: 50,
  });
  const FIRST_ELEMENT_REFRENCE =
    yDisplacementValues[0] + offset[0]?.offsetHeight ?? 0;

  useEffect(() => {
    // console.log(screenHeight);
    if (targetRef.current && !isMedScreen) {
      let offsetArr = [];
      const { offsetTop: parentOffsetTop, offsetLeft: parentOffsetLeft } =
        targetRef.current[3] as any;
      for (let i = 0; i < 4; i++) {
        const {
          offsetHeight,
          offsetWidth,
          offsetLeft: tempLeft,
        } = targetRef.current[i] as any;

        offsetArr.push({
          offsetTop: parentOffsetTop - screenHeight * 0.55,
          offsetHeight,
          offsetWidth,
          offsetLeft: tempLeft + parentOffsetLeft,
          offsetRight:
            screenWidth - (tempLeft + parentOffsetLeft + offsetWidth),
        });
      }
      console.log('offset', offsetArr);
      setOffset(offsetArr);
    }
  }, [screenWidth, screenHeight, isMedScreen]);

  useEffect(() => {
    scrollValue.onChange((v) => setHookedYPosition(v));
  }, [scrollPosition, scrollValue]);

  const y0 = useTransform(
    springScrollY,
    [
      offset[0]?.offsetTop,
      offset[0]?.offsetTop + offset[0]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[0] + offset[0]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x0 = useTransform(
    springScrollY,
    [
      offset[0]?.offsetTop,
      offset[0]?.offsetTop + offset[0]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[0]?.offsetLeft - xDisplacementValues[0] < 0
        ? -offset[0]?.offsetLeft + 40
        : -xDisplacementValues[0],
    ]
  );

  const y1 = useTransform(
    springScrollY,
    [
      offset[1]?.offsetTop,
      offset[1]?.offsetTop + offset[1]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[1] + offset[1]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x1 = useTransform(
    springScrollY,
    [
      offset[1]?.offsetTop,
      offset[1]?.offsetTop + offset[1]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[1]?.offsetRight + xDisplacementValues[1] <= 0
        ? offset[1]?.offsetLeft -
          offset[1]?.offsetWidth -
          xDisplacementValues[1]
        : -xDisplacementValues[1],
    ]
  );

  const y2 = useTransform(
    springScrollY,
    [
      offset[2]?.offsetTop,
      offset[2]?.offsetTop + offset[2]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[2] + offset[2]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x2 = useTransform(
    springScrollY,
    [
      offset[2]?.offsetTop,
      offset[2]?.offsetTop + offset[2]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[2]?.offsetLeft - xDisplacementValues[2] < 0
        ? -offset[2]?.offsetLeft
        : -xDisplacementValues[2],
    ]
  );

  const y3 = useTransform(
    springScrollY,
    [
      offset[3]?.offsetTop,
      offset[3]?.offsetTop + offset[3]?.offsetHeight + OFFSET,
    ],
    [
      0,
      yDisplacementValues[3] + offset[3]?.offsetHeight - FIRST_ELEMENT_REFRENCE,
    ]
  );
  const x3 = useTransform(
    springScrollY,
    [
      offset[3]?.offsetTop,
      offset[3]?.offsetTop + offset[3]?.offsetHeight + OFFSET,
    ],
    [
      0,
      offset[3]?.offsetLeft - xDisplacementValues[3] < 0
        ? -offset[3]?.offsetLeft
        : -xDisplacementValues[3],
    ]
  );

  return (
    <LayoutStyles.SpreadAnimation.Container

      style={{
        height: 2898 - FIRST_ELEMENT_REFRENCE + 200,
        paddingTop: 36,
        backgroundColor: theme.palette.neutral.light,
      }}
    >
      <LayoutStyles.SpreadAnimation.InitialImageState
        style={{ height: 936, width: 1043 }}
        ref={(el) => (targetRef.current[3] = el)}
      >
        <LayoutStyles.LandingSpread.Image
          $top={"59px"}
          $left={"129px"}
          style={{
            height: 402,
            width: 516,
            y: y0,
            x: x0,
            backgroundImage: `url(${img_1})`,
            zIndex: 6,
          }}
          ref={(el) => (targetRef.current[0] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[0]?.offsetTop + offset[0]?.offsetHeight * 0.8
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: 516 + 61, top: 120, width: 434 }}
          >
            {"We value purpose"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "We believe the human experience and sense of purpose within an organization drive confidence, growth, and success."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.LandingSpread.Image>
        <LayoutStyles.LandingSpread.Image
          $bottom={"0px"}
          $right={"140px"}
          style={{
            height: 499,
            width: 495,
            y: y1,
            x: x1,
            backgroundImage: `url(${img_2})`,
            zIndex: 2,
          }}
          ref={(el) => (targetRef.current[1] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[1]?.offsetTop + yDisplacementValues[1] * 0.9
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: -63 - 434, top: 145, width: 434 }}
          >
            {"We center collaboration"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "With a sense of collective awareness and common vision, teams can achieve the unachievable—and individuals renew a sense of personal fulfillment and happiness."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.LandingSpread.Image>
        <LayoutStyles.LandingSpread.Image
          $bottom={"76px"}
          $left={"51px"}
          style={{
            height: 612,
            width: 459,
            y: y2,
            x: x2,
            backgroundImage: `url(${img_3})`,
            zIndex: 1,
          }}
          ref={(el) => (targetRef.current[2] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[2]?.offsetTop + yDisplacementValues[2] * 0.9
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: 121 + 459, top: 225, width: 434 }}
          >
            {"We believe in giving back"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "We’re convinced that being engaged in community influences our innovative and collaborative capacities, while making a difference."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.LandingSpread.Image>
        <LayoutStyles.LandingSpread.Image
          $bottom={"76px"}
          $right={"0px"}
          style={{
            height: 564,
            width: 433,
            y: y3,
            x: x3,
            backgroundImage: `url(${img_4})`,
          }}
          ref={(el) => (targetRef.current[3] = el)}
        >
          <LayoutStyles.SpreadAnimation.AboutText
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity:
                hookedYPostion >=
                offset[3]?.offsetTop + yDisplacementValues[3] * 0.9
                  ? 1
                  : 0,
            }}
            transition={{ duration: 0.2 }}
            style={{ left: -83 - 434, top: 210, width: 434 }}
          >
            {"We empower growth"}
            <LayoutStyles.ImageAndTextBox.Body style={{ marginTop: "20px" }}>
              {
                "With a sense of collective awareness and common vision, teams can achieve the unachievable—and individuals renew a sense of personal fulfillment and happiness."
              }
            </LayoutStyles.ImageAndTextBox.Body>
          </LayoutStyles.SpreadAnimation.AboutText>
        </LayoutStyles.LandingSpread.Image>
      </LayoutStyles.SpreadAnimation.InitialImageState>
    </LayoutStyles.SpreadAnimation.Container>
  );
};

export default LandingSpread;