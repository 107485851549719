import React, { useRef } from "react";
import LayoutStyles from "./App.styles";
import { useApp } from "./context/AppContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

//Components
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import Homepage from "./Pages/Homepage";
import SalesEnablement from "./Pages/SalesEnablement";
import SalesTraining from "./Pages/SalesTraining";
import Metaverse from "./Pages/Metaverse";
import Enterprise from "./Pages/Enterprise";
import TermsOfService from "./Pages/TermsOfService";
import Privacy from "./Pages/Privacy";
import ScrollToTop from "./hooks/scrollToTop";
import VideoModal from "./PageBlocks/VideoModal";
import ProductViewerModal from "./PageBlocks/ProductViewerModal";
import About from "./Pages/About";
import Resources from "./Pages/Resources";
import ScheduleModal from "./Common/ScheduleModal";
import BlogPost from "./Pages/BlogPost";
import Landing from "./Pages/Landing";
import Contact from "./Pages/Contact";
import FormSuccess from "./Pages/FormSuccess";

const Layout = () => {
  const {
    slideOpen,
    scrollingDisabled,
    showVideo,
    showProduct,
    showScheduler,
    videoURL,
    activeBlog
  } = useApp();
  const containerRef = useRef(null);

  return (
    <Router>
      <LayoutStyles.Container
        id="page-container"
        ref={containerRef}
        $slideOpen={slideOpen}
        style={{ overflowY: scrollingDisabled ? "hidden" : "auto" }}
      >
        <ScrollToTop />
        <Header />
        <LayoutStyles.PageContainer>
          <Routes>
            <Route
              path="/"
              element={<Homepage containerRef={containerRef} />}
            />
            <Route
              path="/about"
              element={<About containerRef={containerRef} />}
            />
            <Route path="/metaverse" element={<Metaverse />} />
            <Route
              path="/enterprise"
              element={<Enterprise containerRef={containerRef} />}
            />
            <Route path="/training" element={<SalesTraining />} />
            <Route path="/sales" element={<SalesEnablement />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/resources/:id" element={activeBlog ? <BlogPost /> : <></>} />
            <Route
              path="/landing"
              element={<Landing containerRef={containerRef} />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/success" element={<FormSuccess />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route
              path="*"
              element={<Homepage containerRef={containerRef} />}
            />
          </Routes>
        </LayoutStyles.PageContainer>
        <Footer />
        {showVideo && <VideoModal videoURL={videoURL} />}
        {showScheduler && <ScheduleModal />}
        {showProduct && <ProductViewerModal />}
      </LayoutStyles.Container>
    </Router>
  );
};

export default Layout;
