import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";
import img_7 from "./images/img_7.png";
import img_8 from "./images/img_8.png";

export default {
    v: "5.7.4",
    fr: 30,
    ip: 0,
    op: 75,
    w: 1382,
    h: 1335,
    nm: "Dark-Mode-Analytics",
    ddd: 0,
    assets: [
      {
        id: "image_0",
        w: 282,
        h: 282,
        u: "",
        p: img_0,
        e: 0
      },
      {
        id: "image_1",
        w: 90,
        h: 36,
        u: "",
        p: img_1,
        e: 0
      },
      {
        id: "image_2",
        w: 48,
        h: 24,
        u: "",
        p: img_2,
        e: 0
      },
      {
        id: "image_3",
        w: 228,
        h: 46,
        u: "",
        p: img_3,
        e: 0
      },
      {
        id: "image_4",
        w: 834,
        h: 498,
        u: "",
        p: img_4,
        e: 0
      },
      {
        id: "image_5",
        w: 506,
        h: 502,
        u: "",
        p: img_5,
        e: 0
      },
      {
        id: "image_6",
        w: 430,
        h: 360,
        u: "",
        p: img_6,
        e: 0
      },
      {
        id: "image_7",
        w: 1038,
        h: 148,
        u: "",
        p: img_7,
        e: 0
      },
      {
        id: "image_8",
        w: 1272,
        h: 1046,
        u: "",
        p: img_8,
        e: 0
      }
    ],
    fonts: {
      list: [
        {
          fName: "Gordita-Medium",
          fFamily: "Gordita",
          fStyle: "Medium",
          ascent: 81.409536132589
        }
      ]
    },
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 5,
        nm: "0",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.994
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 30,
                s: [
                  0
                ]
              },
              {
                t: 59.999804491992,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 30,
                s: [
                  991.44,
                  135,
                  0
                ],
                to: [
                  -20,
                  0,
                  0
                ],
                ti: [
                  20,
                  0,
                  0
                ]
              },
              {
                t: 59.999804491992,
                s: [
                  871.44,
                  135,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              1003.231,
              -22.845,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              27,
              27,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "0",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 29.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "124",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 30.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "302",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 31.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "487",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 32.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "503",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 33.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "734",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 34.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "943",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 35.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "1,005",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 36.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "1,109",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 37.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "1,234",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 38.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "1,532",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 39.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "1,756",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 40.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "1,938",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 41.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "2,006",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 42.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "2,134",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 43.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "2,134",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 44.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "2,326",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 45.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "2,533",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 46.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "2,764",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 47.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "2,968",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 48.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "3,123",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 49.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "3,334",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 50.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "3,732",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 51.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "3,956",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 52.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "4,236",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 53.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "4,485",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 54.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "4,664",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 55.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "4,975",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 56.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "5,145",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 57.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "5,312",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 58.999804491992
              },
              {
                s: {
                  sz: [
                    1993.25915527344,
                    359.70361328125
                  ],
                  ps: [
                    -1790.92590332031,
                    3031.07421875
                  ],
                  s: 309,
                  f: "Gordita-Medium",
                  t: "5,346",
                  j: 0,
                  tr: 20,
                  lh: 370.8,
                  ls: 0,
                  fc: [
                    1,
                    1,
                    1
                  ],
                  sc: [
                    0,
                    0,
                    0
                  ],
                  sw: 0.00999999977648,
                  of: false
                },
                t: 59.999804491992
              }
            ]
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 2
            }
          },
          a: []
        },
        ip: 30,
        op: 75,
        st: -8.00800800800801,
        bm: 0
      },
      {
        ddd: 0,
        ind: 2,
        ty: 2,
        nm: "Chart2.png",
        cl: "png",
        refId: "image_0",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1.001
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 30,
                s: [
                  0
                ]
              },
              {
                t: 50,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 0,
            k: [
              571,
              1117.5,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              137,
              237,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0.311,
                    0,
                    0.667
                  ],
                  y: [
                    0.849,
                    0.997,
                    1
                  ]
                },
                o: {
                  x: [
                    0.167,
                    0.167,
                    0.167
                  ],
                  y: [
                    0,
                    0.167,
                    0
                  ]
                },
                t: 30,
                s: [
                  100,
                  42,
                  100
                ]
              },
              {
                t: 50,
                s: [
                  100,
                  100,
                  100
                ]
              }
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 30,
        op: 75,
        st: -23,
        bm: 0
      },
      {
        ddd: 0,
        ind: 3,
        ty: 2,
        nm: "+55%.png",
        cl: "png",
        refId: "image_1",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 45,
                s: [
                  0
                ]
              },
              {
                t: 65,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 45,
                s: [
                  142.187,
                  1151.921,
                  0
                ],
                to: [
                  0,
                  -3.333,
                  0
                ],
                ti: [
                  0,
                  3.333,
                  0
                ]
              },
              {
                t: 65,
                s: [
                  142.187,
                  1131.921,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              -33,
              16,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 45,
        op: 75,
        st: 45,
        bm: 0
      },
      {
        ddd: 0,
        ind: 4,
        ty: 2,
        nm: "UpArrow.png",
        cl: "png",
        refId: "image_2",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 41,
                s: [
                  0
                ]
              },
              {
                t: 61,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 41,
                s: [
                  142.187,
                  1151.921,
                  0
                ],
                to: [
                  0,
                  -3.333,
                  0
                ],
                ti: [
                  0,
                  3.333,
                  0
                ]
              },
              {
                t: 61,
                s: [
                  142.187,
                  1131.921,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              24,
              12,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 41,
        op: 75,
        st: 41,
        bm: 0
      },
      {
        ddd: 0,
        ind: 5,
        ty: 2,
        nm: "Video plays.png",
        cl: "png",
        refId: "image_3",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1.002
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 30,
                s: [
                  0
                ]
              },
              {
                t: 50,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 30,
                s: [
                  268.967,
                  888.935,
                  0
                ],
                to: [
                  -6.667,
                  0,
                  0
                ],
                ti: [
                  6.667,
                  0,
                  0
                ]
              },
              {
                t: 50,
                s: [
                  228.967,
                  888.935,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              114,
              23,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 30,
        op: 75,
        st: -23,
        bm: 0
      },
      {
        ddd: 0,
        ind: 6,
        ty: 2,
        nm: "Group 15.png",
        cl: "png",
        refId: "image_4",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.989
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 15,
                s: [
                  0
                ]
              },
              {
                t: 40,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 15,
                s: [
                  620,
                  1019.5,
                  0
                ],
                to: [
                  -33.5,
                  0,
                  0
                ],
                ti: [
                  33.5,
                  0,
                  0
                ]
              },
              {
                t: 40,
                s: [
                  419,
                  1019.5,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              417,
              249,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 15,
        op: 75,
        st: 15,
        bm: 0
      },
      {
        ddd: 0,
        ind: 7,
        ty: 2,
        nm: "Donut Chart.png",
        cl: "png",
        refId: "image_5",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    1
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 10,
                s: [
                  0
                ]
              },
              {
                t: 30,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.999
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 10,
                s: [
                  -50
                ]
              },
              {
                t: 30,
                s: [
                  0
                ]
              }
            ],
            ix: 10
          },
          p: {
            a: 0,
            k: [
              477,
              681.5,
              0
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              253,
              251,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0,
                    0,
                    0.667
                  ],
                  y: [
                    1.002,
                    1.002,
                    1
                  ]
                },
                o: {
                  x: [
                    0.167,
                    0.167,
                    0.167
                  ],
                  y: [
                    0.167,
                    0.167,
                    0
                  ]
                },
                t: 10,
                s: [
                  72,
                  72,
                  100
                ]
              },
              {
                t: 30,
                s: [
                  100,
                  100,
                  100
                ]
              }
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 10,
        op: 75,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 8,
        ty: 2,
        nm: "ContentTypes.png",
        cl: "png",
        refId: "image_6",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.999
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 10,
                s: [
                  0
                ]
              },
              {
                t: 30,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 10,
                s: [
                  1053,
                  738.5,
                  0
                ],
                to: [
                  0,
                  -10.833,
                  0
                ],
                ti: [
                  0,
                  10.833,
                  0
                ]
              },
              {
                t: 30,
                s: [
                  1053,
                  673.5,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              215,
              180,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 10,
        op: 75,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 9,
        ty: 2,
        nm: "ContentViews.png",
        cl: "png",
        refId: "image_7",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.999
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 10,
                s: [
                  0
                ]
              },
              {
                t: 30,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 10,
                s: [
                  743,
                  256.5,
                  0
                ],
                to: [
                  0,
                  8.833,
                  0
                ],
                ti: [
                  0,
                  -8.833,
                  0
                ]
              },
              {
                t: 30,
                s: [
                  743,
                  309.5,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              519,
              74,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 0,
        op: 75,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 10,
        ty: 2,
        nm: "Group 16 Copy.png",
        cl: "png",
        refId: "image_8",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [
                    0
                  ],
                  y: [
                    0.991
                  ]
                },
                o: {
                  x: [
                    0.167
                  ],
                  y: [
                    0.167
                  ]
                },
                t: 0,
                s: [
                  0
                ]
              },
              {
                t: 25,
                s: [
                  100
                ]
              }
            ],
            ix: 11
          },
          r: {
            a: 0,
            k: 0,
            ix: 10
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1
                },
                o: {
                  x: 0.167,
                  y: 0.167
                },
                t: 0,
                s: [
                  745,
                  457.5,
                  0
                ],
                to: [
                  0,
                  23,
                  0
                ],
                ti: [
                  0,
                  -23,
                  0
                ]
              },
              {
                t: 25,
                s: [
                  745,
                  595.5,
                  0
                ]
              }
            ],
            ix: 2,
            l: 2
          },
          a: {
            a: 0,
            k: [
              636,
              523,
              0
            ],
            ix: 1,
            l: 2
          },
          s: {
            a: 0,
            k: [
              100,
              100,
              100
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        ip: 0,
        op: 75,
        st: 0,
        bm: 0
      }
    ],
    markers: [],
    chars: [
      {
        ch: "0",
        size: 309,
        style: "Medium",
        w: 76.4,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -11.481
                        ],
                        [
                          -6.143,
                          -7.755
                        ],
                        [
                          -9.769,
                          0
                        ],
                        [
                          -6.143,
                          7.755
                        ],
                        [
                          0,
                          11.581
                        ],
                        [
                          6.143,
                          7.654
                        ],
                        [
                          9.769,
                          0
                        ],
                        [
                          6.244,
                          -7.755
                        ]
                      ],
                      o: [
                        [
                          0,
                          11.581
                        ],
                        [
                          6.143,
                          7.654
                        ],
                        [
                          9.769,
                          0
                        ],
                        [
                          6.143,
                          -7.755
                        ],
                        [
                          0,
                          -11.481
                        ],
                        [
                          -6.143,
                          -7.755
                        ],
                        [
                          -9.769,
                          0
                        ],
                        [
                          -6.143,
                          7.755
                        ]
                      ],
                      v: [
                        [
                          5.338,
                          -39.478
                        ],
                        [
                          14.502,
                          -10.574
                        ],
                        [
                          38.37,
                          0.906
                        ],
                        [
                          62.338,
                          -10.675
                        ],
                        [
                          71.603,
                          -39.679
                        ],
                        [
                          62.439,
                          -68.381
                        ],
                        [
                          38.571,
                          -79.962
                        ],
                        [
                          14.603,
                          -68.28
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          -8.157
                        ],
                        [
                          3.726,
                          -5.539
                        ],
                        [
                          6.143,
                          0
                        ],
                        [
                          3.726,
                          5.64
                        ],
                        [
                          0,
                          8.157
                        ],
                        [
                          -3.625,
                          5.539
                        ],
                        [
                          -6.143,
                          0
                        ],
                        [
                          -3.726,
                          -5.64
                        ]
                      ],
                      o: [
                        [
                          0,
                          8.157
                        ],
                        [
                          -3.726,
                          5.539
                        ],
                        [
                          -6.143,
                          0
                        ],
                        [
                          -3.726,
                          -5.64
                        ],
                        [
                          0,
                          -8.157
                        ],
                        [
                          3.726,
                          -5.539
                        ],
                        [
                          6.143,
                          0
                        ],
                        [
                          3.726,
                          5.64
                        ]
                      ],
                      v: [
                        [
                          58.813,
                          -39.478
                        ],
                        [
                          53.275,
                          -18.832
                        ],
                        [
                          38.571,
                          -10.474
                        ],
                        [
                          23.767,
                          -18.933
                        ],
                        [
                          18.228,
                          -39.679
                        ],
                        [
                          23.666,
                          -60.223
                        ],
                        [
                          38.37,
                          -68.582
                        ],
                        [
                          53.174,
                          -60.123
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "0",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "1",
        size: 309,
        style: "Medium",
        w: 46.2,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          0.705,
                          -64.352
                        ],
                        [
                          6.445,
                          -54.886
                        ],
                        [
                          24.17,
                          -65.561
                        ],
                        [
                          24.17,
                          0
                        ],
                        [
                          36.557,
                          0
                        ],
                        [
                          36.557,
                          -78.854
                        ],
                        [
                          24.774,
                          -78.854
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "2",
        size: 309,
        style: "Medium",
        w: 65.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.921,
                          4.129
                        ],
                        [
                          0,
                          5.74
                        ],
                        [
                          5.035,
                          4.33
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          4.935,
                          -3.525
                        ],
                        [
                          1.611,
                          -5.74
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.553,
                          0
                        ],
                        [
                          0,
                          -7.553
                        ],
                        [
                          2.014,
                          -2.82
                        ],
                        [
                          5.338,
                          -4.532
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          6.949,
                          -5.74
                        ],
                        [
                          2.921,
                          -4.23
                        ],
                        [
                          0,
                          -7.05
                        ],
                        [
                          -5.035,
                          -4.431
                        ],
                        [
                          -6.747,
                          0
                        ],
                        [
                          -4.834,
                          3.525
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          3.122,
                          -8.258
                        ],
                        [
                          8.762,
                          0
                        ],
                        [
                          0,
                          3.525
                        ],
                        [
                          -2.014,
                          2.719
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          4.834,
                          0
                        ],
                        [
                          60.324,
                          0
                        ],
                        [
                          60.324,
                          -11.179
                        ],
                        [
                          22.357,
                          -11.179
                        ],
                        [
                          40.686,
                          -26.385
                        ],
                        [
                          55.49,
                          -41.19
                        ],
                        [
                          59.921,
                          -56.195
                        ],
                        [
                          52.368,
                          -73.315
                        ],
                        [
                          33.133,
                          -79.962
                        ],
                        [
                          15.61,
                          -74.725
                        ],
                        [
                          5.942,
                          -60.828
                        ],
                        [
                          17.02,
                          -56.195
                        ],
                        [
                          33.032,
                          -68.582
                        ],
                        [
                          47.131,
                          -55.289
                        ],
                        [
                          44.11,
                          -45.822
                        ],
                        [
                          33.032,
                          -34.845
                        ],
                        [
                          4.834,
                          -10.776
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "4",
        size: 309,
        style: "Medium",
        w: 71.2,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          40.686,
                          -78.854
                        ],
                        [
                          3.122,
                          -29.91
                        ],
                        [
                          3.122,
                          -17.422
                        ],
                        [
                          43.707,
                          -17.422
                        ],
                        [
                          43.707,
                          0
                        ],
                        [
                          55.893,
                          0
                        ],
                        [
                          55.893,
                          -17.422
                        ],
                        [
                          67.575,
                          -17.422
                        ],
                        [
                          67.575,
                          -28.601
                        ],
                        [
                          55.893,
                          -28.601
                        ],
                        [
                          55.893,
                          -78.854
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          43.707,
                          -64.856
                        ],
                        [
                          43.707,
                          -28.601
                        ],
                        [
                          15.811,
                          -28.601
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "4",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "3",
        size: 309,
        style: "Medium",
        w: 64.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          6.445
                        ],
                        [
                          9.064,
                          3.525
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.157
                        ],
                        [
                          4.733,
                          4.129
                        ],
                        [
                          8.057,
                          0
                        ],
                        [
                          3.625,
                          -10.474
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.05,
                          0
                        ],
                        [
                          0,
                          -6.445
                        ],
                        [
                          2.719,
                          -2.316
                        ],
                        [
                          4.028,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.719,
                          -2.417
                        ],
                        [
                          0,
                          -3.323
                        ],
                        [
                          2.921,
                          -2.417
                        ],
                        [
                          4.23,
                          0
                        ],
                        [
                          3.424,
                          7.755
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -13.696,
                          0
                        ],
                        [
                          -5.237,
                          4.431
                        ]
                      ],
                      o: [
                        [
                          0,
                          -8.862
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.956,
                          -3.827
                        ],
                        [
                          0,
                          -5.942
                        ],
                        [
                          -4.733,
                          -4.129
                        ],
                        [
                          -13.394,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          3.223,
                          -7.553
                        ],
                        [
                          8.862,
                          0
                        ],
                        [
                          0,
                          3.223
                        ],
                        [
                          -2.618,
                          2.316
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.33,
                          0
                        ],
                        [
                          2.82,
                          2.316
                        ],
                        [
                          0,
                          3.223
                        ],
                        [
                          -2.921,
                          2.316
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.733,
                          11.783
                        ],
                        [
                          8.157,
                          0
                        ],
                        [
                          5.338,
                          -4.532
                        ]
                      ],
                      v: [
                        [
                          59.619,
                          -22.156
                        ],
                        [
                          46.024,
                          -40.686
                        ],
                        [
                          46.024,
                          -40.787
                        ],
                        [
                          57.907,
                          -58.813
                        ],
                        [
                          50.757,
                          -73.819
                        ],
                        [
                          31.522,
                          -79.962
                        ],
                        [
                          5.136,
                          -62.238
                        ],
                        [
                          15.912,
                          -57.303
                        ],
                        [
                          31.32,
                          -68.683
                        ],
                        [
                          45.319,
                          -57.504
                        ],
                        [
                          41.29,
                          -49.146
                        ],
                        [
                          31.32,
                          -45.721
                        ],
                        [
                          23.566,
                          -45.721
                        ],
                        [
                          23.566,
                          -34.543
                        ],
                        [
                          32.025,
                          -34.543
                        ],
                        [
                          42.599,
                          -30.917
                        ],
                        [
                          46.829,
                          -22.357
                        ],
                        [
                          42.499,
                          -13.898
                        ],
                        [
                          31.824,
                          -10.373
                        ],
                        [
                          14.703,
                          -22.055
                        ],
                        [
                          4.028,
                          -16.818
                        ],
                        [
                          31.622,
                          0.906
                        ],
                        [
                          51.663,
                          -5.74
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "8",
        size: 309,
        style: "Medium",
        w: 67.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -8.157,
                          0
                        ],
                        [
                          -5.539,
                          4.129
                        ],
                        [
                          0,
                          6.848
                        ],
                        [
                          9.97,
                          3.625
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.459
                        ],
                        [
                          5.338,
                          3.827
                        ],
                        [
                          7.352,
                          0
                        ],
                        [
                          5.338,
                          -3.928
                        ],
                        [
                          0,
                          -6.445
                        ],
                        [
                          -8.057,
                          -2.316
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -9.366
                        ],
                        [
                          -5.539,
                          -4.129
                        ]
                      ],
                      o: [
                        [
                          8.258,
                          0
                        ],
                        [
                          5.539,
                          -4.23
                        ],
                        [
                          0,
                          -9.064
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.157,
                          -2.719
                        ],
                        [
                          0,
                          -6.546
                        ],
                        [
                          -5.338,
                          -3.928
                        ],
                        [
                          -7.15,
                          0
                        ],
                        [
                          -5.338,
                          3.928
                        ],
                        [
                          0,
                          8.459
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -10.071,
                          3.726
                        ],
                        [
                          0,
                          6.546
                        ],
                        [
                          5.539,
                          4.129
                        ]
                      ],
                      v: [
                        [
                          34.14,
                          0.906
                        ],
                        [
                          54.785,
                          -5.338
                        ],
                        [
                          63.144,
                          -21.954
                        ],
                        [
                          48.138,
                          -40.988
                        ],
                        [
                          48.138,
                          -41.089
                        ],
                        [
                          61.13,
                          -58.612
                        ],
                        [
                          53.073,
                          -74.121
                        ],
                        [
                          34.14,
                          -79.962
                        ],
                        [
                          15.308,
                          -74.121
                        ],
                        [
                          7.251,
                          -58.612
                        ],
                        [
                          20.343,
                          -41.089
                        ],
                        [
                          20.343,
                          -40.988
                        ],
                        [
                          5.237,
                          -21.451
                        ],
                        [
                          13.495,
                          -5.338
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          3.424
                        ],
                        [
                          -8.56,
                          0
                        ],
                        [
                          0,
                          -6.848
                        ],
                        [
                          8.459,
                          0
                        ],
                        [
                          2.921,
                          2.216
                        ]
                      ],
                      o: [
                        [
                          0,
                          -6.949
                        ],
                        [
                          8.459,
                          0
                        ],
                        [
                          0,
                          6.848
                        ],
                        [
                          -4.028,
                          0
                        ],
                        [
                          -2.82,
                          -2.316
                        ]
                      ],
                      v: [
                        [
                          19.537,
                          -57.404
                        ],
                        [
                          34.14,
                          -69.287
                        ],
                        [
                          48.843,
                          -57.404
                        ],
                        [
                          34.14,
                          -45.52
                        ],
                        [
                          23.767,
                          -48.843
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          3.525
                        ],
                        [
                          -3.122,
                          2.316
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -3.223,
                          -2.417
                        ],
                        [
                          0,
                          -3.525
                        ],
                        [
                          3.323,
                          -2.417
                        ],
                        [
                          4.633,
                          0
                        ],
                        [
                          3.223,
                          2.417
                        ]
                      ],
                      o: [
                        [
                          0,
                          -3.726
                        ],
                        [
                          3.122,
                          -2.316
                        ],
                        [
                          4.633,
                          0
                        ],
                        [
                          3.323,
                          2.316
                        ],
                        [
                          0,
                          3.625
                        ],
                        [
                          -3.223,
                          2.316
                        ],
                        [
                          -4.633,
                          0
                        ],
                        [
                          -3.122,
                          -2.417
                        ]
                      ],
                      v: [
                        [
                          17.624,
                          -22.458
                        ],
                        [
                          22.256,
                          -31.421
                        ],
                        [
                          34.14,
                          -34.946
                        ],
                        [
                          45.923,
                          -31.32
                        ],
                        [
                          50.858,
                          -22.458
                        ],
                        [
                          45.923,
                          -13.394
                        ],
                        [
                          34.14,
                          -9.869
                        ],
                        [
                          22.357,
                          -13.495
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "8",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "7",
        size: 309,
        style: "Medium",
        w: 61.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          2.921,
                          -78.854
                        ],
                        [
                          2.921,
                          -67.676
                        ],
                        [
                          45.016,
                          -67.676
                        ],
                        [
                          8.762,
                          0
                        ],
                        [
                          23.062,
                          0
                        ],
                        [
                          59.216,
                          -68.683
                        ],
                        [
                          59.216,
                          -78.854
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "7",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "7",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "5",
        size: 309,
        style: "Medium",
        w: 63.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -12.085,
                          0
                        ],
                        [
                          -5.539,
                          4.733
                        ],
                        [
                          0,
                          7.956
                        ],
                        [
                          4.733,
                          4.733
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          4.23,
                          -3.525
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -5.942,
                          0
                        ],
                        [
                          0,
                          -9.265
                        ],
                        [
                          3.122,
                          -2.618
                        ],
                        [
                          4.431,
                          0
                        ],
                        [
                          3.323,
                          7.654
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      o: [
                        [
                          8.057,
                          0
                        ],
                        [
                          5.539,
                          -4.733
                        ],
                        [
                          0,
                          -7.352
                        ],
                        [
                          -4.733,
                          -4.733
                        ],
                        [
                          -6.143,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.028,
                          -4.532
                        ],
                        [
                          8.762,
                          0
                        ],
                        [
                          0,
                          4.431
                        ],
                        [
                          -3.021,
                          2.618
                        ],
                        [
                          -7.553,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.834,
                          11.179
                        ]
                      ],
                      v: [
                        [
                          30.917,
                          0.906
                        ],
                        [
                          51.361,
                          -6.143
                        ],
                        [
                          59.72,
                          -25.177
                        ],
                        [
                          52.57,
                          -43.304
                        ],
                        [
                          33.737,
                          -50.455
                        ],
                        [
                          18.127,
                          -45.117
                        ],
                        [
                          21.249,
                          -67.676
                        ],
                        [
                          55.893,
                          -67.676
                        ],
                        [
                          55.893,
                          -78.854
                        ],
                        [
                          11.884,
                          -78.854
                        ],
                        [
                          5.942,
                          -37.262
                        ],
                        [
                          16.516,
                          -32.932
                        ],
                        [
                          31.421,
                          -39.679
                        ],
                        [
                          46.93,
                          -24.976
                        ],
                        [
                          42.297,
                          -14.401
                        ],
                        [
                          31.119,
                          -10.474
                        ],
                        [
                          14.804,
                          -21.954
                        ],
                        [
                          4.129,
                          -16.013
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "9",
        size: 309,
        style: "Medium",
        w: 68.6,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          4.834,
                          0
                        ],
                        [
                          5.438,
                          4.532
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.359,
                          0
                        ],
                        [
                          0,
                          22.357
                        ],
                        [
                          5.942,
                          5.942
                        ],
                        [
                          8.56,
                          0
                        ],
                        [
                          5.64,
                          -5.136
                        ],
                        [
                          0,
                          -7.956
                        ],
                        [
                          -4.935,
                          -4.733
                        ],
                        [
                          -7.654,
                          0
                        ],
                        [
                          -4.532,
                          6.345
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          6.949,
                          -5.64
                        ]
                      ],
                      o: [
                        [
                          -5.338,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          6.747,
                          5.841
                        ],
                        [
                          24.673,
                          0
                        ],
                        [
                          0,
                          -13.495
                        ],
                        [
                          -5.74,
                          -5.74
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          -5.539,
                          5.035
                        ],
                        [
                          0,
                          7.553
                        ],
                        [
                          4.935,
                          4.733
                        ],
                        [
                          7.755,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.705,
                          9.567
                        ],
                        [
                          -3.424,
                          2.719
                        ]
                      ],
                      v: [
                        [
                          28.601,
                          -9.869
                        ],
                        [
                          12.387,
                          -16.718
                        ],
                        [
                          5.942,
                          -7.755
                        ],
                        [
                          28.601,
                          1.007
                        ],
                        [
                          63.647,
                          -42.297
                        ],
                        [
                          54.785,
                          -71.402
                        ],
                        [
                          33.334,
                          -79.962
                        ],
                        [
                          12.991,
                          -72.308
                        ],
                        [
                          4.633,
                          -52.872
                        ],
                        [
                          12.085,
                          -34.341
                        ],
                        [
                          31.018,
                          -27.191
                        ],
                        [
                          51.361,
                          -37.262
                        ],
                        [
                          51.562,
                          -37.262
                        ],
                        [
                          40.988,
                          -13.998
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          4.431,
                          0
                        ],
                        [
                          0,
                          9.366
                        ],
                        [
                          -9.366,
                          0
                        ],
                        [
                          0,
                          -9.668
                        ],
                        [
                          3.323,
                          -2.921
                        ]
                      ],
                      o: [
                        [
                          -9.567,
                          0
                        ],
                        [
                          0,
                          -9.265
                        ],
                        [
                          9.668,
                          0
                        ],
                        [
                          0,
                          4.431
                        ],
                        [
                          -3.323,
                          2.82
                        ]
                      ],
                      v: [
                        [
                          33.536,
                          -37.967
                        ],
                        [
                          17.422,
                          -53.476
                        ],
                        [
                          33.536,
                          -68.683
                        ],
                        [
                          50.052,
                          -53.174
                        ],
                        [
                          45.117,
                          -42.197
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "9",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: ",",
        size: 309,
        style: "Medium",
        w: 30.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.057
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.237,
                          0
                        ],
                        [
                          0,
                          -4.935
                        ],
                        [
                          -4.129,
                          0
                        ],
                        [
                          -0.906,
                          0.403
                        ],
                        [
                          4.532,
                          -3.122
                        ]
                      ],
                      o: [
                        [
                          7.352,
                          -5.237
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -6.747
                        ],
                        [
                          -5.338,
                          0
                        ],
                        [
                          0,
                          4.733
                        ],
                        [
                          1.108,
                          0
                        ],
                        [
                          -2.115,
                          4.935
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      v: [
                        [
                          12.488,
                          16.315
                        ],
                        [
                          24.673,
                          -5.136
                        ],
                        [
                          24.673,
                          -5.64
                        ],
                        [
                          15.308,
                          -17.12
                        ],
                        [
                          6.244,
                          -8.359
                        ],
                        [
                          13.998,
                          -0.101
                        ],
                        [
                          17.02,
                          -0.705
                        ],
                        [
                          7.05,
                          11.481
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: ",",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: ",",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      },
      {
        ch: "6",
        size: 309,
        style: "Medium",
        w: 68.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -6.949,
                          0
                        ],
                        [
                          -2.316,
                          -1.611
                        ],
                        [
                          -1.913,
                          -1.511
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.956,
                          0
                        ],
                        [
                          0,
                          -22.256
                        ],
                        [
                          -5.942,
                          -5.942
                        ],
                        [
                          -8.56,
                          0
                        ],
                        [
                          -5.539,
                          4.935
                        ],
                        [
                          0,
                          7.755
                        ],
                        [
                          4.935,
                          4.733
                        ],
                        [
                          7.553,
                          0
                        ],
                        [
                          4.431,
                          -6.345
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.827,
                          5.338
                        ]
                      ],
                      o: [
                        [
                          4.431,
                          0
                        ],
                        [
                          1.208,
                          0.705
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.747,
                          -5.64
                        ],
                        [
                          -24.271,
                          0
                        ],
                        [
                          0,
                          13.495
                        ],
                        [
                          5.74,
                          5.74
                        ],
                        [
                          7.956,
                          0
                        ],
                        [
                          5.64,
                          -4.935
                        ],
                        [
                          0,
                          -7.553
                        ],
                        [
                          -4.935,
                          -4.733
                        ],
                        [
                          -7.956,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.504,
                          -7.855
                        ],
                        [
                          3.928,
                          -5.338
                        ]
                      ],
                      v: [
                        [
                          40.384,
                          -69.086
                        ],
                        [
                          51.462,
                          -65.762
                        ],
                        [
                          56.094,
                          -62.439
                        ],
                        [
                          62.338,
                          -71.503
                        ],
                        [
                          40.384,
                          -79.962
                        ],
                        [
                          5.338,
                          -36.658
                        ],
                        [
                          14.2,
                          -7.553
                        ],
                        [
                          35.651,
                          1.007
                        ],
                        [
                          55.893,
                          -6.445
                        ],
                        [
                          64.352,
                          -25.58
                        ],
                        [
                          56.9,
                          -44.009
                        ],
                        [
                          38.168,
                          -51.16
                        ],
                        [
                          17.725,
                          -41.19
                        ],
                        [
                          17.624,
                          -41.19
                        ],
                        [
                          24.069,
                          -61.029
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [
                          -4.431,
                          0
                        ],
                        [
                          -3.021,
                          -2.82
                        ],
                        [
                          0,
                          -4.532
                        ],
                        [
                          9.265,
                          0
                        ],
                        [
                          3.122,
                          2.719
                        ],
                        [
                          0,
                          4.633
                        ],
                        [
                          -3.323,
                          2.82
                        ]
                      ],
                      o: [
                        [
                          4.733,
                          0
                        ],
                        [
                          3.021,
                          2.719
                        ],
                        [
                          0,
                          8.862
                        ],
                        [
                          -4.733,
                          0
                        ],
                        [
                          -3.122,
                          -2.719
                        ],
                        [
                          0,
                          -4.33
                        ],
                        [
                          3.323,
                          -2.82
                        ]
                      ],
                      v: [
                        [
                          35.449,
                          -40.384
                        ],
                        [
                          47.031,
                          -36.154
                        ],
                        [
                          51.562,
                          -25.177
                        ],
                        [
                          35.449,
                          -10.272
                        ],
                        [
                          23.666,
                          -14.401
                        ],
                        [
                          18.933,
                          -25.479
                        ],
                        [
                          23.868,
                          -36.154
                        ]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                }
              ],
              nm: "6",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ]
        },
        fFamily: "Gordita"
      }
    ]
  }