import React, { useState } from "react";
import LayoutStyles from "../App.styles";
import closeImg from "../assets/images/Close.svg";
import { useApp } from "../context/AppContext";
import { Link } from "react-router-dom";
import ArrowButton from "../Common/Button/ArrowButton";
import { useTheme, useMediaQuery } from "@mui/material";

const FaqSection = ({ hideButton = false }: { hideButton?: boolean }) => {
  const { setCurrentPage } = useApp();
  return (
    <LayoutStyles.FaqSection.Container>
      <LayoutStyles.FaqSection.Title>
        Frequently Asked Questions
      </LayoutStyles.FaqSection.Title>
      <FaqBox />
      <FaqBox />
      <FaqBox />
      <FaqBox />
      <FaqBox />
      <div style={{ marginTop: 28 }} />
      {!hideButton && (
        <Link to="/resources" onClick={() => setCurrentPage("resources")}>
          <ArrowButton text={"See all our FAQs"} />
        </Link>
      )}
    </LayoutStyles.FaqSection.Container>
  );
};

const FaqBox = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  return (
    <LayoutStyles.FaqSection.Box
      style={{ height: 96 }}
      initial={{ height: 96 }}
      animate={{
        height: open ? "fit-content" : isSmallScreen ? "66px" : "96px",
      }}
      transition={{ duration: 0.3 }}
    >
      <LayoutStyles.FaqSection.ExpandButton
        $isSmallScreen={isSmallScreen}
        src={closeImg}
        initial={{ transform: "rotate(45deg)" }}
        animate={{ transform: open ? "rotate(45deg)" : "rotate(0deg)" }}
        transition={{ duration: 0.3 }}
        onClick={() => setOpen(!open)}
      />
      <LayoutStyles.FaqSection.Question onClick={() => setOpen(!open)}>
        What is a metaverse?
      </LayoutStyles.FaqSection.Question>
      <LayoutStyles.FaqSection.Answer>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.{" "}
      </LayoutStyles.FaqSection.Answer>
    </LayoutStyles.FaqSection.Box>
  );
};

export default FaqSection;
