import React, { useState, useRef } from "react";
import dataJson from "../data.json";
import LayoutStyles from "../App.styles";
import { useTheme, useMediaQuery } from "@mui/material";

import ImageText from "../PageBlocks/ImageText";
import DualTxtImg from "../PageBlocks/DualTxtImg";
import HeroImage from "../PageBlocks/HeroImage";
import TextFirstHero from "../PageBlocks/TextFirstHero";
import ReactPlayer from "react-player";
import VideoButton from "../Common/Button/VideoButton";

// Assets
import ECommerce from "../assets/images/SalesEnablement/ecommerce.png";
import VirtualRoom from "../assets/images/SalesEnablement/virtual_room.png";
import ContentGroup from '../assets/images/SalesEnablement/content_group.png';
import Library from '../assets/images/SalesEnablement/library.png';
import UserMgmtImg from '../assets/images/SalesEnablement/user_mgmt.png';
import AnalyticsAnim from '../assets/animations/sales/sales-analytics/sales-analytics';
import ContentMgmtAnim from '../assets/animations/sales/content-mgmt/content-mgmt';
import HeroAnimation from "../assets/animations/metaverse/hero/hero";

const SalesEnablement: React.FC = () => {
  const theme = useTheme();
  const videoRef = useRef<ReactPlayer | null>(null);
  const [playing, setPlaying] = useState(false);
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  return (
    <>
      <TextFirstHero textFirst data={dataJson.sales_enablement.hero} />
      <LayoutStyles.FlexboxRow
        $padding={isSmallScreen ? "0" : "0 85px"}
        $margin="45px 0 0 0"
        $flexDirection="column"
        $width="100%"
        style={{ maxWidth: '1300px', width: isSmallScreen ? '100%' : '85%', position: 'relative'}}
      >
        <div
          
          style={{
            pointerEvents: "auto",
            borderRadius: isSmallScreen ? "0" : "21px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
            paddingTop: `${9 / 16 * 100}%`,
            zIndex: '3',
            height: '100%',
            maxWidth: '1300px'
          }}
        >
          {!playing && (
            <div
            onClick={() => {
                if (!playing) setPlaying(true)
              }}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.42",
                display: "flex",
                bottom: '0',
                left: '0',
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                borderRadius: isSmallScreen ? "0" : "21px",
                cursor: "pointer",
                zIndex: "3"
              }}
            >
              <VideoButton text="See how it works" clickFunction={() => {}} />
            </div>
          )}
          <ReactPlayer
            controls
            loop
            autoPlay  
            muted
            className="react-player"
            ref={videoRef}
            width="100%"
            height="100%"
            onStart={() => setPlaying(true)}
            onEnded={() => setPlaying(false)}
            onPause={() => setPlaying(false)}
            style={{ backgroundColor: theme.palette.neutral.light }}
            playing={playing}
            playIcon={<VideoButton text="See how it works" />}
            url={"https://player.vimeo.com/video/812075102?h=8efd90630f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"}
          />
        </div>
        <LayoutStyles.VideoBlock.ColorBlock style={{zIndex: '1'}} $bgColor={"#000000"} />
      </LayoutStyles.FlexboxRow>
      <LayoutStyles.SectionContainer $backgroundColor={theme.palette.neutral.black} id="content_mgmt" $padding={isSmallScreen ? "53px 0 100px" : "165px 0"}>
        <ImageText
          lottieAnimation={ContentMgmtAnim}
          loopAnimation
          buttonType="animated"
          color={theme.palette.primary.main}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          sectionID="content_mgmt"
          imageSize={[isMediumScreen ? "auto" : 766, "100%"]}
          height={"100%"}
          data={dataJson.sales_enablement.content_mgmt}
          hideButton={false}
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isSmallScreen ? "60px 0 100px" : "165px 0"}
        id="distribution"
      >
        <ImageText
          data={dataJson.sales_enablement.distribution}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          hideButton
          sectionID="distribution"
          imageSize={[isMediumScreen ? "auto" : 796, "100%"]}
          image={ContentGroup}
          reverse
          color={"#F9F9F9"}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer $backgroundColor={theme.palette.neutral.light} id="library" $padding={isSmallScreen ? "60px 0 100px" : "165px 0"}>
        <ImageText
          data={dataJson.sales_enablement.library}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          height={"100%"}
          hideButton
          sectionID="library"
          image={Library}
          imageSize={[isMediumScreen ? "auto" : 782, "100%"]}
          color={theme.palette.primary.main}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $backgroundColor={theme.palette.neutral.black}
        $padding={isSmallScreen ? "60px 0 100px" : "165px 0"}
        id="analytics"
      >
        <ImageText
          data={dataJson.sales_enablement.analytics}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          sectionID="analytics"
          imageSize={[isMediumScreen ? "auto" : 814, "100%"]}
          lottieAnimation={AnalyticsAnim}
          reverse
          hideButton
          color={"#F9F9F9"}
          height={"100%"}
          altColor={true}
        />
      </LayoutStyles.SectionContainer>

      <LayoutStyles.SectionContainer id="user_mgmt" $padding={isSmallScreen ? "60px 0 100px" : "165px 0"}>
        <ImageText
        hideButton={false}
          data={dataJson.sales_enablement.user_management}
          height={"100%"}
          padding={!isMediumScreen ? "0 75px" : "unset"}
          sectionID="user_mgmt"
          color={theme.palette.primary.main}
          image={UserMgmtImg}
          imageSize={[isMediumScreen ? "auto" : 778, "100%"]}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        id="virtual-sales"
        $padding={
          isSmallScreen ? "100px 0" : isMediumScreen ? "150px 0" : "201px 75px"
        }
        $backgroundColor={theme.palette.neutral.light}
      >
        <DualTxtImg
          altColor={false}
          image1={VirtualRoom}
          image2={ECommerce}
          data1={dataJson.metaverse.dual_component.data1}
          data2={dataJson.metaverse.dual_component.data2}
        />
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default SalesEnablement;