import React from 'react';
import LayoutStyles from '../../App.styles';
import { Blog } from '../../context/AppContext';
import { formatDate } from '../../utils/data';


const BigBlogCard: React.FC<{data: Blog}> = ({data}) => {
    const { BlogCards } = LayoutStyles;
    return(
        <BlogCards.LargeCard>
            <BlogCards.LargeImg src={data.featuredImage} alt={data.featuredImageAltText} />
            <BlogCards.LargeInfo>
                <BlogCards.LargeHeadline>{data.name}</BlogCards.LargeHeadline>
                <LayoutStyles.BlogSwiper.Date>{formatDate(data.publishDate)}</LayoutStyles.BlogSwiper.Date>
            </BlogCards.LargeInfo>
        </BlogCards.LargeCard>
    )
}

export default BigBlogCard;